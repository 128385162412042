import React, { useEffect, useState } from 'react'
import { Form, Col } from 'antd'
import { Row } from '../../../Components/Row';
import Input from '../../../Components/Form/Input';
import Flex from '../../../Components/Flex';
import Button from '../../../Components/Form/Button';
import { CustomDatePicker } from '../../../Components/Form/CustomDatePicker';
import dayjs from 'dayjs';
import request from '../../../utils/request';
import { toast } from 'react-toastify';
import { CustomInputNumber } from '../../../Components/Form/CustomInputNumber';
import { useDispatch } from 'react-redux';
import { getNotification } from '../../AddModules/AddModulesSlice';

const Bill = ({ handlerecord, GetUnpaid, FormExternalClose, formReset }) => {

    const [selectedDates, setSelectedDates] = useState(dayjs().format('YYYY-MM-DD'));

    const [balanceAmt, setBalanceAmt] = useState("")

    const [disabless, setDisabless] = useState(false)

    const [formRecall, setFormRecall] = useState(0)
    const dispatch = useDispatch()

    useEffect(() => {
        getBalance()
    }, [])

    const getBalance = () => {
        setBalanceAmt(form.getFieldValue('customer_balance_amt'))
    }

    // ----- Define Form
    const [form] = Form.useForm();

    const onFinish = (values) => {

        const NewValue = {

            ...values,
            payment_date: values?.payment_date === null ? '' : dayjs(selectedDates).format('YYYY-MM-DD') ? dayjs(values?.payment_date).format('YYYY-MM-DD') : dayjs(values?.payment_date).format('YYYY-MM-DD'),
        }
        let NewValues = {
            customer_name: NewValue.customer_name,
            sale_confirmation_no: NewValue.sc_no,
            bill_amount: NewValue.customer_bill_amt,
            paid_amount: NewValue.customer_receive_amt,
            balance_amount: NewValue.customer_balance_amt,
            amount: NewValue.amount,
            sale_confirmation_date: NewValue.sale_date,
            payment_date: NewValue.payment_date,
            consignment: NewValue.consignment

        }
        PostUnpaid(NewValues)
    };

    const onReset = () => {
        const requiredFields = ['amount', 'payment_date'];
        form.resetFields(requiredFields);
        setFormRecall(formRecall + 1)
    };

    useEffect(() => {
        form.resetFields()
    }, [formReset])

    useEffect(() => {
        form.setFieldsValue(handlerecord)
    }, [handlerecord, formReset, formRecall])

    useEffect(() => {
        form.setFieldsValue({ consignment: handlerecord?.id })
    }, [handlerecord, formRecall, formReset])


    //==================Calculate the balance total====================================

    const handleAmount = (e) => {

        let Amt = handlerecord?.customer_balance_amt;

        let PayAmt = e;
        let PaidAmt = Amt - PayAmt

        const balanceAmt = parseFloat(form.getFieldValue('customer_balance_amt')) || 0;

        form.setFieldsValue({
            customer_balance_amt: PaidAmt
        })

        if (PaidAmt < 0) {
            form.setFieldsValue({
                Amt: "",
                customer_balance_amt: PaidAmt
            });
            setFormRecall(formRecall + 1)
            showToastMessage("Amount cannot be negative.", "error");
            setDisabless(true)
        }
        else if (PayAmt < 0) {
            form.setFieldsValue({
                amount: "",
                customer_balance_amt: PaidAmt
            });
            setFormRecall(formRecall + 1)
            showToastMessage("Please enter positive value.", "error");
            setDisabless(false)
        }
        else {
            form.setFieldsValue({
                customer_balance_amt: PaidAmt
            });
            setDisabless(false)
        }
    }

    const showToastMessage = (message, type) => {
        if (type === 'error') {
            toast.warn(message);
        } else {
            toast(message);
        }
    };

    //======================================================================
    // const paymentdate = form.resetFields(payment_date:'');

    const PostUnpaid = (values) => {
        request.post(`customerpay/add_payment/`, values)
            .then(function (response) {
                if (response.status === 200) {
                    if (FormExternalClose) {
                        FormExternalClose();
                        getBalance()
                    }
                    if (GetUnpaid) {
                        GetUnpaid();
                    }
                    dispatch(getNotification())
                    toast.success('Successfully Paid')
                }

                if (response.status === 226) {
                    toast.warning('This consignment is in invoice draft, confirm it')
                    dispatch(getNotification())
                }

            })
            .catch(function (error) {
                console.log(error)
            });
    }

    const Datechange = (value) => {
        setSelectedDates(value)
    }



    return (
        <Form
            labelCol={{
                span: 24,
            }}
            wrapperCol={{
                span: 24,
            }}
            initialValues={
                {
                    payment_date: dayjs(),
                }
            }
            form={form}
            onFinish={onFinish}
        >
            <Row space={[24, 24]}>
                <Col span={12} md={12}>
                    <Input disabled
                        label={"Customer Name"}
                        placeholder={"Customer Name"}
                        name={"customer_name"}
                    />
                </Col>

                <Col span={12} md={12}>
                    <Input disabled
                        label={"Bill No"}
                        placeholder={"Bill No"}
                        name={"sc_no"}
                    />
                </Col>
                <Col span={12} md={12}>
                    <Input disabled
                        label={"Bill Date"}
                        placeholder={"Bill Date"}
                        name={"sale_date"}
                    />
                </Col>
                <Col span={12} md={12}>
                    <Input disabled
                        label={"Bill Amount"}
                        placeholder={"Bill Amount"}
                        name={"customer_bill_amt"}
                    />

                </Col>
                <Col span={12} md={12}>
                    <CustomDatePicker label={"Payment Date"} name={"payment_date"} onChange={Datechange}
                        rules={[
                            {
                                required: true,
                                message: "Please Select a Payment Date!",
                            },

                        ]}
                    />

                </Col>
                <Col span={12} md={12}>
                    <Input disabled
                        label={"Received Amount"}
                        placeholder={"Received Amount"}
                        name={"customer_receive_amt"}
                    />
                </Col>
                <Col span={12} md={12}>
                    <CustomInputNumber
                        precision={2}
                        label={"Amount"}
                        placeholder={"Amount"}
                        name={"amount"}
                        rules={[
                            {
                                required: true,
                                message: "This is required field !",
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (parseFloat(value) <= 0) {
                                        return Promise.reject('Amount must be greater than 1');
                                    }
                                    return Promise.resolve();
                                },
                            }),
                        ]}
                        onChange={(e) => handleAmount(e)}
                    />
                </Col>

                <Col span={12} md={12}>
                    <CustomInputNumber
                        disabled
                        label={"Balance Amount"}
                        placeholder={"Balance Amount"}
                        name={"customer_balance_amt"}

                    />
                </Col>
                <Input name={'consignment'} display={'none'} />

            </Row>
            <Flex center={"true"} gap={"20px"} margin={"20px 0"}>
                <Button.Primary text={"Submit"} htmlType={"submit"} disabled={disabless} />
                <Button.Danger text={"Reset"} onClick={() => onReset()} />
            </Flex>
        </Form>
    )
}

export default Bill