import React, { useState } from 'react'
import { Table } from '../../../../Components/Table';
import { Overhid } from './Style';
import request from '../../../../utils/request';
import { useEffect } from 'react';

const PaymentDetailsModal = ({ dataid }) => {

    const [dataSource, setDataSource] = useState([])

    const GET_URL = 'customerpay/sale_confirmation_unpaidpay/'

    const sendData = {
        sale_confirm: dataid
    }

    useEffect(() => {
        GetPaymentDetails();
    }, [dataid])


    const GetPaymentDetails = () => {
        request.post(`${GET_URL}`, sendData)
            .then(function (response) {
                setDataSource(response.data)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const columns = [
        {
            title: 'Sl No',
            render: (item, value, index) => index + 1,
        },
        {
            title: 'Bill No',
            dataIndex: 'sale_confirmation',
        },
        {
            title: 'Bill Date',
            dataIndex: 'sale_confirmation_date',
        },
        {
            title: 'Bill Amount',
            dataIndex: 'bill_amount',
            render: (value, record) => `${value} ${record.currency}`,
        },
        {
            title: 'Paid Amount',
            dataIndex: 'amount',
            render: (value, record) => `${value} ${record.currency}`,
        },
        {
            title: 'Balance Amount',
            dataIndex: 'balance_amount',
            render: (value, record) => `${value} ${record.currency}`,
        },
        {
            title: 'Payment Date',
            dataIndex: 'payment_date',
        },

    ];

    return (
        <Overhid>
            <Table columns={columns} data={dataSource} />
        </Overhid>
    )
}

export default PaymentDetailsModal