import React from 'react'
import QuotationTable from './Partials/QuotationTable'
import { TopTitle } from '../../Components/Form/TopTitle'

const Quotation = () => {
    return (
        <div>
            <TopTitle Heading={'Quotation'} />
            <QuotationTable />
        </div>
    )
}

export default Quotation