import React, { useState } from 'react'
import { Table } from '../../../../Components/Table'
import Flex from '../../../../Components/Flex'
import Button from '../../../../Components/Form/Button'
import { useNavigate } from 'react-router-dom'
import { Modal } from '../../../../Components/Modal'
import Label from '../../../../Components/Form/Label'
import Input from '../../../../Components/Form/Input'
import { Tag } from 'antd'
import Bills from '../../../Bills/Partials/Bills'
import { selectCurrentRole, selectCurrentSuper } from '../../../Auth/authSlice'
import { useSelector } from 'react-redux'

const TableDetailsCustomer = ({ tableData, GetUser }) => {

    const nevigate = useNavigate();

    const [searchText, setSearchText] = useState([]);

    const [formReset, setFormReset] = useState(0);

    // ========== Roll hidden ===========

    const Role = useSelector(selectCurrentRole);

    const Super_r = useSelector(selectCurrentSuper);

    // ======  Modal Open ========
    const [isModalOpen, setIsModalOpen] = useState(false);

    // ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
        FormCancelRest();
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        FormCancelRest();
    };

    const handleSearch = (value) => {
        setSearchText(value);
    };
    const Paymodal = (record) => {
        setFormReset(formReset + 1);
        showModal();
        setModalTitle("");
        setModalContent(<Bills tableData={record} Handleclose={Handleclose} GetUser={GetUser}
            formResetBill={formReset}

        />);
    }

    const FormCancelRest = () => {
        setFormReset(formReset + 1);
    };

    const Handleclose = () => {
        handleOk()
    }

    const columns = [
        {
            title: 'S.No',
            render: (value, item, index) => index + 1,
        },
        {
            title: 'SC.No',
            dataIndex: 'sc_no',
            filteredValue: searchText ? [searchText] : null,
            onFilter: (value, record) => {
                return String(record.sc_no).includes(value.toLowerCase()) ||
                    String(record.sc_no).includes(value.toLowerCase());
            },
        },
        {
            title: 'Date',
            dataIndex: 'sale_date',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (_, record) => {
                if (record.factory_balance_amt > 0) {
                    return (
                        <>
                            {record.status === 'Sale Confirmation Pending' && (
                                record.draft_consign === true ?
                                    <Tag color="red">{`${record.status} (Draft)`}</Tag> :
                                    <Tag color="red">{record.status}</Tag>
                            )}
                            {record.status === 'Purchase Order Pending' && (
                                record.draft_purchase_consign === true ?
                                    <Tag color="red">{`${record.status} (Draft)`}</Tag> :
                                    <Tag color="red">{record.status}</Tag>
                            )}
                            {record.status === 'Invoice Pending' && (
                                record.draft_invoice_consign === true ?
                                    <Tag color="red">{`${record.status} (Draft)`}</Tag> :
                                    <Tag color="red">{record.status}</Tag>
                            )}
                            {record.status === 'Packing Pending' && (
                                record.draft_packing_consign === true ?
                                    <Tag color="red">{`${record.status} (Draft)`}</Tag> :
                                    <Tag color="red">{record.status}</Tag>
                            )}
                        </>
                    )
                }
                return <Tag color="green">{record.status}</Tag>;
            },
        },
        {
            title: 'Bill Amt',
            dataIndex: 'customer_bill_amt',
            render: (value) => `${value} USD `,
        },
        {
            title: "Action",

            render: (record, i) => {
                return (
                    <Flex spaceEvenly={"true"} gap={"10px"}>

                        {Role === 'Master1' || Role === 'Master2' ? (
                            record.customer_balance_amt != 0 ? (
                                <Button.Danger text="Pay" onClick={() => Paymodal(record)} />
                            ) : (
                                <Button.Primary text="Paid" />
                            )
                        ) : null}

                        {Super_r === true ? (
                            record.customer_balance_amt != 0 ? (
                                <Button.Danger text="Pay" onClick={() => Paymodal(record)} />
                            ) : (
                                <Button.Primary text="Paid" />
                            )
                        ) : null}

                        <Button.Primary text={"View"} onClick={() => nevigate(`/ViewTotalList/${record.id}`)} />


                    </Flex>
                )

            },
        },
    ]
    return (
        <div>
            <Flex end={true} centervertically={true}>
                <Label>Search by SC NO: &nbsp;</Label>
                <Input
                    placeholder="Search by SC NO"
                    value={searchText}
                    onSearch={handleSearch}
                    onChange={(e) => handleSearch(e.target.value)} />
            </Flex>
            <Table columns={columns} data={tableData} />
            <Modal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={1000} modalTitle={modalTitle} modalContent={modalContent} />

        </div>
    )
}

export default TableDetailsCustomer