import React from 'react'
import { AddItems } from './Partials/AddItems'
import { TopTitle } from '../../../Components/Form/TopTitle'
import { CustomCard } from '../../../Components/CustomCard'

export const AddProduct = () => {
  return (
    <div>
      <TopTitle Heading={'Add Product'} />

      <CustomCard width={'95%'}>
        <AddItems />
      </CustomCard>
    </div>
  )
}
