import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import request from "../../utils/request";

const initialState = {
    quotationprint: [],
}

export const getQuatationPrint = createAsyncThunk(
    "quatationreport/Get",
    async (id) => {
        try {
            const response = await request.get(`quotation/quotation_print_get/${id}/`);
            return response.data;
        }
        catch (error) {
            throw error;
        }
    }
);

const QuotationSlice = createSlice({
    name: 'quotationreports',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getQuatationPrint.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.quotationprint = action.payload;
            })
          
    }
})

export const selectGetQuatation = (state) => state.quotations.quotationprint


export const { reducer } = QuotationSlice;

export default QuotationSlice.reducer


