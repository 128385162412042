import { Col, Form } from 'antd';
import React, { useEffect } from 'react'
import { Row } from '../../../../Components/Row';
import Input from '../../../../Components/Form/Input';
import Button from '../../../../Components/Form/Button';
import Flex from '../../../../Components/Flex';
import { TextAreas } from '../../../../Components/Form/TextArea';
import request from '../../../../utils/request';
import { toast } from 'react-toastify';

const Addsppliers = ({  handleSupplier, SaleHeaderTrigger }) => {

    const [form] = Form.useForm();

    useEffect(() => {
        GetSupplierId()
    }, [handleSupplier])

    const URL = 'supplier/add_supplier/'

    const onFinish = (values) => {
        Addsupplier(values)
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

 

    useEffect(() => {
        form.resetFields()
    }, [SaleHeaderTrigger])

    const GetSupplierId = () => {
        request.get('supplier/supplier_invoice/')
            .then(response => {
                form.setFieldsValue({ invoice_no: response.data.supplier_invoice })
            })
            .catch(error => console.log(error, 'error'))
    }



    const Addsupplier = (values) => {
        request.post(`${URL}`, values)
            .then(function (response) {
                form.resetFields();
                if (response.status == 201) {
                    toast.success('Supplier details Added Successfully')
                    GetSupplierId()
                    if (handleSupplier) {
                        handleSupplier();
                    }
                }
                else {
                    toast.success('Supplier details Added Successfully')
                }
            })

            .catch(function (error) {
                if (error.response && error.response.status === 400) {
                    if (error.response.data) {
                        if (error.response.data.gstin) {
                            toast.warn(error.response.data.gstin[0]);
                        } else if (error.response.data.mobile_number) {
                            toast.warn(error.response.data.mobile_number[0]);
                        } else {
                            toast.error('Invalid input.');
                        }
                    } else {
                        toast.error('Invalid input.');
                    }
                }
            });
    }

    const onReset = () => {
        form.resetFields();

        if (GetSupplierId) {
            GetSupplierId()
        }
        if (handleSupplier) {
            handleSupplier();
        }
    }

    return (
        <div>
            <Form
                name='AddSupplier'
                form={form}
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                initialValues={{
                    bank_detail: false
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off">
                <Row space={[24, 24]}>

                    <Col span={24} md={12}>
                        <Input label={'Company Name'} placeholder={'Company Name'} name={'supplier_name'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Company Name!',
                                }
                            ]} />
                    </Col>

                    <Col span={24} md={12}>
                        <Input label={'Supplier ID'} disabled placeholder={'Supplier ID'}
                            name={'invoice_no'} />
                    </Col>

                    <Col span={24} md={12}>
                        <Input label={'Phone No'} placeholder={'Factory Phone Number'} name={'supplier_mobile'}

                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter Phone Number!',
                                }
                            ]} />
                    </Col>

                    <Col span={24} md={12}>
                        <Input label={'FAX number'} placeholder={'FAX number'} name={'fax'} />
                    </Col>

                    <Col span={24} md={12}>
                        <Input label={'Email id'} name={'email'} type="email" placeholder={"Email ID"} />
                    </Col>

                    <Col span={24} md={12}>
                        <Input label={'Contact Person'} placeholder={'Contact Person'}
                            name={'contact_person_name'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Contact Person Name!',
                                }
                            ]} />
                    </Col>

                    <Col span={24} md={12}>
                        <Input label={'Mobile number'} placeholder={'Contact Person Number'}

                            name={'mobile_number'}

                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter Phone Number!',
                                }
                            ]} />
                    </Col>

                    <Col span={24} md={12}>
                        <TextAreas label={'Address'} placeholder={'Address'} name={'address'}
                        />
                    </Col>
                </Row>
                <Flex center={'true'} gap={'20px'} margin={'20px 0px'}>
                    <Button.Primary text={'ADD'} htmlType={'submit'} />
                    <Button.Danger text={'RESET'} onClick={() => onReset()} />
                </Flex>
            </Form>
        </div>
    )
}

export default Addsppliers