import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import request from "../../utils/request";

const initialState = {
    customer: [],
    customerDetails: {},
    customerProfileDetails: [],
    customerStatus: 'idle',
    customerError: null,
}

export const getCustomer = createAsyncThunk(
    "Customer/Get",
    async () => {
        try {
            const response = await request.get('customer/add_customer/');
            return [...response.data];
        }
        catch (error) {
            throw error;
        }
    }
);

export const getSupplierDetails = createAsyncThunk(
    "SupplierDetails/Get",
    async (id) => {
        try {
            const response = await request.get(`customer/customer_profile/${id}`);
            return response.data
        }
        catch (error) {
            throw error;
        }
    }
);



const CustomerReducer = createSlice({
    name: 'Customer',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCustomer.pending, (state, action) => {
                state.customerStatus = 'loading'
            })
            .addCase(getCustomer.fulfilled, (state, action) => {
                state.customerStatus = 'succeeded'
                state.customer = action.payload
            })
            .addCase(getCustomer.rejected, (state, action) => {
                state.customerStatus = 'failed'
                state.customerError = action.payload
            })
            .addCase(getSupplierDetails.pending, (state, action) => {
                state.customerStatus = 'loading'
            })
            .addCase(getSupplierDetails.fulfilled, (state, action) => {
                state.customerStatus = 'succeeded'
                state.customerDetails = action.payload.customer_details
                state.customerProfileDetails = action.payload.customer_profile_details
            })
            .addCase(getSupplierDetails.rejected, (state, action) => {
                state.customerStatus = 'failed'
                state.customerError = action.payload
            })
    }
})

export const getAllCustomers = (state) => state.customers.customer
export const getAllCustomerDetails = (state) => state.customers.customerDetails
export const getAllCustomerProfileDetails = (state) => state.customers.customerProfileDetails
export const getCustomerError = (state) => state.customers.customerError
export const getCustomerStatus = (state) => state.customers.customerStatus

export const { reducer } = CustomerReducer

export default CustomerReducer.reducer
