import { Col, Form, Input as Inputs } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';
import { Row } from '../../../Components/Row';
import { CustomSelect } from '../../../Components/Form/CustomSelect';
import Input from '../../../Components/Form/Input';
import { Select } from '../../../Components/Form/Select';
import { TextAreas } from '../../../Components/Form/TextArea';
import { CustomDatePicker } from '../../../Components/Form/CustomDatePicker';
import { Modal } from '../../../Components/Modal';
import request from '../../../utils/request';
import AddingCustomers from '../../Customers/AddCustomers/Partials/AddCustomers';
import PaymentTearms from '../../AddModules/PaymentTearms';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCountries, getAllPaymentTerms, getCountry, getPaymentTerms } from '../../AddModules/AddModulesSlice';
import Country from '../../AddModules/Country';
import { toast } from 'react-toastify';


export const SalesFormHeader = ({ setAddNewPaymentTerms, setShipTo, choose, setChoose, handleship, setScnoData, setSelectedDate, setSelectedDates, setSelectedrevisedDates, setSelectedSale, trigger, setShipState }) => {
    const [dataSource, setDataSource] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);
    const [termsData, setTermsData] = useState([])
    const [contryData, setContryData] = useState([])
    const [countryget, setCountryget] = useState([])
    // const [choose, setChoose] = useState('');
    const [width, setWidth] = useState(0)
    const [triggers, setTriggers] = useState(0);
    const [payterms, setPayterms] = useState(0)

    const dispatch = useDispatch()

    const onViewRow = () => {
        setTriggers(triggers + 1)
        setWidth(800)
        setModalTitle("Add Customer");
        setModalContent(<AddingCustomers handleSalesCustomer={handleSalesCustomer} SaleHeaderTrigger={triggers} />);
        showModal();
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleSalesCustomer = () => {
        handleCancel();
        GetSaleCustomer();
    };

    useEffect(() => {
        if (trigger) {
            GetSaleCustomer();
        }
    }, [trigger]);

    useEffect(() => {
        GetSaleCustomer();
    }, []);

    const GetSaleCustomer = () => {
        request
            .get('sale_confirmation/get_detail_sale_confirmation/')
            .then(function (response) {
                setDataSource(response.data.customer);
                setScnoData(response.data)
            })
            .catch(function (error) {
                toast.error('Failed')
            });
    };


    //=====================Customer name ===================================

    const SaleCompanies = dataSource?.map((com) => ({ label: com.company_name, value: com.id }));


    const handleSelectCompany = (value) => {
        const SelectedCompanyDetails = dataSource?.find((mem) => mem.id === value);
        setSelectedSale(SelectedCompanyDetails);
    };

    const ShipBy = [
        { label: 'Sea', value: 'Sea' },
        { label: 'Air', value: 'Air' },
    ]

    const handleChoose = (value) => {
        handleship();
        setChoose(value)
    }
    //===================Date onChange fn======================//
    const handleOnChange = (date) => {  
        setSelectedDate(date);
    };

    const handleOnChanges = (date) => {
        setSelectedDates(date);
    };
    const handleOnChangeRevised = (date) => {
        setSelectedrevisedDates(date);
    };

    //==================Payment terms ===========================//

    const StorePayementres = useSelector(getAllPaymentTerms)

    useEffect(() => {
        dispatch(getPaymentTerms())
    }, [payterms])

    useEffect(() => {
        setTermsData(StorePayementres)
    }, [StorePayementres])

    const handleGetpayterms = () => {
        setPayterms(payterms + 1)
    }

    const handleSubmit = () => {
        handleOk()
    }

    const paymentOptions = termsData?.map((com) => ({ label: com.terms, value: com.terms }));

    const onViewpay = () => {
        setTriggers(triggers + 1)
        setWidth(500)
        setModalTitle("Add Payment");
        setModalContent(<PaymentTearms setAddNewPaymentTerms={setAddNewPaymentTerms} handleGetpayterms={handleGetpayterms}
            handleSubmit={handleSubmit}
            SaleHeaderTrigger={triggers} />);
        showModal();
    }
    //========================country get================================//

    const countryreponse = useSelector(getAllCountries)

    useEffect(() => {
        dispatch(getCountry())
    }, [countryget])

    useEffect(() => {
        setContryData(countryreponse)
    }, [countryreponse])

    const AddContry = contryData.map((com) => ({ label: com.country, value: com.country }));

    const handleGetCountry = () => {
        setCountryget(countryget + 1)
    }
    const onViewCounty = () => {
        setTriggers(triggers + 1)
        setWidth(500)
        setModalTitle("Add Country");
        setModalContent(<Country setShipTo={setShipTo} handleGetCountry={handleGetCountry} handleOk={handleOk} SaleHeaderTrigger={triggers} />);
        showModal();
    }
 

    return (
        <Fragment>
            <Row space={[24, 24]} >
                <Col span={24} md={12}>
                    <Row space={[24, 24]}>
                        <Col span={24} md={24}>
                            <CustomSelect options={SaleCompanies} onButtonClick={onViewRow}
                                showSearch={true} buttonLabel="CUSTOMER NAME"
                                onChange={handleSelectCompany} label={'CUSTOMER NAME'} name={'company_name'}
                                placeholder={'CUSTOMER NAME'}  rules={[
                                    {
                                      required: true,
                                      message: 'Please Fill Details!',
                                    },
                                  ]}/>
                        </Col>
                        <Col span={24} md={24}>
                            <TextAreas label="ADDRESS"
                                name="Address" placeholder="ADDRESS" rules={[
                                    {
                                      required: true,
                                      message: 'Please Fill Details!',
                                    },
                                  ]}/>
                        </Col>


                        <Col span={24} md={24}>
                            <Input label='CUSTOMER ID' disabled
                                name="customer_id" placeholder="CUSTOMER ID" />

                            <Input display={'none'} name="customer_obj" />
                        </Col>

                        <Col span={24} md={24}>
                            <Input label={'FAX NO'} placeholder={'FAX NO'} name={'fax'} rules={[
                                    {
                                      required: true,
                                      message: 'Please Fill Details!',
                                    },
                                  ]}/>
                        </Col>

                        <Col span={24} md={24}>
                            <Input label={'EMAIL ID'} type="email" name={'email'} placeholder={'EMAIL ID'} rules={[
                                    {
                                      required: true,
                                      message: 'Please Fill Details!',
                                    },
                                  ]} />
                        </Col>

                        <Col span={24} md={24}>
                            <Input label={'CONTACT PERSON'} placeholder={'CONTACT PERSON'} name={'contact_person'} rules={[
                                    {
                                      required: true,
                                      message: 'Please Fill Details!',
                                    },
                                  ]}
                            />
                        </Col>

                        <Col span={24} md={24}>
                            <Input label={'MOBILE NUMBER'} placeholder={'MOBILE NUMBER'} name={'mobile'} rules={[
                                    {
                                      required: true,
                                      message: 'Please Fill Details!',
                                    },
                                  ]}
                            />
                        </Col>

                        <Input label={'phone NUMBER'} placeholder={'Phone'} name={'phone_no'} display={'none'} />

                        <Col span={24} md={24}>
                            <CustomSelect
                                options={paymentOptions}
                                showSearch label={'PAYMENT TERMS'}
                                placeholder={'PAYMENT TERMS'} name={'payment_terms'}
                                onButtonClick={onViewpay}
                                buttonLabel={'Add'}
                                rules={[
                                    {
                                      required: true,
                                      message: 'Please Fill Details!',
                                    },
                                  ]} 
                            />
                        </Col>
                    </Row>
                </Col>

                <Col span={24} md={12}>
                    <Row space={[24, 24]}>
                        <Col span={24} md={24}>
                            <Input label={'SC NO'} disabled placeholder={'SC NO'} name={'sale_confirmation_no'} rules={[
                                    {
                                      required: true,
                                      message: 'Please Fill Details!',
                                    },
                                  ]}/>
                        </Col>
                        <Col span={24} md={24}>
                            <CustomDatePicker label={'SC DATE'} onChange={handleOnChange} name={'sale_confirmation_date'} rules={[
                                    {
                                      required: true,
                                      message: 'Please Fill Details!',
                                    },
                                  ]}/>
                        </Col>
                        <Col span={24} md={24}>
                            <CustomDatePicker label={'SHIPPING DATE'} onChange={handleOnChanges} name={'shipping_date'} rules={[
                                    {
                                      required: true,
                                      message: 'Please Fill Details!',
                                    },
                                  ]}
                            />
                        </Col>
                        <Col span={24} md={24}>
                            <CustomSelect label={'SHIP To'} name={'country'} showSearch={true}
                                onButtonClick={onViewCounty} options={AddContry}
                                buttonLabel="ADD COUNTRY"
                                placeholder={'SHIP To'}
                                rules={[
                                    {
                                      required: true,
                                      message: 'Please Fill Details!',
                                    },
                                  ]}
                            />
                        </Col>

                        <Col span={24} md={24}>
                            <Input showSearch label={'ORIGIN'} placeholder={'ORIGIN'} name={'origin'}
                            rules={[
                                {
                                  required: true,
                                  message: 'Please Fill Details!',
                                },
                              ]}
                            />
                        </Col>

                        <Col span={24} md={24}>
                            <Input showSearch label={'DELIVERY TERMS'} placeholder={'DELIVERY TERMS'}
                                name={'delivery_terms'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter terms!'
                                }
                            ]}
                            />
                        </Col>
                        <Col span={24} md={24}>
                            <Select label={'SHIP BY'} options={ShipBy} placeholder={'SHIP BY'}
                                name={'ship_by'} onChange={handleChoose}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Select a Ship By!'
                                }
                            ]} 
                            />
                        </Col>
                        {choose === 'Sea' && (
                            <Col span={24} md={24}>
                                <Input label={'CONTAINER'} placeholder={'Container'} name={'container'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter container!'
                                    }
                                ]} 
                                />
                            </Col>
                        )}
                        <Col span={24} md={24}>
                            <CustomDatePicker label={'REVISED DATE'} onChange={handleOnChangeRevised} name={'revised_date'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Select Revised date!'
                                }
                            ]} 
                            />
                        </Col>
                    </Row>
                </Col>

            </Row>
            <Modal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={width} modalTitle={modalTitle} modalContent={modalContent} />
        </Fragment>
    );
};
