import React from 'react'
import FinanceTableReport from './Partials/FinanceTableReport'

const Financereport = () => {
  return (
    <div>
        <FinanceTableReport/>
    </div>
  )
}

export default Financereport