import React from 'react'
import { PrintTableTitle } from '../../../../Components/Form/FormTitle'
import { TableHolder } from '../../../../Components/Styled'


const ParintTable = ({ componentRef, data, total }) => {

    return (
        <div ref={componentRef} style={{ margin: '10px' }}>
            <PrintTableTitle Title={'Invoice Reports'} />

            <TableHolder>
                <table>
                    <thead>
                        <tr>
                            <th>S.NO</th>
                            <th>Date</th>
                            <th>INVOICE NUMBER</th>
                            <th>CUSTOMER ID</th>
                            <th>SEALING DATE</th>
                            <th>GRAND TOTAL</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map((item, i) => (
                            <tr key={i}>
                                <td>{i + 1}</td>
                                <td>{new Date(item.invoice_date).toLocaleDateString('en-GB')}</td>
                                <td>{item.invoice_no}</td>
                                <td>{item.customer_id}</td>
                                <td>{item.sealing_date}</td>
                                <td>{item.grand_total}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </TableHolder>
            <br />
        </div>
    )
}

export default ParintTable