import React, { useEffect, useState } from 'react'
import TabDetails from './Partials/TabDetails';
import { useParams } from 'react-router';
import request from '../../utils/request';

const ViewTotalList = () => {

    const { id } = useParams()

    const [invoice, setInvoice] = useState({})
    const [sales, setSales] = useState({})
    const [purchase, setPurchase] = useState({})
    const [packing, setPacking] = useState({})
    const [salesImg, setSalesImg] = useState([])
    const [purchaseImg, setPurchaseImg] = useState([])
    const [invoiceImg, setInvoiceImg] = useState([])
    const [packingImg, setPackingImg] = useState([])

    const URLE = 'customer/customer_consignment_details'

    useEffect(() => {
        if (id) {
            GetViewReport();
        }
    }, [])

    const GetViewReport = () => {
        request.get(`${URLE}/${id}`)
            .then(function (response) {
                console.error(response.data);
                setInvoice(response.data?.invoice)
                setInvoiceImg(response.data?.invoice_table)
                setSales(response.data?.sale_confirmation)
                setSalesImg(response.data?.sale_table)
                setPurchase(response.data?.purchase_order)
                setPurchaseImg(response.data?.purchase_order_table)
                setPacking(response.data?.packing)
                setPackingImg(response.data?.packing_table)
            })
            .catch(function (error) {
                console.error(error);
            });
    }

    return (
        <div>
            <TabDetails id={id} invoice={invoice} alldtass={sales}
                purchase={purchase} salesImg={salesImg} purchaseImg={purchaseImg} invoiceImg={invoiceImg}
                packing={packing} packingImg={packingImg} />
        </div>
    )
}

export default ViewTotalList