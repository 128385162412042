import { Card, Col, Form } from 'antd'
import React, { Fragment } from 'react'
import { useState } from 'react'
import { Row } from '../../../Components/Row';
import Flex from '../../../Components/Flex';
import { useEffect } from 'react';
import Input from '../../../Components/Form/Input';
import { TextAreas } from '../../../Components/Form/TextArea';
import Label from '../../../Components/Form/Label';


export const PurchaseFormFooter = ({ setPayType, payType, BalanceOnChange, HandleCheQueChage, TotalBalance, setRoundDecimalValue, RoundOffChecked, round, footerCalData, setSaleorder, tableSecondaryData }) => {

    const [disRoundOff, setDisRoundOff] = useState(false)


    useEffect(() => {
        if (payType === 'Cheque') {
            setDisRoundOff(true)
        }
        else {
            setDisRoundOff(false)
        }
    }, [payType])



    return (
        <Fragment>
            <Row space={[24, 24]} >
                <Col lg={24} md={24} span={24}>
                    <Card>
                        <Row space={[24, 24]}>
                            <Col span={24} md={11}></Col>
                            <Col span={24} md={5}>
                                <Flex alignCenter={true}>
                                    {/* <Label style={{paddingTop:'30px'}}>Total&nbsp;:</Label>&nbsp;&nbsp;&nbsp; */}
                                    <Input label={'Total pcs'} disabled name={'total_pieces'} />
                                </Flex>
                            </Col>
                            {/* <Col span={24} md={4}>
                                <Input label={'Rate'} disabled name={'total_rate'} />
                            </Col> */}
                            <Col span={24} md={4}>
                                <Input label={'Total Amount'} disabled name={'total_all'} />
                            </Col>
                        </Row>
                    </Card>
                </Col>

                <Col lg={24} md={24} span={24}>
                    <Row space={[12, 12]} style={{ backgroundColor: 'white', padding: '15px', borderRadius: '6px' }}>
                    
                        {/* <Col span={24} md={9}></Col> */}
                        <Col span={24} md={12} >
                            <TextAreas label={'NOTES'} placeholder={'NOTES'}
                                name={'notes'} />
                        </Col>

                        <Col span={24} md={12} >
                            <TextAreas label={'MARKS'} placeholder={'MARKS'}
                                name={'shipping_marks'} />
                        </Col>

                        <Col span={24} md={12} >
                            <TextAreas label={'REMARKS'} placeholder={'REMARKS'}
                                name={'remarks'} />
                        </Col>

                    </Row>
                </Col>
               
            </Row>

        </Fragment>
    )
}

