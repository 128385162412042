import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Row } from '../../../Components/Row'
import { Col } from 'antd'
import { AuthersiedBox, ProfileAddress, FooterTitle, PrintHolder, PrintViewTitle, PrintViewValue, ProfileHeader, ProfileSubTitle, SignImageHolder, TableHolder, PrintTableFooterHolder } from '../../../Components/Styled'
import Flex from '../../../Components/Flex'
import { useDispatch, useSelector } from 'react-redux'
import { getActivePro, getActiveProfile } from '../../Profile/ProfileSlice'
import { useReactToPrint } from 'react-to-print'
import { TableImageHolder } from '../../../Components/TableImageHolder'

export const InvoiceView = ({ record, invoiceImg }) => {

    const [viewData, setViewData] = useState({})

    const [viewDatas, setViewDatas] = useState([])

    const componentRef = useRef();

    const dispatch = useDispatch();

    const activePro = useSelector(getActivePro);

    useEffect(() => {
        dispatch(getActiveProfile());
    }, [])

    useEffect(() => {
        setViewData(record)
    }, [record])

    useEffect(() => {
        setViewDatas(invoiceImg)
    }, [invoiceImg])


    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })

    return (
        <Fragment>

            {/* <Flex margin={"20px"} gap={"10px"}>
                <Button.Primary text={<AiFillPrinter style={{ fontSize: '30px' }} />} onClick={handlePrint} />
            </Flex> */}

            <PrintHolder ref={componentRef}>
                {
                    record.draft && (
                        <div style={{ display: 'flex', justifyContent: 'end', marginTop: '10px', marginRight: '20px' }}>
                            <span style={{ borderBottom: '2px solid black', fontSize: '14px', fontWeight: 'bold' }}>DRAFT COPY</span>
                        </div>
                    )
                }
                <ProfileHeader>{activePro.business_name}</ProfileHeader>
                <ProfileAddress>{activePro.address}</ProfileAddress>
                <ProfileAddress>{activePro.phone_no} , Fax : {activePro.fax_number} , Email : {activePro.email}</ProfileAddress>
                <div style={{ textAlign: 'center' }}>
                    <ProfileSubTitle>Invoice</ProfileSubTitle>
                </div>

                <Row space={[8, 8]}>
                    <Col span={24} md={12}>
                        <Row space={[2, 2]}>
                            <Col span={24} sm={12} md={10}>
                                <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                    <PrintViewTitle>
                                        BILL TO
                                    </PrintViewTitle>
                                    <h1 style={{ alignSelf: 'baseline' }}>:</h1>
                                </Flex>
                            </Col>

                            <Col span={24} sm={12} md={14}>
                                <Flex alignEnd={'true'} H_100>
                                    <PrintViewValue>
                                        {viewData.company_name}<br />
                                        {viewData.Address}
                                    </PrintViewValue>
                                </Flex>
                            </Col>

                            {/* <Col span={24} sm={12} md={10}>
                                <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                    <PrintViewTitle>
                                        CUSTOMER ID
                                    </PrintViewTitle>
                                    <h1>:</h1>
                                </Flex>
                            </Col>

                            <Col span={24} sm={12} md={14}>
                                <Flex alignEnd={'true'} H_100>
                                    <PrintViewValue>
                                        {viewData.customer_id}
                                    </PrintViewValue>
                                </Flex>
                            </Col>
                            <Col span={24} sm={12} md={10}>
                                <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                    <PrintViewTitle>
                                        email&nbsp;
                                    </PrintViewTitle>
                                    <h1>:</h1>
                                </Flex>
                            </Col>

                            <Col span={24} sm={12} md={14}>
                                <Flex alignEnd={'true'} H_100>
                                    <PrintViewValue>
                                        {viewData.email}
                                    </PrintViewValue>
                                </Flex>
                            </Col> */}
                            <Col span={24} sm={12} md={10}>
                                <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                    <PrintViewTitle>
                                        CR No
                                    </PrintViewTitle>
                                    <h1>:</h1>
                                </Flex>
                            </Col>

                            <Col span={24} sm={12} md={14}>
                                <Flex alignEnd={'true'} H_100>
                                    <PrintViewValue>
                                        {viewData.cr_no}
                                    </PrintViewValue>
                                </Flex>
                            </Col>
                            <Col span={24} sm={12} md={10}>
                                <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                    <PrintViewTitle>
                                        IMPORT No
                                    </PrintViewTitle>
                                    <h1>:</h1>
                                </Flex>
                            </Col>

                            <Col span={24} sm={12} md={14}>
                                <Flex alignEnd={'true'} H_100>
                                    <PrintViewValue>
                                        {viewData.import_no}
                                    </PrintViewValue>
                                </Flex>
                            </Col>
                            <Col span={24} sm={12} md={10}>
                                <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                    <PrintViewTitle>
                                        VAT No
                                    </PrintViewTitle>
                                    <h1>:</h1>
                                </Flex>
                            </Col>
                            <Col span={24} sm={12} md={14}>
                                <Flex alignEnd={'true'} H_100>
                                    <PrintViewValue>
                                        {viewData.vat_no}
                                    </PrintViewValue>
                                </Flex>
                            </Col>
                            {/* 
                            <Col span={24} sm={12} md={10}>
                                <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                    <PrintViewTitle>
                                        contact person
                                    </PrintViewTitle>
                                    <h1>:</h1>
                                </Flex>
                            </Col>

                            <Col span={24} sm={12} md={14}>
                                <Flex alignEnd={'true'} H_100>
                                    <PrintViewValue>
                                        {viewData.contact_person}
                                    </PrintViewValue>
                                </Flex>
                            </Col>

                            <Col span={24} sm={12} md={10}>
                                <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                    <PrintViewTitle>
                                        mobile
                                    </PrintViewTitle>
                                    <h1>:</h1>
                                </Flex>
                            </Col>

                            <Col span={24} sm={12} md={14}>
                                <Flex alignEnd={'true'} H_100>
                                    <PrintViewValue>
                                        {viewData.mobile}
                                    </PrintViewValue>
                                </Flex>
                            </Col> */}
                            <Col span={24} sm={12} md={10}>
                                <Flex spacebetween={'true'} alignEnd={'true'}>
                                    <PrintViewTitle>
                                        PAYMENT TERM
                                    </PrintViewTitle>
                                    <h1>:</h1>
                                </Flex>
                            </Col>

                            <Col span={24} sm={12} md={14}>
                                <Flex alignEnd={'true'} H_100>
                                    <PrintViewValue>
                                        {viewData.payment_term}
                                    </PrintViewValue>
                                </Flex>
                            </Col>
                            <Col span={24} sm={12} md={10}>
                                <Flex spacebetween={'true'} alignEnd={'true'}>
                                    <PrintViewTitle>
                                        DELIVERY TERM
                                    </PrintViewTitle>
                                    <h1>:</h1>
                                </Flex>
                            </Col>
                            <Col span={24} sm={12} md={14}>
                                <Flex alignEnd={'true'} H_100>
                                    <PrintViewValue>
                                        {viewData.delivery_terms}
                                    </PrintViewValue>
                                </Flex>
                            </Col>
                            <Col span={24} sm={12} md={10}>
                                <Flex spacebetween={'true'} alignEnd={'true'}>
                                    <PrintViewTitle>
                                        CONFIRM QNTY
                                    </PrintViewTitle>
                                    <h1>:</h1>
                                </Flex>
                            </Col>
                            <Col span={24} sm={12} md={14}>
                                <Flex alignEnd={'true'} H_100>
                                    <PrintViewValue>
                                        {viewData.confirm_quantity}
                                    </PrintViewValue>
                                </Flex>
                            </Col>


                        </Row>
                    </Col>

                    <Col span={24} md={12}>
                        <Row space={[2, 2]}>
                            <Col span={24} sm={12} md={10}>
                                <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                    <PrintViewTitle>
                                        INVOICE NO
                                    </PrintViewTitle>
                                    <h1>:</h1>
                                </Flex>
                            </Col>

                            <Col span={24} sm={12} md={14}>
                                <Flex alignEnd={'true'} H_100>
                                    <PrintViewValue>
                                        {viewData.invoice_no}
                                    </PrintViewValue>
                                </Flex>
                            </Col>
                            <Col span={24} sm={12} md={10}>
                                <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                    <PrintViewTitle>
                                        INVOICE DATE
                                    </PrintViewTitle>
                                    <h1>:</h1>
                                </Flex>
                            </Col>

                            <Col span={24} sm={12} md={14}>
                                <Flex alignEnd={'true'} H_100>
                                    <PrintViewValue>
                                        {viewData.invoice_date}
                                    </PrintViewValue>
                                </Flex>
                            </Col>
                            <Col span={24} sm={12} md={10}>
                                <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                    <PrintViewTitle>
                                        SAILING ON
                                    </PrintViewTitle>
                                    <h1>:</h1>
                                </Flex>
                            </Col>

                            <Col span={24} sm={12} md={14}>
                                <Flex alignEnd={'true'} H_100>
                                    <PrintViewValue>
                                        {viewData.sealing_date}
                                    </PrintViewValue>
                                </Flex>
                            </Col>
                            <Col span={24} sm={12} md={10}>
                                <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                    <PrintViewTitle>
                                        SHIP TO
                                    </PrintViewTitle>
                                    <h1>:</h1>
                                </Flex>
                            </Col>

                            <Col span={24} sm={12} md={14}>
                                <Flex alignEnd={'true'} H_100>
                                    <PrintViewValue>
                                        {viewData.ship_to}
                                    </PrintViewValue>
                                </Flex>
                            </Col>
                            <Col span={24} sm={12} md={10}>
                                <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                    <PrintViewTitle>
                                        SC NO
                                    </PrintViewTitle>
                                    <h1>:</h1>
                                </Flex>
                            </Col>

                            <Col span={24} sm={12} md={14}>
                                <Flex alignEnd={'true'} H_100>
                                    <PrintViewValue>
                                        {viewData.invoice_no}
                                    </PrintViewValue>
                                </Flex>
                            </Col>
                            <Col span={24} sm={12} md={10}>
                                <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                    <PrintViewTitle>
                                        VESSEL NAME
                                    </PrintViewTitle>
                                    <h1>:</h1>
                                </Flex>
                            </Col>

                            <Col span={24} sm={12} md={14}>
                                <Flex alignEnd={'true'} H_100>
                                    <PrintViewValue>
                                        {viewData.vessel_name}
                                    </PrintViewValue>
                                </Flex>
                            </Col>
                            <Col span={24} sm={12} md={10}>
                                <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                    <PrintViewTitle>
                                        BL No
                                    </PrintViewTitle>
                                    <h1>:</h1>
                                </Flex>
                            </Col>

                            <Col span={24} sm={12} md={14}>
                                <Flex alignEnd={'true'} H_100>
                                    <PrintViewValue>
                                        {viewData.bl_no}
                                    </PrintViewValue>
                                </Flex>
                            </Col>


                            <Col span={24} sm={12} md={10}>
                                <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                    <PrintViewTitle>
                                        CONTAINER & SEAL NO
                                    </PrintViewTitle>
                                    <h1>:</h1>
                                </Flex>
                            </Col>

                            <Col span={24} sm={12} md={14}>
                                <Flex alignEnd={'true'} H_100>
                                    <PrintViewValue>
                                        {viewData.container_seal_no}
                                    </PrintViewValue>
                                </Flex>
                            </Col>

                        </Row>
                    </Col>
                </Row>

                <TableHolder>
                    <table>
                        <thead>
                            <tr>
                                <th>SI.NO</th>
                                <th>PRODUCT</th>
                                <th>BARCODE</th>
                                <th>IMAGE</th>
                                <th>DESCRIPTION</th>
                                <th>QUANTITY</th>
                                <th>RATE ({viewData?.currency})</th>
                                <th>TOTAL ({viewData?.currency})</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                viewDatas?.map((item, index) => (
                                    <tr key={item.id}>
                                        <td>{index + 1}</td>
                                        <td>{item.product_name}</td>
                                        <td>{item.barcode}</td>
                                        <td>
                                            <TableImageHolder imageUrl={item.product_image} />
                                        </td>
                                        <td>{item.description}</td>
                                        <td>{item.quantity} {item.unit}</td>
                                        <td>{item.rate}</td>
                                        <td>{item.total_amt}</td>
                                    </tr>
                                ))
                            }

                        </tbody>
                    </table>
                </TableHolder>
                <PrintTableFooterHolder>
                    <Row space={[12, 12]}>
                        <Col span={24} md={12}>
                            <Row space={[2, 2]}>
                                <Col span={24} sm={12} md={12}>
                                    <Flex spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            TOTAL AMOUNT IN WORDS
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>
                                <Col span={24} sm={12} md={12}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            <h4 style={{ textTransform: 'uppercase', fontSize: '12px' }}>{viewData?.gt_inwords}</h4>
                                        </PrintViewValue>
                                    </Flex>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={18} md={12}></Col>
                        <Col span={18} md={12}></Col>
                        <Col span={24} md={12}>
                            <Flex flexEnd={'true'}>
                                <Row space={[2, 2]}>
                                    <Col span={24} sm={12} md={12}>
                                        <Flex spacebetween={'true'} alignEnd={'true'}>
                                            <PrintViewTitle>
                                                TOTAL QUANTITY
                                            </PrintViewTitle>
                                            <h1>:</h1>
                                        </Flex>
                                    </Col>
                                    <Col span={24} sm={12} md={12}>
                                        <Flex alignEnd={'true'} H_100>
                                            <PrintViewValue>
                                                {viewData?.total_quantity}
                                            </PrintViewValue>
                                        </Flex>
                                    </Col>
                                    <Col span={24} sm={12} md={12}>
                                        <Flex spacebetween={'true'} alignEnd={'true'}>
                                            <PrintViewTitle>
                                                SUB TOTAL
                                            </PrintViewTitle>
                                            <h1>:</h1>
                                        </Flex>
                                    </Col>

                                    <Col span={24} sm={12} md={12}>
                                        <Flex alignEnd={'true'} H_100>
                                            <PrintViewValue>
                                                {viewData?.sub_total} {viewData?.currency}
                                            </PrintViewValue>
                                        </Flex>
                                    </Col>
                                    <Col span={24} sm={12} md={12}>
                                        <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                            <PrintViewTitle>
                                                LESS DISCOUNT
                                            </PrintViewTitle>
                                            <h1>:</h1>
                                        </Flex>
                                    </Col>

                                    <Col span={24} sm={12} md={12}>
                                        <Flex alignEnd={'true'} H_100>
                                            <PrintViewValue>
                                                {viewData?.discount_total}{viewData?.currency}
                                            </PrintViewValue>
                                        </Flex>
                                    </Col>
                                    <Col span={24} sm={12} md={12}>
                                        <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                            <PrintViewTitle>
                                                TOTAL AMOUNT
                                            </PrintViewTitle>
                                            <h1>:</h1>
                                        </Flex>
                                    </Col>

                                    <Col span={24} sm={12} md={12}>
                                        <Flex alignEnd={'true'} H_100>
                                            <PrintViewValue>
                                                {viewData?.grand_total} {viewData?.currency}
                                            </PrintViewValue>
                                        </Flex>
                                    </Col>
                                </Row>
                            </Flex>
                        </Col>
                    </Row>
                    <Row space={[12, 12]}>
                        <Col span={18} >
                            <Row space={[2, 2]}>
                                <Col span={24} sm={12} md={6}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            SHIPPING MARKS
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>
                                <Col span={24} sm={12} md={18}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            <pre>
                                                {viewData.shipping_mark}
                                            </pre>
                                        </PrintViewValue>
                                    </Flex>
                                </Col>
                                <Col span={24} sm={12} md={6}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            REMARKS
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={18}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            <pre>
                                                {viewData.remarks}
                                            </pre>
                                        </PrintViewValue>
                                    </Flex>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </PrintTableFooterHolder>

                <AuthersiedBox />
                <Flex W_100 spacebetween={'true'} alignEnd={'true'}>
                    <span>
                        CONFIRM & ACCEPTED BY
                    </span>
                    <span>
                        FOR AND ON BEHALF OF
                    </span>
                </Flex>

                <Flex flexEnd={'true'}>
                    <SignImageHolder>
                        <img src={activePro.signature} alt="signature" />
                    </SignImageHolder>
                </Flex>

                <Flex W_100 spacebetween={'true'} alignEnd={'true'}>
                    <span>
                        {/* BRANCH ASFAR */}
                    </span>

                    <span>
                        {activePro.business_name}
                    </span>
                </Flex>
                <AuthersiedBox />
                <p style={{ fontWeight: '500', fontSize: '14px' }}>*Please be advised that it is our Corporate Policy that we will not transact any Business with any
                    Parties namely. Customers/Suppliers/Service Provider, having either Direct or Indirect links or
                    Business Dealings or Transactions .with any Individuals, Organizations, Businesses, Corporates or
                    whatsoever, operating in/with or Belonging to, any .one of the UN Sanctioned countries viz: Cuba,
                    Iran, Sudan, North Korea, Syria etc.</p>
            </PrintHolder>
        </Fragment>
    )
}
