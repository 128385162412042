import { Card, Col, Form, } from "antd"
import React, { Fragment, useEffect, useState } from "react"
import dayjs from 'dayjs'
import Flex from "../../../Components/Flex"
import Button from "../../../Components/Form/Button"
import { DeleteOutlined } from "@ant-design/icons"
import Input from "../../../Components/Form/Input"
import { CustomInputNumber } from "../../../Components/Form/CustomInputNumber"
import { DeleteButtonWrapper, Table } from "../../../Components/Table"
import { Select } from "../../../Components/Form/Select"
import { Row } from "../../../Components/Row"
import { Modal } from "../../../Components/Modal"
import { PurchaseFormHeader } from "./PurchaseFormHeader"
import request from "../../../utils/request"
import { toast } from "react-toastify"
import { CustomSelect } from "../../../Components/Form/CustomSelect"
import { PurchaseFormFooter } from "./PurchaseFormFooter"
import { useNavigate, useParams } from "react-router-dom"
import { AddItems } from "../../Products/Add Items/Partials/AddItems"
import AddUnitMeasurement from "../../AddModules/AddUnit"
import AddCurrency from "../../AddModules/AddCurrency"
import { numberToWords } from "../../../utils/numtowords"
import { TextAreas } from "../../../Components/Form/TextArea"


export const PurchaseEntryPage = ({ setSaleorder, getSaleorders }) => {

  const [count, setCount] = useState(1);

  const [width, setWidth] = useState(0)

  const [unit, setUnit] = useState([])

  const [productname, setProductname] = useState([])

  const [foosaleData, setFoosaleData] = useState([])

  const [currency, setCurrency] = useState([])

  const { id } = useParams()
  const { poNo } = useParams()
  const navigate = useNavigate()


  // ======  Modal Open ========
  const [isModalOpen, setIsModalOpen] = useState(false);

  // ======  Modal Title and Content ========
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const [invoiceNumber, setInvoiceNumber] = useState({})

  // ======  Selected Date ========
  const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [selectedDates, setSelectedDates] = useState(dayjs().format('YYYY-MM-DD'));
  const [selectedrevisedDates, setSelectedrevisedDates] = useState(dayjs().format('YYYY-MM-DD'));

  // ================  SalesFormFooter checked ==========
  const [round, setRound] = useState(false);
  const [roundDecimalValue, setRoundDecimalValue] = useState(null);
  const [balance, setBalance] = useState(false);

  // -----------------  Balance Checking ------------
  const [withDecimal, setWithDecimal] = useState(null);
  const [withOutDecimal, setWithOutDecimal] = useState(null);
  const [balanceChangeAmount, setBalanceChangeAmount] = useState(0);
  const [balanceChange, setBalanceChange] = useState(false);

  const [footermsg, setFootermsg] = useState();

  const [estiDetails, setEstiDetails] = useState([]);

  const [selectedSupplierDetails, setSelectedSupplierDetails] = useState({})

  const [payType, setPayType] = useState('Cash');

  const [ShipTo, setShipTo] = useState()
  const [addNewPaymentTerms, setAddNewPaymentTerms] = useState()

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [form] = Form.useForm();

  const [choose, setChoose] = useState('');

  // =========================  Modal Content Start  ===========================
  useEffect(() => {
    form.setFieldsValue({ fax: selectedSupplierDetails.fax })
    form.setFieldsValue({ email: selectedSupplierDetails.email })
    form.setFieldsValue({ company_name: selectedSupplierDetails.supplier_company })
    form.setFieldsValue({ address: selectedSupplierDetails.address })
    form.setFieldsValue({ contact_person_name: selectedSupplierDetails.contact_person_name })
    form.setFieldsValue({ mobile_number: selectedSupplierDetails.mobile_number })
    form.setFieldsValue({ supplier_mobile: selectedSupplierDetails.supplier_mobile })
    form.setFieldsValue({ supplier: selectedSupplierDetails.id })
    form.setFieldsValue({ supplieid: selectedSupplierDetails.invoice_no })
    form.setFieldsValue({ supplier_name: selectedSupplierDetails.supplier_name })

  }, [selectedSupplierDetails, form])

  const dateFormat = 'YYYY/MM/DD';
  const personDate = new Date(estiDetails?.revised_date);
  const personFormattedDate = dayjs(personDate).format(dateFormat);
  const partnerDate = estiDetails?.revised_date ? new Date(estiDetails.revised_date) : null;
  const partnerFormattedDate = partnerDate ? dayjs(partnerDate).format(dateFormat) : null;

  const dateFormats = 'YYYY/MM/DD';
  const personDates = new Date(estiDetails?.shipping_date);
  const personFormattedDates = dayjs(personDates).format(dateFormats);
  const partnerDates = estiDetails?.shipping_date ? new Date(estiDetails.shipping_date) : null;
  const partnerFormattedDates = partnerDates ? dayjs(partnerDates).format(dateFormats) : null;

  useEffect(() => {
    form.setFieldsValue({
      shipping_date: dayjs(personFormattedDates, dateFormats),
      shipping_date: partnerFormattedDates ? dayjs(partnerFormattedDates, dateFormats) : null
    });
    form.setFieldsValue({ country: estiDetails.country })
    form.setFieldsValue({ origin: estiDetails.origin })
    form.setFieldsValue({ delivery_terms: estiDetails.delivery_terms })
    form.setFieldsValue({ ship_by: estiDetails.ship_by })
    form.setFieldsValue({ container: estiDetails.container })
    form.setFieldsValue({ revised_date: estiDetails.revised_date })
    form.setFieldsValue({ terms: estiDetails.payment_terms })
    form.setFieldsValue({ remarks: estiDetails.remarks })
    form.setFieldsValue({
      revised_date: dayjs(personFormattedDate, dateFormat),
      revised_date: partnerFormattedDate ? dayjs(partnerFormattedDate, dateFormat) : null
    });
    if (estiDetails.container) {
      setChoose(estiDetails.ship_by)
    }

  }, [estiDetails, form])

  useEffect(() => {
    form.setFieldsValue({ country: ShipTo })
  }, [ShipTo])

  useEffect(() => {
    form.setFieldsValue({ terms: addNewPaymentTerms })
  }, [addNewPaymentTerms])



  // -----------------------  RoundOff Checked Function ----------

  useEffect(() => {
    form.setFieldsValue({ total_pieces: foosaleData.total_pieces })

  }, [foosaleData])

  const TotalBalance = (value) => {
    setBalance(value)
    setWithDecimal(tableSecondaryData[0].total_amount - roundDecimalValue);
    setWithOutDecimal(tableSecondaryData[0].total_amount)
  }

  // =========================  Footer Cheque Hide  ===========================

  const HandleCheQueChage = (value) => {

    if (value === 'Cheque') {
      form.setFieldsValue({ paid: false })

      const totalAmt = tableSecondaryData[0].total_amount - roundDecimalValue;

      if (round) {
        form.setFieldsValue({ received: '0.00' })
        form.setFieldsValue({ balance: totalAmt });
        form.setFieldsValue({ roundoff_amount: totalAmt });
      }
      else {
        form.setFieldsValue({ received: '0.00' })
        form.setFieldsValue({ balance: tableSecondaryData[0].total_amount });
        form.setFieldsValue({ roundoff_amount: tableSecondaryData[0].total_amount });
      }
    }
  }

  // =========================  Other Functions start  =========================




  // ===================== Add Unit Select ====================

  const hadleUnitAdd = () => {
    setWidth(400)
    setModalTitle("Add Unit");
    setModalContent(<AddUnitMeasurement AddUnitGet={AddUnitGets} />);
    showModal();
  }

  const AddUnitGets = () => {
    if (AddUnitGet) {
      AddUnitGet()
      handleOk()
    }
  }
  useEffect(() => {
    AddUnitGet()
  }, [])

  const UNITADD = 'choice/add_measurement/'

  const AddUnitMeasure = unit.map((com) => ({ label: com.unit, value: com.unit }));

  const AddUnitGet = () => {
    request.get(`${UNITADD}`)
      .then(function (response) {
        setUnit(response.data)
      })
      .catch(function (error) {
      });
  }

  // ===================== Currency Name Select ====================

  const handleCurrency = () => {
    setWidth(400)
    setModalTitle("Add Currency");
    setModalContent(<AddCurrency AddCurrenynameGet={AddCurrencyData} />);
    showModal();
  };

  const AddCurrencyData = () => {
    if (AddCurrenynameGet) {
      AddCurrenynameGet();
      handleOk()
    }
  }

  const ADCURENCY = 'choice/add_currency/'

  const AddCuurencyName = currency?.map((com) => ({ label: com.rate, value: com.rate }));

  const AddCurrenynameGet = () => {
    request.get(`${ADCURENCY}`)
      .then(function (response) {
        setCurrency(response.data)
      })
      .catch(function (error) {
        // toast.error('Failed');
      });
  }
  useEffect(() => {
    AddCurrenynameGet()
  }, [])


  // =========================  Other Functions End  =========================



  useEffect(() => {
    if (id) {
      Getpatch()
    }
  }, [])

  const URLE = 'sale_confirmation/edit_sale_confirmation'

  useEffect(() => {
    if (id) {
      form.setFieldsValue({ id: estiDetails.sale_confirmation_no })
    }
  }, [estiDetails])


  const Getpatch = () => {
    request.get(`${URLE}/${id}/`)
      .then(function (response) {
        setTableData(response.data?.sale_table)
        setEstiDetails(response.data?.esti_quot)
      })
      .catch(function (error) {
        // toast.error('Failed')
      });
  }


  const initialData = [
    {
      key: 0,
      item: '',
      product_name: '',
      hsn_code: '',
      description: '',
      qty: '',
      product_image: '',
      item_unit: '',
      price_per_unit: '',
      price: '',
      sell: '',
      discount_percentage: '',
      discount_amt: '',
      tax_percentage: '',
      tax_amt: '',
      amount: '',
      total_rate: '',
      total_pieces: '',
      total_all: '',
      total_amt: '',
      net_weight: '',
      gross_weight: '',
      cbm: '',
    },
  ];

  const secondaryData = [
    {
      total_qty: '',
      total_discount: '',
      total_tax: '',
      total_amount: '',
      total_rate: '',
      total_pieces: '',
      total_all: '',
    },
  ];

  const footerCalData = [
    {
      roundoff: '',
      total_amount: '',
      total_rowamount: '',
      received: '',
      balance: '',
      total_rate: '',
      total_pieces: '',
      total_all: '',
    },
  ];


  const [tableData, setTableData] = useState(initialData);
  const [tableSecondaryData, setTableSecondaryData] = useState(secondaryData);
  const [tableFooterData, setTableFooterData] = useState(footerCalData);
  const [purchForm, setPurchForm] = useState({})

  const [trigger, setTrigger] = useState(0);

  // +++++++++++++++++++++   Use Effects Start +++++++++++++++++++

  const handleship = (value) => {
    form.setFieldsValue({ container: '' })
  }


  useEffect(() => {
    purchDraft()
  }, [])

  // ------------------  Dynamic Table  --------------------

  const purchDraft = () => {
    if (poNo) {
      request.get(`purchase_order/get_detail_purchase_order/${poNo}/`)
        .then(response => {

          setTableData(response.data.purchase_order_table)
          setPurchForm(response.data)
        })
        .catch(error => {
          // toast.error('Failed')
        })
    }
  }


  useEffect(() => {
    tableData.forEach(record => {

      form.setFieldsValue({ [`product_name${record.key}`]: record.product_name });
      {
        poNo ?
          form.setFieldsValue({ [`product_id${record.key}`]: record.product }) :
          form.setFieldsValue({ [`product_id${record.key}`]: record.product_id })
      }
      form.setFieldsValue({ [`product_image${record.key}`]: record.product_image });
      form.setFieldsValue({ [`description${record.key}`]: record.description });
      form.setFieldsValue({ [`barcode${record.key}`]: record.barcode });
      form.setFieldsValue({ [`item_unit${record.key}`]: record.item_unit });
      form.setFieldsValue({ [`price_per_unit${record.key}`]: record.price_per_unit });
      form.setFieldsValue({ [`quantity${record.key}`]: record.quantity });

      form.setFieldsValue({ [`rate${record.key}`]: record?.rate })

      form.setFieldsValue({ [`total_amt${record.key}`]: record.total_amt })
      form.setFieldsValue({ [`net_weight${record.key}`]: record.net_weight });
      form.setFieldsValue({ [`gross_weight${record.key}`]: record.gross_weight });
      form.setFieldsValue({ [`cbm${record.key}`]: record.cbm });


      form.setFieldsValue({ [`unit${record.key}`]: record.unit });
    });

    form.setFieldsValue({ [`total_pieces`]: tableSecondaryData[0].total_pieces });
    form.setFieldsValue({ [`total_rate`]: tableSecondaryData[0].total_rate });
    form.setFieldsValue({ [`total_all`]: tableSecondaryData[0].total_all });
    form.setFieldsValue({ [`amount_in_words`]: tableSecondaryData[0].amount_in_words });

    setWithOutDecimal(tableSecondaryData[0].total_amount);
  }, [tableData])


  useEffect(() => {
    if (poNo) {
      form.setFieldsValue(purchForm)
      form.setFieldsValue({ shipping_date: dayjs(purchForm.shipping_date, 'YYYY/MM/DD') })
      form.setFieldsValue({ revised_date: dayjs(purchForm.revised_date, 'YYYY/MM/DD') })
      form.setFieldsValue({ purchaseorderconfirmation_date: dayjs(purchForm.purchaseorderconfirmation_date, 'YYYY/MM/DD') })
      form.setFieldsValue({ id: parseFloat(purchForm.purchaseorderconfirmation_no) })
      form.setFieldsValue({ contact_person_name: purchForm.contact_person })
      form.setFieldsValue({ terms: purchForm.payment_terms })
      form.setFieldsValue({ rates: purchForm.currency })
      form.setFieldsValue({ supplier: purchForm.supplier });
      form.setFieldsValue({ supplieid: purchForm.supplieid });
      if (purchForm.container) {
        setChoose(purchForm.ship_by)
      }

    }
  }, [purchForm])

  // --------------------- Round Off Checked  -----------------
  useEffect(() => {
    const totalAmt = tableSecondaryData[0].total_amount - roundDecimalValue;
    if (round) {
      if (balance) {
        form.setFieldsValue({ round_off: roundDecimalValue });
        form.setFieldsValue({ roundoff_amount: totalAmt });
        form.setFieldsValue({ balance: 0 });
        form.setFieldsValue({ received: totalAmt });
        setBalanceChangeAmount(totalAmt);
      }
      else {
        form.setFieldsValue({ round_off: roundDecimalValue });
        form.setFieldsValue({ roundoff_amount: totalAmt });
        form.setFieldsValue({ balance: totalAmt });
        form.setFieldsValue({ received: 0 });
        setBalanceChangeAmount(0);
      }
    }
    else {
      if (balance) {
        form.setFieldsValue({ round_off: 0 });
        form.setFieldsValue({ roundoff_amount: tableSecondaryData[0].total_amount });
        form.setFieldsValue({ balance: 0 });
        form.setFieldsValue({ received: tableSecondaryData[0].total_amount });
        setBalanceChangeAmount(tableSecondaryData[0].total_amount);
      }
      else {
        form.setFieldsValue({ round_off: 0 });
        form.setFieldsValue({ roundoff_amount: tableSecondaryData[0].total_amount });
        form.setFieldsValue({ balance: tableSecondaryData[0].total_amount });
        form.setFieldsValue({ received: 0 });
        setBalanceChangeAmount(0);
      }
    }

  }, [round])

  //==============================Reverse =============================================
  useEffect(() => {

    const num = tableSecondaryData[0].total_amount;
    const newInteger = parseInt(num);
    const newDecimal = (num - newInteger).toFixed(2).substr(1);
    setRoundDecimalValue(newDecimal);

    setWithDecimal(tableSecondaryData[0].total_amount - newDecimal);
    const totalAmt = tableSecondaryData[0].total_amount - newDecimal;


    if (round) {
      if (balance) {
        form.setFieldsValue({ round_off: newDecimal });
        form.setFieldsValue({ roundoff_amount: totalAmt });
        form.setFieldsValue({ balance: 0 });
        form.setFieldsValue({ received: totalAmt });
      }
      else {
        form.setFieldsValue({ round_off: newDecimal });
        form.setFieldsValue({ roundoff_amount: totalAmt });
        form.setFieldsValue({ balance: totalAmt });
        form.setFieldsValue({ received: 0 });
      }
    }
    else {
      if (balance) {
        form.setFieldsValue({ round_off: 0 });
        form.setFieldsValue({ roundoff_amount: tableSecondaryData[0].total_amount });
        form.setFieldsValue({ balance: 0 });
        form.setFieldsValue({ received: tableSecondaryData[0].total_amount });
      }
      else {
        form.setFieldsValue({ round_off: 0 });
        form.setFieldsValue({ roundoff_amount: tableSecondaryData[0].total_amount });
        form.setFieldsValue({ balance: tableSecondaryData[0].total_amount });
        form.setFieldsValue({ received: 0 });
      }
    }

  }, [tableData])


  // ===========  CURRENCY MODAL SHOW  ==================


  const hadleProdctAdd = () => {
    setWidth(900)
    setModalTitle("Add Product");
    setModalContent(<AddItems AddProductnameGet={AddProductn} handleOkPurchse={handleOk} />);
    showModal();
  }

  const AddProductn = () => {

  }

  //==========================================================================
  const BalanceOnChange = (value) => {
    setBalanceChangeAmount(value)
  }

  useEffect(() => {

    if (round) {
      if (balance) {
        form.setFieldsValue({ received: withDecimal });
        form.setFieldsValue({ balance: 0 });
        setBalanceChangeAmount(withDecimal);
      }
      else {
        form.setFieldsValue({ received: 0 });
        form.setFieldsValue({ balance: withDecimal });
        setBalanceChangeAmount(0);

      }
    }
    else {
      if (balance) {
        form.setFieldsValue({ received: withOutDecimal });
        form.setFieldsValue({ balance: 0 });
        setBalanceChangeAmount(withOutDecimal);
      }
      else {
        form.setFieldsValue({ received: 0 });
        form.setFieldsValue({ balance: withOutDecimal });
        setBalanceChangeAmount(0);
      }
    }
  }, [balance])

  useEffect(() => {

    let fizedAmount = 0;

    if (round) {
      fizedAmount = withDecimal;

      if (balance) {
        form.setFieldsValue({ received: withDecimal });
        form.setFieldsValue({ balance: 0 });
        setBalanceChange(false);
      }
      else {
        // ===
        let setAmt = balanceChangeAmount;
        let balSetAmt = withDecimal - setAmt;

        if (balSetAmt < 0) {
          setBalanceChange(true);
        }
        else {
          setBalanceChange(false);
        }
        form.setFieldsValue({ received: setAmt });
        form.setFieldsValue({ balance: balSetAmt });
      }
    }
    else {
      fizedAmount = withOutDecimal;
      if (balance) {
        form.setFieldsValue({ received: withOutDecimal });
        form.setFieldsValue({ balance: 0 });
        setBalanceChange(false);
      }
      else {
        // ===
        let setAmt = balanceChangeAmount;
        let balSetAmt = withOutDecimal - setAmt;

        if (balSetAmt < 0) {
          setBalanceChange(true);
        }
        else {
          setBalanceChange(false);
        }

        form.setFieldsValue({ received: setAmt });
        form.setFieldsValue({ balance: balSetAmt });
      }
    }

  }, [balanceChangeAmount])




  // ===============  Hidden Table Data End ==================

  const columns = [
    {
      title: '#',
      render: (text, record, index) => {

        return (
          (
            <Flex alignCenter gap={'20px'} style={{ alignItems: 'center' }}>
              <h4>{index + 1}</h4>
              <DeleteButtonWrapper>
                <Button
                  style={{
                    display: 'none',
                    padding: '10px',
                    height: 'auto',
                    fontSize: '16px',
                  }}
                  htmlType="button"
                  danger
                  onClick={() => onDelete(record.key)}
                >
                  <DeleteOutlined />
                </Button>
              </DeleteButtonWrapper>
            </Flex>
          )
        );
      },
    },
    {
      title: 'PRODUCT NAME',
      children: [
        {
          title: <Button.Yellow text={'Add Product'} onClick={() => hadleProdctAdd()} />,
          dataIndex: 'product_name',
          key: 'product_name',
          render: (text, record) => (
            <>
              <Input
                disabled
                minWidth={'120px'}
                name={`product_name${record.key}`}
              />
              <Input name={`product_id${record.key}`} display={'none'} />
            </>
          )
        },
      ]
    },
    {
      title: 'IMAGE',
      dataIndex: 'product_image',
      render: (image, record) => <>
        <img src={`${image}`} style={{ width: 50, height: 50 }} />
        <Input name={`product_image${record.key}`} display={'none'} />
      </>,
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'description',
      render: (text, record) => (
        <TextAreas
          minWidth={'120px'}
          placed={'end'}
          name={`description${record.key}`}
          onChange={(value) => handleOnChangeDescription(value, record)}
        />
      )
    },
    {
      title: 'BARCODE',
      dataIndex: 'barcode',
      render: (text, record) => {
        return (
          <>
            <CustomInputNumber
              disabled
              minWidth={'120px'}
              name={`barcode${record.key}`}
            />
          </>
        )
      }
    },
    {
      title: 'QUANTITY',
      children: [
        {
          title: <Button.Yellow text={'Add Unit'} onClick={() => hadleUnitAdd()} />,
          render: (text, record) => (

            <>
              <CustomInputNumber name={`quantity${record.key}`}
                onChange={(value) => handleOnChangeQuantity(value, record)}
                rules={[
                  {
                    required: true,
                    message: 'This is a required field',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (parseFloat(value) <= 0) {
                        return Promise.reject('Quantity must be greater than 1');
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              />

              <Select
                rules={[
                  {
                    required: true,
                    message: 'This is a required field',
                  },
                ]}
                minWidth={'120px'}
                showSearch
                name={`unit${record.key}`}
                options={AddUnitMeasure}
                onChange={(value) => handleOnChangeUnit(value, record)}
              />
            </>
          )
        },
      ]
    },
    {
      title: 'RATE',
      dataIndex: 'rates',
      key: 'rates',
      children: [
        {
          title: <CustomSelect options={AddCuurencyName} placeholder={'CURRENCY'}
            buttonLabel={'ADD'} onButtonClick={handleCurrency} name={'rates'}
            rules={[
              {
                required: true,
                message: 'This is a required field',
              }
            ]} />,

          render: (text, record) => (
            <CustomInputNumber precision={2} rules={[
              {
                required: true,
                message: 'This is a required field'
              },
              {
                type: 'number',
                min: 0,
                message: 'value must be positive'
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (parseFloat(value) <= 0) {
                    return Promise.reject('Quantity must be greater than 1');
                  }
                  return Promise.resolve();
                },
              }),
            ]}
              name={`rate${record.key}`}
              onChange={(value) => handleOnChangeRate(value, record)}
              minWidth={'120px'} />
          )
        },
      ]
    },
    {
      title: 'TOTAL',
      children: [
        {
          title: <Input placeholder={'CURRENCY'} name={'rates'} disabled style={{ background: '#fff' }} />,
          dataIndex: 'total_amt',
          key: 'total_amt',
          render: (text, record) => (
            <CustomInputNumber precision={2} rules={[
              {
                required: true,
                message: 'This is a required field'
              },
            ]}
              name={`total_amt${record.key}`}
              disabled
              minWidth={'120px'}
            />
          )
        }
      ]

    },
    {
      title: 'NET WEIGHT (KG)',
      dataIndex: 'net_weight',
      render: (text, record) => (
        <CustomInputNumber precision={2}
          minWidth={'120px'}
          min={0.00}
          placed={'end'}
          name={`net_weight${record.key}`}
          onChange={(value) => handleOnChangeNetWeight(value, record)}
        />
      )
    },
    {
      title: 'G.W (kg)',
      dataIndex: 'gross_weight',
      key: 'gross_weight',
      render: (text, record) => (
        <CustomInputNumber
          minWidth={'120px'}
          min={0.00}
          placed={'end'}
          name={`gross_weight${record.key}`}
          onChange={(value) => handleOnChangeGrossWeight(value, record)}
        />
      )
    },
    {
      title: 'CBM',
      dataIndex: 'cbm',
      key: 'cbm',
      render: (text, record) => (
        <CustomInputNumber precision={2}
          minWidth={'120px'}
          min={0.00}
          placed={'end'}
          name={`cbm${record.key}`}
          onChange={(value) => handleOnChangeCbm(value, record)}
        />
      )
    },
  ]


  // ----------------- Add Row Function 

  const AddRow = () => {
    const newData = {
      key: count,
      item: '',
      product_name: '',
      itemCode: '',
      hsn_code: '',
      description: '',
      qty: '',
      item_unit: '',
      price_per_unit: '',
      price: '',
      sell: '',
      discount_percentage: '',
      discount_amt: '',
      tax_percentage: '',
      tax_amt: '',
      amount: '',
      total_rate: '',
      total_pieces: '',
      total_all: '',
    };
    setTableData(pre => {
      return [...pre, newData]
    })
    setCount(count + 1);
  }

  // -----------------------  Delete Row Function

  const onDelete = (key) => {
    if (tableData.length > 1) {
      setTableData(prevState => {
        const newData = prevState.filter(item => item.key !== key);

        // ------ Variables 
        let totalQuantity = 0;
        let totalDiscount = 0;
        let totalTax = 0;
        let totalAmount = 0;

        newData.forEach(item => {
          if (item.qty !== '' || item.amount !== '' || item.discount_amt !== '' || item.tax_amt !== '') {
            totalQuantity += parseFloat(item.qty);
            totalDiscount += parseFloat(item.discount_amt);
            totalTax += parseFloat(item.tax_amt);
            totalAmount += parseFloat(item.amount);
          }
        });

        // update the total_amount value in the tableSecondaryData array
        setTableSecondaryData([{
          total_qty: totalQuantity.toFixed(2),
          total_discount: totalDiscount.toFixed(2),
          total_tax: totalTax.toFixed(2),
          total_amount: totalAmount.toFixed(2)
        }]);

        return newData;
      });
    } else {
      toast.error(`only ${tableData.length} row is available`)
    }
  };

  // ========================   Total Calculating Functions
  // ----------------- 1. Calculate TotalAmount 

  const CalculateTotal = (record) => {

    setTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];
      item.total = record.total || 0;
      item.quantity = record.quantity;
      item.rate = record.rate || 0;

      // ------ Variables 
      let totalQuantity = 0;
      let totalRate = 0;
      let totalAmount = 0;

      newData.forEach(item => {
        if (item.quantity !== '' || item.total !== '' || item.rate !== '') {
          totalQuantity += parseFloat(item.quantity);
          totalRate += parseFloat(item.rate) || 0;
          totalAmount += parseFloat(item.total_amt) || 0;
        }
      });

      const amountInWords = numberToWords(totalAmount);

      setTableSecondaryData([{
        total_pieces: totalQuantity.toFixed(2) || 0,
        total_rate: totalRate.toFixed(2) || 0,
        total_all: totalAmount.toFixed(2) || 0,
        amount_in_words: amountInWords,
      }]);

      return newData;
    })

  };

  // ============  OnChange Functions  ==============

  const HandleQty = (value, record) => {
    setTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];

      item.quantity = value || 0;

      const Quantity = (item.quantity) || 0;
      const Rate = (item.rate) || 0;
      const Total = Quantity * Rate;

      item.total_amt = Total || 0;
      item.price_amount = record.price_amount || 0;

      CalculateTotal({
        ...item,
        quantity: item.quantity,
        total_amt: Total,
        rate: item.rate,
      });

      return newData;
    });
  };
  const HandleUnit = (value, record) => {
    setTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];
      item.unit = value || 0;

      CalculateTotal({
        ...item,
        unit: item.unit
      })

      return newData;
    });
  };
  const HandleDescript = (value, record) => {
    setTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];

      item.description = value;
      CalculateTotal(item)
      return newData;
    });
  };

  const HandleRate = (value, record) => {
    setTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];

      item.rate = value || 0;

      const Quantity = (item.quantity) || 0;
      const Rate = (item.rate) || 0;
      const Total = Quantity * Rate || 0;

      item.total_amt = Total || 0;
      item.price_amount = record.price_amount || 0;


      CalculateTotal({
        ...item,
        quantity: item.quantity,
        total_amt: Total,
        rate: item.rate,

      });

      return newData;
    });
  };

  const HandleGrossWeight = (value, record) => {
    setTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];

      item.gross_weight = value;
      CalculateTotal(item)
      return newData;
    });
  };
  const HandleNetWeight = (value, record) => {
    setTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];

      item.net_weight = value;
      CalculateTotal(item)
      return newData;
    });
  };

  const HandleCbm = (value, record) => {
    setTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];

      item.cbm = value;
      CalculateTotal(item)
      return newData;
    });
  };




  // ---------------- 1.TotalQuantity ONCHANGE Function
  const handleOnChangeQuantity = (value, record) => {
    HandleQty(value, record)
  }
  const handleOnChangeUnit = (value, record) => {
    HandleUnit(value, record)
  }
  const handleOnChangeDescription = (e, record) => {
    HandleDescript(e.target.value, record)
  }
  const handleOnChangeRate = (value, record) => {
    HandleRate(value, record);
  };
  const handleOnChangeNetWeight = (value, record) => {
    HandleNetWeight(value, record)
  }
  const handleOnChangeGrossWeight = (e, record) => {
    HandleGrossWeight(e, record)
  }
  const handleOnChangeCbm = (value, record) => {
    HandleCbm(value, record)
  };



  //  ======================  Other Functions =========
  const onOpenSubmit = (values) => {
    setWidth(400)
    setModalTitle("");
    setModalContent(<HandleModel result={values} />);
    showModal();
  }

  const HandleModel = ({ result }) => {
    return (
      <>
        <Flex center>
          <h1 style={{ fontSize: '20px', color: '#685e5e' }}>Do you Want to Confirm or Draft</h1>
        </Flex>
        <Flex center gap={'50px'} style={{ margin: '20px 0px' }} >

          <Button.Primary text={'Confirm'} onClick={() => poNo ? EditConfirmClick(result) : confirmClick(result)} />
          <Button.Danger text={'Draft'} onClick={() => poNo ? EditDraftClick(result) : draftChange(result)} />
        </Flex>
      </>
    )
  }

  const EditConfirmClick = (values) => {
    request.put(`purchase_order/edit_purchaseorder/${poNo}/`, values)
      .then(function (response) {
        if (response.status == 201) {
          toast.success('Successfully Billed Purchase Entry ')
          form.resetFields();
          setTrigger((trigger) => trigger + 1);
          setTableData(initialData);
          setTableSecondaryData(secondaryData);
          setTableFooterData(footerCalData);
          setRound(false);
          setPayType('Cash');
          if (response.data?.id) {
            printPurchase(response.data.id);
          }
        }
        else {
          toast.error('Something went Wrong')
        }
      })
      .catch(function (error) {
        // toast.error('Failed');
      });
  }

  const EditDraftClick = (values) => {
    const record = { ...values, draft: 'True' }
    request.put(`purchase_order/edit_purchaseorder/${poNo}/`, record)
      .then(function (response) {
        if (response.status == 201) {
          toast.success('Purchase Entry Saved to Draft')
          form.resetFields();
          setTrigger((trigger) => trigger + 1);
          setTableData(initialData);
          setTableSecondaryData(secondaryData);
          setTableFooterData(footerCalData);
          setRound(false);
          setPayType('Cash');
          window.history.go(-1);


        }

        else {
          toast.error('Something went Wrong')
        }
      })
      .catch(function (error) {
        // toast.error('Failed');
      });
  }
  const printPurchase = (id) => {
    setWidth(400)
    setModalTitle("Purchase Print");
    setModalContent(<PurchaseConfirm id={id} />);
    showModal();
  }

  const onResett = () => {
    handleOk()
  }
  const PurchaseConfirm = ({ id }) => {
    return (
      <>
        <Flex center>
          <h1 style={{ fontSize: '20px', color: '#685e5e' }}>Do you Want to go the Print page</h1>
        </Flex>
        <Flex center gap={'50px'} style={{ margin: '20px 0px' }} >

          <Button.Primary text={'Yes'} onClick={() => navigate(`/PrintPurchase/${id}`)} />
          <Button.Danger text={'No'} onClick={() => onResett()} />
        </Flex>
      </>
    )
  }

  const confirmClick = (values) => {
    request.post('purchase_order/add_purchaseorder_details/', values)
      .then(function (response) {
        if (response.status == 201) {
          toast.success('Successfully Billed Purchase Entry ')
          form.resetFields();
          setTrigger((trigger) => trigger + 1);
          setTableData(initialData);
          setTableSecondaryData(secondaryData);
          setTableFooterData(footerCalData);
          setRound(false);
          setPayType('Cash');
          // window.history.go(-1);
          if (response.data?.id) {
            printPurchase(response.data.id);
          }

        }

        else {
          toast.error('Something went Wrong');
        }
      })
      .catch(function (error) {
        // toast.error('Failed');
      });
  }

  const draftChange = (values) => {
    const record = { ...values, draft: 'True' }
    request.post('purchase_order/add_purchaseorder_details/', record)
      .then(function (response) {
        if (response.status == 201) {
          toast.success('Purchase Entry Saved To Draft')
          form.resetFields();
          setTrigger((trigger) => trigger + 1);
          setTableData(initialData);
          setTableSecondaryData(secondaryData);
          setTableFooterData(footerCalData);
          setRound(false);
          setPayType('Cash');
          window.history.go(-1);
        }
        else {
          toast.error('Something went Wrong');
        }
      })
      .catch(function (error) {
        // toast.error('Failed');
      });
  }
  // ====================  On Finish Function ============

  const onFinish = (values) => {
    const record = {
      ...values, draft: 'False',
      shipping_date: values?.shipping_date === null ? '' : dayjs(selectedDates).format('YYYY-MM-DD') ? dayjs(values?.shipping_date).format('YYYY-MM-DD') : dayjs(values?.shipping_date).format('YYYY-MM-DD'),
      revised_date: values?.revised_date === null ? '' : dayjs(selectedrevisedDates).format('YYYY-MM-DD') ? dayjs(values?.revised_date).format('YYYY-MM-DD') : dayjs(values?.revised_date).format('YYYY-MM-DD'),
      purchaseorderconfirmation_date: values?.purchaseorderconfirmation_date === null ? '' : dayjs(selectedDate).format('YYYY-MM-DD') ? dayjs(values?.purchaseorderconfirmation_date).format('YYYY-MM-DD') : dayjs(values?.purchaseorderconfirmation_date).format('YYYY-MM-DD'),
    };
    let result = {
      supplier: record.supplier,
      company_name: record.company_name,
      mobile_number: record.mobile_number,
      supplier_mobile: record.supplier_mobile,
      supplier_name: record.supplier_name,
      address: record.address,
      draft: record.draft,
      supplieid: record.supplieid,
      fax: record.fax,
      email: record.email,
      contact_person: record.contact_person_name,
      payment_terms: record.terms,
      purchaseorderconfirmation_no: record.id,
      purchaseorderconfirmation_date: record.purchaseorderconfirmation_date,
      shipping_date: record.shipping_date,
      country: record.country,
      origin: record.origin,
      delivery_terms: record.delivery_terms,
      ship_by: record.ship_by,
      container: record.container,
      revised_date: record.revised_date,
      shipping_marks: record.shipping_marks,
      amount_in_words: record.amount_in_words,
      notes: record.notes,
      currency: record.rates,
      total_pieces: record.total_pieces,
      total_rate: record.total_rate,
      total_all: record.total_all,
      remarks: record.remarks,


      purchaseorder: Object.entries(record)
        .filter(([key]) => key.startsWith('description'))
        .map(([key, description]) => {
          const index = key.match(/\d+/)[0];
          const itemKey = `product_name${index}`;
          const itemProdID = `product_id${index}`;
          const itemImg = `product_image${index}`;
          const barcode = `barcode${index}`;
          const unitt = `unit${index}`;
          const itemquantity = `quantity${index}`;
          const itemRate = `rate${index}`;
          // rates
          const netweightAmtKey = `net_weight${index}`;
          const grossweightKey = `gross_weight${index}`;
          const cbmKey = `cbm${index}`;
          const amountKey = `total_amt${index}`;

          return {
            description,
            product_name: record[itemKey],
            product: record[itemProdID],
            product_image: record[itemImg],
            barcode: record[barcode],
            unit: record[unitt],
            quantity: record[itemquantity],
            purchase_rate: record[itemRate],
            net_weight: record[netweightAmtKey],
            gross_weight: record[grossweightKey],
            cbm: record[cbmKey],
            total: parseFloat(record[amountKey]).toFixed(2),

          };
        }),
    };

    if (poNo) {
      onOpenSubmit(result)
    } else {
      onOpenSubmit(result)
    }

  };


  const onFinishFailed = (errorInfo) => {
    toast.error('Please fill the Details')
  };


  // ==============  Add Row Component  ================

  const FooterComponent = () => {
    return (
      <div style={{ background: 'var(--light-color)', padding: '20px', display: 'none' }}>
        <Row>
          <Col lg={4} sm={12} span={24}><Button type="primary" style={{
            fontSize: '1rem',
            height: 'auto',
            fontFamily: 'Poppins',
            fontWeight: 500,
            letterSpacing: '1px',
          }}
            htmlType="button"
            onClick={AddRow}>
            Add Row
          </Button>
          </Col>
        </Row>
      </div >
    )
  }

  // ==================  Table  ==================
  const onRest = () => {
    form.resetFields();
    form.setFieldsValue({ id: id })
    form.setFieldsValue({ invoice_no: invoiceNumber })
    setSelectedSupplierDetails(!selectedSupplierDetails)
    setTrigger((trigger) => trigger + 1);
    setTableData(initialData);
    Getpatch()
    setTableSecondaryData(secondaryData);
    setTableFooterData(footerCalData);
    setRound(false);
    setPayType('Cash');
    if (poNo) {
      purchDraft()
    }
  }

  useEffect(() => {
    form.setFieldsValue({ origin: 'CHINA' })
  }, [])

  return (
    <Fragment>
      <Form name="sales"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        form={form}
        initialValues={
          {
            purchaseorderconfirmation_date: dayjs(),
            apply_round_off: false,
            paid: false,
            payment_type: 'Cash',
            round_off: 0,
            received: 0,
          }
        }
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off">

        <PurchaseFormHeader poNo={poNo} handleship={handleship} setChoose={setChoose} choose={choose} setShipTo={setShipTo} setAddNewPaymentTerms={setAddNewPaymentTerms} setSaleorder={setSaleorder}
          setSelectedSupplierDetails={setSelectedSupplierDetails} trigger={trigger}
          setInvoiceNumber={setInvoiceNumber} setSelectedDate={setSelectedDate}
          setSelectedDates={setSelectedDates} setSelectedrevisedDates={setSelectedrevisedDates} />

        <div style={{ margin: '20px 0' }}>
          <Table columns={columns.filter(Boolean)} data={tableData} pagination={false} />
          <FooterComponent />
        </div>


        <div style={{ margin: '20px 0' }}>
          <PurchaseFormFooter payType={payType} setPayType={setPayType} HandleCheQueChage={HandleCheQueChage} footermsg={footermsg} BalanceOnChange={BalanceOnChange} TotalBalance={TotalBalance} tableSecondaryData={tableSecondaryData} footerCalData={footerCalData} setRoundDecimalValue={setRoundDecimalValue} round={round} />
        </div>

        <Card>
          <Flex center={'true'} gap={'20px'} margin={'20px 0'}>
            <Button.Primary text={'Submit'} htmlType="submit" disabled={balanceChange} />
            <Button.Danger text={'Cancel'} onClick={onRest} />
            <Button.Primary text={'Back'} onClick={() => navigate(-1)} />
          </Flex>
        </Card>
      </Form>

      <Modal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel}
        width={width}
        modalTitle={modalTitle} modalContent={modalContent} />
    </Fragment>
  )
}