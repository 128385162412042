import React, { Fragment, useEffect } from 'react'
import AuthPage from './AuthPage'
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom'
import { anonymous } from '../config/user'
import request from '../../utils/request'
import initializeApp from '../../utils/initializeApp'
import { logOut, selectCurrentData } from '../../Modules/Auth/authSlice'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify';

const AuthRouter = ({ isAuthenticated }) => {

  console.log(isAuthenticated)

  const data = useSelector(selectCurrentData);

  const navigate = useNavigate()

const dispatch = useDispatch()

const location = useLocation();

  useEffect(() => {
    GetTokenExpire();
  }, [location?.pathname])

  const GetTokenExpire = () => {
    request.get('token/token_check')
      .then(function (response) {
        if (response.data?.avenger) {
          toast.warn("User Expire")
          dispatch(logOut())
          navigate('/signin')
        }        

      })
      .catch(function (error) {
        console.log(error);
      });
  }


  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/signin')
    }
  }, [isAuthenticated])


  return (
    <Fragment>
      {
        isAuthenticated ? (
          <AuthPage isAuthenticated={isAuthenticated} />
        ) :
          (
            <Routes>
              {anonymous.map(({ routePath, Component }) => {
                return (
                  <Route
                    key={routePath}
                    path={routePath}
                    element={<Component />}
                  ></Route>
                )
              })}
            </Routes>
          )
      }
    </Fragment>
  )
}

export default AuthRouter
