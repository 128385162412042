import React, { useEffect, useState } from 'react'
import { Table } from '../../../../Components/Table';
import { Modal } from '../../../../Components/Modal';
import { THEME } from '../../../../theme';
import Flex from '../../../../Components/Flex';
import { CustomPopconfirm } from '../../../../Components/CustomPopConfirm';
import { TableIconHolder } from '../../../../Components/Styled';
import { MdDeleteForever } from 'react-icons/md';
import request from '../../../../utils/request';
import { toast } from 'react-toastify';
import Label from '../../../../Components/Form/Label';
import Input from '../../../../Components/Form/Input';

const DeleteCurrency = () => {

    //   ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const CURRENCY_GET_URL = 'choice/add_currency/'

    const DEL_CURRENCY_URL = 'choice/edit_currency'

    const [contryArray, setContryArray] = useState([]);

    const [formUpdate, setFormUpdate] = useState(0);

    const [searchText, setSearchText] = useState([]);

    const handleSearch = (value) => {
        setSearchText(value);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        GetCurrency()
    }, [formUpdate])


    const DeleteProduct = (record) => {
        request.delete(`${DEL_CURRENCY_URL}/${record?.id}/`).then((response) => {
            GetCurrency()

        }).catch(error => {
            if (error.response.status === 401) {
                toast.warning('This product used for Transactions')
            }
            else {
                toast.error("Something Went Wrong")
            }
        });
    };

    // ======  PopConfirm Function ====

    const handleConfirm = (record) => {
        DeleteProduct(record);
    };

    const GetCurrency = () => {
        request.get(`${CURRENCY_GET_URL}`)
            .then(function (response) {
                setContryArray(response.data);
            })
            .catch(function (error) {
                console.log(error)
            });
    }

    const columns = [
        {
            title: 'SI.No',
            render: (value, item, index) => index + 1,
        },
        {
            title: 'Currency',
            dataIndex: 'rate',
            filteredValue: searchText ? [searchText] : null,
            onFilter: (value, record) => {
                return String(record.rate).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.rate).includes(value.toUpperCase());
            },
        },
        {
            title: 'Action',
            render: (record) => {
                return (
                    <Flex center={"true"} gap={'10px'}>
                        <CustomPopconfirm
                            record={record}
                            confirm={handleConfirm}
                            title={"Delete the Currency"}
                            description={"Are you sure to delete this Currency ?"}>
                            <TableIconHolder color={THEME.red} size={'22px'}>
                                <MdDeleteForever />
                            </TableIconHolder>
                        </CustomPopconfirm>
                    </Flex>
                )
            }

        },
    ]

    return (
        <div>
            <Flex end={true} centervertically={true}>
                <Label>Search by Currency Name&nbsp;: &nbsp;</Label>
                <Input
                    placeholder="Search by Currency"
                    value={searchText}
                    onSearch={handleSearch}
                    onChange={(e) => handleSearch(e.target.value)} />
            </Flex>
            <Table columns={columns} data={contryArray} />
            <Modal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={1000} modalTitle={modalTitle} modalContent={modalContent} />

        </div>
    )
}

export default DeleteCurrency