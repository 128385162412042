import React from 'react'
import InvoiceTableReport from './Partials/InvoiceTableReport'

const InvoiceReport = () => {
  return (
    <div>
        <InvoiceTableReport />
    </div>
  )
}

export default InvoiceReport