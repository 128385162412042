import { Card, Col } from 'antd';
import React from 'react'
import Input from '../../../Components/Form/Input';
import { TextAreas } from '../../../Components/Form/TextArea';
import { Row } from '../../../Components/Row';
import { CustomInputNumber } from '../../../Components/Form/CustomInputNumber';

const InvoiceFooter = ({ handleDepositamr ,handleDiscoutAmt}) => {



    return (

        <Row space={[24, 24]}>
            <Col span={24} md={12}>
                <Row space={[24, 24]}>
                    <Col span={24} md={24}>

                        <Card>
                            <Row space={[24, 24]}>

                                <Col span={24} md={12}>
                                    <Input label={'Total pcs'} disabled name={'total_pieces'} />
                                </Col>
                                <Col span={24} md={12}>
                                    <Input label={'Total Rate'} disabled name={'total_rate'} />
                                </Col>
                            </Row>
                        </Card>

                    </Col>
                    <Col span={24} md={24} >

                    </Col>
                    <Col span={24} md={12} >
                        <TextAreas label={'SHIPPING MARKS'} placeholder={'SHIPPING MARKS'}
                            name={'shipping_mark'} />
                    </Col>

                    <Col span={24} md={12} >
                        <TextAreas label={'REMARKS'} placeholder={'REMARKS'}
                            name={'remarks'} />
                    </Col>
                </Row>
            </Col>

            <Col span={24} md={12}>
                <Card>
                    <Row space={[24, 24]}>
                        <Col span={24} md={12}>
                            <Input label={'Sub Total'} name={'sub_total'} disabled />
                        </Col>
                        <Col span={24} md={12}>
                            <CustomInputNumber label={'Discount Amt'} name={'discount_total'} defaultValue={0}  onChange={handleDiscoutAmt} />
                        </Col>
                    </Row>
                </Card>
                <Card>
                    <Row space={[24, 24]}>
                        <Col span={24} md={24}>
                            <Input label={'TOTAL'} name={'total_all'} placeholder={'TOTAL'}
                                disabled
                            />
                        </Col>
                        <Col span={24} md={24}>
                            <Input label={'deposit amt'} name={'deposit_amt_enter'} placeholder={'TOTAL'}
                                disabled
                            />
                        </Col>
                        <Col span={24} md={24}>
                            <CustomInputNumber label={'PAID'} defaultValue={0} name={'deposit_amt'} placeholder={'PAID'}
                                onChange={handleDepositamr}
                            />
                        </Col>
                        <Col span={24} md={24}>
                            <Input label={'BALANCE'} name={'balance_amt'} placeholder={'BALANCE'}
                                disabled
                            />
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    )
}

export default InvoiceFooter