import React from 'react'
import { CustomCard } from '../../Components/CustomCard'
import { TopTitle } from '../../Components/Form/TopTitle'
import ProfitorLoss from './Partials/ProfitorLoss'


export const Profit = () => {
    return (
        <div>
            <TopTitle Heading={'Profit or Loss'} />
            <CustomCard>
                <ProfitorLoss/>
            </CustomCard>

        </div>
    )
}
