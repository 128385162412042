import React from 'react'
import { TopTitle } from '../../../Components/Form/TopTitle'
import { CustomCard } from '../../../Components/CustomCard'
import { PackingTable } from './PackingEntryPage'

const PackListMain = () => {
    return (

        <div>
            <TopTitle Heading={'Packing List'} />
            <CustomCard>
                <PackingTable />
            </CustomCard>
        </div>
    )
}

export default PackListMain