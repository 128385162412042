import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import request from "../../utils/request";

const initialState = {
    supplier: [],
    supplierDetails: {},
    supplierProfileDetails: [],
    supplierStatus: 'idle',
    supplierError: null,
}

export const getSupplierList = createAsyncThunk(
    'Supplier/Get',
    async () => {
        try {
            const response = await request.get('supplier/add_supplier/')
            return [...response.data]
        }
        catch (error) {
            throw (error)
        }
    }
)

export const getSupplierDetails = createAsyncThunk(
    'SupplierDetails/Get',
    async (id) => {
        try {
            const response = await request.get(`supplier/supplier_profile/${id}`)
            return response.data
        }
        catch (error) {
            throw (error)
        }
    }
)



const SupplierReducer = createSlice({
    name: "Supplier",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getSupplierList.pending, (state, action) => {
                state.supplierStatus = 'loading'
            })
            .addCase(getSupplierList.fulfilled, (state, action) => {
                state.supplierStatus = 'succeeded'
                state.supplier = action.payload
            })
            .addCase(getSupplierList.rejected, (state, action) => {
                state.supplierStatus = 'failed'
                state.supplierError = action.payload
            })
            .addCase(getSupplierDetails.pending, (state, action) => {
                state.supplierStatus = 'loading'
            })
            .addCase(getSupplierDetails.fulfilled, (state, action) => {
                state.supplierStatus = 'succeeded'
                state.supplierDetails = action.payload.supplier_details
                state.supplierProfileDetails = action.payload.supplier_profile_details
            })
            .addCase(getSupplierDetails.rejected, (state, action) => {
                state.supplierStatus = 'failed'
                state.supplierError = action.payload
            })
    }
})

export const getAllsuppliersList = (state) => state.suppliers.supplier
export const getAllsupplierDetails = (state) => state.suppliers.supplierDetails
export const getsupplierProfileDetails = (state) => state.suppliers.supplierProfileDetails
export const getSupplierError = (state) => state.suppliers.supplierError
export const getSupplierStatus = (state) => state.suppliers.supplierStatus

export const { reducer } = SupplierReducer

export default SupplierReducer.reducer