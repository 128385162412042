import React from 'react'
import ProfileForm from './AddProfile/Partials/ProfileForm'
import { CustomCard } from '../../Components/CustomCard'
import { TopTitle } from '../../Components/Form/TopTitle'

const ProfileMain = () => {
    return (
        <div>
         
            <CustomCard>
                <ProfileForm />
            </CustomCard>
        </div>
    )
}

export default ProfileMain