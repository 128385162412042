import React from 'react'
import { FormTitle } from '../../../Components/Form/FormTitle'

import { useEffect } from 'react'
import { useState } from 'react'
import { Table } from '../../../Components/Table'
import { useDispatch, useSelector } from 'react-redux'
import { getAllCustomerCredit, getDashboard } from '../../AddModules/AddModulesSlice'

const CustomerCredit = ({ getDash }) => {

    const [dataSource, setDataSource] = useState([]);
    const dispatch = useDispatch();
    const customercredit = useSelector(getAllCustomerCredit);

    useEffect(() => {
        dispatch(getDashboard())
    }, [])

    useEffect(() => {
        setDataSource(customercredit)
    }, [customercredit])

    const columns = [
        {
            title: 'S.No',
            render: (value, item, index) => index + 1,
        },
        {
            title: 'Customer ID',
            dataIndex: 'customer',
        },
        {
            title: 'Contact No',
            dataIndex: 'contact_no',
        },
        {
            title: 'Contact Person',
            dataIndex: 'contact_person',
        },
        {
            title: 'Invoice No',
            dataIndex: 'invoice_no',
        },
        {
            title: 'Bill Amount',
            dataIndex: 'bill_amt',
            render: (value, record) => `${value} ${record.currency}`,
        },
        {
            title: 'Amount DUE',
            dataIndex: 'balance',
            render: (value, record) => `${value} ${record.currency}`,
        },
    ]

    const Content = () => {
        const rowKey = (dataSource) => dataSource.id;
        return <Table columns={columns} data={dataSource} rowKey={rowKey} />
    }
    return (
        <div>
            <FormTitle Title={'Customer Credit'} />
            <Content/>
        </div>
    )
}

export default CustomerCredit