import { Card, Col, Form, Image } from 'antd'
import React, { useEffect, useState } from 'react'
import { Row } from '../../../../Components/Row'
import Input from '../../../../Components/Form/Input'
import { CustomInputNumber } from '../../../../Components/Form/CustomInputNumber'
import { TextAreas } from '../../../../Components/Form/TextArea'
import { Modal } from '../../../../Components/Modal'
import { CustomPageFormSubTitle, TopTitle } from '../../../../Components/Form/TopTitle'
import request from '../../../../utils/request'
import { CustomUpload } from '../../../../Components/Form/CustomUpload'

const ViewDetails = ({ handlerecord, ProductFormUpdateCall }) => {

    const [form] = Form.useForm()
    const [imgUrl, setImgUrl] = useState(null);
    const [ImageInitialValue, setImageInitialValue] = useState([]);
    const [imageSignatureValue, setImageSignatureValue] = useState([]);
    const [updateFormData, setUpdateFormData] = useState({})

    //   ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);


    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };


    const onFinish = (values) => {
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        DisplayImage();
    }, [])

    const DisplayImage = () => {
        request.get('business/business_view')
            .then(function (response) {
                const blob = response.data;
                setImgUrl(blob);
                ProductFormUpdateCall();


            })
            .catch(function (error) {
                console.log(error)
            })

    }



    useEffect(() => {
        if (handlerecord) {

            setImageInitialValue([
                {
                    uid: '1',
                    name: 'example.jpg',
                    status: 'done',
                    url: handlerecord.signature,
                },
            ])

            setImageSignatureValue([
                {
                    uid: '1',
                    name: 'signatureexample.jpg',
                    status: 'done',
                    url: handlerecord.purchase_signature,
                },
            ])
        }
        setUpdateFormData(handlerecord)

    }, [handlerecord])
    useEffect(() => {
        form.setFieldsValue(updateFormData)
        form.setFieldsValue({ 'signature': ImageInitialValue })
        form.setFieldsValue({ 'purchase_signature': imageSignatureValue })
      }, [updateFormData])
    

    return (
        <>
            <TopTitle Heading={'View Details'} />
            <Form
                form={form}
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off">

                <Row space={[24, 24]}>

                    <Col span={24} md={12}>
                        <Input label={'Business Name/ Company Name'} name={'business_name'} placeholder={'Enter Name'}
                            disabled
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Your Business!',
                                }
                            ]} />
                    </Col>

                    <Col span={24} md={12}>
                        <Input label={'Email ID'} name={'email'} type={'email'} placeholder={"Enter Your Mail ID"}
                            disabled
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Your Mail id!',
                                }
                            ]} />
                    </Col>

                    <Col span={24} md={12}>
                        <CustomInputNumber label={'Phone No 1'} name={'phone_no'}
                            disabled
                            placeholder={"Enter Your Phone no 1"}
                            maxLength={10}
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter Phone Number!',
                                },
                            ]} />
                    </Col>
                    <Col span={24} md={12}>
                        <CustomInputNumber label={'Phone No 2'} name={'phone_no2'}
                            disabled
                            placeholder={"Enter Your Phone no 2"}
                            maxLength={10}
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }} />
                    </Col>
                    <Col span={24} md={12}>
                        <TextAreas label={'Address'} name={'address'} disabled placeholder={"Enter Your Address"} rules={[
                            {
                                required: true,
                                message: 'Please Enter Your Address!',
                            }
                        ]} />
                    </Col>
                    <Col span={24} md={12}>
                        <Input label={'Fax Number'} name={'fax_number'} disabled placeholder={'Fax Number'} />
                    </Col>

                    <Col span={24} md={12}>
                        <CustomUpload form={form} initialValue={ImageInitialValue} disabled label={'Signature'} name={'signature'} listType='picture-card' maxCount={1} accept='.png,.jpeg,.jpg'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please upload signature',
                                },
                            ]} />

                    </Col>
                    <Col span={24} md={24}><h1>BANK DETAILS</h1></Col>
                    <Col span={24} md={12}>
                        <Input label={'Beneficiary name'} placeholder={'Enter Account Name'} disabled
                            name={'bank_account_name'} />
                    </Col>

                    <Col span={24} md={12}>
                        <Input label={'Beneficiary Bank'} placeholder={'Beneficiary Bank'} disabled
                            name={'bank_name'} />
                    </Col>

                    <Col span={24} md={12}>
                        <Input label={'Beneficiary A/C NO'} placeholder={'Beneficiary A/C NO'} disabled
                            name={'bank_account_number'} />
                    </Col>
                    <Input name={'id'} display={'none'} />
                </Row>
                <TopTitle Heading={'Purchase Profile Details'} />

                <Row space={[24, 24]} >

                    <Col span={24} md={12}>
                        <Input label={'Business Name/ Company Name'} name={'purchase_business_name'} placeholder={'Enter Name'}
                            disabled
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Your Business!',
                                }
                            ]} />
                    </Col>

                    <Col span={24} md={12}>
                        <Input label={'Email ID'} name={'purchase_email'} type={'email'} placeholder={"Enter Your Mail ID"}
                            disabled
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Your Mail id!',
                                }
                            ]} />
                    </Col>

                    <Col span={24} md={12}>
                        <Input label={'Phone No 1'} name={'purchase_phone_no'}
                            disabled
                            placeholder={"Enter Your Phone no 1"}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter Phone Number!',
                                },
                            ]} />
                    </Col>

                    <Col span={24} md={12}>
                        <Input label={'Phone No 2'} name={'purchase_phone_no2'}
                            disabled
                            placeholder={"Enter Your Phone no 2"} />
                    </Col>

                    <Col span={24} md={12}>
                        <TextAreas label={'Address'} name={'purchase_address'} placeholder={"Enter Your Address"} disabled rules={[
                            {
                                required: true,
                                message: 'Please Enter Your Address!',
                            }
                        ]} />
                    </Col>

                    <Col span={24} md={12}>
                        <CustomInputNumber label={'Fax Number'} name={'purchase_fax_number'} placeholder={'Fax Number'} disabled />
                    </Col>
                    <Col span={24} md={12}>

                        <CustomUpload form={form} initialValue={imageSignatureValue} label={'Signature'} name={'purchase_signature'} listType='picture-card' maxCount={1} accept='.png,.jpeg,.jpg'
                            disabled
                            rules={[
                                {
                                    required: true,
                                    message: 'Please upload signature',
                                },
                            ]} />
                    </Col>

                    <Col span={24} md={24}>
                        <CustomPageFormSubTitle Heading={'BANK DETAILS'} disabled />
                    </Col>


                    <Col span={24} md={12}>
                        <Input label={'Beneficiary name'} placeholder={'Enter Account Name'}
                            disabled
                            name={'purchase_bank_account_name'} />
                    </Col>

                    <Col span={24} md={12}>
                        <Input label={'Beneficiary Bank'} placeholder={'Beneficiary Bank'}
                            disabled
                            name={'purchase_bank_name'} />
                    </Col>

                    <Col span={24} md={12}>
                        <Input label={'Beneficiary A/C NO'} placeholder={'Beneficiary A/C NO'}
                            disabled
                            name={'purchase_bank_account_number'} />
                    </Col>
                    <Input name={'id'} display={'none'} disabled />

                </Row>
            </Form>
            <Modal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={1000} modalTitle={modalTitle} modalContent={modalContent} />

        </>
    )
}

export default ViewDetails