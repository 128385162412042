import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Col } from 'antd'
import {
    AuthersiedBox,
    PrintTableFooterHolder,
    ProfileAddress,
    FooterTitle,
    PrintHolder,
    PrintViewTitle,
    PrintViewValue,
    ProfileHeader,
    ProfileSubTitle,
    SignImageHolder,
    TableHolder,
    PrintWrapper,
    PrintTableFooterHolders
} from '../../../Components/Styled'
import { useDispatch, useSelector } from 'react-redux'

import { AiFillPrinter } from 'react-icons/ai'
import { useReactToPrint } from 'react-to-print'
import { TableImageHolder } from '../../../Components/TableImageHolder'
import Button from '../../../Components/Form/Button'
import Flex from '../../../Components/Flex'
import { Row } from '../../../Components/Row'
import request from '../../../utils/request'
import { useNavigate, useParams } from 'react-router-dom'
import { CustomCard } from '../../../Components/CustomCard'
import { getActivePro, getActiveProfile } from '../../Profile/ProfileSlice'
import { toast } from 'react-toastify'

export const PrintQuatation = () => {

    const [viewData, setViewData] = useState({})
    const { id } = useParams()
    const navigate = useNavigate()

    const componentRef = useRef();

    const dispatch = useDispatch();
    const activePro = useSelector(getActivePro);

    useEffect(() => {
        dispatch(getActiveProfile());
    }, [])

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })
    useEffect(() => {
        QuotationPrintGet()
    }, [])

    const QuotationPrintGet = () => {
        request.get(`quotation/quotation_print_get/${id}/`)
            .then(function (response) {
                setViewData(response.data)
                console.log(response.data, 'quotationprint');
            })
            .catch(function (error) {
                toast.error("Failed")
            });
    }

    return (
        <CustomCard>

            <Flex margin={"20px"} gap={"10px"}>
                <Button.Primary text={<AiFillPrinter style={{ fontSize: '30px' }} />} onClick={handlePrint} />

            </Flex>

            <PrintWrapper>
                <PrintHolder ref={componentRef}>
                    <ProfileHeader>{activePro.business_name}</ProfileHeader>
                    <ProfileAddress>{activePro.address}</ProfileAddress>
                    <ProfileAddress>Mob :{activePro.phone_no} , Fax : {activePro.fax_number} , Email : {activePro.email}</ProfileAddress>
                    <div style={{ textAlign: 'center' }}>
                        <ProfileSubTitle>QUOTATION</ProfileSubTitle>
                    </div>

                    <Row space={[8, 8]}>
                        <Col span={24} md={12}>
                            <Row space={[2, 2]}>
                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            Client NAME
                                        </PrintViewTitle>
                                        <h1 style={{ alignSelf: 'baseline' }}>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {viewData.customer_name} <br />
                                        </PrintViewValue>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                           Contact No
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {viewData.mobile}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>


                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            email&nbsp;
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {viewData.email}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>
                                {/* <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            mobile
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {viewData.mobile}
                                        </PrintViewValue>
                                    </Flex>
                                </Col> */}

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {viewData.payment_terms}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>
                            </Row>
                        </Col>

                        <Col span={24} md={12}>
                            <Row space={[2, 2]}>
                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            Date
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {viewData.date}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>
                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            Country
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {viewData.country}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            origin
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {viewData.origin}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            ship by
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {viewData.ship_by}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>
                                {
                                    viewData.container_no &&

                                    <Col span={24} sm={12} md={10}>
                                        <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                            <PrintViewTitle>
                                                container 
                                            </PrintViewTitle>
                                            <h1>:</h1>
                                        </Flex>
                                    </Col>

                                }

                                {
                                    viewData.container_no &&

                                    <Col span={24} sm={12} md={14}>
                                        <Flex alignEnd={'true'} H_100>
                                            <PrintViewValue>
                                                {viewData.container_no}
                                            </PrintViewValue>
                                        </Flex>
                                    </Col>
                                }
                                {/* <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            container
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>
                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {viewData.container_new}
                                        </PrintViewValue>
                                    </Flex>
                                </Col> */}
                            </Row>
                        </Col>
                    </Row>
                    <TableHolder>
                        <table>
                            <thead>
                                <tr>
                                    <th>SI.NO</th>
                                    <th>PRODUCT</th>
                                    <th>IMAGE</th>
                                    <th>DESCRIPTION</th>
                                    <th>WEIGHT</th>
                                    <th>PACKING</th>
                                    <th >CONTAINER({viewData.container_new})</th>
                                    <th>PCK NOTE</th>
                                    <th>QUANTITY</th>
                                    <th>PRICE({viewData?.currency})</th>
                                    <th>REMARKS</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    viewData?.quot_table?.map((item, index) => (
                                        <tr key={item.id}>
                                            <td>{index + 1}</td>
                                            <td>{item.product_name}</td>
                                            <td>
                                                <TableImageHolder imageUrl={item.product_image} />
                                            </td>
                                            <td>{item.description}</td>
                                            <td>{item.weight}</td>
                                            <td>{item.packing}</td>
                                            <td>{item.container}</td>
                                            <td>{item.packing_note}</td>
                                            <td>{item.quantity} {item.unit}</td>
                                            <td>{item.total}</td>
                                            <td>{item.remarks}</td>
                                        </tr>
                                    ))
                                }

                            </tbody>
                        </table>
                    </TableHolder>
                    <PrintTableFooterHolders>
                        {/* <FooterTitle>
                            BANK DETAILS
                        </FooterTitle> */}
                        <Row space={[6, 6]}>
                            <Col span={24} md={24}>
                                <Row space={[2, 2]}>
                                    <Col span={24} sm={12} md={5}>
                                        <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                            <PrintViewTitle>
                                                TERMS & CONDITIONS
                                            </PrintViewTitle>
                                            <h1>:</h1>
                                        </Flex>
                                    </Col>

                                    <Col span={24} sm={12} md={19}>
                                        <Flex alignEnd={'true'} H_100>
                                            <PrintViewValue>
                                                <pre>
                                                    {viewData.termsandconditions}
                                                </pre>
                                            </PrintViewValue>
                                        </Flex>
                                    </Col>

                                    {/* <Col span={24} sm={12} md={12}>
                                        <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                            <PrintViewTitle>
                                                BENEFICIARY BANK
                                            </PrintViewTitle>
                                            <h1>:</h1>
                                        </Flex>
                                    </Col>

                                    <Col span={24} sm={12} md={12}>
                                        <Flex alignEnd={'true'} H_100>
                                            <PrintViewValue>
                                                {activePro.bank_name}
                                            </PrintViewValue>
                                        </Flex>
                                    </Col>

                                    <Col span={24} sm={12} md={12}>
                                        <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                            <PrintViewTitle>
                                                BENEFICIARY A/C NO
                                            </PrintViewTitle>
                                            <h1>:</h1>
                                        </Flex>
                                    </Col>

                                    <Col span={24} sm={12} md={12}>
                                        <Flex alignEnd={'true'} H_100>
                                            <PrintViewValue>
                                                {activePro.bank_account_number}
                                            </PrintViewValue>
                                        </Flex>
                                    </Col> */}
                                </Row>
                            </Col>

                            <Col span={24} md={10}>
                                <Row space={[2, 2]}>
                                    {/* <Col span={24} sm={12} md={12}>
                                        <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                            <PrintViewTitle>
                                                TOTAL QUANTITY
                                            </PrintViewTitle>
                                            <h1>:</h1>
                                        </Flex>
                                    </Col>

                                    <Col span={24} sm={12} md={12}>
                                        <Flex alignEnd={'true'} H_100>
                                            <PrintViewValue>
                                                {viewData?.total_quantity}
                                            </PrintViewValue>
                                        </Flex>
                                    </Col>

                                    <Col span={24} sm={12} md={12}>
                                        <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                            <PrintViewTitle>
                                                TOTAL AMOUNT
                                            </PrintViewTitle>
                                            <h1>:</h1>
                                        </Flex>
                                    </Col>

                                    <Col span={24} sm={12} md={12}>
                                        <Flex alignEnd={'true'} H_100>
                                            <PrintViewValue>
                                                {viewData?.grand_total} {viewData?.currency}
                                            </PrintViewValue>
                                        </Flex>
                                    </Col> */}
                                </Row>
                            </Col>
                        </Row>

                        {/* <AuthersiedBox/>
                            <Flex W_100 spacebetween={'true'} alignEnd={'true'}>
                                <span>
                                    CONFIRM & ACCEPTED BY
                                </span>
                                <span>
                                    FOR AND ON BEHALF OF
                                </span>
                            </Flex>

                            <Flex flexEnd={'true'}>
                                <SignImageHolder>
                                    <img src={activePro.signature} alt="signature" />
                                </SignImageHolder>
                            </Flex>

                            <Flex W_100 spacebetween={'true'} alignEnd={'true'}>
                                <span>
                                    BRANCH ASFAR
                                </span>
                                <span>
                                    {activePro.business_name}
                                </span>
                            </Flex> */}

                    </PrintTableFooterHolders>

                </PrintHolder>
            </PrintWrapper>
            <Button.Primary text={'back'} onClick={() => navigate('/quotation')} />
        </CustomCard>
    )
}
