import { Col, Form } from 'antd';
import React, { useEffect } from 'react'
import request from '../../utils/request';
import Button from '../../Components/Form/Button';
import Flex from '../../Components/Flex';
import Input from '../../Components/Form/Input';
import { Row } from '../../Components/Row';
import { toast } from 'react-toastify';

const AddUnitMeasurement = ({ SaleTrigger, handleOk, AddUnitGet, handleSaleUnitGet, quaotationunitGet }) => {

    const [form] = Form.useForm();

    const UNITADD = 'choice/add_measurement/'

    const onRest = () => {
        form.resetFields();
    }

    const onFinish = (values) => {
        AddUnitPost(values)
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        form.resetFields()
    }, [SaleTrigger])

    const AddUnitPost = (values) => {
        request.post(`${UNITADD}`, values)
            .then(function (response) {

                if (response.status == 200) {
                    form.resetFields();
                    toast.success('Unit Added Successfully')

                    if (AddUnitGet) {
                        AddUnitGet()
                    }
                    if (handleOk) {
                        handleOk()
                    }
                    if (handleSaleUnitGet) {
                        handleSaleUnitGet()

                    }
                    if (quaotationunitGet) {
                        quaotationunitGet();
                    }
                }
                else if (response.status == 226) {
                    toast.warn('Unit Already Added')
                }

                else {
                    toast.error('Failed');
                }
            })
            .catch(function (error) {
                toast.error('Failed');
            });
    }

    return (
        <Form
            form={form}
            labelCol={{
                span: 24,
            }}
            wrapperCol={{
                span: 24,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off">
            <Row space={[24, 24]}>

                <Col span={24} md={24}>
                    <Input label={'ADD UNIT NAME'} placeholder={'Enter UNIT NAME'} name={'unit'}
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter UNIT NAME!',
                            }
                        ]}
                    />
                </Col>
            </Row>
            <Flex center gap={'20px'} style={{ margin: '30px' }}>
                <Button.Success text={'ADD'} htmlType={'submit'} />
                <Button.Danger text={'RESET'} onClick={onRest} />
            </Flex>
        </Form>
    )
}

export default AddUnitMeasurement