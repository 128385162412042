import React, { useEffect, useState } from 'react'
import { Table } from '../../../../Components/Table';
import { Overhid } from './Style';
import request from '../../../../utils/request';

const PaymentDetailsModal = ({ finance }) => {

    const [dataSource, setDataSource] = useState([])

    useEffect(() => {
        GetFinaceDetails()
    }, [])


    const GetFinaceDetails = () => {
        request.get(`consignment/pay_details_consignment/${finance}/`)
            .then(function (response) {
                setDataSource(response.data)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const columns = [
        {
            title: 'Sl No',
            render: (item, value, index) => index + 1,
        },
        {
            title: 'Bill No',
            dataIndex: 'sale_confirmation_no',
        },
        {
            title: 'Bill Date',
            dataIndex: 'sale_confirmation_date',
        },
        {
            title: 'Bill Amount',
            dataIndex: 'amount',
            render: (value, record) => `${value} ${record.currency}`,
        },
        {
            title: 'Paid Amount',
            dataIndex: 'amount',
            render: (value, record) => `${value} ${record.currency}`,
        },
        {
            title: 'Balance Amount',
            dataIndex: 'balance_amount',
            render: (value, record) => `${value} ${record.currency}`,
        },
        {
            title: 'Payment Date',
            dataIndex: 'payment_date',
        }
    ];

    return (
        <div>
            <Overhid>
                <Table columns={columns} data={dataSource} />
            </Overhid>
        </div>
    )
}

export default PaymentDetailsModal