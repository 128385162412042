import { Col, Form } from 'antd';
import React, { useEffect } from 'react'
import { TextAreas } from '../../../../Components/Form/TextArea';
import Input from '../../../../Components/Form/Input';
import Button from '../../../../Components/Form/Button';
import Flex from '../../../../Components/Flex';
import { Row } from '../../../../Components/Row';
import request from '../../../../utils/request';
import { toast } from 'react-toastify';

const AddingCustomers = ({ handleSalesCustomer, quotationTrigger, SaleHeaderTrigger, HandleCustFromQuot }) => {

    const [form] = Form.useForm();

    const URL = 'customer/add_customer/'

    useEffect(() => {
        GetId()
    }, [handleSalesCustomer,quotationTrigger])

    useEffect(() => {
        form.resetFields()
    }, [quotationTrigger])


    useEffect(() => {
        form.resetFields()
    }, [SaleHeaderTrigger])

    const GetId = () => {
        request.get('customer/customer_invoice/')
            .then(resp => {
                form.setFieldsValue({ invoice_no: resp.data.customer_invoice })
            })
            .catch(error => console.log(error, 'error'))
    }


    const onFinish = (values) => {
        Addcustomer(values)
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const Addcustomer = (values) => {
        request.post(`${URL}`, values)
            .then(function (response) {
                if (response.status == 200) {
                    form.resetFields();
                    toast.success('Customer Details Added Successfully')
                    GetId()
                    if (handleSalesCustomer) {
                        handleSalesCustomer();
                    }
                    if (HandleCustFromQuot) {
                        HandleCustFromQuot()
                    }
                }
                else {
                    toast.error('add faild');
                }
            })
            .catch(function (error) {
                console.log(error, 'check');
            });
    }

    const onReset = () => {
        form.resetFields();
        if (handleSalesCustomer) {
            handleSalesCustomer();
        }
        if (HandleCustFromQuot) {
            HandleCustFromQuot()
        }
        if (GetId) {
            GetId()
        }
    }


    return (
        <Form
            name='AddCustomer'
            form={form}
            labelCol={{
                span: 24,
            }}
            wrapperCol={{
                span: 24,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete='off'>

            <Row space={[24, 24]}>

                <Col span={24} md={12}>
                    <Input label={'Company Name'} placeholder={'Customer/Company Name'}
                        name={'company_name'}
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter Your Companyname!',
                            }
                        ]} />
                </Col>
                <Col span={24} md={12}>
                    <Input label={'Customer ID'} disabled placeholder={'Customer ID'}
                        name={'invoice_no'} />
                </Col>

                <Col span={24} md={12}>
                    <Input label={'FAX number'} placeholder={'FAX number'} name={'fax'} />
                </Col>
                <Col span={24} md={12}>
                    <Input label={'Phone No'} placeholder={'Company Contact Number'}
                        name={'customer_mobile'}
                        rules={[
                            {
                                required: true,
                                message: 'Please enter Phone Number!',
                            },
                        ]} />
                </Col>

                <Col span={24} md={12}>
                    <Input label={'Contact Person'} placeholder={'Contact Person'}
                        name={'contact_person_name'}
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter Contact Person Name !',
                            },
                        ]} />
                </Col>

                <Col span={24} md={12}>
                    <Input label={'Email id'} name={'email'} type="email" placeholder={"Email ID"} />
                </Col>

                <Col span={24} md={12}>
                    <Input label={'Mobile number'} placeholder={'Contact Person Number'}
                        name={'mobile_number'}
                        rules={[
                            {
                                required: true,
                                message: 'Please enter Phone Number!',
                            },
                        ]} />
                </Col>

                <Col span={24} md={12}>
                    <Input label={'CR NO'} placeholder={'CR NO'} name={'cr_no'}
                        rules={[
                            {
                                required: true,
                                message: 'This field is required!',
                            },
                        ]} />
                </Col>

                <Col span={24} md={12}>
                    <Input label={'IMPORT NO'} placeholder={'IMPORT NO'} name={'import_no'}
                        rules={[
                            {
                                required: true,
                                message: 'This field is required!',
                            },
                        ]} />
                </Col>

                <Col span={24} md={12}>
                    <Input label={'VAT NO'} placeholder={'VAT NO'} name={'vat_no'}
                        rules={[
                            {
                                required: true,
                                message: 'This field is required!',
                            },
                        ]} />
                </Col>

                <Col span={24} md={12}>
                    <TextAreas label={'Address'} placeholder={'Address'} name={'address'} />
                </Col>

            </Row><br />
            <Flex center={'true'} gap={'20px'} margin={'20px 0'}>
                <Button.Primary text={'ADD'} htmlType={'submit'} />
                <Button.Danger text={'RESET'} onClick={() => onReset()} />
            </Flex>

        </Form>
    )
}

export default AddingCustomers