import React, { useEffect, useState } from 'react'
import request from '../../../../utils/request';
import { Table } from '../../../../Components/Table';
import { THEME } from '../../../../theme';
import { CustomPopconfirm } from '../../../../Components/CustomPopConfirm';
import { MdDeleteForever } from 'react-icons/md';
import { TableIconHolder } from '../../../../Components/Styled';
import Flex from '../../../../Components/Flex';
import { toast } from 'react-toastify';
import { Modal } from '../../../../Components/Modal';
import Label from '../../../../Components/Form/Label';
import Input from '../../../../Components/Form/Input';

const DeleteSize = () => {

  //   ======  Modal Title and Content ========
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const SIZE_GET_URL = 'choice/add_size/'

  const DEL_PRODUCT_SIZE_URL = 'choice/edit_size'

  const [sizeArray, setSizeArray] = useState([]);   //  Size UseState


  const [formUpdate, setFormUpdate] = useState(0);

  const [searchText, setSearchText] = useState([]);

  const handleSearch = (value) => {
    setSearchText(value);
  };

 
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    GetSize()
  }, [formUpdate])


  const DeleteProduct = (record) => {
    request.delete(`${DEL_PRODUCT_SIZE_URL}/${record?.id}/`).then((response) => {
      GetSize()

    }).catch(error => {
      if (error.response.status === 401) {
        toast.warning('This product used for Transactions')
      }
      else {
        toast.error("Something Went Wrong")
      }
    });
  };

  // ======  PopConfirm Function ====
  const handleConfirm = (record) => {
    DeleteProduct(record);
  };

  const GetSize = () => {
    request.get(`${SIZE_GET_URL}`)
      .then(function (response) {
        setSizeArray(response.data);
        console.log(response.data, 'getdatadd');
      })
      .catch(function (error) {
        console.log(error)
      });
  }

  const columns = [
    {
      title: 'SI.No',
      render: (value, item, index) => index + 1,
    },
    {
      title: 'SIze',
      dataIndex: 'size',
      filteredValue: searchText ? [searchText] : null,
      onFilter: (value, record) => {
        return String(record.size).toLowerCase().includes(value.toLowerCase()) ||
          String(record.size).includes(value.toUpperCase());
      },
    },
    {
      title: 'Action',
      render: (record) => {
        return (
          <Flex center={"true"} gap={'10px'}>
            <CustomPopconfirm
              record={record}
              confirm={handleConfirm}
              title={"Delete the Product Size"}
              description={"Are you sure to delete this Product Size ?"}>
              <TableIconHolder color={THEME.red} size={'22px'}>
                <MdDeleteForever />
              </TableIconHolder>
            </CustomPopconfirm>
          </Flex>
        )
      }
    },
  ]

  return (
    <div>
      <Flex end={true} centervertically={true}>
        <Label>Search by Size Name&nbsp;: &nbsp;</Label>
        <Input
          placeholder="Search by Size"
          value={searchText}
          onSearch={handleSearch}
          onChange={(e) => handleSearch(e.target.value)} />
      </Flex>
      <Table columns={columns} data={sizeArray} />
      <Modal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={1000} modalTitle={modalTitle} modalContent={modalContent} />
    </div>
  )
}

export default DeleteSize