import { Col, Form } from 'antd'
import React, { useEffect, useState } from 'react'
import { Row } from '../../../Components/Row';
import { CustomSelect } from '../../../Components/Form/CustomSelect';
import AddingCustomers from '../../Customers/AddCustomers/Partials/AddCustomers'
import dayjs from 'dayjs'
import { CustomDatePicker } from '../../../Components/Form/CustomDatePicker';
import Input from '../../../Components/Form/Input';
import { Select } from '../../../Components/Form/Select';
import { Modal } from '../../../Components/Modal';
import request from '../../../utils/request';
import Country from '../../AddModules/Country';
import { useDispatch, useSelector } from 'react-redux';
import { getCountry, getAllCountries } from '../../AddModules/AddModulesSlice';
import { getCustomer, getAllCustomers } from '../../Customers/CustomerSlice';

const QuotationForm = ({ setSelectedCustomer, handleship, setShipTo }) => {

    const [form] = Form.useForm();

    const [selectedDate, setSelectedDate] = useState(dayjs().format('MMMM DD, YYYY'));

    // ======  Modal Open ========
    const [isModalOpen, setIsModalOpen] = useState(false);

    // ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);

    const [choose, setChoose] = useState('');

    const [customers, setCustomers] = useState([])

    const [counties, setCounties] = useState([])

    const [trigger, setTrigger] = useState(0)

    const [width, setWidth] = useState(0)



    const dispatch = useDispatch()

    // ======   Country   ======
    const cntry = useSelector(getAllCountries)

    useEffect(() => {
        dispatch(getCountry())
    }, [])

    useEffect(() => {
        setCounties(cntry)
    }, [cntry])


    const CountrySelect = counties.map(val => ({
        label: val.country,
        value: val.country
    }))

    //=====     customer select     ======
    const ctrs = useSelector(getAllCustomers)

    useEffect(() => {
        dispatch(getCustomer())
    }, [])

    useEffect(() => {
        setCustomers(ctrs)
    }, [ctrs])


    const CustomerSelect = customers.map(cust => ({
        label: cust.company_name,
        value: cust.company_name
    }))

    const handleSelectedCustomer = (e) => {
        const selectCust = customers.find(obj => obj.company_name === e);
        setSelectedCustomer(selectCust)
    }

    //=====     ends    ======

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const HandleAddCustomer = () => {
        dispatch(getCustomer())
        handleOk()
    }

    const HandleAddCountry = () => {
        dispatch(getCountry())
        handleOk()
    }

    const onViewRow = () => {
        setWidth(1000)
        setTrigger(trigger + 1)
        setModalTitle("Add Customer");
        showModal();
        setModalContent(<AddingCustomers quotationTrigger={trigger} HandleCustFromQuot={HandleAddCustomer} />);
    }

    const onViewRows = () => {
        setWidth(500)
        setTrigger(trigger + 1)
        setModalTitle("Add Country");
        showModal();
        setModalContent(<Country setShipTo={setShipTo} quotationTrigger={trigger} HandleCountryFormQuot={HandleAddCountry} />);
    }

    const SetCountry = (values) => {
        request.post('choice/add_country/', values)
            .then(resp => {
                handleOk()
            })
            .catch(error => console.log(error))
    }


    const handleOnChange = (date) => {
        setSelectedDate(date);
    };

    const Shipby = [
        { label: 'Sea', value: 'Sea' },
        { label: 'Air', value: 'Air' },
    ]


    const handleChoose = (value) => {
        setChoose(value)
        handleship()
    }

   
    return (
        <div>
            <Row space={[24, 24]}>
                <Col span={24} md={12}>
                    <CustomSelect
                        options={CustomerSelect}
                        showSearch label={'Client Name'}
                        placeholder={'Client Name'} name={'customer_name'}
                        onChange={handleSelectedCustomer}
                        onButtonClick={onViewRow}
                        buttonLabel={'Add Customer'}
                        rules={[
                            {
                                required: true,
                                message: 'Select Client Name !'
                            }
                        ]} />
                    <Input name="customer_obj" display={'none'} />
                </Col>

                <Col span={24} md={12}>
                    <CustomDatePicker label={'Date'} onChange={handleOnChange} name={'date'}
                        rules={[{
                            required: 'true',
                            message: 'Enter the details !'
                        }]} />
                </Col>

                <Col span={24} md={12}>
                    <Input label={'CONTACT NO'} placeholder={'CONTACT NO'} name={'mobile'} />
                </Col>

                <Col span={24} md={12}>
                    <CustomSelect label={'SHIP To'} name={'country'} showSearch={true}
                        onButtonClick={onViewRows} options={CountrySelect} buttonLabel="ADD COUNTRY"
                        placeholder={'Ship To'}
                        rules={[
                            {
                                required: true,
                                message: 'Please enter detail!',
                            },
                        ]}
                    />
                </Col>

                <Col span={24} md={12}>
                    <Input label={'Email ID'} type='email' placeholder={'Email ID'} name={'email'} />
                </Col>

                <Col span={24} md={12}>
                    <Input label={'Origin'} name={'origin'} placeholder={'Origin'} rules={[
                        {
                            required: true,
                            message: 'Please enter detail!',
                        },
                    ]} />
                </Col>

                <Col span={24} md={12}></Col>

                <Col span={24} md={12}>
                    <Select label={'Ship By'} options={Shipby} placeholder={'Ship By'} name={'ship_by'}
                        onChange={handleChoose} rules={[
                            {
                                required: true,
                                message: 'Please enter detail!',
                            },
                        ]} />
                </Col>
                {choose === 'Sea' && (
                    <>
                        <Col span={24} md={12}></Col>
                        <Col span={24} md={12}>
                            <Input label={'Container'} placeholder={'Container'} name={'container_no'} rules={[
                                {
                                    required: true,
                                    message: 'Please enter detail!',
                                },
                            ]}
                            />
                        </Col></>
                )}

            </Row><br />

            <Modal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={width} modalTitle={modalTitle} modalContent={modalContent} />


        </div >
    )
}

export default QuotationForm