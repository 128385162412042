import React, { Fragment, useEffect, useState } from 'react'
import Flex from '../../../Components/Flex';
import Button from '../../../Components/Form/Button';
import { Table } from '../../../Components/Table';
import { Modal } from '../../../Components/Modal';
import Bill from './Bill';
import { TopTitle } from '../../../Components/Form/TopTitle';
import Label from '../../../Components/Form/Label';
import Input from '../../../Components/Form/Input';
import request from '../../../utils/request';



const UnpaidTable = () => {

    const [unpaidData,setUnpaidData] = useState([])

    // ======  Modal Open ========
    const [isModalOpen, setIsModalOpen] = useState(false);

    // ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);

    // ----------  Form Reset UseState ---------
    const [formReset, setFormReset] = useState(0);

    const [searchText, setSearchText] = useState("");

    const handleSearch = (value) => {
        setSearchText(value);
    };
    // ===== Modal Functions Start =====

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const FormExternalClose = () => {
        handleOk();
    };


    const Showbill = (record) => {
        setFormReset(formReset + 1);
        setModalTitle("Unpaid Bills");
        setModalContent(
            <Bill
                formname={"UpdateForm"}
                FormExternalClose={FormExternalClose}
                formReset={formReset}
                handlerecord={record}
                GetUnpaid={GetUnpaid}
            />
        );
        showModal();
    }

    const TableColumn = [
        {
            title: "SI No",
            render: (value, item, index) => index + 1,
        },
        {
            title: "Invoice No",
            dataIndex: "sc_no",
            filteredValue: searchText ? [searchText] : null,
            onFilter: (value, record) => {
                return String(record.sc_no).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.sc_no).includes(value.toUpperCase());
            },
        },
        {
            title: "Date",
            dataIndex: "sale_date",
        },
        {
            title: "Customer Name",
            dataIndex: "customer_name",
        },
        {
            title: "Phone",
            dataIndex: "mobile",
        },
        {
            title: (<div dangerouslySetInnerHTML={{ __html: 'Total bill<br>Amount' }} />),
            dataIndex: "customer_bill_amt",
        },
        {
            title: (<div dangerouslySetInnerHTML={{ __html: 'Deposit<br>Amount' }} />),
            dataIndex: "customer_receive_amt",
        },
        {
            title: (<div dangerouslySetInnerHTML={{ __html: 'Balance<br>Amount' }} />),
            dataIndex: "customer_balance_amt",
        },
        {
            title: "Action",
            render: (record, i) => {
                return (
                    <Flex spaceEvenly={"true"} gap={"10px"}>
                        <Button.Primary text={"pay"} onClick={()=>Showbill(record)} />
                    </Flex>
                );
            },
        },
    ];


    useEffect(()=>{
        GetUnpaid();
    },[])

    const GetUnpaid = () => {
        request.get(`customerpay/get_detail_unpaid_customer/`)
            .then(function (response) {
                setUnpaidData(response.data?.unpaid);
            })
            .catch(function (error) {
                console.log(error)
            });
    }

    return (
        <Fragment>
            <TopTitle Heading={'View Details'} />
            <Flex end={true} centervertically={true}>
                <Label>Search by Invoice No: &nbsp;</Label>
                <Input
                    placeholder="Search by Invoice No"
                    value={searchText}
                    onChange={(e) => handleSearch(e.target.value)} />
            </Flex>
            <Table columns={TableColumn} data={unpaidData} />

            <Modal
                isVisible={isModalOpen}
                handleOk={handleOk}
                handleCancel={handleCancel}
                width={800}
                modalTitle={modalTitle}
                modalContent={modalContent}
            />
        </Fragment>
    )
}

export default UnpaidTable;