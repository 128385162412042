
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import request from "../../../utils/request";

const initialState = {
    finance: {},
    status: 'idle',  // 'idle' | 'loading' | 'succeeded' | 'failed'  
    error: null
}

export const getFinace = createAsyncThunk(
    "consignment/Get",
    async () => {
        try {
            const response = await request.get('consignment/finance_report/');
            return response.data
        }
        catch (error) {
            throw error;
        }
    }
);


const FinanceSlice = createSlice({
    name: 'finance',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getFinace.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(getFinace.fulfilled, (state, action) => {

                state.status = 'succeeded'
                state.finance = action.payload;
            })
            .addCase(getFinace.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
})

export const selectAllFinace = (state) => state.finace.finance
export const getFinaceStatus = (state) => state.finace.status
export const getFinaceError = (state) => state.finace.error

export const { reducer } = FinanceSlice;

export default FinanceSlice.reducer


