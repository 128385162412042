import React from 'react'
import PackingList from './Partials/PackingList'

const PackingListReport = () => {
  return (
    <div>
        <PackingList />
    </div>
  )
}

export default PackingListReport

