import { Card, Col, Divider, Form, Popover } from "antd"
import React, { Fragment, useEffect, useState } from "react"
import dayjs from 'dayjs'
import Flex from "../../../Components/Flex"
import Button from "../../../Components/Form/Button"
import { DeleteOutlined } from "@ant-design/icons"
import Input from "../../../Components/Form/Input"
import { CustomInputNumber } from "../../../Components/Form/CustomInputNumber"
import { DeleteButtonWrapper, Table } from "../../../Components/Table"
import { Select } from "../../../Components/Form/Select"
import { Row } from "../../../Components/Row"
import { Modal } from "../../../Components/Modal"
import request from "../../../utils/request"
import { toast } from "react-toastify"
import { CustomSelect } from "../../../Components/Form/CustomSelect"
import QuotationForm from "./QuotationForm"
import { CustomCard } from "../../../Components/CustomCard"
import FooterForm from "./FooterForm"
import { TextAreas } from "../../../Components/Form/TextArea"
import { selectAllProducts, getProduct } from '../../Products/ProductSlice'
import { useDispatch, useSelector } from "react-redux"
import AddCurrency from "../../AddModules/AddCurrency"
import { getAllCurrencies, getCurrency, getContainer, getAllContainers } from "../../AddModules/AddModulesSlice"
import { AddContainer } from "../../AddModules/AddContainer"
import { TableImageHolder } from "../../../Components/TableImageHolder"
import { getSaleUnit, selectGetSaleUnit } from "../../Sales/SalesSlice"
import AddUnitMeasurement from "../../AddModules/AddUnit"
import { useNavigate, useParams } from "react-router-dom"
import { PrintQuatation } from "./Print"
import { AddItems } from "../../Products/Add Items/Partials/AddItems"

const QuotationTable = () => {

  const [count, setCount] = useState(1);
  const [unitData, setUnitData] = useState([])
  const [width, setWidth] = useState(0)
  const [getproductData, setGetproductData] = useState(0)

  const dispatch = useDispatch()

  // ======  Modal Open ========
  const [isModalOpen, setIsModalOpen] = useState(false);

  // ======  Modal Title and Content ========
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  // =============  Product Select  =============
  const [productList, setProductList] = useState([])

  // ======   Selected Customer   ======
  const [selectedCustomer, setSelectedCustomer] = useState({})

  // ======   trigger to reset fields   ======
  const [trigger, setTrigger] = useState(0)
  const [allCurrency, setAllCurrency] = useState([])
  const [allContainers, setAllContainers] = useState([])
  const [ShipTo, setShipTo] = useState()
  const [ContainerAdd, setcontainerAdd] = useState()
  const [PriceAdd, setpriceAdd] = useState()

  useEffect(() => {

    form.setFieldsValue({
      mobile: selectedCustomer?.mobile_number,
      email: selectedCustomer?.email,
      customer_obj: selectedCustomer.id
    })
  }, [selectedCustomer])

  useEffect(() => {
    form.setFieldsValue({ country: ShipTo })
  }, [ShipTo])

  useEffect(() => {
    form.setFieldsValue({ container_name: ContainerAdd })
  }, [ContainerAdd])

  useEffect(() => {
    form.setFieldsValue({ currency: PriceAdd })
  }, [PriceAdd])

  // =====  get all products  ======

  const allProducts = useSelector(selectAllProducts)

  useEffect(() => {
    dispatch(getProduct())
  }, [getproductData])

  useEffect(() => {
    setProductList(allProducts)
  }, [allProducts])

  //=========================get Unit =============================//

  const unitreponse = useSelector(selectGetSaleUnit)

  useEffect(() => {
    dispatch(getSaleUnit())
  }, [])

  useEffect(() => {
    setUnitData(unitreponse)
  }, [unitreponse])


  const quaotationunitGet = () => {
    dispatch(getSaleUnit())

  }


  const itemDetails = productList.map(item => ({ label: item.item_name, value: item.id }));

  const unitselectOptions = unitData.map((com) => ({ label: com.unit, value: com.unit }));


  //======================================================


  // =====  get all currency  ======
  const Curr = useSelector(getAllCurrencies)

  useEffect(() => {
    dispatch(getCurrency())
  }, [])

  useEffect(() => {
    setAllCurrency(Curr)
  }, [Curr])

  const currencySelect = allCurrency.map(val => ({
    label: val.rate,
    value: val.rate
  }))

  //=====  get all containers  ======
  const Container = useSelector(getAllContainers)

  useEffect(() => {
    dispatch(getContainer())
  }, [])

  useEffect(() => {
    setAllContainers(Container)
  }, [Container])

  const containerSelect = allContainers.map((val) => ({
    label: val.container_name,
    value: val.container_name
  }))

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [form] = Form.useForm();

  // =========================  Other Functions End  =========================

  const initialData = [
    {
      key: 0,
      product_name: '',
      // item_name: '',
      product_image: '',
      description: '',
      unit: '',
      weight: '',
      packing: '',
      container: '',
      packing_note: '',
      quantity: '',
      total: '',
      amount: '',
      remarks: '',
    },
  ];


  const [tableData, setTableData] = useState(initialData);

  // +++++++++++++++++++++   Use Effects Start +++++++++++++++++++

  // ------------------  Dynamic Table  --------------------

  useEffect(() => {
    tableData.forEach(record => {
      form.setFieldsValue({ [`item_name${record.key}`]: record.item_name });
      form.setFieldsValue({ [`product${record.key}`]: record.product });
      form.setFieldsValue({ [`product_image${record.key}`]: record.product_image });
      form.setFieldsValue({ [`product_name${record.key}`]: record.product_name });
      form.setFieldsValue({ [`description${record.key}`]: record.description });
      form.setFieldsValue({ [`quantity${record.key}`]: record.quantity });
      form.setFieldsValue({ [`total${record.key}`]: record.total });
      form.setFieldsValue({ [`amount${record.key}`]: record.amount });
      form.setFieldsValue({ [`remarks${record.key}`]: record.remarks });

    });
  }, [tableData])



  //==============================Reverse =============================================


  // ===========  ITEM MODAL SHOW  ==================

  const handleAddContainer = () => {
    dispatch(getContainer())
    handleOk()
  }

  const AddContainerModel = () => {
    setWidth(500)
    setTrigger(trigger + 1)
    setModalTitle("Add Container");
    setModalContent(<AddContainer setcontainerAdd={setcontainerAdd} QuotationTrigger={trigger} handleAddContainer={handleAddContainer} />);
    showModal();
  };

  const handleAddCurrency = () => {
    dispatch(getCurrency())
    handleOk()
  }

  const AddCurrencyModel = () => {
    setWidth(500)
    setTrigger(trigger + 1)
    setModalTitle("Add Currency");
    setModalContent(<AddCurrency setpriceAdd={setpriceAdd} QuotationTrigger={trigger} handleAddCurrency={handleAddCurrency} />);
    showModal();
  };

  const handleship = () => {
    form.setFieldsValue({ container_no: setTrigger(trigger + 1) })

  }

  //================Add Unit=======================================//
  const hadleUnitAdd = () => {
    setWidth(500)
    setTrigger(trigger + 1)
    setModalTitle("");
    setModalContent(<AddUnitMeasurement quaotationunitGet={quaotationunitGet} handleOk={handleOk} SaleTrigger={trigger} />);
    showModal();

  }

  //================Add Product=======================================//
  const handleQuotationSelectProductGet = () => {
    setGetproductData(getproductData + 1)
  }
  const hadleProdctAdd = () => {
    setTrigger(trigger + 1)
    setWidth(700)
    setModalTitle("Add Product");
    setModalContent(<AddItems handleOkSale={handleOk} handleQuotationSelectProductGet={handleQuotationSelectProductGet} QuotationTrigger={trigger} />);
    showModal();
  }



  const columns = [
    {
      title: '#',
      render: (text, record, index) => {

        return (
          (
            <Flex alignCenter gap={'20px'} style={{ alignItems: 'center' }}>
              <h4>{index + 1}</h4>
              <DeleteButtonWrapper>
                <Button
                  style={{
                    display: 'flex',
                    padding: '10px',
                    height: 'auto',
                    fontSize: '16px',
                  }}
                  htmlType="button"
                  danger
                  onClick={() => onDelete(record.key)}
                >
                  <DeleteOutlined />
                </Button>
              </DeleteButtonWrapper>
            </Flex>
          )
        );
      },
    },
    {
      title: 'PRODUCT',
      children: [
        {
          title: <Button.Yellow text={'Add Product'} onClick={() => hadleProdctAdd()} />,
          dataIndex: 'item_name',
          key: 'item_name',
          render: (text, record, index) => (

            <>
              <Select
                rules={[
                  {
                    required: true,
                    message: 'This is a required field',
                  },
                ]}
                minWidth={'120px'}
                showSearch
                name={`product${record.key}`}
                options={itemDetails}
                onChange={(value) => handleOnChangeProduct(value, record)}
              />
              <Input name={`product_name${record.key}`} display={'none'}/>
            </>

          )
        },
      ]
    },
    {
      title: 'IMAGE',
      dataIndex: 'product_image',
      render: (image, record) => {
        return (
          (image) ?
            <>
              <TableImageHolder imageUrl={image} />
              <Input name={`product_image${record.key}`} display={'none'} />
            </>
            :
            null
        )
      }

    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'description',
      render: (text, record) => (
        <TextAreas
          style={{ width: '200px', }}
          name={`description${record.key}`}
          onChange={(value) => handleOnChangeDescription(value, record)}
          rules={[
            {
              required: true,
              message: 'This is a required field',
            },
          ]}
        />
      )
    },
    {
      title: 'weight',
      dataIndex: 'weight',
      key: 'weight',
      render: (text, record) => {
        return (
          <>
            <Input
              minWidth={'120px'}
              name={`weight${record.key}`}
              onChange={(value) => handleOnChangeWeight(value, record)}
              rules={[
                {
                  required: true,
                  message: 'This is a required field',
                },
              ]}
            />
          </>
        )
      }
    },
    {
      title: 'PACKING',
      dataIndex: 'packing',
      key: 'packing',
      render: (text, record) => {
        return (
          <>
            <TextAreas
              style={{ width: '200px' }}
              name={`packing${record.key}`}
              onChange={(value) => handleOnChangePacking(value, record)}
              rules={[
                {
                  required: true,
                  message: 'This is a required field',
                },
              ]}
            />
          </>
        )
      }
    },
    {
      title: 'CONTAINER',
      dataIndex: 'container',
      key: 'container',
      children: [
        {
          title: <CustomSelect name={'container_name'} options={containerSelect} placeholder={'CONTAINER'}
            buttonLabel={'ADD'} onButtonClick={AddContainerModel}
            rules={[
              {
                required: true,
                message: 'This is a required field',
              },
            ]} />,
          dataIndex: 'container',
          key: 'container',
          render: (text, record) => (
            <Input precision={2} rules={[
              {
                required: true,
                message: 'This is a required field'
              },
            ]}
              minWidth={'120px'}
              name={`container${record.key}`}

            />
          )
        },
      ]
    },
    {
      title: 'PCK NOTE',
      dataIndex: 'packing_note',
      key: 'packing_note',
      render: (text, record) => (
        <TextAreas
          style={{ width: '200px' }}
          name={`packing_note${record.key}`}
          onChange={(value) => handleOnChangePckNote(value, record)}
          rules={[
            {
              required: true,
              message: 'This is a required field',
            },
          ]}
        />
      )
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      children: [
        {
          title: <Button.Yellow text={'Add Unit'} onClick={() => hadleUnitAdd()} />,

          render: (text, record) => (
            <>
              <CustomInputNumber precision={2} rules={[
                {
                  required: true,
                  message: 'This is a required field'
                },
              ]}
                minWidth={'120px'}
                min={1.00}
                placed={'end'}
                name={`quantity${record.key}`}
                onChange={(value) => handleOnChangeQuantity(value, record)}
              />
              <Select
                rules={[
                  {
                    required: true,
                    message: 'This is a required field',
                  },
                ]}
                minWidth={'120px'}
                showSearch
                name={`unit${record.key}`}
                options={unitselectOptions}
                onChange={(value) => handleOnChangeUnits(value, record)}
              />
            </>
          )
        }
      ]
    },
    {
      title: 'Price',
      dataIndex: 'total',
      key: 'total',
      children: [
        {
          title: <CustomSelect name={'currency'} options={currencySelect} placeholder={'CURRENCY'}
            buttonLabel={'ADD'} onButtonClick={AddCurrencyModel}
            rules={[
              {
                required: true,
                message: 'This is a required field',
              },
            ]} />,
          dataIndex: 'currency',
          key: 'currency',
          render: (text, record) => (
            <CustomInputNumber precision={2} rules={[
              {
                required: true,
                message: 'This is a required field'
              },
              {
                type: 'number',
                min: 0,
                message: 'Value must be positive'
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (parseFloat(value) <= 0) {
                    return Promise.reject('Quantity must be greater than 1');
                  }
                  return Promise.resolve();
                },
              }),
            ]}
              minWidth={'120px'}
              name={`total${record.key}`}
              onChange={(value) => handleOnChangePrice(value, record)}
            />
          )
        },
      ]
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (text, record) => {
        return (
          <>
            <CustomInputNumber precision={2} disabled rules={[
              {
                required: true,
                message: 'This is a required field'
              },
            ]}
              minWidth={'120px'}
              name={`amount${record.key}`}
              onChange={(value) => handleOnChangeAmount(value, record)}
            />
          </>
        )
      }
    },
    {
      title: <div style={{ whiteSpace: 'pre-line' }}>Remarks or<br />Notes</div>,
      dataIndex: 'remarks',
      key: 'remarks',
      render: (text, record) => (
        <TextAreas
          style={{ width: '200px' }}
          placed={'end'}
          name={`remarks${record.key}`}
          onChange={(value) => handleOnChangeRemarks(value, record)}
        />
      )
    },
  ]

  // ===============  Table Data End ==================


  // ==================  Table Functions Start ==================

  // ----------------- Add Row Function 

  const AddRow = () => {
    const newData = {
      key: count,
      product_name: '',
      product_image: '',
      description: '',
      weight: '',
      packing: '',
      container: '',
      packing_note: '',
      quantity: '',
      total: '',
      remarks: '',
    };
    setTableData(pre => {
      return [...pre, newData]
    })
    setCount(count + 1);
  }

  // -----------------------  Delete Row Function

  const onDelete = (key) => {
    if (tableData.length > 1) {
      setTableData(prevState => {
        const newData = prevState.filter(item => item.key !== key);

        return newData;
      });
    } else {
      console.log(`only ${tableData.length} is available`)
    }
  };

  const HandleProduct = (value, record) => {
    setTableData(prevState => {
      const newData = [...prevState]
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];


      const setSelectedProduct = productList.find(item => item.id === value);
      item.item_name = setSelectedProduct.item_name;
      // item.item = setSelectedProduct.id;
      item.product = setSelectedProduct.id;
      item.product_name = setSelectedProduct.item_name
      item.product_image = setSelectedProduct.item_image;
      item.description = setSelectedProduct.item_description
      const isItemAlreadyAdded = newData.some(
        (item, i) => i !== index && item.product === value
      );

      if (isItemAlreadyAdded) {
        item.item = '';
        item.item_name = '';
        item.product = '';
        item.description = '';
        item.product_image = '';
        toast.error("Product already added in the table.");
        return newData;
      }
      item.item_name = value;
      return newData;
    });
  }

  const HandleDescription = (value, record) => {
    setTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];
      item.description = value;

      return newData;
    });
  }

  const HandleUnit = (value, record) => {
    setTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];
      item.unit = value || 0;
      return newData;
    });
  };

  const HandleWeight = (value, record) => {
    setTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];
      item.weight = value;

      return newData;
    });
  }

  const HandlePacking = (value, record) => {
    setTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];
      item.packing = value;

      return newData;
    });
  }

  const HandlePckNote = (value, record) => {
    setTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];
      item.packing_note = value;

      return newData;
    });
  }

  const HandleQuantity = (value, record) => {
    setTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];
      item.quantity = value;
      item.total = record.total;

      let TotalAmt = item.quantity * item.total;
      item.amount = TotalAmt;

      return newData;
    });
  }

  const HandleRemarks = (value, record) => {
    setTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];
      item.remarks = value;

      return newData;
    });
  }

  const HandlePrice = (value, record) => {
    setTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];
      item.quantity = record.quantity;
      item.total = value;

      let TotalAmt = item.quantity * item.total;
      item.amount = TotalAmt;

      return newData;
    });
  }

  const HandleAmount = (value, record) => {
    setTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];
      item.amount = value;

      return newData;
    });
  }


  // ----------------  ONCHANGE Function

  const handleOnChangeProduct = (value, record) => {
    HandleProduct(value, record)
  }

  const handleOnChangeDescription = (value, record) => {
    HandleDescription(value.target.value, record)
  }
  const handleOnChangeUnits = (value, record) => {
    HandleUnit(value, record);
  };

  const handleOnChangeWeight = (value, record) => {
    HandleWeight(value, record)
  }

  const handleOnChangePacking = (value, record) => {
    HandlePacking(value.target.value, record)
  }

  const handleOnChangePckNote = (value, record) => {
    HandlePckNote(value.target.value, record)
  }

  const handleOnChangeQuantity = (value, record) => {
    HandleQuantity(value, record)
  }

  const handleOnChangeRemarks = (value, record) => {
    HandleRemarks(value.target.value, record)
  }

  const handleOnChangePrice = (value, record) => {
    if (value < 0) {
      toast.warn("Please enter Amount more than 'Zero' ")
    }
    else {
      HandlePrice(value, record)
    }
  }

  const handleOnChangeAmount = (value, record) => {
    HandleAmount(value, record)
  }

  const onRests = () => {
    handleOk();
  }
  const navigate = useNavigate()

  const HandleModel = ({ id }) => {
    return (
      <>
        <Flex center>
          <h1 style={{ fontSize: '20px', color: '#685e5e' }}>Do you Want to go the Print page</h1>
        </Flex>
        <Flex center gap={'50px'} style={{ margin: '20px 0px' }} >
          <Button.Primary text={'Yes'} onClick={() => navigate(`/Print/${id}`)} />
          <Button.Danger text={'No'} onClick={onRests} />
        </Flex>
      </>
    )
  }

  const onOpenConfirm = (id) => {
    setWidth(400)
    setModalTitle("");
    setModalContent(<HandleModel id={id} />);
    showModal();
  };

  const PrintPdfOpen = (values) => {
    setWidth(800)
    setModalTitle("Add Customer");
    setModalContent(<PrintQuatation />);
    showModal();
  };




  //  ======================  Other Functions =========

  // ====================  On Finish Function ============

  const onFinish = (values) => {
    const record = { ...values };
    console.log(values, 'aaaaaa');
    const formateDate = new Date(record.date)
    const format = 'YYYY-MM-DD'
    const formattedDate = dayjs(formateDate).format(format)

    let result = {
      customer_name: record.customer_name,
      customer_obj: record.customer_obj,
      date: formattedDate,
      mobile: record.mobile,
      country: record.country,
      email: record.email,
      origin: record.origin,
      ship_by: record.ship_by,
      container_no: record.container_no,
      termsandconditions: record.termsandconditions,
      currency: record.currency,
      container_new: record.container_name,

      sales: Object.entries(record)
        .filter(([key]) => key.startsWith('product_name'))
        .map(([key, product_name]) => {
          const index = key.match(/\d+/)[0];

          const productImageKey = `product_image${index}`
          const itemidkey = `product${index}`;
          const descriptionKey = `description${index}`
          const unitKey = `unit${index}`
          const weightKey = `weight${index}`
          const packingKey = `packing${index}`
          const containerKey = `container${index}`
          const packingNoteKey = `packing_note${index}`
          const quantityKey = `quantity${index}`
          const totalKey = `total${index}`
          const amountKey = `amount${index}`
          const remarksKey = `remarks${index}`

          return {
            product_name,
            product: record[itemidkey],
            product_image: record[productImageKey],
            description: record[descriptionKey],
            unit: record[unitKey],
            weight: record[weightKey],
            packing: record[packingKey],
            container: record[containerKey],
            packing_note: record[packingNoteKey],
            quantity: record[quantityKey],
            total: record[totalKey],
            total_amt: record[amountKey],
            remarks: record[remarksKey],
          }
        })

    }
    QuotationPost(result);
  };


  const QuotationPost = (values) => {
    request.post('quotation/add_quo/', values)
      .then(function (response) {
        if (response.status == 201) {
          toast.success('Quotation Created Successfully!')
          form.resetFields();
          setSelectedCustomer({})
          setTableData(initialData);
        }
        if (response.data?.id) {
          onOpenConfirm(response.data.id);
        }
      })
      .catch(function (error) {
        console.log(error, 'error');
      });
  }

  const onFinishFailed = (errorInfo) => {
    toast.error('Please fill the Details')
  };


  // ==============  Add Row Component  ================

  const FooterComponent = () => {
    return (
      <div style={{ background: 'var(--light-color)', padding: '20px' }}>
        <Row>
          <Col lg={4} sm={12} span={24}><Button type="primary" style={{
            fontSize: '1rem',
            height: 'auto',
            fontFamily: 'Poppins',
            fontWeight: 500,
            letterSpacing: '1px',
          }}
            htmlType="button"
            onClick={AddRow}>
            Add Row
          </Button>
          </Col>
        </Row>
      </div >
    )
  }

  // ==================  Table  ==================
  const onRest = () => {
    form.resetFields();
    dispatch(getProduct())
    dispatch(getCurrency())
    dispatch(getContainer())
    setTrigger((trigger) => trigger + 1);
    setTableData(initialData);
    setSelectedCustomer({})
  }

  useEffect(() => {
    form.setFieldsValue({origin:'CHINA'})
  }, [])
  return (
    <Fragment>
      <Form
        name="quotation"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        form={form}
        initialValues={
          {
            date: dayjs(),
          }
        }
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off">

        <CustomCard>
          <QuotationForm setShipTo={setShipTo} setSelectedCustomer={setSelectedCustomer} handleship={handleship} />
        </CustomCard>

        <div style={{ margin: '20px 0' }}>
          <Table columns={columns.filter(Boolean)} data={tableData} pagination={false} />
          <FooterComponent />
        </div>


        <div style={{ background: 'white', padding: '10px 20px' }}>
          <FooterForm />
        </div>

        <Card>
          <Flex center={'true'} gap={'20px'} margin={'20px 0'}>
            <Button.Primary text={'Submit'} htmlType="submit" />
            <Button.Danger text={'Cancel'} onClick={onRest} />
          </Flex>
        </Card>
      </Form>

      <Modal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={width} modalTitle={modalTitle} modalContent={modalContent} />
    </Fragment>
  )
}

export default QuotationTable
