import { Card, Col, Form } from "antd"
import React, { Fragment, useEffect, useState } from "react"
import dayjs from 'dayjs'
import Flex from "../../../Components/Flex"
import Button from "../../../Components/Form/Button"
import { DeleteOutlined } from "@ant-design/icons"
import Input from "../../../Components/Form/Input"
import { CustomInputNumber } from "../../../Components/Form/CustomInputNumber"
import { DeleteButtonWrapper, Table } from "../../../Components/Table"
import { Select } from "../../../Components/Form/Select"
import { Row } from "../../../Components/Row"
import { Modal } from "../../../Components/Modal"
import request from "../../../utils/request"
import { toast } from "react-toastify"
import { CustomSelect } from "../../../Components/Form/CustomSelect"
import { useNavigate, useParams } from "react-router-dom"
import { AddItems } from "../../Products/Add Items/Partials/AddItems"
import AddUnitMeasurement from "../../AddModules/AddUnit"
import AddCurrency from "../../AddModules/AddCurrency"
import InvoiceHeader from "./InvoiceHeader"
import InvoiceFooter from "./InvoiceFooter"
import { numberToWords } from "../../../utils/numtowords"
import { TextAreas } from "../../../Components/Form/TextArea"



export const InvoiceEntryTable = ({ setSaleorder }) => {

    const [count, setCount] = useState(1);

    const [width, setWidth] = useState(0)

    const [disabless, setDisabless] = useState(false)

    const [unit, setUnit] = useState([])

    const [productname, setProductname] = useState([])

    const [currency, setCurrency] = useState([])

    const { id } = useParams()

    const { invoiceNo } = useParams()

    const navigate = useNavigate()

    const [rateAddTable, setRateAddTable] = useState()

    const [addNewPaymentTerms, setAddNewPaymentTerms] = useState()

    const [ShipTo, setShipTo] = useState()

    // ======  Modal Open ========
    const [isModalOpen, setIsModalOpen] = useState(false);

    // ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);

    const [invoiceNumber, setInvoiceNumber] = useState({})

    // ======  Selected Date ========
    const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY-MM-DD'));
    const [selectedDates, setSelectedDates] = useState(dayjs().format('YYYY-MM-DD'));
    const [selectedrevisedDates, setSelectedrevisedDates] = useState(dayjs().format('YYYY-MM-DD'));

    // ================  SalesFormFooter checked ==========
    const [round, setRound] = useState(false);
    const [roundDecimalValue, setRoundDecimalValue] = useState(null);
    const [balance, setBalance] = useState(false);

    // -----------------  Balance Checking ------------
    const [withDecimal, setWithDecimal] = useState(null);
    const [withOutDecimal, setWithOutDecimal] = useState(null);
    const [balanceChangeAmount, setBalanceChangeAmount] = useState(0);
    const [balanceChange, setBalanceChange] = useState(false);

    const [footermsg, setFootermsg] = useState();
    const [fotData, setFotData] = useState({})

    const [selectedSupplierDetails, setSelectedSupplierDetails] = useState({})

    const [terms, setTerms] = useState({})


    const [payType, setPayType] = useState('Cash');


    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const [form] = Form.useForm();

    // =========================  Modal Content Start  ===========================
    useEffect(() => {
        form.setFieldsValue({ country: ShipTo })
    }, [ShipTo])

    useEffect(() => {
        form.setFieldsValue({ currency: rateAddTable })
    }, [rateAddTable])

    useEffect(() => {
        form.setFieldsValue({ payment_terms: terms.payment_terms })
        form.setFieldsValue({ country: terms.country })
    }, [terms])

    useEffect(() => {
        form.setFieldsValue({ customer_obj: selectedSupplierDetails.id })
        form.setFieldsValue({ company_name: selectedSupplierDetails.company_name })
        form.setFieldsValue({ email: selectedSupplierDetails.email })
        form.setFieldsValue({ address: selectedSupplierDetails.address })
        form.setFieldsValue({ contact_person_name: selectedSupplierDetails.contact_person_name })
        form.setFieldsValue({ cr_no: selectedSupplierDetails.cr_no })
        form.setFieldsValue({ import_no: selectedSupplierDetails.import_no })
        form.setFieldsValue({ vat_no: selectedSupplierDetails.vat_no })
        form.setFieldsValue({ customer_name: selectedSupplierDetails.contact_person_name })
        form.setFieldsValue({ mobile_number: selectedSupplierDetails.mobile_number })
        form.setFieldsValue({ invoice_no: selectedSupplierDetails.invoice_no })
    }, [selectedSupplierDetails, form])

    useEffect(() => {
        form.setFieldsValue({ payment_terms: addNewPaymentTerms })
    }, [addNewPaymentTerms])


    // -----------------------  RoundOff Checked Function ----------

    const TotalBalance = (value) => {
        setBalance(value)
        setWithDecimal(tableSecondaryData[0].total_amount - roundDecimalValue);
        setWithOutDecimal(tableSecondaryData[0].total_amount)
    }

    // =========================  Footer Cheque Hide  ===========================

    const HandleCheQueChage = (value) => {

        if (value === 'Cheque') {
            form.setFieldsValue({ paid: false })

            const totalAmt = tableSecondaryData[0].total_amount - roundDecimalValue;

            if (round) {
                form.setFieldsValue({ received: '0.00' })
                form.setFieldsValue({ balance: totalAmt });
                form.setFieldsValue({ roundoff_amount: totalAmt });
            }
            else {
                form.setFieldsValue({ received: '0.00' })
                form.setFieldsValue({ balance: tableSecondaryData[0].total_amount });
                form.setFieldsValue({ roundoff_amount: tableSecondaryData[0].total_amount });
            }
        }
    }

    // =========================  Other Functions start  =========================

    const [confirmation, setConfirmation] = useState([])

    // ===================== Add Unit Select ====================

    const hadleUnitAdd = () => {
        setWidth(400)
        setModalTitle("Add Unit");
        setModalContent(<AddUnitMeasurement AddUnitGet={AddUnitGets} />);
        showModal();
    }

    const AddUnitGets = () => {
        if (AddUnitGet) {
            AddUnitGet()
            handleOk()
        }
    }
    useEffect(() => {
        AddUnitGet()
    }, [])

    const UNITADD = 'choice/add_measurement/'

    const AddUnitMeasure = unit.map((com) => ({ label: com.unit, value: com.unit }));

    const AddUnitGet = () => {
        request.get(`${UNITADD}`)
            .then(function (response) {
                setUnit(response.data)
            })
            .catch(function (error) {
            });
    }

    // ===================== Currency Name Select ====================

    const handleCurrency = () => {
        setWidth(400)
        setModalTitle("Add Currency");
        setModalContent(<AddCurrency AddCurrenynameGet={AddCurrencyData} setRateAddTable={setRateAddTable} />);
        showModal();
    };

    const AddCurrencyData = () => {
        if (AddCurrenynameGet) {
            AddCurrenynameGet();
            handleOk()
        }
    }

    const ADCURENCY = 'choice/add_currency/'

    const AddCuurencyName = currency?.map((com) => ({ label: com.rate, value: com.rate }));

    const AddCurrenynameGet = () => {
        request.get(`${ADCURENCY}`)
            .then(function (response) {
                setCurrency(response.data)
            })
            .catch(function (error) {
                // toast.error('Failed');
            });
    }
    useEffect(() => {
        AddCurrenynameGet()
    }, [])


    // =========================  Other Functions End  =========================




    useEffect(() => {
        if (id) {
            Getpatch()
        }
    }, [])

    const URLE = 'sale_confirmation/new_invoice_get_sale_confirmation'

    useEffect(() => {
        if (id) {
            form.setFieldsValue({ id })
        }
    }, [id])

    useEffect(() => {
        if (id) {
            form.setFieldsValue({ purchaseorderconfirmation_no: confirmation.id })
        }
    }, [])

    const Getpatch = () => {
        request.get(`${URLE}/${id}/`)
            .then(function (response) {
                setTableData(response.data?.sale_table)
                setFotData(response.data?.esti_quot)
            })
            .catch(function (error) {
                console.error(error);
            });
    }

    const initialData = [
        {
            key: 0,
            item: '',
            product_name: '',
            hsn_code: '',
            description: '',
            qty: '',
            item_unit: '',
            price_per_unit: '',
            price: '',
            unit: '',
            sell: '',
            discount_percentage: '',
            discount_total: '',
            tax_percentage: '',
            tax_amt: '',
            amount: '',
        },
    ];

    const secondaryData = [
        {
            total_all: '',
            total_pieces: '',
            total_qty: '',
            total_discount: '',
            total_tax: '',
            total_amount: '',
            deposit_amt: '',
        },
    ];

    const footerCalData = [
        {
            roundoff: '',
            total_amount: '',
            total_rowamount: '',
            received: '',
            balance: '',
            deposit_amt: '',
        },
    ];


    const [tableData, setTableData] = useState(initialData);
    const [tableSecondaryData, setTableSecondaryData] = useState(secondaryData);
    const [tableFooterData, setTableFooterData] = useState(footerCalData);
    const [InvoiceForm, setInvoiceForm] = useState({})

    const [trigger, setTrigger] = useState(0);

    // +++++++++++++++++++++   Use Effects Start +++++++++++++++++++

    useEffect(() => {
        GetDraft()
    }, [])

    const GetDraft = () => {
        if (invoiceNo) {
            request.get(`invoice/new_get_detail_invoice/${invoiceNo}`)
                .then(response => {
                    setTableData(response.data.invoice_table)
                    setInvoiceForm(response.data)
                })
                .catch(error => console.log(error))
        }
    }

    // ------------------  Dynamic Table  --------------------

    useEffect(() => {
        tableData.forEach(record => {

            form.setFieldsValue({ [`product_name${record.key}`]: record.product_name });
            {
                invoiceNo ?
                    form.setFieldsValue({ [`product_id${record.key}`]: record.product }) :
                    form.setFieldsValue({ [`product_id${record.key}`]: record.product_id });
            }
            form.setFieldsValue({ [`description${record.key}`]: record.description });
            form.setFieldsValue({ [`barcode${record.key}`]: record.barcode });
            form.setFieldsValue({ [`item_unit${record.key}`]: record.item_unit });
            form.setFieldsValue({ [`unit${record.key}`]: record.unit });
            form.setFieldsValue({ [`price_per_unit${record.key}`]: record.price_per_unit });
            form.setFieldsValue({ [`quantity${record.key}`]: record.quantity });
            form.setFieldsValue({ [`rate${record.key}`]: record.rate });
            form.setFieldsValue({ [`total${record.key}`]: record.total });
            form.setFieldsValue({ [`net_weight${record.key}`]: record.net_weight });
            form.setFieldsValue({ [`gross_weight${record.key}`]: record.gross_weight });
            form.setFieldsValue({ [`cbm${record.key}`]: record.cbm });
            form.setFieldsValue({ [`total_amt${record.key}`]: record.total_amt });
        });

        form.setFieldsValue({ [`total_pieces`]: tableSecondaryData[0].total_pieces });
        form.setFieldsValue({ [`total_rate`]: tableSecondaryData[0].total_rate });
        form.setFieldsValue({ [`total_all`]: tableSecondaryData[0].total_all });
        form.setFieldsValue({ [`sub_total`]: tableSecondaryData[0].sub_total });
        form.setFieldsValue({ [`discount_total`]: tableSecondaryData[0].discount_total });
        form.setFieldsValue({ [`gt_inwords`]: tableSecondaryData[0].gt_inwords });



        setWithOutDecimal(tableSecondaryData[0].total_amount);
    }, [tableData])

    useEffect(() => {
        if (invoiceNo) {
            form.setFieldsValue(InvoiceForm)
            form.setFieldsValue({ invoice_date: dayjs(InvoiceForm.invoice_date, 'YYYY/MM/DD') })
            form.setFieldsValue({ shipping_date: dayjs(InvoiceForm.shipping_date, 'YYYY/MM/DD') })
            form.setFieldsValue({ address: InvoiceForm.Address })
            form.setFieldsValue({ country: InvoiceForm.ship_to })
            form.setFieldsValue({ payment_terms: InvoiceForm.payment_term });

            form.setFieldsValue({ total_pieces: InvoiceForm.total_quantity })
            form.setFieldsValue({ total_rate: InvoiceForm.total_rate })
            form.setFieldsValue({ sub_total: InvoiceForm.sub_total })
            form.setFieldsValue({ total_all: InvoiceForm.grand_total })
            form.setFieldsValue({ container: InvoiceForm.container_seal_no });
            form.setFieldsValue({ deposit_amt_enter: InvoiceForm.paid_amt });
            form.setFieldsValue({ sc_no: InvoiceForm.invoice_no });
        }
    }, [InvoiceForm])

    // --------------------- Round Off Checked  -----------------
    useEffect(() => {
        const totalAmt = tableSecondaryData[0].total_amount - roundDecimalValue;
        if (round) {
            if (balance) {
                form.setFieldsValue({ round_off: roundDecimalValue });
                form.setFieldsValue({ roundoff_amount: totalAmt });
                form.setFieldsValue({ balance: 0 });
                form.setFieldsValue({ received: totalAmt });
                setBalanceChangeAmount(totalAmt);
            }
            else {
                form.setFieldsValue({ round_off: roundDecimalValue });
                form.setFieldsValue({ roundoff_amount: totalAmt });
                form.setFieldsValue({ balance: totalAmt });
                form.setFieldsValue({ received: 0 });
                setBalanceChangeAmount(0);
            }
        }
        else {
            if (balance) {
                form.setFieldsValue({ round_off: 0 });
                form.setFieldsValue({ roundoff_amount: tableSecondaryData[0].total_amount });
                form.setFieldsValue({ balance: 0 });
                form.setFieldsValue({ received: tableSecondaryData[0].total_amount });
                setBalanceChangeAmount(tableSecondaryData[0].total_amount);
            }
            else {
                form.setFieldsValue({ round_off: 0 });
                form.setFieldsValue({ roundoff_amount: tableSecondaryData[0].total_amount });
                form.setFieldsValue({ balance: tableSecondaryData[0].total_amount });
                form.setFieldsValue({ received: 0 });
                setBalanceChangeAmount(0);
            }
        }

    }, [round])

    //==============================Reverse =============================================
    useEffect(() => {

        const num = tableSecondaryData[0].total_amount;
        const newInteger = parseInt(num);
        const newDecimal = (num - newInteger).toFixed(2).substr(1);
        setRoundDecimalValue(newDecimal);

        setWithDecimal(tableSecondaryData[0].total_amount - newDecimal);
        const totalAmt = tableSecondaryData[0].total_amount - newDecimal;

        if (round) {
            if (balance) {
                form.setFieldsValue({ round_off: newDecimal });
                form.setFieldsValue({ roundoff_amount: totalAmt });
                form.setFieldsValue({ balance: 0 });
                form.setFieldsValue({ received: totalAmt });
            }
            else {
                form.setFieldsValue({ round_off: newDecimal });
                form.setFieldsValue({ roundoff_amount: totalAmt });
                form.setFieldsValue({ balance: totalAmt });
                form.setFieldsValue({ received: 0 });
            }
        }
        else {
            if (balance) {
                form.setFieldsValue({ round_off: 0 });
                form.setFieldsValue({ roundoff_amount: tableSecondaryData[0].total_amount });
                form.setFieldsValue({ balance: 0 });
                form.setFieldsValue({ received: tableSecondaryData[0].total_amount });
            }
            else {
                form.setFieldsValue({ round_off: 0 });
                form.setFieldsValue({ roundoff_amount: tableSecondaryData[0].total_amount });
                form.setFieldsValue({ balance: tableSecondaryData[0].total_amount });
                form.setFieldsValue({ received: 0 });
            }
        }

    }, [tableData])

    // ===========  CURRENCY MODAL SHOW  ==================


    const hadleProdctAdd = () => {
        setWidth(900)
        setModalTitle("");
        setModalContent(<AddItems AddProductnameGet={AddProductn} />);
        showModal();
    }

    const AddProductn = () => {

    }

    //==========================================================================
    const BalanceOnChange = (value) => {
        setBalanceChangeAmount(value)
    }

    useEffect(() => {

        if (round) {
            if (balance) {
                form.setFieldsValue({ received: withDecimal });
                form.setFieldsValue({ balance: 0 });
                setBalanceChangeAmount(withDecimal);
            }
            else {
                form.setFieldsValue({ received: 0 });
                form.setFieldsValue({ balance: withDecimal });
                setBalanceChangeAmount(0);

            }
        }
        else {
            if (balance) {
                form.setFieldsValue({ received: withOutDecimal });
                form.setFieldsValue({ balance: 0 });
                setBalanceChangeAmount(withOutDecimal);
            }
            else {
                form.setFieldsValue({ received: 0 });
                form.setFieldsValue({ balance: withOutDecimal });
                setBalanceChangeAmount(0);
            }
        }
    }, [balance])

    useEffect(() => {

        let fizedAmount = 0;

        if (round) {
            fizedAmount = withDecimal;

            if (balance) {
                form.setFieldsValue({ received: withDecimal });
                form.setFieldsValue({ balance: 0 });
                setBalanceChange(false);
            }
            else {
                // ===
                let setAmt = balanceChangeAmount;
                let balSetAmt = withDecimal - setAmt;

                if (balSetAmt < 0) {
                    setBalanceChange(true);
                }
                else {
                    setBalanceChange(false);
                }
                form.setFieldsValue({ received: setAmt });
                form.setFieldsValue({ balance: balSetAmt });
            }
        }
        else {
            fizedAmount = withOutDecimal;
            if (balance) {
                form.setFieldsValue({ received: withOutDecimal });
                form.setFieldsValue({ balance: 0 });
                setBalanceChange(false);
            }
            else {
                // ===
                let setAmt = balanceChangeAmount;
                let balSetAmt = withOutDecimal - setAmt;

                if (balSetAmt < 0) {
                    setBalanceChange(true);
                }
                else {
                    setBalanceChange(false);
                }

                form.setFieldsValue({ received: setAmt });
                form.setFieldsValue({ balance: balSetAmt });
            }
        }

    }, [balanceChangeAmount])


    const dateFormat = 'YYYY/MM/DD';
    const personDate = new Date(fotData?.shipping_date);
    const personFormattedDate = dayjs(personDate).format(dateFormat);
    const partnerDate = fotData?.shipping_date ? new Date(fotData.shipping_date) : null;
    const partnerFormattedDate = partnerDate ? dayjs(partnerDate).format(dateFormat) : null;

    // ===============  Hidden Table Data End ==================

    useEffect(() => {

        form.setFieldsValue({ company_name: fotData?.company_name })
        form.setFieldsValue({ address: fotData?.Address })
        form.setFieldsValue({ cr_no: fotData?.cr_no })
        form.setFieldsValue({ import_no: fotData?.import_no })
        form.setFieldsValue({ vat_no: fotData?.vat_no })
        form.setFieldsValue({ payment_terms: fotData?.payment_terms })
        form.setFieldsValue({ delivery_terms: fotData?.delivery_terms })
        form.setFieldsValue({ company_name: fotData?.company_name })
        form.setFieldsValue({ email: fotData?.email })
        form.setFieldsValue({ invoice_no: fotData?.sale_confirmation_no })
        form.setFieldsValue({ sc_no: fotData?.sale_confirmation_no }) 
        form.setFieldsValue({ shipping_mark: fotData?.shipping_mark })
        form.setFieldsValue({ remarks: fotData?.remarks })
        form.setFieldsValue({ contact_person: fotData?.contact_person })
        form.setFieldsValue({ mobile_number: fotData?.mobile })
        form.setFieldsValue({ customer_obj: fotData?.customer_obj })
        form.setFieldsValue({ customer_id: fotData?.customer_id})
        form.setFieldsValue({ country: fotData?.country })
        form.setFieldsValue({ currency: fotData?.currency })
        form.setFieldsValue({ container: fotData?.container })
        form.setFieldsValue({
            shipping_date: dayjs(personFormattedDate, dateFormat),
            shipping_date: partnerFormattedDate ? dayjs(partnerFormattedDate, dateFormat) : null
        });
        form.setFieldsValue({ sub_total: fotData?.grand_total })
        form.setFieldsValue({ total_all: fotData?.grand_total })
        form.setFieldsValue({ total_pieces: fotData?.total_quantity })
        form.setFieldsValue({ total_rate: fotData?.total_rate })
        form.setFieldsValue({ deposit_amt_enter: fotData?.deposit_amt })
        form.setFieldsValue({ balance_amt: fotData?.balance_amt })


    }, [fotData])
    console.log(fotData,'fotData');

    const columns = [
        {
            title: '#',
            render: (text, record, index) => {

                return (
                    (
                        <Flex alignCenter gap={'20px'} style={{ alignItems: 'center' }}>
                            <h4>{index + 1}</h4>
                            <DeleteButtonWrapper>
                                <Button
                                    style={{
                                        display: 'none',
                                        padding: '10px',
                                        height: 'auto',
                                        fontSize: '16px',
                                    }}
                                    htmlType="button"
                                    danger
                                    onClick={() => onDelete(record.key)}
                                >
                                    <DeleteOutlined />
                                </Button>
                            </DeleteButtonWrapper>
                        </Flex>
                    )
                );
            },
        },
        {
            title: 'PRODUCT',
            children: [
                {
                    title: <Button.Yellow text={'Add Product'} onClick={() => hadleProdctAdd()} />,
                    dataIndex: 'product_name',
                    key: 'product_name',
                    render: (text, record) => (
                        <>

                            <Input
                                minWidth={'120px'}
                                showSearch
                                name={`product_name${record.key}`}

                            />

                            <Input name={`product_id${record.key}`} display={'none'} />
                        </>
                    )
                },
            ]
        },
        {
            title: 'DESCRIPTION',
            dataIndex: 'description',
            render: (text, record) => (
                <TextAreas
                    minWidth={'120px'}
                    placed={'end'}
                    name={`description${record.key}`}
                    onChange={(value) => handleOnChangeDescription(value, record)}
                />
            )
        },
        {
            title: 'Quantity / Unit',
            children: [
                {
                    title: <Button.Yellow text={'Add Unit'} onClick={() => hadleUnitAdd()}
                    />,
                    render: (text, record) => (

                        <>
                            <CustomInputNumber name={`quantity${record.key}`}
                                onChange={(value) => handleOnChangeQuantity(value, record)}
                                minWidth={'120px'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'This is a required field',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (parseFloat(value) <= 0) {
                                                return Promise.reject('Quantity must be greater than 1');
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            />
                            <Select
                                rules={[
                                    {
                                        required: true,
                                        message: 'This is a required field',
                                    },
                                ]}
                                minWidth={'120px'}
                                showSearch
                                name={`unit${record.key}`}
                                options={AddUnitMeasure}
                                onChange={(value) => handleOnChangeUnits(value, record)}
                            />
                            <Input label={'barcode'} name={`barcode${record.key}`} display={'none'} />
                            <Input label={'cbm'} name={`cbm${record.key}`} display={'none'} />
                            <Input label={'net_weight'} name={`net_weight${record.key}`} display={'none'} />
                            <Input label={'gross_weight'} name={`gross_weight${record.key}`} display={'none'} />
                        </>
                    )
                },
            ]
        },
        {
            title: 'RATE',
            children: [
                {
                    title: <CustomSelect options={AddCuurencyName} placeholder={'CURRENCY'}
                        buttonLabel={'ADD'} onButtonClick={handleCurrency} name={'currency'} />,
                    dataIndex: 'currency',
                    key: 'currency',
                    render: (text, record) => (
                        <CustomInputNumber precision={2} rules={[
                            {
                                required: true,
                                message: 'This is a required field'
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (parseFloat(value) <= 0) {
                                        return Promise.reject('Rate must be greater than 1');
                                    }
                                    return Promise.resolve();
                                },
                            }),
                        ]}
                            name={`rate${record.key}`}
                            onChange={(value) => handleOnChangeRate(value, record)}
                            minWidth={'120px'} />
                    )
                },
            ]
        },
        {
            title: 'TOTAL',
            children: [
                {
                    title: <Input placeholder={'CURRENCY'} name={'currency'}
                        disabled style={{ background: '#fff' }} />,
                    dataIndex: 'total_amt',
                    key: 'total_amt',
                    render: (text, record) => (
                        <CustomInputNumber precision={2} rules={[
                            {
                                required: true,
                                message: 'This is a required field'
                            },
                        ]}
                            name={`total_amt${record.key}`}
                            disabled
                            minWidth={'120px'}
                        />
                    )
                }
            ]
        }
    ]

    // ----------------- Add Row Function 

    const AddRow = () => {
        const newData = {
            key: count,
            item: '',
            product_name: '',
            itemCode: '',
            hsn_code: '',
            description: '',
            product_image: '',
            qty: '',
            item_unit: '',
            price_per_unit: '',
            price: '',
            sell: '',
            discount_percentage: '',
            discount_total: '',
            tax_percentage: '',
            tax_amt: '',
            amount: '',
        };
        setTableData(pre => {
            return [...pre, newData]
        })
        setCount(count + 1);
    }

    // -----------------------  Delete Row Function

    const onDelete = (key) => {
        if (tableData.length > 1) {
            setTableData(prevState => {
                const newData = prevState.filter(item => item.key !== key);

                // ------ Variables 
                let totalQuantity = 0;
                let totalDiscount = 0;
                let totalTax = 0;
                let totalAmount = 0;

                newData.forEach(item => {
                    if (item.qty !== '' || item.amount !== '' || item.discount_total !== '' || item.tax_amt !== '') {
                        totalQuantity += parseFloat(item.qty);
                        totalDiscount += parseFloat(item.discount_total);
                        totalTax += parseFloat(item.tax_amt);
                        totalAmount += parseFloat(item.amount);
                    }
                });

                // update the total_amount value in the tableSecondaryData array
                setTableSecondaryData([{
                    total_qty: totalQuantity.toFixed(2),
                    total_discount: totalDiscount.toFixed(2),
                    total_tax: totalTax.toFixed(2),
                    total_amount: totalAmount.toFixed(2)
                }]);



                return newData;
            });
        } else {
            console.log(`only ${tableData.length} is available`)
        }
    };

    // ========================   Total Calculating Functions
    // ----------------- 1. Calculate TotalAmount 

    const CalculateTotal = (record) => {

        setTableData(prevState => {
            const newData = [...prevState];
            const index = newData.findIndex(item => record.key === item.key);
            const item = newData[index];
            item.total = record.total || 0;
            item.tax_amt = record.tax_amt || 0;
            item.quantity = record.quantity;
            item.rate = record.rate || 0;

            // ------ Variables 
            let totalQuantity = 0;
            let totalRate = 0;
            let totalTax = 0;
            let totalAmount = 0;

            newData.forEach(item => {
                if (item.quantity !== '' || item.total !== '' || item.rate !== '' || item.tax_amt !== '') {
                    totalQuantity += parseFloat(item.quantity);
                    totalRate += parseFloat(item.rate);
                    totalTax += parseFloat(item.tax_amt);
                    totalAmount += parseFloat(item.total_amt);
                }
            });

            const amountwordsinvoice = numberToWords(totalAmount)
            setTableSecondaryData([{
                total_pieces: totalQuantity.toFixed(2),
                total_rate: totalRate.toFixed(2),
                total_all: totalAmount.toFixed(2),
                sub_total: totalAmount.toFixed(2),
                gt_inwords: amountwordsinvoice
            }]);

            const TotalAmt = (item.total_amt);

            if (TotalAmt < 0) {
                toast.warn('Plese Enter a positive number')
                setDisabless(true)
            }
            else {
                setDisabless(false)
            }

            return newData;
        })
    };

    // ============  OnChange Functions  ==============

    const HandleUnit = (value, record) => {
        setTableData(prevState => {
            const newData = [...prevState];
            const index = newData.findIndex(item => record.key === item.key);
            const item = newData[index];
            item.unit = value || 0;
            CalculateTotal(item)
            return newData;
        });
    };

    const HandleQty = (value, record) => {
        setTableData(prevState => {
            const newData = [...prevState];
            const index = newData.findIndex(item => record.key === item.key);
            const item = newData[index];

            item.quantity = value || 0;

            const Quantity = parseInt(item.quantity) || 0;
            const Rate = parseInt(item.rate) || 0;
            const Total = Quantity * Rate;

            item.total_amt = Total || 0;
            item.price_amount = record.price_amount || 0;

            CalculateTotal({
                ...item,
                quantity: item.quantity,
                total_amt: Total,
                rate: item.rate,

            });

            return newData;
        });
    };

    const HandleDescript = (value, record) => {
        setTableData(prevState => {
            const newData = [...prevState];
            const index = newData.findIndex(item => record.key === item.key);
            const item = newData[index];

            item.description = value;
            CalculateTotal(item)
            return newData;
        });
    };


    const HandleRate = (value, record) => {
        setTableData(prevState => {
            const newData = [...prevState];
            const index = newData.findIndex(item => record.key === item.key);
            const item = newData[index];

            item.rate = value || 0;

            const Quantity = parseInt(item.quantity) || 0;
            const Rate = parseInt(item.rate) || 0;
            const Total = Quantity * Rate;

            item.total_amt = Total || 0;
            item.price_amount = record.price_amount || 0;


            CalculateTotal({
                ...item,
                quantity: item.quantity,
                total_amt: Total,
                rate: item.rate,

            });

            return newData;
        });
    };



    const HandleProduct = (value, record) => {

        setTableData(prevState => {
            const newData = [...prevState];
            const index = newData.findIndex(item => record.key === item.key);
            const item = newData[index];
            const setSelectedProduct = productname.find(item => item.id === value);

            item.product_name = setSelectedProduct.item_name;
            item.barcode = setSelectedProduct.barcode;
            item.product_image = setSelectedProduct.item_image;
            item.description = setSelectedProduct.item_description;
            item.barcode = setSelectedProduct.item_barcode;
            item.cbm = setSelectedProduct.item_cbm;
            item.net_weight = setSelectedProduct.item_net_weight;
            item.gross_weight = setSelectedProduct.item_gross_weight;
            return newData;
        });

        // ====================== Paid Amount ==================
    }

    // ---------------- 1.TotalQuantity ONCHANGE Function
    const handleOnChangeQuantity = (value, record) => {
        HandleQty(value, record)
    }

    const handleOnChangeUnits = (value, record) => {
        HandleUnit(value, record)
    }

    const handleOnChangeDescription = (value, record) => {
        HandleDescript(value.target.value, record)
    }

    const handleOnChangeRate = (value, record) => {
        HandleRate(value, record);
    };

    const handleOnChangeProduct = (value, record) => {
        HandleProduct(value, record)
    }
    //  ======================  Other Functions =========
    const onOpenConfirm = (result) => {
        setWidth(400)
        setModalTitle("");
        setModalContent(<HandleModel result={result} />);
        showModal();
    };

    const HandleModel = ({ result }) => {
        return (
            <>
                <Flex center>
                    <h1 style={{ fontSize: '20px', color: '#685e5e' }}>Do you Want to Confirm or Draft</h1>
                </Flex>
                <Flex center gap={'50px'} style={{ margin: '20px 0px' }} >

                    <Button.Primary text={'Confirm'} onClick={() => invoiceNo ? EditConfirmClick(result) : confirmClick(result)} />
                    <Button.Danger text={'Draft'} onClick={() => invoiceNo ? EditDraftChange(result) : draftChange(result)} />
                </Flex>
            </>
        )
    }

    const EditConfirmClick = (values) => {
        request.put(`invoice/edit_invoice/${invoiceNo}/`, values)
            .then(function (response) {
                if (response.status == 201) {
                    toast.success('Successfully Billed Invoice Entry ')
                    form.resetFields();
                    setTrigger((trigger) => trigger + 1);
                    setTableData(initialData);
                    setTableSecondaryData(secondaryData);
                    setTableFooterData(footerCalData);
                    setRound(false);
                    setPayType('Cash');
                    if (response.data?.id) {
                        printInvoice(response.data.id);
                    }

                }
                else {
                    console.log('Something went Wrong');
                }
            })

            .catch(function (error) {
                if (error.response.status == 400) {
                    if (error.response.data?.GSTIN == 'Invalid GSTIN') {
                        toast.warn('Invalid GSTIN');
                    }
                    else {
                        toast.error('Failed');
                    }
                }
            });
    }

    const EditDraftChange = (values) => {
        const record = { ...values, draft: 'True' }
        request.put(`invoice/edit_invoice/${invoiceNo}/`, record)
            .then(function (response) {
                if (response.status == 201) {
                    toast.success('Successfully Billed Invoice Entry ')
                    form.resetFields();
                    setTrigger((trigger) => trigger + 1);
                    setTableData(initialData);
                    setTableSecondaryData(secondaryData);
                    setTableFooterData(footerCalData);
                    setRound(false);
                    setPayType('Cash');
                    window.history.go(-1);
                }
                else {
                    console.log('Something went Wrong');
                }
            })

            .catch(function (error) {
                if (error.response.status == 400) {
                    if (error.response.data?.GSTIN == 'Invalid GSTIN') {
                        toast.warn('Invalid GSTIN');
                    }
                    else {
                        toast.error('Failed');
                    }
                }
            });
    }

    const onResett = () => {
        handleOk()
    }

    const printInvoice = (id) => {
        setWidth(400)
        setModalTitle("Invoice Print");
        setModalContent(<InvoicePrintConfirm id={id} />);
        showModal();
    }


    const InvoicePrintConfirm = ({ id }) => {
        return (
            <>
                <Flex center>
                    <h1 style={{ fontSize: '20px', color: '#685e5e' }}>Do you Want to go the Print page</h1>
                </Flex>
                <Flex center gap={'50px'} style={{ margin: '20px 0px' }} >

                    <Button.Primary text={'Yes'} onClick={() => navigate(`/PrintInvoice/${id}`)} />
                    <Button.Danger text={'No'} onClick={() => onResett()} />
                </Flex>
            </>
        )
    }

    const confirmClick = (result) => {
        request.post('invoice/add_invoice/', result)
            .then(function (response) {
                if (response.status == 201) {
                    toast.success('Successfully Billed Invoice Entry ')
                    form.resetFields();
                    setTrigger((trigger) => trigger + 1);
                    setTableData(initialData);
                    setTableSecondaryData(secondaryData);
                    setTableFooterData(footerCalData);
                    setRound(false);
                    setPayType('Cash');
                    if (response.data?.id) {
                        printInvoice(response.data.id);
                    }

                }
                else {
                    console.log('Something went Wrong');
                }
            })

            .catch(function (error) {
                if (error.response.status == 400) {
                    if (error.response.data?.GSTIN == 'Invalid GSTIN') {
                        toast.warn('Invalid GSTIN');
                    }
                    else {
                        toast.error('Failed');
                    }
                }
            });
    }

    const draftChange = (result) => {
        const record = { ...result, draft: 'True' }
        request.post('invoice/add_invoice/', record)
            .then(function (response) {
                if (response.status == 201) {
                    toast.success('Invoice Entry Saved To Draft')
                    form.resetFields();
                    setTrigger((trigger) => trigger + 1);
                    setTableData(initialData);
                    setTableSecondaryData(secondaryData);
                    setTableFooterData(footerCalData);
                    setRound(false);
                    setPayType('Cash');
                    window.history.go(-1);
                }
                else {
                    console.log('Something went Wrong');
                }
            })

            .catch(function (error) {
                if (error.response.status == 400) {
                    if (error.response.data?.GSTIN == 'Invalid GSTIN') {
                        toast.warn('Invalid GSTIN');
                    }
                    else {
                        toast.error('Failed');
                    }
                }
            });
    }

    // ====================  On Finish Function ============

    const onFinish = (values) => {
        const record = {
            ...values, draft: 'False',
            invoice_date: values?.invoice_date === null ? '' : dayjs(selectedDate).format('YYYY-MM-DD') ? dayjs(values?.invoice_date).format('YYYY-MM-DD') : dayjs(values?.invoice_date).format('YYYY-MM-DD'),

            shipping_date: values?.shipping_date === null ? '' : dayjs(selectedDates).format('YYYY-MM-DD') ? dayjs(values?.shipping_date).format('YYYY-MM-DD') : dayjs(values?.shipping_date).format('YYYY-MM-DD'),
        };
        let result = {
            customer_id: record.customer_id,
            customer_obj: record.customer_obj,
            company_name: record.company_name,
            Address: record.address,
            import_no: record.import_no,
            draft: record.draft,
            cr_no: record.cr_no,
            vat_no: record.vat_no,
            payment_term: record.payment_terms,
            delivery_terms: record.delivery_terms,
            confirm_quantity: record.confirm_quantity,
            invoice_no: record.invoice_no,
            invoice_date: record.invoice_date,
            sealing_date: record.shipping_date,
            ship_to: record.country,
            sc_no: record.id,
            vessel_name: record.vessel_name,
            bl_no: record.bl_no,
            container_seal_no: record.container,
            contact_person: record.contact_person,
            mobile: record.mobile_number,
            email: record.email,
            currency: record.currency,
            shipping_mark: record.shipping_mark,
            remarks: record.remarks,
            deposit_amt: record.deposit_amt || 0,
            total_quantity: record.total_pieces,
            grand_total: record.total_all,
            balance_amt: record.balance_amt,
            customer_name: record.customer_name,
            total_rate: record.total_rate,
            sub_total:record.sub_total,
            discount_total:record.discount_total || 0,

            sales: Object.entries(record)
                .filter(([key]) => key.startsWith('description'))
                .map(([key, description]) => {
                    const index = key.match(/\d+/)[0];
                    const itemKey = `product_name${index}`;
                    const itemProdID = `product_id${index}`;
                    const itemImg = `product_image${index}`;
                    const itemquantity = `quantity${index}`;
                    const itemunit = `unit${index}`;
                    const itembarcode = `barcode${index}`;
                    const itemcbm = `cbm${index}`;
                    const itemnetweight = `net_weight${index}`;
                    const itemgrossweight = `gross_weight${index}`;
                    const itemRate = `rate${index}`;
                    const amountKey = `total_amt${index}`;

                    return {
                        description,
                        product_name: record[itemKey],
                        product: record[itemProdID],
                        product_image: record[itemImg],
                        quantity: record[itemquantity],
                        unit: record[itemunit],
                        rate: record[itemRate],
                        barcode: record[itembarcode],
                        net_weight: record[itemnetweight],
                        gross_weight: record[itemgrossweight],
                        cbm: record[itemcbm],
                        total_amt: parseFloat(record[amountKey]).toFixed(2),
                    };
                }),
        };
        console.log(result,'ggfgfg');
        onOpenConfirm(result)
    };


    const onFinishFailed = (errorInfo) => {
        toast.error('Please fill the Details')
    };


    // ==============  Add Row Component  ================

    const FooterComponent = () => {
        return (
            <div style={{ background: 'var(--light-color)', padding: '20px', display: 'none' }}>
                <Row>
                    <Col lg={4} sm={12} span={24}><Button type="primary" style={{
                        fontSize: '1rem',
                        height: 'auto',
                        fontFamily: 'Poppins',
                        fontWeight: 500,
                        letterSpacing: '1px',
                    }}
                        htmlType="button"
                        onClick={AddRow}>
                        Add Row
                    </Button>
                    </Col>
                </Row>
            </div >
        )
    }

    // ==================  Table  ==================
    const onRest = () => {
        form.resetFields();
        Getpatch()
        form.setFieldsValue({ id: id })
        setSelectedSupplierDetails(!selectedSupplierDetails)
        setTerms(!terms)
        setTrigger((trigger) => trigger + 1);
        setTableData(initialData);
        setTableSecondaryData(secondaryData);
        setTableFooterData(footerCalData);
        setRound(false);
        setPayType('Cash');
        if (invoiceNo) {
            GetDraft()
        }

    }

    const calculatePortBalances = () => {
        const totalall = parseFloat(form.getFieldValue('total_all')) || 0;
        const deposiAmt = parseFloat(form.getFieldValue('deposit_amt_enter')) || 0;

        let balanceAmt = 0;

        let TotalBalance = 0;

        balanceAmt = (totalall - deposiAmt).toFixed(2);

        if (balanceAmt < 0) {
            toast.warn('Balance Amount is a negative value');
        }

        return TotalBalance;
    };

    const calculatePortBalance = (value) => {
        const totalall = parseFloat(form.getFieldValue('total_all')) || 0;
        const paidamt = parseFloat(form.getFieldValue('deposit_amt')) || 0;
        const deposiAmt = parseFloat(form.getFieldValue('deposit_amt_enter')) || 0;

        let balanceAmt = 0;

        let TotalBalance = 0;

        balanceAmt = (totalall - deposiAmt).toFixed(2);
        console.log(balanceAmt, 'bbbbbb');

        if (paidamt === 0 || paidamt <= balanceAmt) {

            TotalBalance = (balanceAmt - paidamt).toFixed(2);
            form.setFieldValue('balance_amt', balanceAmt);


        }
        else {
            toast.warn('Enter amount not exceeds than debt amount !')
            form.setFieldValue('deposit_amt', '');
        }

        return TotalBalance;
    };



    const handleDiscoutAmt = () => {
        const SubTotal = parseFloat(form.getFieldValue('sub_total')) || 0;
        const DiscountTotal = parseFloat(form.getFieldValue('discount_total')) || 0;
        const paidamt = parseFloat(form.getFieldValue('deposit_amt')) || 0;
        const deposiAmt = parseFloat(form.getFieldValue('deposit_amt_enter')) || 0;
        const balances = parseFloat(form.getFieldValue('balance_amt')) || 0;

        let balanceAmt = 0;

        let GrandTotal = 0;
        let TotalBalance = 0;
        GrandTotal = SubTotal - DiscountTotal || 0;
        balanceAmt = (GrandTotal - deposiAmt).toFixed(2);
        TotalBalance = (balanceAmt - paidamt).toFixed(2);


        console.log(balanceAmt, 'balanceAmt');
        form.setFieldValue('balance_amt', TotalBalance);
        console.log(GrandTotal, 'handleDiscoutAmt');


        if (DiscountTotal > balances) {

            toast.warn('Discount Amount not greater than Balance Amount!');

            form.setFieldsValue({ discount_total: '' });
            form.setFieldValue('balance_amt', SubTotal);
            GrandTotal = SubTotal

        }
        form.setFieldsValue({ total_all: GrandTotal })


    }
    // useEffect((value) => {
    //     if (value) {
    //         form.setFieldsValue({ balance_amt: handleDiscoutAmt() })
    //     }
    // }, [])

    const handleDepositamr = (value) => {
        const totalall = parseFloat(form.getFieldValue('total_all')) || 0;
        const depositAmt = parseFloat(form.getFieldValue('deposit_amt')) || 0;
        const deposiAmt = parseFloat(form.getFieldValue('deposit_amt_enter')) || 0;
        let balanceAmt = 0;

        balanceAmt = (totalall - deposiAmt).toFixed(2) || 0;
        if (totalall < depositAmt || depositAmt < 0) {
            form.resetFields(['deposit_amt'])
            form.setFieldsValue({ balance_amt: balanceAmt });
        }
        else if (tableData) {
            form.setFieldsValue({ balance_amt: calculatePortBalance() || balanceAmt || handleDiscoutAmt() });
        }
        else {

        }
    };


    if (handleDepositamr()) {

        form.setFieldsValue({ balance_amt: calculatePortBalances() });
    }

    useEffect(() => {
        form.setFieldsValue({origin:'CHINA'})
      }, [])
      
    return (
        <Fragment>
            <Form name="sales"
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                form={form}
                initialValues={
                    {
                        invoice_date: dayjs(),
                        apply_round_off: false,
                        paid: false,
                        round_off: 0,
                        received: 0,
                    }
                }
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off">

                <InvoiceHeader setShipTo={setShipTo} setSaleorder={setSaleorder} setWidth={setWidth} setAddNewPaymentTerms={setAddNewPaymentTerms}
                    setSelectedSupplierDetails={setSelectedSupplierDetails} setTerms={setTerms} trigger={trigger}
                    setInvoiceNumber={setInvoiceNumber} setSelectedDate={setSelectedDate}
                    setSelectedDates={setSelectedDates} setSelectedrevisedDates={setSelectedrevisedDates} />

                <div style={{ margin: '20px 0' }}>
                    <Table columns={columns.filter(Boolean)} data={tableData} pagination={false} />
                    <FooterComponent />
                </div>


                <div style={{ margin: '20px 0' }}>
                    <InvoiceFooter handleDepositamr={handleDepositamr} handleDiscoutAmt={handleDiscoutAmt} payType={payType} setPayType={setPayType} HandleCheQueChage={HandleCheQueChage} footermsg={footermsg} BalanceOnChange={BalanceOnChange} TotalBalance={TotalBalance} tableSecondaryData={tableSecondaryData} footerCalData={footerCalData} setRoundDecimalValue={setRoundDecimalValue} round={round} />
                </div>

                <Card>
                    {invoiceNo ? (
                        <>
                            <Flex center={'true'} gap={'20px'} margin={'20px 0'}>
                                <Button.Primary text={'Submit'} htmlType="submit" disabled={disabless} />
                                <Button.Danger text={'Cancel'} onClick={onRest} />
                                <Button.Primary text={'Back'} onClick={() => navigate(-1)} />
                            </Flex>
                        </>
                    ) : (
                        <Flex center={'true'} gap={'20px'} margin={'20px 0'}>
                            <Button.Primary text={'Submit'} htmlType="submit" disabled={disabless} />
                            <Button.Danger text={'Cancel'} onClick={onRest} />
                            <Button.Primary text={'Back'} onClick={() => navigate(-1)} />
                        </Flex>
                    )}
                </Card>
            </Form>

            <Modal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel}
                width={width}
                modalTitle={modalTitle} modalContent={modalContent} />
        </Fragment>
    )
}
