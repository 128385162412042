import React, { Fragment } from 'react'
import Submenu from './Partials/Submenu'
import { TopTitle } from '../../Components/Form/TopTitle'
import { Select } from '../../Components/Form/Select'
import Input from '../../Components/Form/Input'
import { CustomCard } from '../../Components/CustomCard'
import { Row } from '../../Components/Row'
import { Col } from 'antd'


export const SubmenuTable = () => {

    return (
        <Fragment>
            <TopTitle Heading={'Consign'} />
            {/* ============ Table Datas =========== */}
            <Submenu />
        </Fragment>
    )
}
