import React, { Fragment, useEffect, useLayoutEffect } from 'react'
import Routers from './routes'
import GlobalStyle from './theme/GlobalStyle';
import { useLocation } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import { selectCurrentData, selectCurrentToken } from './Modules/Auth/authSlice';
import initializeApp from './utils/initializeApp';

const App = () => {

  const location = useLocation()
  
  const alldata = useSelector(selectCurrentData);
  const token = useSelector(selectCurrentToken);


  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  useEffect(() => {
    initializeApp(alldata);
  }, [token])
  

  return (
    <Fragment>
        <GlobalStyle />
        <Routers token={token} />
        <ToastContainer />
    </Fragment>
  )
}

export default App

