import { combineReducers } from "redux"
import authReducer from '../Modules/Auth/authSlice'
import ProductReducer from '../Modules/Products/ProductSlice'
import AddModuleReducer from "../Modules/AddModules/AddModulesSlice"
import SalesReducer from "../Modules/Sales/SalesSlice"
import CustomerReducer from "../Modules/Customers/CustomerSlice"
import SupplierReducer from "../Modules/Suppliers/SupplierSlice"
import ProfileSlice from "../Modules/Profile/ProfileSlice"
import FinaceReducer from '../Modules/Reports/FinanceReport/FinanceSlice'
import UserReducer from '../Modules/User/UserSlice'
import PurchaseReducer from '../Modules/Purchase/PurchaseSlice'
import ProfitReducer from '../Modules/ProfitorLoss/ProfLosSlice'
import PackingReducer from "../Modules/Reports/PackingListReport/PackingSlice"
import ReportAll from '../Modules/Reports/ReportViewSlice'
import QuatationReducer from "../Modules/Quotations/QuotationSlice"


const rootReducer = combineReducers({
    auth: authReducer,
    products: ProductReducer,
    addModules: AddModuleReducer,
    customers: CustomerReducer,
    suppliers: SupplierReducer,
    profiles: ProfileSlice,
    sales: SalesReducer,
    purchase: PurchaseReducer,
    user: UserReducer,
    profit: ProfitReducer,
    packingsreport: PackingReducer,
    finace: FinaceReducer,
    report: ReportAll,
    quotations:QuatationReducer
})

export default rootReducer;