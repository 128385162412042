import { Col, Form } from 'antd';
import React, { useEffect } from 'react'
import { toast } from 'react-toastify';
import Button from '../../Components/Form/Button';
import Input from '../../Components/Form/Input';
import { Row } from '../../Components/Row';
import Flex from '../../Components/Flex';
import request from '../../utils/request';

const AddCurrency = ({ setRateAddTable, setaddNewCurrency, setpriceAdd, AddCurrencyData, ProfitTrigger, AddCurrenynameGet, QuotationTrigger, SaleTrigger, handleAddCurrency, handleGetSaleCurrency, handleOk }) => {

    const [form] = Form.useForm();

    const UNITADD = 'choice/add_currency/'

    useEffect(() => {
        form.resetFields()
    }, [QuotationTrigger])

    useEffect(() => {
        form.resetFields()
    }, [ProfitTrigger])

    useEffect(() => {
        form.resetFields()
    }, [SaleTrigger])

    const onRest = () => {
        form.resetFields();
        if (handleAddCurrency) {
            handleAddCurrency()
        }
        if (AddCurrencyData) {
            AddCurrencyData()
        }

    }

    const onFinish = (values) => {
        AddUnitPost(values)
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const AddUnitPost = (values) => {
        request.post(`${UNITADD}`, values)
            .then(function (response) {
                if (response.status == 200) {
                    if (setpriceAdd) {
                        setpriceAdd(response.data.rate)
                    }
                    form.resetFields();
                    toast.success('Currency Added Successfully')
                    if (AddCurrenynameGet) {
                        AddCurrenynameGet()
                    }
                    if (setRateAddTable) {
                        setRateAddTable(response.data.rate)
                    }
                    if (setaddNewCurrency) {
                        setaddNewCurrency(response.data.rate)
                    }
                    if (handleAddCurrency) {
                        handleAddCurrency()
                    }
                    if (handleGetSaleCurrency) {
                        handleGetSaleCurrency();

                    }
                    if (AddCurrencyData) {
                        AddCurrencyData()
                    }
                    if (handleOk) {
                        handleOk()
                    }
                }
                else if (response.status == 226) {
                    toast.warn('Currency Already Added')
                }

                else {
                    toast.error('Failed');
                }

            })
            .catch(function (error) {
                console.log(error, 'error');
                toast.error('Failed');
            });
    }

    return (
        <Form
            name='AddCurrency'
            form={form}
            labelCol={{
                span: 24,
            }}
            wrapperCol={{
                span: 24,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off">
            <Row space={[24, 24]}>

                <Col span={24} md={24}>
                    <Input label={'Add Currency'} placeholder={'Enter Currency'} name={'rate'}
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter Currency!',
                            }
                        ]}
                    />
                </Col>
            </Row>
            <Flex center gap={'20px'} style={{ margin: '30px' }}>
                <Button.Success text={'ADD'} htmlType={'submit'} />
                <Button.Danger text={'RESET'} onClick={onRest} />
            </Flex>
        </Form>
    )
}

export default AddCurrency