import React, { useEffect } from 'react'
import { Table } from '../../../../Components/Table';
import { Modal } from '../../../../Components/Modal';
import { useState } from 'react';
import { Col, Modal as Modals } from 'antd';
import Flex from '../../../../Components/Flex';
import { useNavigate, useParams } from 'react-router-dom';
import request from '../../../../utils/request';
import EditCustomerForm from '../../AddCustomers/Partials/EditCustomerForm';
import Input from '../../../../Components/Form/Input';
import { Row } from '../../../../Components/Row';
import { Select } from '../../../../Components/Form/Select';
import { BiSolidUserDetail } from 'react-icons/bi';
import { FiEdit } from 'react-icons/fi';
import { THEME } from '../../../../theme';
import { TableIconHolder } from '../../../../Components/Styled';
import { CustomPopconfirm } from '../../../../Components/CustomPopConfirm';
import { MdDelete } from 'react-icons/md';
import { toast } from 'react-toastify';
import { getCustomer, getAllCustomers, getCustomerError, getCustomerStatus } from '../../CustomerSlice';
import { useSelector, useDispatch } from 'react-redux';
import { CommonLoading } from '../../../../Components/CommonLoading';

const TableEdit = () => {

    const navigate = useNavigate();

    const { id } = useParams();

    const dispatch = useDispatch()

    const [searchText, setSearchText] = useState([]);
    const [searchTexts, setSearchTexts] = useState([]);
    const [selectedOption, setSelectedOption] = useState('Customerid');
    // ======  Modal Open ========
    const [isModalOpen, setIsModalOpen] = useState(false);

    // ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);

    const [dataSource, setDataSource] = useState([])

    const [trigger, setTrigger] = useState(0)

    const custs = useSelector(getAllCustomers)
    const custsStatus = useSelector(getCustomerStatus)
    const custsError = useSelector(getCustomerError)


    useEffect(() => {
        dispatch(getCustomer())
    }, [])

    useEffect(() => {
        setDataSource(custs)
    }, [custs])


    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleSearch = (value) => {
        setSearchText(value);
    };

    const handleSearchs = (value) => {
        setSearchTexts(value);
    };

    const handleCustomerClose = () => {
        dispatch(getCustomer())
        handleOk()
    }
    const onEditStudent = (record) => {
        setTrigger(trigger + 1)
        showModal();
        setModalTitle("Edit Customer");
        setModalContent(<EditCustomerForm CustomerTrigger={trigger} record={record} handleCustomerClose={handleCustomerClose} />);
    }


    // ======  PopConfirm Function ====
    const handleConfirm = (record) => {
        DeleteCustomer(record);
    };

    const DeleteCustomer = (record) => {
        request.delete(`customer/edit_cutomer/${record?.id}`)
            .then(response => {
                toast.success('Customer Deleted Successfully !')
                dispatch(getCustomer())
            })
            .catch(error => {
                if (error.response.status === 401) {
                    toast.warning("This customer have transactions so can't be deleted")
                }
                else {
                    toast.error("Something Went Wrong")
                }
            });
    }

    const handlePopConfrmCancel = () => {
        console.log('cancelled');
    };


    const choose = [
        {
            label: "Company Name",
            value: "Companyname",
        },
        {
            label: "Customer ID",
            value: "Customerid",
        },
    ];

    const pick = (value) => {
        setSelectedOption(value);
        setSearchText(''); // Clear the searchText state
        setSearchTexts(''); // Clear the searchTexts state
    }

    const columns = [
        {
            title: 'S.No',
            render: (value, item, index) => index + 1,
        },
        {
            title: 'Customer ID',
            dataIndex: 'invoice_no',
            filteredValue: searchTexts ? [searchTexts] : null,
            onFilter: (value, record) => {
                return String(record.invoice_no).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.invoice_no).includes(value.toUpperCase());
            },
        },
        {
            title: 'Company Name',
            dataIndex: 'company_name',
            filteredValue: searchText ? [searchText] : null,
            onFilter: (value, record) => {
                return String(record.company_name).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.customer_company_name).includes(value.toUpperCase());
            },
        },
        {
            title: 'Contact Person',
            dataIndex: 'contact_person_name',
        },
        {
            title: 'Phone No',
            dataIndex: 'customer_mobile',
        },
        {
            title: 'Action',
            render: (record, i) => {
                return (
                    <>
                        <Flex spaceEvenly gap={'10px'}>
                            <TableIconHolder color={THEME.blue} size={'22px'} onClick={() => onEditStudent(record)}>
                                <FiEdit />
                            </TableIconHolder>
                            <TableIconHolder color={THEME.gray} size={'22px'} onClick={() => navigate(`/CustomerProfiless/${record.id}`)}>
                                <BiSolidUserDetail />
                            </TableIconHolder>
                            <CustomPopconfirm
                                record={record}
                                confirm={handleConfirm}
                                cancel={handlePopConfrmCancel}
                                title={"Delete the Customer List"}
                                description={"Are you sure to delete this Customer List ?"}>
                                <TableIconHolder color={THEME.red} size={'22px'}>
                                    <MdDelete />
                                </TableIconHolder>
                            </CustomPopconfirm>
                        </Flex>
                    </>
                );
            },

        }
    ]

    let content;

    if (custsStatus === 'loading') {
        content = <CommonLoading />
    } else if (custsStatus === 'succeeded') {
        const rowKey = (dataSource) => dataSource.id;
        content = <Table columns={columns} data={dataSource} rowKey={rowKey} />
    } else if (custsStatus === 'failed') {
        content = <h2>{custsError}</h2>
    }


    return (
        <div>
            <Row space={[24, 24]}>
                <Col span={0} sm={8} md={12}></Col>
                <Col span={24} sm={16} md={12}>
                    <Row space={[12, 12]}>
                        <Col span={24} sm={12}>
                            <Select label={"Select by"} value={selectedOption} options={choose} onChange={pick}
                                placeholder="Search by Name or Id"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select !",
                                    },
                                ]}
                            />
                        </Col>
                        <Col span={24} sm={1}></Col>
                        <Col span={24} sm={11}>

                            {selectedOption === 'Companyname' ? (
                                <Input
                                    placeholder="Search by Company Name"
                                    value={searchText}
                                    label="Search by Company Name"
                                    onSearch={handleSearch}
                                    onChange={(e) => handleSearch(e.target.value)}
                                />
                            ) : (
                                <Input
                                    placeholder="Search by CUSTOMER ID"
                                    value={searchTexts}
                                    label="Search by CUSTOMER ID"
                                    onSearch={handleSearchs}
                                    onChange={(e) => handleSearchs(e.target.value)}
                                />
                            )}
                        </Col>
                    </Row>
                </Col>
            </Row>
            {content}
            <Modal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={1000} modalTitle={modalTitle} modalContent={modalContent} />
        </div>
    )
}

export default TableEdit