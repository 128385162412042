import { Col, Form } from 'antd';
import React, { useEffect } from 'react'
import { Row } from '../../../../Components/Row';
import Input from '../../../../Components/Form/Input';
import { useState } from 'react';
import Button from '../../../../Components/Form/Button';
import Flex from '../../../../Components/Flex';
import { TextAreas } from '../../../../Components/Form/TextArea';
import request from '../../../../utils/request';
import { toast } from 'react-toastify';


const EditSuppliers = ({ suppilerData, handleSuppilers, supplierTrigger }) => {

    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields()
    }, [supplierTrigger])

    useEffect(() => {
        form.setFieldsValue(suppilerData)
    }, [suppilerData, supplierTrigger])

    const onFinish = (values) => {
        EditSuppilers(values, suppilerData.id)
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onReset = () => {
        form.resetFields();
        if (handleSuppilers) {
            handleSuppilers();
        }
    }


    //===================edit====================//
    useEffect(() => {
        form.setFieldsValue(suppilerData)
    }, [suppilerData])

    const EditSuppilers = (record, id) => {
        request.patch(`supplier/add_supplier_edit/${id}`, record).then((response) => {
            toast.info("Updated Sucessfully")
            handleSuppilers();
        }).catch(error => {
            if (error.response && error.response.status === 400) {
                if (error.response.data) {
                    if (error.response.data.gstin) {
                        toast.warn(error.response.data.gstin[0]);
                    } else if (error.response.data.mobile_number) {
                        toast.warn(error.response.data.mobile_number[0]);
                    } else {
                        toast.error('Invalid input.');
                    }
                } else {
                    toast.error('Invalid input.');
                }
            }
        });

    }

    return (
        <div>
            <Form
                name='EditSupplier'
                form={form}
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                initialValues={{
                    bank_detail: false
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off">
                <Row space={[24, 24]}>

                    <Col span={24} md={12}>
                        <Input label={'Company Name'} placeholder={'Company Name'} name={'supplier_name'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Company Name!',
                                }
                            ]} />
                    </Col>

                    <Col span={24} md={12}>
                        <Input label={'Supplier ID'} disabled placeholder={'Supplier ID'}
                            name={'invoice_no'} />
                    </Col>

                    <Col span={24} md={12}>
                        <Input label={'Phone No'} placeholder={'Factory Phone Number'} name={'supplier_mobile'}

                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter Phone Number!',
                                },
                            ]} />
                    </Col>

                    <Col span={24} md={12}>
                        <Input label={'FAX number'} placeholder={'FAX number'} name={'fax'} />
                    </Col>

                    <Col span={24} md={12}>
                        <Input label={'Email id'} name={'email'} type="email" placeholder={"Email ID"} />
                    </Col>

                    <Col span={24} md={12}>
                        <Input label={'Contact Person'} placeholder={'Contact Person'}
                            name={'contact_person_name'} rules={[
                                {
                                    required: true,
                                    message: 'Please enter contact person!',
                                }
                            ]} />
                    </Col>

                    <Col span={24} md={12}>
                        <Input label={'Mobile number'} placeholder={'Contact Person Number'}
                            name={'mobile_number'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter Phone Number!',
                                }
                            ]} />
                    </Col>

                    <Col span={24} md={12}>
                        <TextAreas label={'Address'} placeholder={'Address'} name={'address'}
                        />
                    </Col>

                </Row>
                <Flex center={'true'} gap={'20px'} margin={'20px 0px'}>
                    <Button.Primary text={'Save'} htmlType={'submit'} />
                    <Button.Danger text={'Cancel'} htmlType={'cancel'} onClick={() => onReset()} />
                </Flex>
            </Form>
        </div>
    )
}

export default EditSuppliers