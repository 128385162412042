
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import request from "../../utils/request";

const initialState = {
    report: {},
    status: 'idle',  // 'idle' | 'loading' | 'succeeded' | 'failed'  
    error: null
}

export const getReport = createAsyncThunk(
    "customer_consignment_details/Get",
    async () => {
        try {
            const response = await request.get('customer/customer_consignment_details/');
            return response.data
        }
        catch (error) {
            throw error;
        }
    }
);


const ReportSlice = createSlice({
    name: 'report',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getReport.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(getReport.fulfilled, (state, action) => {

                state.status = 'succeeded'
                state.report = action.payload;
            })
            .addCase(getReport.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
})

export const ListReport = (state) => state.report.report
export const getReportStatus = (state) => state.report.status
export const getReportError = (state) => state.report.error

export const { reducer } = ReportSlice;

export default ReportSlice.reducer


