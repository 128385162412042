import React from 'react'
import { useState } from 'react'
import Flex from '../../../../Components/Flex'
import { TopTitle } from '../../../../Components/Form/TopTitle'
import { Modal } from '../../../../Components/Modal'
import { Table } from '../../../../Components/Table'
import request from '../../../../utils/request'
import { useEffect } from 'react'
import { AddItems } from '../../Add Items/Partials/AddItems'
import Label from '../../../../Components/Form/Label'
import Input from '../../../../Components/Form/Input'
import { MdDelete } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import { useDispatch, useSelector } from 'react-redux'
import { getProduct, getProductError, getProductsStatus, selectAllProducts } from '../../ProductSlice'
import { TableIconHolder } from '../../../../Components/Styled'
import { THEME } from '../../../../theme'
import { CustomPopconfirm } from '../../../../Components/CustomPopConfirm'
import { CommonLoading } from '../../../../Components/CommonLoading'
import { TableImageHolder } from '../../../../Components/TableImageHolder'
import { toast } from 'react-toastify'

const TableViewItem = () => {

    const [dataSource, setDataSource] = useState([]);
    //   ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [searchText, setSearchText] = useState([]);   //  --  Search Filter
    const [productFormReset, setProductFormReset] = useState(0);  //  --  FormReset
    const [productFormUpdate, setProductFormUpdate] = useState(0);    //  --  FormUpdate


    const DEL_PRODUCT_URL = 'product/edit_products_details';

    const dispatch = useDispatch();

    const AllProducts = useSelector(selectAllProducts)
    const ProductStatus = useSelector(getProductsStatus)
    const ProductError = useSelector(getProductError)

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
        ProductFormCancelRest();
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        ProductFormCancelRest();
    };

    const handleSearch = (value) => {
        setSearchText(value);
    };

    // ======  PopConfirm Function ====
    const handleConfirm = (record) => {
        DeleteProduct(record);
    };

    const handlePopConfrmCancel = () => {
        console.log('Called');
    };

    useEffect(() => {
        dispatch(getProduct());
    }, [productFormUpdate]);

    useEffect(() => {
        setDataSource(AllProducts);
    }, [AllProducts])

    const columns = [
        {
            title: 'SI.No',
            render: (value, item, index) => index + 1,

        },
        {
            title: 'Product Name',
            dataIndex: 'item_name',
            filteredValue: searchText ? [searchText] : null,
            onFilter: (value, record) => {
                return String(record.item_name).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.item_name).includes(value.toUpperCase());
            },
        },
        {
            title: 'Description',
            dataIndex: 'item_description',
        },
        {
            title: 'Image',
            dataIndex: 'item_image',
            render: (image) => <TableImageHolder imageUrl={image} />,
        },
        {
            title: 'Size',
            dataIndex: 'item_size',
        },
        {
            title: 'Color',
            dataIndex: 'item_color',
        },
        {
            title: 'Barcode',
            dataIndex: 'barcode',
        },
        {
            title: 'Action',
            render: (record) => {
                return (
                    <Flex center={"true"} gap={'10px'}>
                        <TableIconHolder color={THEME.blue} size={'22px'} onClick={() => EditProduct(record)}>
                            <FiEdit />
                        </TableIconHolder>

                        <CustomPopconfirm
                            record={record}
                            confirm={handleConfirm}
                            cancel={handlePopConfrmCancel}
                            title={"Delete the Product"}
                            description={"Are you sure to delete this Product ?"}>
                            <TableIconHolder color={THEME.red} size={'22px'}>
                                <MdDelete />
                            </TableIconHolder>
                        </CustomPopconfirm>
                    </Flex>
                )

            }

        },
    ]

    const ProductFormExternalClose = () => {
        handleOk();
    }

    const ProductFormCancelRest = () => {
        setProductFormReset(productFormReset + 1)
    }

    const ProductFormUpdateCall = () => {
        setProductFormUpdate(productFormUpdate + 1)
    }

    const EditProduct = (record) => {
        setModalTitle("Edit Product");
        setModalContent(<AddItems Itemdata={record} ProductFormExternalClose={ProductFormExternalClose}
            ProductFormUpdateCall={ProductFormUpdateCall} productFormReset={productFormReset} handleOks={handleOk} />);
        showModal();
    }

    const DeleteProduct = (record) => {
        request.delete(`${DEL_PRODUCT_URL}/${record?.id}`).then((response) => {
            dispatch(getProduct());

        }).catch(error => {
            if (error.response.status === 401) {
                toast.warning('This product used for Transactions')
            }
            else {
                toast.error("Something Went Wrong")
            }
        });
    };

    let content;

    if (ProductStatus === 'loading') {
        content = <CommonLoading />
    } else if (ProductStatus === 'succeeded') {
        const rowKey = (dataSource) => dataSource.id;
        content = <Table columns={columns} data={dataSource} rowKey={rowKey} />
    } else if (ProductStatus === 'failed') {
        content = <h2>{ProductError}</h2>
    }

    return (
        <div>
            <TopTitle Heading={'View Product'} />
            <Flex end={true} centervertically={true} gap={"10px"}>
                <Label>Search by Product Name: &nbsp;</Label>
                <Input
                    placeholder="Search by Product Name"
                    value={searchText}
                    onSearch={handleSearch}
                    onChange={(e) => handleSearch(e.target.value)} />
            </Flex>

            {content}

            <Modal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={1000} modalTitle={modalTitle} modalContent={modalContent} />
        </div>
    )
}

export default TableViewItem
