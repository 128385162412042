import React, { useEffect, useState, useRef } from 'react'
import { Row } from '../../../../Components/Row';
import { CardOnBottom, CardOnTop } from '../../SaleReport/Partials/Style';
import Button from '../../../../Components/Form/Button';
import { Table } from '../../../../Components/Table';
import { Col, Form } from 'antd';
import CardPayment from './CardPayment';
import Flex from '../../../../Components/Flex';
import request from '../../../../utils/request';
import { CustomDateRangePicker } from '../../../../Components/Form/CustomDateRangePicker';
import dayjs from 'dayjs';
import ParintTable from './print';
import { useReactToPrint } from 'react-to-print';
import Input from '../../../../Components/Form/Input';
import PaymentDetailsModal from './PaymentDetailsModal';
import { Modal } from '../../../../Components/Modal';
import { AiFillPrinter, AiOutlineEye } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { getPurchase, getPurchaseError, getPurchaseStatus, selectAllPurchase } from '../../../Purchase/PurchaseSlice';
import { CommonLoading } from '../../../../Components/CommonLoading';
import { THEME } from '../../../../theme';
import { TableIconHolder } from '../../../../Components/Styled';
import { PurchaseTBView } from './PurchTableView';
import { selectCurrentRole, selectCurrentSuper } from '../../../Auth/authSlice';

const Purchasereport = () => {

    const [form] = Form.useForm();

    const SEARCH_URL = 'purchase_order/purchase_orderdetail_search/'

    const [dataSource, setDataSource] = useState([])

    const [dateRange, setDateRange] = useState([]);

    const [modalwidth, setModalwidth] = useState(0)

    const [searchText, setSearchText] = useState([]);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);

    const [searchAmount, setSearchAmount] = useState({})

    // ========== Roll hidden ===========

    const Role = useSelector(selectCurrentRole);

    const Super_r = useSelector(selectCurrentSuper);

    const dispatch = useDispatch();

    const AllPurchase = useSelector(selectAllPurchase)
    const PurchaseStatus = useSelector(getPurchaseStatus)
    const PurchaseError = useSelector(getPurchaseError)

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })

    const onFinish = (values) => {
        const range = {
            range: dateRange
        }
        PostDatePurchse(range)
    };

    useEffect(() => {
        dispatch(getPurchase());
    }, []);

    const CallOnFunction = () => {
        setDataSource(AllPurchase?.purchaseorder);

        const MonthStart = new Date(AllPurchase?.date?.month_start);
        const MonthEnd = new Date(AllPurchase?.date?.month_end);
        const dateFormat = 'YYYY/MM/DD';

        const MonthStartDate = dayjs(MonthStart).format(dateFormat);
        const MonthEndDate = dayjs(MonthEnd).format(dateFormat);

        form.setFieldsValue({ range: [dayjs(MonthStartDate, dateFormat), dayjs(MonthEndDate, dateFormat)] });

        setDateRange([AllPurchase?.date?.month_start, AllPurchase?.date?.month_end])

        setSearchAmount(AllPurchase?.amount)
    }

    useEffect(() => {
        CallOnFunction();
    }, [AllPurchase])

    const PurchaseReportViw = async (record) => {
        try {
            const response = await request.get(`purchase_order/get_print_purchase_order/${record?.purchase_order}/`);
            setModalwidth(1200)
            setModalTitle("Purchase Confirmation Details");
            setModalContent(<PurchaseTBView record={response.data} />);
            showModal();
            return response.data;
        }
        catch (error) {
            throw error;
        }
    };


    const TablePrintData = dataSource?.filter((item) => {

        // Filter the dataSource based on the searchText
        if (!searchText || typeof searchText !== 'string') return true; // If no searchText provided or not a string, show all data

        const searchTextLower = searchText.toLowerCase();
        const searchTextUpper = searchText.toUpperCase();

        // Check if any of the fields match the searchText
        return (
            String(item?.Customer_No).toLowerCase().includes(searchTextLower) ||
            String(item?.Customer_No).includes(searchTextUpper)
        );
    })
        .map((item) => ({
            date: item?.purchase_date,
            sc_no: item?.sc_no,
            Customer_No: item?.Customer_No,
            shipping_date: item?.shipping_date,
            factory_bill_amt: item?.factory_bill_amt,
        }));


    //=======================Date post===============================//

    const PostDatePurchse = (values) => {
        request.post(`${SEARCH_URL}`, values)
            .then(function (response) {
                setDataSource(response.data?.purchaseorder)
                setSearchAmount(response.data?.amount)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    //========================================================================//

    const onFinishFailed = (errorInfo) => {
        console.log(errorInfo);
    };

    const handleDateRangeChange = (values) => {
        setDateRange(values);
    };

    const handleSearch = (value) => {
        setSearchText(value);
    };
    const PaymentDetails = (record) => {
        setModalwidth(1200)
        setModalTitle("Payment Details");
        setModalContent(<PaymentDetailsModal purchaseid={record.id} />);
        showModal();
    };


    const columns = [
        {
            title: 'Sl No',
            render: (item, value, index) => index + 1,
        },
        {
            title: 'Date',
            dataIndex: 'purchase_date',
        },
        {
            title: 'PC / PO No',
            dataIndex: 'sc_no',
            filteredValue: searchText ? [searchText] : null,
            onFilter: (value, record) => {
                return String(record.sc_no).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.sc_no).includes(value.toUpperCase());
            },
        },
        {
            title: 'Supplier Id',
            dataIndex: 'supplier_No',
        },
        {
            title: 'Shipment Date',
            dataIndex: 'shipping_date',
        },
        {
            title: 'Bill Amount',
            dataIndex: 'factory_bill_amt',
            render: (value, record) => `${value} ${record.currency}`,
        },
        {
            title: 'Action',
            render: (record) => {
                return (
                    <Flex gap={'10px'}>

                        <TableIconHolder color={THEME.green} size={'22px'} onClick={() => PurchaseReportViw(record)}>
                            <AiOutlineEye />
                        </TableIconHolder>

                        {Role === 'Master1' || Role === 'Master2' ? (
                            <Button.Danger text={'Pay Details'} onClick={() => PaymentDetails(record)} />
                        ) : null}

                        {Super_r === true ? (
                            <Button.Danger text={'Pay Details'} onClick={() => PaymentDetails(record)} />
                        ) : null}
                    </Flex>
                )
            }
        }

    ];

    let content;

    if (PurchaseStatus === 'loading') {
        content = <CommonLoading />
    } else if (PurchaseStatus === 'succeeded') {
        const rowKey = (dataSource) => dataSource.id;
        content = <Table columns={columns} data={dataSource} rowKey={rowKey} />
    } else if (PurchaseStatus === 'failed') {
        content = <h2>{PurchaseError}</h2>
    }


    return (
        <div>
            <CardOnTop>
                <Form
                    form={form}
                    labelCol={{
                        span: 24,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off">
                    <Row space={[24, 24]} style={{ padding: '10px 0' }}>
                        <Col span={24} sm={4} md={3}>
                            <Flex W_100 H_100 centervertically={"true"}>
                                <b>Between</b>
                            </Flex>
                        </Col>

                        <Col span={24} sm={20} md={12}>

                            <CustomDateRangePicker
                                onChange={handleDateRangeChange}
                                value={dateRange}
                                name={'range'}
                                rules={[{ required: true, message: 'Please Select the Date' }]}
                            />
                        </Col>

                        <Col span={12} sm={6} md={4} style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button.Primary text={'Submit'} htmlType='submit' />
                        </Col>
                        <Col span={6} sm={8} md={4} style={{ display: 'flex', justifyContent: 'center' }}>
                            <div style={{ display: "none" }}>
                                <ParintTable componentRef={componentRef} data={TablePrintData} />
                            </div>
                            {
                                TablePrintData?.length > 0 && (
                                    <Button.Primary text={<AiFillPrinter style={{ fontSize: '30px' }} />} onClick={handlePrint} />
                                )
                            }

                        </Col>
                    </Row>
                    <CardPayment PassData={searchAmount} />
                </Form>
            </CardOnTop >
            <CardOnBottom>
                <Row space={[24, 24]}>
                    <Col span={24} md={13}>
                        <h1>Transactions :</h1>
                    </Col>
                    <Col span={24} md={4}></Col>
                    <Col span={24} md={7} style={{ marginTop: '25px' }}>
                        <Input
                            placeholder="Search by Sc No"
                            value={searchText}
                            onSearch={handleSearch}
                            onChange={(e) => handleSearch(e.target.value)}

                        />
                    </Col>
                </Row>

                {content}


                <Modal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={modalwidth} modalTitle={modalTitle} modalContent={modalContent} />
            </CardOnBottom>
        </div>
    )
}

export default Purchasereport