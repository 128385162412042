import React, { useEffect, useState } from 'react'
import { Table } from '../../../../Components/Table'
import { Modal } from '../../../../Components/Modal'
import { THEME } from '../../../../theme';
import { CustomPopconfirm } from '../../../../Components/CustomPopConfirm';
import Flex from '../../../../Components/Flex';
import { TableIconHolder } from '../../../../Components/Styled';
import { MdDeleteForever } from 'react-icons/md';
import { toast } from 'react-toastify';
import request from '../../../../utils/request';
import Label from '../../../../Components/Form/Label';
import Input from '../../../../Components/Form/Input';

const ContainerDelete = () => {

    //   ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const CONTAINER_GET_URL = 'choice/add_container/'

    const DEL_CONTAINER_URL = 'choice/edit_container'

    const [containerArray, setContainerArray] = useState([]);

    const [formUpdate, setFormUpdate] = useState(0);

    const [searchText, setSearchText] = useState([]);

    const handleSearch = (value) => {
        setSearchText(value);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        GetContainer()
    }, [formUpdate])


    const DeleteProduct = (record) => {
        request.delete(`${DEL_CONTAINER_URL}/${record?.id}/`)
            .then((response) => {
                GetContainer()

            }).catch(error => {
                if (error.response.status === 401) {
                    toast.warning('This product used for Transactions')
                }
                else {
                    toast.error("Something Went Wrong")
                }
            });
    };

    // ======  PopConfirm Function ====

    const handleConfirm = (record) => {
        DeleteProduct(record);
    };

    const GetContainer = () => {
        request.get(`${CONTAINER_GET_URL}`)
            .then(function (response) {
                setContainerArray(response.data);
            })
            .catch(function (error) {
                console.log(error)
            });
    }

    const columns = [
        {
            title: 'SI.No',
            render: (value, item, index) => index + 1,
        },
        {
            title: 'container name',
            dataIndex: 'container_name',
            filteredValue: searchText ? [searchText] : null,
            onFilter: (value, record) => {
                return String(record.container_name).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.container_name).includes(value.toUpperCase());
            },
        },
        {
            title: 'Action',
            render: (record) => {
                return (
                    <Flex center={"true"} gap={'10px'}>
                        <CustomPopconfirm
                            record={record}
                            confirm={handleConfirm}
                            title={"Delete the Container"}
                            description={"Are you sure to delete this Container ?"}>
                            <TableIconHolder color={THEME.red} size={'22px'}>
                                <MdDeleteForever />
                            </TableIconHolder>
                        </CustomPopconfirm>
                    </Flex>
                )
            }

        },
    ]
    return (
        <div>
            <Flex end={true} centervertically={true}>
                <Label>Search by Container Name&nbsp;: &nbsp;</Label>
                <Input
                    placeholder="Search by Container"
                    value={searchText}
                    onSearch={handleSearch}
                    onChange={(e) => handleSearch(e.target.value)} />
            </Flex>
            <Table columns={columns} data={containerArray} />
            <Modal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={1000} modalTitle={modalTitle} modalContent={modalContent} />
        </div>
    )
}

export default ContainerDelete