import { Col } from 'antd'
import React from 'react'
import { Row } from '../../Components/Row'
import Cards_Dasboard from './Partials/Cards_Dasboard'
import CustomerCredit from './Partials/CustomerCredit'

const Dashboard = () => {

    return (
        <div>
            <Cards_Dasboard />
            <br />
            <Row space={[12, 12]} style={{ margin: '30px 0' }}>

                <Col span={24} lg={24}>
                    <CustomerCredit />
                </Col>

            </Row>
        </div>
    )
}
export default Dashboard