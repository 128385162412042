

export const numberToWords = (num) => {
  const units = [
    '', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine',
    'Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'
  ];
  const tens = [
    '', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'
  ];
  const thousands = ['', 'Thousand', 'million', 'billion', 'trillion'];

  function convertChunk(num) {
    if (num === 0) return '';
    if (num < 20) return units[num];
    if (num < 100) return tens[Math.floor(num / 10)] + ' ' + convertChunk(num % 10);
    return units[Math.floor(num / 100)] + ' Hundred ' + convertChunk(num % 100);
  }

  if (num === 0) return 'zero';

  let words = '';
  let chunkCount = 0;

  while (num > 0) {
    if (num % 1000 !== 0) {
      const chunk = convertChunk(num % 1000);
      words = chunk + ' ' + thousands[chunkCount] + ' ' + words;
    }
    num = Math.floor(num / 1000);
    chunkCount++;
  }

  return words.trim();
};

