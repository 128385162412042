import React from 'react'
import { SalesEntryPage } from './Partials/SalesEntryPage'
import { TopTitle } from '../../Components/Form/TopTitle'
import { CustomCard } from '../../Components/CustomCard'

const SalesMain = ({ setSale, getSale, selectedDate }) => {

  return (
    <div>
      <TopTitle Heading={'Create Sales Confirmations'} />
      <CustomCard>
        <SalesEntryPage setSale={setSale} getSale={getSale} selectedDate={selectedDate} />
      </CustomCard>
    </div>
  )
}


export default SalesMain