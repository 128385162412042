import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import request from "../../utils/request";

const initialState = {
    user: [],
    status: 'idle',  // 'idle' | 'loading' | 'succeeded' | 'failed'  
    error: null
}

export const getUser = createAsyncThunk(
    "User/Get",
    async () => {
        try {
            const response = await request.get('user/g_users');
            return [...response.data];
        }
        catch (error) {
            throw error;
        }
    }
);

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUser.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(getUser.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.user = action.payload;
            })
            .addCase(getUser.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.payload
            })
    }
})

export const selectAllUser = (state) => state.user.user
export const getUnitStatus = (state) => state.user.status
export const getUnitError = (state) => state.user.error

export const { reducer } = userSlice;

export default userSlice.reducer


