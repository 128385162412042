import { Card, Col, Form } from "antd"
import React, { Fragment, useEffect, useState } from "react"
import dayjs from 'dayjs'
import Flex from "../../../Components/Flex"
import Button from "../../../Components/Form/Button"
import { DeleteOutlined } from "@ant-design/icons"
import Input from "../../../Components/Form/Input"
import { CustomInputNumber } from "../../../Components/Form/CustomInputNumber"
import { DeleteButtonWrapper, Table } from "../../../Components/Table"
import { Row } from "../../../Components/Row"
import { Modal } from "../../../Components/Modal"
import request from "../../../utils/request"
import { toast } from "react-toastify"
import { useNavigate, useParams } from "react-router-dom"
import { numberToWords } from "../../../utils/numtowords"
import PackingForm from "./PackingForm"
import PackingFooter from "./PackingFooter"
import { Select } from "../../../Components/Form/Select"
import { TextAreas } from "../../../Components/Form/TextArea"



export const PackingTable = ({ setSaleorder, getSaleorders }) => {

    const [count, setCount] = useState(1);
    const [width, setWidth] = useState(0)
    const [productname, setProductname] = useState([])
    const [foosaleData, setFoosaleData] = useState([])
    const [addNewPaymentTerms, setAddNewPaymentTerms] = useState()
    const [ShipTo, setShipTo] = useState()

    const { id } = useParams()
    const { PkNo } = useParams()

    const navigate = useNavigate()

    const [unit, setUnit] = useState([])


    // ======  Modal Open ========
    const [isModalOpen, setIsModalOpen] = useState(false);

    // ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);
    const [invoiceNumber, setInvoiceNumber] = useState({})

    // ======  Selected Date ========
    const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY-MM-DD'));
    const [selectedDates, setSelectedDates] = useState(dayjs().format('YYYY-MM-DD'));
    const [selectedrevisedDates, setSelectedrevisedDates] = useState(dayjs().format('YYYY-MM-DD'));

    // ================  SalesFormFooter checked ==========
    const [round, setRound] = useState(false);
    const [roundDecimalValue, setRoundDecimalValue] = useState(null);
    const [balance, setBalance] = useState(false);

    // -----------------  Balance Checking ------------
    const [withDecimal, setWithDecimal] = useState(null);
    const [withOutDecimal, setWithOutDecimal] = useState(null);
    const [balanceChangeAmount, setBalanceChangeAmount] = useState(0);
    const [balanceChange, setBalanceChange] = useState(false);

    const [footermsg, setFootermsg] = useState();

    const [selectedSupplierDetails, setSelectedSupplierDetails] = useState({})

    const [terms, setTerms] = useState({})

    const [headerdata, setHeaderdata] = useState({})


    const [payType, setPayType] = useState('Cash');


    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const [form] = Form.useForm();

    // =========================  Modal Content Start  ===========================
    useEffect(() => {
        form.setFieldsValue({ payment_terms: addNewPaymentTerms })
    }, [addNewPaymentTerms])

    useEffect(() => {
        form.setFieldsValue({ country: ShipTo })
    }, [ShipTo])

    const dateFormat = 'YYYY/MM/DD';
    const personDate = new Date(headerdata?.sealing_date);
    const personFormattedDate = dayjs(personDate).format(dateFormat);
    const partnerDate = headerdata?.sealing_date ? new Date(headerdata.sealing_date) : null;
    const partnerFormattedDate = partnerDate ? dayjs(partnerDate).format(dateFormat) : null;

    useEffect(() => {
        form.setFieldsValue({ payment_terms: terms.payment_terms })
        form.setFieldsValue({ country: terms.country })
    }, [terms])


    useEffect(() => {
        form.setFieldsValue({ customer_obj: headerdata.id })
        form.setFieldsValue({ company_name: headerdata.company_name })
        form.setFieldsValue({ customer_name: headerdata.customer_name })
        form.setFieldsValue({ cr_no: headerdata.cr_no })
        form.setFieldsValue({ import_no: headerdata.import_no })
        form.setFieldsValue({ vat_no: headerdata.vat_no })
        form.setFieldsValue({ shipping_marks: headerdata.shipping_mark });
        form.setFieldsValue({ notes: headerdata.notes });
        form.setFieldsValue({ delivery_terms: headerdata.delivery_terms })
        form.setFieldsValue({ confirm_quantity: headerdata.confirm_quantity })
        form.setFieldsValue({ invoice_no: headerdata.invoice_no })
        form.setFieldsValue({
            sealing_date: dayjs(personFormattedDate, dateFormat),
            sealing_date: partnerFormattedDate ? dayjs(partnerFormattedDate, dateFormat) : null
        });
        form.setFieldsValue({ country: headerdata.ship_to })
        form.setFieldsValue({ vessel_name: headerdata.vessel_name })
        form.setFieldsValue({ customer_id: headerdata.customer_id })
        form.setFieldsValue({ bl_no: headerdata.bl_no })
        form.setFieldsValue({ payment_terms: headerdata.payment_term })
        form.setFieldsValue({ container_seal_no: headerdata.container_seal_no })
        form.setFieldsValue({ address: headerdata.Address })
        form.setFieldsValue({ email: headerdata.email })
        form.setFieldsValue({ contact_person: headerdata.contact_person })
        form.setFieldsValue({ mobile: headerdata.mobile })
        form.setFieldsValue({ currency: headerdata.currency })

    }, [headerdata])

    // -----------------------  RoundOff Checked Function ----------

    useEffect(() => {
        form.setFieldsValue({ total_pieces: foosaleData.total_pieces })
    }, [foosaleData])

    const TotalBalance = (value) => {
        setBalance(value)
        setWithDecimal(tableSecondaryData[0].total_amount - roundDecimalValue);
        setWithOutDecimal(tableSecondaryData[0].total_amount)
    }

    // =========================  Footer Cheque Hide  ===========================

    const HandleCheQueChage = (value) => {

        if (value === 'Cheque') {
            form.setFieldsValue({ paid: false })

            const totalAmt = tableSecondaryData[0].total_amount - roundDecimalValue;

            if (round) {
                form.setFieldsValue({ received: '0.00' })
                form.setFieldsValue({ balance: totalAmt });
                form.setFieldsValue({ roundoff_amount: totalAmt });
            }
            else {
                form.setFieldsValue({ received: '0.00' })
                form.setFieldsValue({ balance: tableSecondaryData[0].total_amount });
                form.setFieldsValue({ roundoff_amount: tableSecondaryData[0].total_amount });
            }
        }
    }

    // =========================  Other Functions start  =========================



    const [confirmation, setConfirmation] = useState([])

    // ===================== Product Name Select ====================


    const calculatePortBalance = () => {
        const totalall = parseFloat(form.getFieldValue('total_all')) || 0;
        const paidamt = parseFloat(form.getFieldValue('deposit_amt')) || 0;

        let balanceAmt = 0;

        if (paidamt === 0 || paidamt <= totalall) {
            balanceAmt = (totalall - paidamt).toFixed(2);
        } else {
            form.setFieldValue('deposit_amt', totalall);
        }

        return balanceAmt;
    };

    const handleDepositamr = (value) => {
        form.setFieldsValue({ balance_amt: calculatePortBalance() });

    };

    useEffect(() => {
        AddUnitGet()
    }, [])

    const UNITADD = 'choice/add_measurement/'

    const AddUnitMeasure = unit.map((com) => ({ label: com.unit, value: com.unit }));

    const AddUnitGet = () => {
        request.get(`${UNITADD}`)
            .then(function (response) {
                setUnit(response.data)
            })
            .catch(function (error) {
                // toast.error('Failed');
            });
    }



    // =========================  Other Functions End  =========================

    const AddProductName = productname?.map((com) => ({ label: com.product_name, value: com.id }));

    useEffect(() => {
        if (id) {
            Getpatch()
        }
    }, [])

    const URLE = 'invoice/edit_invoice'

    useEffect(() => {
        if (id) {
            form.setFieldsValue({ id })
        }
    }, [])

    useEffect(() => {
        if (id) {
            form.setFieldsValue({ purchaseorderconfirmation_no: confirmation.id })
        }
    }, [])

    const Getpatch = () => {
        request.get(`${URLE}/${id}/`)
            .then(function (response) {
                setHeaderdata(response.data?.invoice)
                setTableData(response.data?.invoice_table)
            })
            .catch(function (error) {
            });
    }


    const initialData = [
        {
            key: 0,
            item: '',
            product_name: '',
            hsn_code: '',
            ctns: '',
            description: '',
            qty: '',
            unit: '',
            item_unit: '',
            price_per_unit: '',
            price: '',
            sell: '',
            discount_percentage: '',
            discount_amt: '',
            tax_percentage: '',
            tax_amt: '',
            amount: '',
        },
    ];

    const secondaryData = [
        {
            total_qty: '',
            total_discount: '',
            total_tax: '',
            total_amount: '',
        },
    ];

    const footerCalData = [
        {
            roundoff: '',
            total_amount: '',
            total_rowamount: '',
            received: '',
            balance: '',
        },
    ];


    const [tableData, setTableData] = useState(initialData);
    const [tableSecondaryData, setTableSecondaryData] = useState(secondaryData);
    const [tableFooterData, setTableFooterData] = useState(footerCalData);
    const [PackForm, setPackForm] = useState({})

    const [trigger, setTrigger] = useState(0);

    // +++++++++++++++++++++   Use Effects Start +++++++++++++++++++
    useEffect(() => {
        GetDraftPack()
    }, [])

    const GetDraftPack = () => {
        if (PkNo) {
            request.get(`packing/get_detail_packing/${PkNo}/`)
                .then(response => {
                    setTableData(response.data.purchase_order_table)
                    setPackForm(response.data)
                })
                .catch(error => console.log(error, 'error'))
        }
    }

    // ------------------  Dynamic Table  --------------------

    useEffect(() => {
        tableData.forEach(record => {

            form.setFieldsValue({ [`product_name${record.key}`]: record.product_name });
            form.setFieldsValue({ [`description${record.key}`]: record.description });
            form.setFieldsValue({ [`barcode${record.key}`]: record.barcode });
            form.setFieldsValue({ [`item_unit${record.key}`]: record.item_unit });
            form.setFieldsValue({ [`price_per_unit${record.key}`]: record.price_per_unit });
            form.setFieldsValue({ [`quantity${record.key}`]: record.quantity });
            form.setFieldsValue({ [`unit${record.key}`]: record.unit });
            form.setFieldsValue({ [`rate${record.key}`]: record.rate });
            form.setFieldsValue({ [`total${record.key}`]: record.total });
            form.setFieldsValue({ [`net_weight${record.key}`]: record.net_weight });
            form.setFieldsValue({ [`gross_weight${record.key}`]: record.gross_weight });
            form.setFieldsValue({ [`cbm${record.key}`]: record.cbm });
            form.setFieldsValue({ [`total_amt${record.key}`]: record.total_amt });
            {
                PkNo ?
                    form.setFieldsValue({ [`product_id${record.key}`]: record.product }) :
                    form.setFieldsValue({ [`product_id${record.key}`]: record.product_id })

            }
            {
                if (PkNo) {
                    form.setFieldsValue({ [`ctns${record.key}`]: parseFloat(record.ctns) })
                }
            }
        });

        form.setFieldsValue({ [`ctnsd`]: tableSecondaryData[0].ctnsd });
        form.setFieldsValue({ [`total_quantity`]: tableSecondaryData[0].total_quantity });
        form.setFieldsValue({ [`net_weights`]: tableSecondaryData[0].net_weights });
        form.setFieldsValue({ [`gross_weights`]: tableSecondaryData[0].gross_weights });
        form.setFieldsValue({ [`cbms`]: tableSecondaryData[0].cbms });

        setWithOutDecimal(tableSecondaryData[0].total_amount);
    }, [tableData])

    useEffect(() => {
        if (PkNo) {
            form.setFieldsValue(PackForm)
            form.setFieldsValue({ packlistno: PackForm.packing_list_no })
            form.setFieldsValue({ invoice_no: PackForm.ref_invoice_no })
            form.setFieldsValue({ sealing_date: dayjs(PackForm.sailing_on, 'YYYY/MM/DD') })
            form.setFieldsValue({ payment_terms: PackForm.payment_terms });
            form.setFieldsValue({ shipping_marks: PackForm.shipping_marks });
            form.setFieldsValue({ notes: PackForm.notes });
            form.setFieldsValue({ country: PackForm.ship_to });
            // packing footer
            form.setFieldsValue({ ctnsd: PackForm.total_ctns });
            form.setFieldsValue({ net_weights: PackForm.total_net_weight });
            form.setFieldsValue({ gross_weights: PackForm.total_gross_weight });
            form.setFieldsValue({ cbms: PackForm.total_cbm });
        }
    }, [PackForm])


    // --------------------- Round Off Checked  -----------------
    useEffect(() => {
        const totalAmt = tableSecondaryData[0].total_amount - roundDecimalValue;
        if (round) {
            if (balance) {
                form.setFieldsValue({ round_off: roundDecimalValue });
                form.setFieldsValue({ roundoff_amount: totalAmt });
                form.setFieldsValue({ balance: 0 });
                form.setFieldsValue({ received: totalAmt });
                setBalanceChangeAmount(totalAmt);
            }
            else {
                form.setFieldsValue({ round_off: roundDecimalValue });
                form.setFieldsValue({ roundoff_amount: totalAmt });
                form.setFieldsValue({ balance: totalAmt });
                form.setFieldsValue({ received: 0 });
                setBalanceChangeAmount(0);
            }
        }
        else {
            if (balance) {
                form.setFieldsValue({ round_off: 0 });
                form.setFieldsValue({ roundoff_amount: tableSecondaryData[0].total_amount });
                form.setFieldsValue({ balance: 0 });
                form.setFieldsValue({ received: tableSecondaryData[0].total_amount });
                setBalanceChangeAmount(tableSecondaryData[0].total_amount);
            }
            else {
                form.setFieldsValue({ round_off: 0 });
                form.setFieldsValue({ roundoff_amount: tableSecondaryData[0].total_amount });
                form.setFieldsValue({ balance: tableSecondaryData[0].total_amount });
                form.setFieldsValue({ received: 0 });
                setBalanceChangeAmount(0);
            }
        }

    }, [round])

    //==============================Reverse =============================================
    useEffect(() => {

        const num = tableSecondaryData[0].total_amount;
        const newInteger = parseInt(num);
        const newDecimal = (num - newInteger).toFixed(2).substr(1);
        setRoundDecimalValue(newDecimal);

        setWithDecimal(tableSecondaryData[0].total_amount - newDecimal);
        const totalAmt = tableSecondaryData[0].total_amount - newDecimal;

        if (round) {
            if (balance) {
                form.setFieldsValue({ round_off: newDecimal });
                form.setFieldsValue({ roundoff_amount: totalAmt });
                form.setFieldsValue({ balance: 0 });
                form.setFieldsValue({ received: totalAmt });
            }
            else {
                form.setFieldsValue({ round_off: newDecimal });
                form.setFieldsValue({ roundoff_amount: totalAmt });
                form.setFieldsValue({ balance: totalAmt });
                form.setFieldsValue({ received: 0 });
            }
        }
        else {
            if (balance) {
                form.setFieldsValue({ round_off: 0 });
                form.setFieldsValue({ roundoff_amount: tableSecondaryData[0].total_amount });
                form.setFieldsValue({ balance: 0 });
                form.setFieldsValue({ received: tableSecondaryData[0].total_amount });
            }
            else {
                form.setFieldsValue({ round_off: 0 });
                form.setFieldsValue({ roundoff_amount: tableSecondaryData[0].total_amount });
                form.setFieldsValue({ balance: tableSecondaryData[0].total_amount });
                form.setFieldsValue({ received: 0 });
            }
        }

    }, [tableData])



    //==========================================================================
    const BalanceOnChange = (value) => {
        setBalanceChangeAmount(value)
    }

    useEffect(() => {

        if (round) {
            if (balance) {
                form.setFieldsValue({ received: withDecimal });
                form.setFieldsValue({ balance: 0 });
                setBalanceChangeAmount(withDecimal);
            }
            else {
                form.setFieldsValue({ received: 0 });
                form.setFieldsValue({ balance: withDecimal });
                setBalanceChangeAmount(0);

            }
        }
        else {
            if (balance) {
                form.setFieldsValue({ received: withOutDecimal });
                form.setFieldsValue({ balance: 0 });
                setBalanceChangeAmount(withOutDecimal);
            }
            else {
                form.setFieldsValue({ received: 0 });
                form.setFieldsValue({ balance: withOutDecimal });
                setBalanceChangeAmount(0);
            }
        }
    }, [balance])

    useEffect(() => {

        let fizedAmount = 0;

        if (round) {
            fizedAmount = withDecimal;

            if (balance) {
                form.setFieldsValue({ received: withDecimal });
                form.setFieldsValue({ balance: 0 });
                setBalanceChange(false);
            }
            else {
                // ===
                let setAmt = balanceChangeAmount;
                let balSetAmt = withDecimal - setAmt;

                if (balSetAmt < 0) {
                    setBalanceChange(true);
                }
                else {
                    setBalanceChange(false);
                }
                form.setFieldsValue({ received: setAmt });
                form.setFieldsValue({ balance: balSetAmt });
            }
        }
        else {
            fizedAmount = withOutDecimal;
            if (balance) {
                form.setFieldsValue({ received: withOutDecimal });
                form.setFieldsValue({ balance: 0 });
                setBalanceChange(false);
            }
            else {
                // ===
                let setAmt = balanceChangeAmount;
                let balSetAmt = withOutDecimal - setAmt;

                if (balSetAmt < 0) {
                    setBalanceChange(true);
                }
                else {
                    setBalanceChange(false);
                }

                form.setFieldsValue({ received: setAmt });
                form.setFieldsValue({ balance: balSetAmt });
            }
        }

    }, [balanceChangeAmount])




    const columns = [
        {
            title: '#',
            render: (text, record, index) => {

                return (
                    (
                        <Flex alignCenter gap={'20px'} style={{ alignItems: 'center' }}>
                            <h4>{index + 1}</h4>
                            <DeleteButtonWrapper>
                                <Button
                                    style={{
                                        display: 'none',
                                        padding: '10px',
                                        height: 'auto',
                                        fontSize: '16px',
                                    }}
                                    htmlType="button"
                                    danger
                                    onClick={() => onDelete(record.key)}
                                >
                                    <DeleteOutlined />
                                </Button>
                            </DeleteButtonWrapper>
                        </Flex>
                    )
                );
            },
        },
        {
            title: 'PRODUCT Name',
            dataIndex: 'product_name',
            key: 'product_name',
            render: (text, record) => (
                <>
                    <Input
                        minWidth={'120px'}
                        showSearch
                        name={`product_name${record.key}`}
                        options={AddProductName}
                        onChange={(value) => handleOnChangeProduct(value, record)}
                    />
                    <Input name={`product_id${record.key}`} display={'none'} />

                </>
            )
        },
        {
            title: 'DESCRIPTION',
            dataIndex: 'description',
            render: (text, record) => (
                <TextAreas
                    minWidth={'120px'}
                    placed={'end'}
                    name={`description${record.key}`}
                    onChange={(value) => handleOnChangeDescription(value, record)}
                />
            )
        },
        {
            title: 'quantity / Unit',
            dataIndex: 'quantity',
            render: (text, record) => (
                <>
                    <CustomInputNumber name={`quantity${record.key}`}
                        onChange={(value) => handleOnChangeQuantity(value, record)}
                        minWidth={'120px'}
                        rules={[
                            {
                                required: true,
                                message: 'This is a required field',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (parseFloat(value) <= 0) {
                                        return Promise.reject('Quantity must be greater than 1');
                                    }
                                    return Promise.resolve();
                                },
                            }),
                        ]} />
                    <Select
                        rules={[
                            {
                                required: true,
                                message: 'This is a required field',
                            },
                        ]}
                        minWidth={'120px'}
                        showSearch
                        name={`unit${record.key}`}
                        options={AddUnitMeasure}
                        onChange={(value) => handleOnChangeUnits(value, record)}
                    />
                </>
            )
        },
        {
            title: "CRT'S",
            render: (text, record) => (
                <>
                    <CustomInputNumber name={`ctns${record.key}`}
                        onChange={(value) => handleOnChangeCtns(value, record)}
                        minWidth={'120px'}
                        rules={[
                            {
                                required: true,
                                message: 'This is a required field'
                            },
                            {
                                type: 'number',
                                min: 0,
                                message: 'value must be positive'
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (parseFloat(value) <= 0) {
                                        return Promise.reject('CRT must be greater than 1');
                                    }
                                    return Promise.resolve();
                                },
                            }),
                        ]}
                    />
                </>
            )
        },
        {
            title: 'NET WEIGHT (KG)',
            dataIndex: 'net_weight',
            render: (text, record) => (
                <CustomInputNumber precision={2}
                    onChange={(value) => handleOnChangeNetweigh(value, record)}
                    minWidth={'120px'}
                    min={0.00}
                    placed={'end'}
                    name={`net_weight${record.key}`}
                />
            )
        },
        {
            title: 'G.W (kg)',
            dataIndex: 'gross_weight',
            key: 'gross_weight',
            render: (text, record) => (
                <CustomInputNumber
                    onChange={(value) => handleOnChangeGweit(value, record)}
                    minWidth={'120px'}
                    min={0.00}
                    placed={'end'}
                    name={`gross_weight${record.key}`}
                />
            )
        },
        {
            title: 'CBM',
            dataIndex: 'cbm',
            key: 'cbm',
            render: (text, record) => (
                <CustomInputNumber precision={2}
                    onChange={(value) => handleOnChangeCBM(value, record)}
                    minWidth={'120px'}
                    min={0.00}
                    placed={'end'}
                    name={`cbm${record.key}`}
                />
            )
        }
    ]

    // ----------------- Add Row Function 

    const AddRow = () => {
        const newData = {
            key: count,
            item: '',
            product_name: '',
            itemCode: '',
            ctns: '',
            hsn_code: '',
            description: '',
            qty: '',
            item_unit: '',
            price_per_unit: '',
            price: '',
            sell: '',
            discount_percentage: '',
            discount_amt: '',
            tax_percentage: '',
            tax_amt: '',
            amount: '',
        };
        setTableData(pre => {
            return [...pre, newData]
        })
        setCount(count + 1);
    }

    // -----------------------  Delete Row Function

    const onDelete = (key) => {
        if (tableData.length > 1) {
            setTableData(prevState => {
                const newData = prevState.filter(item => item.key !== key);

                // ------ Variables 
                let totalQuantity = 0;
                let totalDiscount = 0;
                let totalTax = 0;
                let totalAmount = 0;

                newData.forEach(item => {
                    if (item.qty !== '' || item.amount !== '' || item.discount_amt !== '' || item.tax_amt !== '') {
                        totalQuantity += parseFloat(item.qty);
                        totalDiscount += parseFloat(item.discount_amt);
                        totalTax += parseFloat(item.tax_amt);
                        totalAmount += parseFloat(item.amount);
                    }
                });

                // update the total_amount value in the tableSecondaryData array
                setTableSecondaryData([{
                    total_qty: totalQuantity.toFixed(2),
                    total_discount: totalDiscount.toFixed(2),
                    total_tax: totalTax.toFixed(2),
                    total_amount: totalAmount.toFixed(2)
                }]);

                // setTableFooterData

                return newData;
            });
        } else {
            console.log(`only ${tableData.length} is available`)
        }
    };

    // ========================   Total Calculating Functions
    // ----------------- 1. Calculate TotalAmount 

    const CalculateTotal = (record) => {

        setTableData(prevState => {
            const newData = [...prevState];
            const index = newData.findIndex(item => record.key === item.key);
            const item = newData[index];
            item.total = record.total || 0;
            item.quantity = record.quantity || 0;
            item.rate = record.rate || 0;
            item.net_weight = record.net_weight || 0;
            item.gross_weight = record.gross_weight || 0;
            item.cbm = record.cbm || 0;
            item.ctns = record.ctns || 0;


            // ------ Variables 
            let totalQuantity = 0;
            let totalRate = 0;
            let totalAmount = 0;
            let ctnsd = 0;
            let total_quantity = 0;
            let netweight = 0;
            let grossweight = 0;
            let cbmm = 0;

            newData.forEach(item => {
                if (item.quantity !== '' || item.total !== '' || item.rate !== '' || item.ctns !== '') {
                    totalQuantity += parseFloat(item.quantity);
                    totalRate += parseFloat(item.rate);
                    totalAmount += parseFloat(item.total_amt);
                    ctnsd += parseFloat(item.ctns) || 0;
                    total_quantity += parseFloat(item.quantity)
                    netweight += parseFloat(item.net_weight);
                    grossweight += parseFloat(item.gross_weight);
                    cbmm += parseFloat(item.cbm);
                }
            });

            const amountwordsinvoice = numberToWords(totalAmount)
            setTableSecondaryData([{
                total_all: totalAmount.toFixed(2),
                gt_inwords: amountwordsinvoice,
                ctnsd: ctnsd.toFixed(2),
                total_quantity: total_quantity.toFixed(2),
                net_weights: netweight.toFixed(2),
                gross_weights: grossweight.toFixed(2),
                cbms: cbmm.toFixed(2),

            }]);

            return newData;
        })
    };

    // ============  OnChange Functions  ==============

    const HandleCBM = (value, record) => {
        setTableData(prevState => {
            const newData = [...prevState];
            const index = newData.findIndex(item => record.key === item.key);
            const item = newData[index];

            item.cbm = value || 0;

            CalculateTotal({
                ...item,
                cbm: item.cbm,
            });
            return newData
        })
    }

    const HandleGWEHT = (value, record) => {
        setTableData(prevState => {
            const newData = [...prevState];
            const index = newData.findIndex(item => record.key === item.key);
            const item = newData[index];

            item.gross_weight = value || 0;

            CalculateTotal({
                ...item,
                gross_weight: item.gross_weight,
            });
            return newData
        })
    }

    const HandleCtns = (value, record) => {
        setTableData(prevState => {
            const newData = [...prevState];
            const index = newData.findIndex(item => record.key === item.key);
            const item = newData[index];

            item.ctns = value;
            item.net_weights = record.net_weights || 0;
            item.total_quantity = record.total_quantity || 0;

            CalculateTotal({
                ...item,
                ctns: item.ctns || 0,
                net_weights: item.net_weights || 0,
                total_quantity: item.total_quantity || 0

            });
            return newData
        })
    }

    const HandleNetweigh = (value, record) => {
        setTableData(prevState => {
            const newData = [...prevState];
            const index = newData.findIndex(item => record.key === item.key);
            const item = newData[index];

            item.net_weight = value || 0;

            CalculateTotal({
                ...item,
                net_weight: item.net_weight,


            });
            return newData
        })
    }


    const HandleQty = (value, record) => {
        setTableData(prevState => {
            const newData = [...prevState];
            const index = newData.findIndex(item => record.key === item.key);
            const item = newData[index];

            item.quantity = value || 0;

            const Quantity = parseInt(item.quantity) || 0;
            const Rate = parseInt(item.rate) || 0;
            const Total = Quantity * Rate;

            item.total_amt = Total || 0;
            item.price_amount = record.price_amount || 0;

            CalculateTotal({
                ...item,
                quantity: item.quantity,
                total_amt: Total,
                rate: item.rate,

            });

            return newData;
        });

    };

    const HandleUnit = (value, record) => {
        setTableData(prevState => {
            const newData = [...prevState];
            const index = newData.findIndex(item => record.key === item.key);
            const item = newData[index];
            item.unit = value || 0;
            return newData;
        });
    };

    const HandleProduct = (value, record) => {

        setTableData(prevState => {
            const newData = [...prevState];
            const index = newData.findIndex(item => record.key === item.key);
            const item = newData[index];
            const setSelectedProduct = productname.find(item => item.id === value);

            item.product_name = setSelectedProduct.item_name;
            item.barcode = setSelectedProduct.barcode;
            item.product_image = setSelectedProduct.item_image;
            item.description = setSelectedProduct.item_description;
            return newData;
        });
    }

    const HandleDescript = (value, record) => {
        setTableData(prevState => {
            const newData = [...prevState];
            const index = newData.findIndex(item => record.key === item.key);
            const item = newData[index];

            item.description = value;
            CalculateTotal(item)
            return newData;
        });
    };


    // ---------------- 1.TotalQuantity ONCHANGE Function

    const handleOnChangeCBM = (value, record) => {
        HandleCBM(value, record)
    }

    const handleOnChangeGweit = (value, record) => {
        HandleGWEHT(value, record)
    }

    const handleOnChangeCtns = (value, record) => {
        HandleCtns(value, record)
    }

    const handleOnChangeNetweigh = (value, record) => {
        HandleNetweigh(value, record)
    }

    const handleOnChangeQuantity = (value, record) => {
        HandleQty(value, record)
    }

    const handleOnChangeUnits = (value, record) => {
        HandleUnit(value, record)
    }

    const handleOnChangeProduct = (value, record) => {
        HandleProduct(value, record)
    }

    const handleOnChangeDescription = (value, record) => {
        HandleDescript(value.target.value, record)
    }

    //  ======================  Other Functions =========
    const onOpenSubmit = (values) => {
        setWidth(400)
        setModalTitle("");
        setModalContent(<HandleModel result={values} />);
        showModal();
    }
    const HandleModel = ({ result }) => {
        return (
            <>
                <Flex center>
                    <h1 style={{ fontSize: '20px', color: '#685e5e' }}>Do you Want to Confirm or Draft</h1>
                </Flex>
                <Flex center gap={'50px'} style={{ margin: '20px 0px' }} >

                    <Button.Primary text={'Confirm'} onClick={() => PkNo ? EditConfirmClick(result) : confirmClick(result)} />
                    <Button.Danger text={'Draft'} onClick={() => PkNo ? EditDraftChange(result) : draftChange(result)} />
                </Flex>
            </>
        )
    }

    const EditConfirmClick = (values) => {
        request.put(`packing/edit_packing/${PkNo}/`, values)
            .then(function (response) {
                if (response.status == 201) {
                    toast.success('Successfully Billed Packing List Entry ')
                    form.resetFields();
                    setTrigger((trigger) => trigger + 1);
                    setTableData(initialData);
                    setTableSecondaryData(secondaryData);
                    setTableFooterData(footerCalData);
                    setRound(false);
                    setPayType('Cash');
                    if (response.data?.id) {
                        printPacking(response.data.id);
                    }
                }
                else {
                    console.log('Something went Wrong');
                }
            })
            .catch(function (error) {
                toast.error('Failed');
            });
    }

    const EditDraftChange = (values) => {
        const record = { ...values, draft: 'True' }
        request.put(`packing/edit_packing/${PkNo}/`, record)
            .then(function (response) {
                if (response.status == 201) {
                    toast.success('Packing List Saved to Draft')
                    form.resetFields();
                    setTrigger((trigger) => trigger + 1);
                    setTableData(initialData);
                    setTableSecondaryData(secondaryData);
                    setTableFooterData(footerCalData);
                    setRound(false);
                    setPayType('Cash');
                    window.history.go(-1);
                }
                else {
                    console.log('Something went Wrong');
                }
            })
            .catch(function (error) {
                toast.error('Failed');
            });
    }

    const onResett = () => {
        handleOk()
    }

    const printPacking = (id) => {
        setWidth(400)
        setModalTitle("Packing Print");
        setModalContent(<PrintPackingConfirm id={id} />);
        showModal();
    }


    const PrintPackingConfirm = ({ id }) => {
        return (
            <>
                <Flex center>
                    <h1 style={{ fontSize: '20px', color: '#685e5e' }}>Do you Want to go the Print page</h1>
                </Flex>
                <Flex center gap={'50px'} style={{ margin: '20px 0px' }} >

                    <Button.Primary text={'Yes'} onClick={() => navigate(`/PrintPacking/${id}`)} />
                    <Button.Danger text={'No'} onClick={() => onResett()} />
                </Flex>
            </>
        )
    }

    const confirmClick = (values) => {
        request.post('packing/add_packing_details/', values)
            .then(function (response) {
                if (response.status == 201) {
                    toast.success('Successfully Billed Packing List Entry ')
                    form.resetFields();
                    setTrigger((trigger) => trigger + 1);
                    setTableData(initialData);
                    setTableSecondaryData(secondaryData);
                    setTableFooterData(footerCalData);
                    setRound(false);
                    setPayType('Cash');
                    if (response.data?.id) {
                        printPacking(response.data.id);
                    }
                }
                else {
                    console.log('Something went Wrong');
                }
            })
            .catch(function (error) {
                toast.error('Failed');
            });
    }

    const draftChange = (values) => {
        const record = { ...values, draft: 'True' }
        request.post('packing/add_packing_details/', record)
            .then(function (response) {
                if (response.status == 201) {
                    toast.success('Packing List Saved to Draft')
                    form.resetFields();
                    setTrigger((trigger) => trigger + 1);
                    setTableData(initialData);
                    setTableSecondaryData(secondaryData);
                    setTableFooterData(footerCalData);
                    setRound(false);
                    setPayType('Cash');
                    window.history.go(-1);
                }
                else {
                    console.log('Something went Wrong');
                }
            })
            .catch(function (error) {
                toast.error('Failed');
            });
    }

    // ====================  On Finish Function ============

    const onFinish = (values) => {
        const record = {
            ...values, draft: 'False', invoice_date: selectedDate,
            sealing_date: values?.sealing_date === null ? '' : dayjs(selectedDates).format('YYYY-MM-DD') ? dayjs(values?.sealing_date).format('YYYY-MM-DD') : dayjs(values?.sealing_date).format('YYYY-MM-DD'),
        };

        let result = {
            company_name: record.company_name,
            packing_list_no: record.packlistno,
            address: record.address,
            ref_invoice_no: record.invoice_no,
            sailing_on: record.sealing_date,
            draft: record.draft,
            cr_no: record.cr_no,
            ship_to: record.country,
            import_no: record.import_no,
            sc_no: record.invoice_no,
            vat_no: record.vat_no,
            vessel_name: record.vessel_name,
            bl_no: record.bl_no,
            payment_terms: record.payment_terms,
            delivery_terms: record.delivery_terms,
            container_seal_no: record.container_seal_no,
            confirm_quantity: record.confirm_quantity,
            total_ctns: record.ctnsd,
            total_quantity: record.total_quantity,
            total_net_weight: record.net_weights,
            total_gross_weight: record.gross_weights,
            total_cbm: record.cbms,
            shipping_marks: record.shipping_marks,
            notes: record.notes,
            customer_id: record.customer_id,
            email: record.email,
            contact_person: record.contact_person,
            mobile: record.mobile,
            currency: record.currency,
            customer_name: record.customer_name,

            packing: Object.entries(record)
                .filter(([key]) => key.startsWith('description'))
                .map(([key, product_description]) => {
                    const index = key.match(/\d+/)[0];
                    const itemKey = `product_name${index}`;
                    const itemquantity = `quantity${index}`;
                    const itemunit = `unit${index}`;
                    const itemCtns = `ctns${index}`;
                    const itemNWht = `net_weight${index}`;
                    const itemGWht = `gross_weight${index}`;
                    const itemCBm = `cbm${index}`;
                    const itemProdID = `product_id${index}`;

                    return {
                        product_description,
                        product_name: record[itemKey],
                        quantity: record[itemquantity],
                        unit: record[itemunit],
                        ctns: record[itemCtns],
                        net_weight: record[itemNWht],
                        gross_weight: record[itemGWht],
                        cbm: record[itemCBm],
                        product: record[itemProdID],

                    };
                }),
        };
        onOpenSubmit(result)
    };

    const PurchsePost = (values) => {
        request.post('packing/add_packing_details/', values)
            .then(function (response) {
                if (response.status == 201) {
                    toast.success('Successfully Billed Packing List Entry ')
                    form.resetFields();
                    setTrigger((trigger) => trigger + 1);
                    setTableData(initialData);
                    setTableSecondaryData(secondaryData);
                    setTableFooterData(footerCalData);
                    setRound(false);
                    setPayType('Cash');
                    window.history.go(-1);
                }
                else {
                    console.log('Something went Wrong');
                }
            })
            .catch(function (error) {
                toast.error('Failed');
            });
    }

    const onFinishFailed = (errorInfo) => {
        toast.error('Please fill the Details')

    };


    // ==============  Add Row Component  ================

    const FooterComponent = () => {
        return (
            <div style={{ background: 'var(--light-color)', padding: '20px', display: 'none' }}>
                <Row>
                    <Col lg={4} sm={12} span={24}><Button type="primary" style={{
                        fontSize: '1rem',
                        height: 'auto',
                        fontFamily: 'Poppins',
                        fontWeight: 500,
                        letterSpacing: '1px',
                    }}
                        htmlType="button"
                        onClick={AddRow}>
                        Add Row
                    </Button>
                    </Col>
                </Row>
            </div >
        )
    }

    // ==================  Table  ==================
    const onRest = () => {
        form.resetFields();
        Getpatch()
        setSelectedSupplierDetails(!selectedSupplierDetails)
        setTerms(!terms)
        setTrigger((trigger) => trigger + 1);
        setTableData(initialData);
        setTableSecondaryData(secondaryData);
        setTableFooterData(footerCalData);
        setRound(false);
        setPayType('Cash');
        if (PkNo) {
            GetDraftPack()
        }
    }
    return (
        <Fragment>
            <Form name="sales"
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                form={form}
                initialValues={
                    {
                        purchase_date: dayjs(),
                        apply_round_off: false,
                        paid: false,
                        round_off: 0,
                        received: 0,
                    }
                }
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off">
                <PackingForm setSaleorder={setSaleorder} setShipTo={setShipTo} setAddNewPaymentTerms={setAddNewPaymentTerms}
                    setSelectedSupplierDetails={setSelectedSupplierDetails} setTerms={setTerms} trigger={trigger}
                    setInvoiceNumber={setInvoiceNumber} setSelectedDate={setSelectedDate}
                    setSelectedDates={setSelectedDates} setSelectedrevisedDates={setSelectedrevisedDates} />

                <div style={{ margin: '20px 0' }}>
                    <Table columns={columns.filter(Boolean)} data={tableData} pagination={false} />
                    <FooterComponent />
                </div>


                <div style={{ margin: '20px 0' }}>
                    <PackingFooter handleDepositamr={handleDepositamr} payType={payType} setPayType={setPayType} HandleCheQueChage={HandleCheQueChage} footermsg={footermsg} BalanceOnChange={BalanceOnChange} TotalBalance={TotalBalance} tableSecondaryData={tableSecondaryData} footerCalData={footerCalData} setRoundDecimalValue={setRoundDecimalValue} round={round} />
                </div>

                <Card>
                    <Flex center={'true'} gap={'20px'} margin={'20px 0'}>
                        <Button.Primary text={'Submit'} htmlType="submit" disabled={balanceChange} />
                        <Button.Danger text={'Cancel'} onClick={onRest} />
                        <Button.Primary text={'Back'} onClick={() => navigate(-1)} />
                    </Flex>
                </Card>
            </Form>

            <Modal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel}
                width={width}
                modalTitle={modalTitle} modalContent={modalContent} />
        </Fragment>
    )
}
