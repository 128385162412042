import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    token: null,
    authUser: null,
    userRole: null,
    userSuper: null,
    alldata:null
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setCredentials: (state, action) => {
            const { username, jwt, role, super_rejin } = action.payload
            state.authUser = username
            state.token = jwt
            state.userRole = role
            state.userSuper = super_rejin
            state.alldata = action.payload
        },
        logOut: (state, action) => {
            state.authUser = null
            state.token = null
            state.userRole = null
            state.userSuper = null
            state.alldata = null
        }
    }
})

export const { setCredentials, logOut } = authSlice.actions

export const selectCurrentUser = (state) => state.auth.authUser
export const selectCurrentToken = (state) => state.auth.token
export const selectCurrentRole = (state) => state.auth.userRole
export const selectCurrentSuper = (state) => state.auth.userSuper
export const selectCurrentData = (state) => state.auth.alldata

export default authSlice.reducer