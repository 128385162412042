
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import request from "../../utils/request";

const initialState = {
    profile: [],
    activeprofile: {},
    status: 'idle',  // 'idle' | 'loading' | 'succeeded' | 'failed'  
    error: null
}

export const getProfile = createAsyncThunk(
    "ProfileTable/Get",
    async () => {
        try {
            const response = await request.get('business/business_view');
            return [...response.data];
        }
        catch (error) {
            throw error;
        }
    }
);

export const getActiveProfile = createAsyncThunk(
    "ActiveProfile/Get",
    async () => {
        try {
            const response = await request.get('business/print_business/');
            return response.data;
        }
        catch (error) {
            throw error;
        }
    }
);


const ProfileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProfile.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(getProfile.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.profile = action.payload;
            })
            .addCase(getProfile.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.payload
            })
            .addCase(getActiveProfile.fulfilled, (state, action) => {
                state.activeprofile = action.payload;
            })
    }
})

export const selectAllProfile = (state) => state.profiles.profile
export const getProfilesStatus = (state) => state.profiles.status
export const getProfileError = (state) => state.profiles.error

export const getActivePro = (state) => state.profiles.activeprofile

export const { reducer } = ProfileSlice;

export default ProfileSlice.reducer


