import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import request from "../../../utils/request";


const initialState = {
    packinglist: [],
    status: 'idle',  // 'idle' | 'loading' | 'succeeded' | 'failed'  
    error: null,
    packingtable:[],
}

export const getPacking = createAsyncThunk(
    "packingreport/Get",
    async () => {
        try {
            const response = await request.get('packing/packing_report_search/');
            return response.data;
        }
        catch (error) {
            throw error;
        }
    }
);
export const getPayPacking = createAsyncThunk(
    "payreportpacking/Get",
    async (packing) => {
        try {
            const response = await request.get(`payment/packing_report_pay_details/`);
            return response.data;
        }
        catch (error) {
            throw error;
        }
    }
);

const PackingSlice = createSlice({
    name: 'packinglist',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPacking.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(getPacking.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.packinglist = action.payload;
            })
            .addCase(getPacking.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(getPayPacking.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.packingtable = action.payload;
            })
            
      
    }
})

export const getpackingselect = (state) => state.packingsreport.packinglist
export const getPackingTableReport = (state) => state.packingsreport.packingtable

export const { reducer } = PackingSlice;

export default PackingSlice.reducer


