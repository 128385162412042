import React, { useEffect, useRef } from 'react'
import { CardOnBottom, CardOnTop } from './Style';
import { Row } from '../../../../Components/Row';
import { Col, Form } from 'antd';
import { useState } from 'react';
import { Table } from '../../../../Components/Table';
import Button from '../../../../Components/Form/Button';
import request from '../../../../utils/request';
import { CustomDateRangePicker } from '../../../../Components/Form/CustomDateRangePicker';
import Flex from '../../../../Components/Flex';
import { useReactToPrint } from 'react-to-print';
import ParintTable from './print';
import Input from '../../../../Components/Form/Input';
import CardPayment from './CardPayment';
import PaymentDetailsModal from './PaymentDetailsModal';
import { Modal } from '../../../../Components/Modal';
import { AiFillPrinter, AiOutlineEye } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { SalesView } from './SalesView';
import { getSalesError, getSalesStatus, selectAllSales, getSales } from '../../../Sales/SalesSlice';
import { THEME } from '../../../../theme';
import { TableIconHolder } from '../../../../Components/Styled';
import { CommonLoading } from '../../../../Components/CommonLoading';
import dayjs from 'dayjs';
import { selectCurrentRole, selectCurrentSuper } from '../../../Auth/authSlice';

const ReportSale = () => {

    const [dataSource, setDataSource] = useState([])
    const [searchAmount, setSearchAmount] = useState({})

    //   ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalWidth, setModalWidth] = useState(null);

    // ===============  Initial Value For date Range 
    const [dateRange, setDateRange] = useState([]);
    const [searchText, setSearchText] = useState([]);

    const componentRef = useRef();

    const [form] = Form.useForm();

    const dispatch = useDispatch();

    const AllSales = useSelector(selectAllSales)
    const SaleStatus = useSelector(getSalesStatus)
    const SaleError = useSelector(getSalesError)

    const SEARCH_URL = 'sale_confirmation/sale_confirm_details_search/'

    // ========== Roll hidden ===========

    const Role = useSelector(selectCurrentRole);

    const Super_r = useSelector(selectCurrentSuper);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        dispatch(getSales());
    }, []);

    const CallOnFunction = () => {
        setDataSource(AllSales?.sale);

        const MonthStart = new Date(AllSales?.date?.month_start);
        const MonthEnd = new Date(AllSales?.date?.month_end);
        const dateFormat = 'YYYY/MM/DD';

        const MonthStartDate = dayjs(MonthStart).format(dateFormat);
        const MonthEndDate = dayjs(MonthEnd).format(dateFormat);

        form.setFieldsValue({ range: [dayjs(MonthStartDate, dateFormat), dayjs(MonthEndDate, dateFormat)] });

        setDateRange([AllSales?.date?.month_start, AllSales?.date?.month_end])

        setSearchAmount(AllSales?.amount)
    }

    useEffect(() => {
        CallOnFunction();
    }, [AllSales])

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })

    const handleDateRangeChange = (values) => {
        setDateRange(values);
    };

    const onFinish = (values) => {
        const range = {
            range: dateRange
        }

        DateSearch(range)
    };

    const reset = () => {
        form.resetFields();
        CallOnFunction();
    }

    const onFinishFailed = (errorInfo) => {
        console.log(errorInfo);
    };


    const handleSearch = (value) => {
        setSearchText(value);
    };

    const PaymentDetails = (record) => {
        setModalWidth(1000)
        setModalTitle("Payment Details");
        setModalContent(<PaymentDetailsModal dataid={record?.id} />);
        showModal();
    };

    const SalesReport = async (record) => {
        try {
            const response = await request.get(`sale_confirmation/get_print_sale_confirmation/${record?.id}`);
            setModalWidth(1200)
            setModalTitle("Sales Confirmation Details");
            setModalContent(<SalesView record={response.data} />);
            showModal();
            return response.data;
        }
        catch (error) {
            throw error;
        }
    };

    const columns = [
        {
            title: 'Sl No',
            render: (item, value, index) => index + 1,
        },
        {
            title: 'Date',
            dataIndex: 'sale_confirmation_date',

        },
        {
            title: 'SC.No',
            dataIndex: 'sale_confirmation_no',
            filteredValue: searchText ? [searchText] : null,
            onFilter: (value, record) => {
                return String(record.sale_confirmation_no).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.sale_confirmation_no).includes(value.toUpperCase());
            },

        },
        {
            title: 'Customer Id',
            dataIndex: 'customer_id',
        },
        {
            title: 'Shipping Date',
            dataIndex: 'shipping_date',
        },
        {
            title: 'Bill Amount',
            dataIndex: 'grand_total',
            render: (value, record) => `${value} ${record.currency}`,
        },
        {
            title: 'Action',
            render: (record) => {
                return (
                    <Flex gap={'10px'}>
                        <TableIconHolder color={THEME.green} size={'22px'} onClick={() => SalesReport(record)}>
                            <AiOutlineEye />
                        </TableIconHolder>

                        {Role === 'Master1' || Role === 'Master2' ? (
                            <Button.Danger text={'Pay Details'} onClick={() => PaymentDetails(record)} />
                        ) : null}

                        {Super_r === true ? (
                            <Button.Danger text={'Pay Details'} onClick={() => PaymentDetails(record)} />
                        ) : null}


                    </Flex>
                )
            }
        }

    ];


    //==============Date-Post request===============///

    const DateSearch = (values) => {
        request.post(`${SEARCH_URL}`, values)
            .then(function (response) {
                setDataSource(response.data?.sale)
                setSearchAmount(response.data?.amount)
            })
            .catch(function (error) {
                console.log(error);
            });

    }


    let content;

    if (SaleStatus === 'loading') {
        content = <CommonLoading />
    } else if (SaleStatus === 'succeeded') {
        const rowKey = (dataSource) => dataSource.id;
        content = <Table columns={columns} data={dataSource} rowKey={rowKey} />
    } else if (SaleStatus === 'failed') {
        content = <h2>{SaleError}</h2>
    }


    const TablePrintData = dataSource?.filter((item) => {
        // Filter the dataSource based on the searchText
        if (!searchText || typeof searchText !== 'string') return true; // If no searchText provided or not a string, show all data

        const searchTextLower = searchText.toLowerCase();
        const searchTextUpper = searchText.toUpperCase();

        // Check if any of the fields match the searchText
        return (
            String(item?.sale_confirmation_no).toLowerCase().includes(searchTextLower) ||
            String(item?.sale_confirmation_no).includes(searchTextUpper)
        );
    })
        .map((item) => ({
            date: item?.sale_confirmation_date,
            sale_confirmation_no: item?.sale_confirmation_no,
            customer_id: item?.customer_id,
            shipping_date: item?.shipping_date,
            grand_total: item?.grand_total,
        }));


    return (
        <div>
            <CardOnTop>
                <Form
                    form={form}
                    labelCol={{
                        span: 24,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off">


                    <Row space={[24, 24]} style={{ padding: '10px 0' }}>
                        <Col span={24} sm={4} md={3}>
                            <Flex W_100 H_100 centervertically={"true"}>
                                <b>Between</b>
                            </Flex>
                        </Col>

                        <Col span={24} sm={20} md={10}>

                            <CustomDateRangePicker
                                onChange={handleDateRangeChange}
                                value={dateRange}
                                name={'range'}
                                rules={[{ required: true, message: 'Please Select the Date' }]}
                            />
                        </Col>

                        <Col span={18} sm={12} md={7} style={{ display: 'flex', justifyContent: 'center' }}>
                            <Flex gap={"10px"}>
                                <Button.Primary text={'Submit'} htmlType='submit' />
                                <Button.Danger text={'Cancel'} onClick={() => reset()} />
                            </Flex>
                        </Col>
                        <Col span={6} sm={8} md={4} style={{ display: 'flex', justifyContent: 'center' }}>
                            <div style={{ display: "none" }}>
                                <ParintTable componentRef={componentRef} data={TablePrintData} />
                            </div>
                            {
                                TablePrintData?.length > 0 && (
                                    <Button.Primary text={<AiFillPrinter style={{ fontSize: '30px' }} />} onClick={handlePrint} />
                                )
                            }

                        </Col>
                    </Row>
                </Form>
                <CardPayment datas={searchAmount} />

            </CardOnTop >
            <CardOnBottom>
                <Row space={[24, 24]} style={{ marginTop: '25px' }}>
                    <Col span={24} md={13}>
                        <h1>Transactions :</h1>
                    </Col>
                    <Col span={24} md={4}></Col>
                    <Col span={24} md={7} style={{ marginTop: '25px' }}>
                        <Input
                            placeholder="Search by SC No"
                            value={searchText}
                            onSearch={handleSearch}
                            onChange={(e) => handleSearch(e.target.value)}

                        />
                    </Col>
                </Row>

                {content}

                <Modal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={modalWidth} modalTitle={modalTitle} modalContent={modalContent} />
            </CardOnBottom>
        </div>
    )
}

export default ReportSale