import React from 'react'
import { MdProductionQuantityLimits } from 'react-icons/md'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { GiMoneyStack } from 'react-icons/gi'
export const DashboardData = [
  {
    key: '1',
    icon: <MdProductionQuantityLimits/>,
    h1: 'Sales Confirmation',
    Amount: '',
    p: 'Monthly Sales',
    Total: '',
  },
  {
    key: '2',
    icon: <AiOutlineShoppingCart />,
    h1: 'Purchase Confirmation',
    Amount: '',
    p: 'Monthly Purchase',
    Total: ''
  },
  {
    key: '2',
    icon: <GiMoneyStack />,
    h1: 'Profit & Loss',
    Amount: '',
    p: 'Monthly Profit & Loss',
    // Total: ''
    
  }
]
export const DashSupplierData = [
  {
    h1: 'Brigestone',
    h3: '9894297542',
    aumd: '₹ 2002'
  },
  {
    h1: 'Ceat rtt',
    h3: '9894297542',
    aumd: '₹ 20022222'
  },

  {
    h1: 'FIRESTONE',
    h3: '9894297542',
    aumd: '₹ 2002'
  },

  {
    h1: 'MICHELIN TRUCK',
    h3: '9894297542',
    aumd: '₹ 2002'
  },
  {
    h1: 'MICHELIN TWO WHEELER',
    h3: '9894297542',
    aumd: '₹ 2002'
  },
  {
    h1: 'TVS',
    h3: '9894297542',
    aumd: '₹ 2002'
  },
  {
    h1: '8.5 tube',
    h3: '9894297542',
    aumd: '₹ 2002'
  },

]
export const CustomercreditData = [
  {
    h1: 'Richn',
    h3: '9894297542',
    aumd: '₹ 2002'
  },
  {
    h1: 'Ceat',
    h3: '9894297542',
    aumd: '₹ 2002'
  },

  {
    h1: 'FIRESTONE',
    h3: '9894297542',
    aumd: '₹ 2002'
  },

  {
    h1: 'MICHELIN TRUCK',
    h3: '9894297542',
    aumd: '₹ 2002'
  },
  {
    h1: 'MICHELIN TWO WHEELER',
    h3: '9894297542',
    aumd: '₹ 2002'
  },
  {
    h1: 'TVS',
    h3: '9894297542',
    aumd: '₹ 2002'
  },
  {
    h1: '8.5 tube',
    h3: '9894297542',
    aumd: '₹ 2002'
  },
]





