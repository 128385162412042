import React from 'react'
import { TopTitle } from '../../../Components/Form/TopTitle'
import ViewProfileTable from './Partials/ViewProfileTable'

const ViewProfile = () => {
  return (
    <div>
        <TopTitle Heading={'View Profile'}/>
        <ViewProfileTable/>
    </div>
  )
}

export default ViewProfile