import React, { useEffect, useState } from "react";
import { HeaderIconHolder, BtnSideHolder, Logo } from "./Style";
import { FaGlobe, FaHome, FaUserTie } from "react-icons/fa";
import { AiOutlineBell, AiOutlineMenuUnfold } from "react-icons/ai";
import { BiLogOut } from "react-icons/bi";
import { CustomDropDownButton } from "../../Components/Form/CustomDropDownButton";
import { CustomRow } from "../../Components/CustomButton";
import { THEME } from "../../theme";
import { CustomBadge } from "../../Components/CustomBadge";
import Flex from "../../Components/Flex";
import { Modal } from "../../Components/Modal";
import Button from "../../Components/Form/Button";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../../Modules/Auth/authSlice";
import { Table } from "../../Components/Table";
import dayjs from "dayjs";
import {
  getAllNotifications,
  getNotification,
  getStatus,
} from "../../Modules/AddModules/AddModulesSlice";
import { CommonLoading } from "../../Components/CommonLoading";
import  {TbNavigationShare}  from "react-icons/tb";

export const NavHeader = ({ updateCollapse }) => {
  // ======  Modal Open ========
  const [isModalOpen, setIsModalOpen] = useState(false);

  // ======  Modal Title and Content ========
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const [dataSource, setDataSource] = useState([]);

  const notify = useSelector(getAllNotifications);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNotification());
  }, []);

  useEffect(() => {
    setDataSource(notify);
  }, [notify]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const AdminLogOut = () => {
    dispatch(logOut());
  };

  const LogOutModal = () => (
    <div>
      <h1 style={{ fontSize: "1.2rem" }}>Are you Sure You Want to Logout ?</h1>
      <br />
      <Flex gap={"20px"} W_100 center verticallyCenter>
        <Button.Success text={"Logout"} onClick={AdminLogOut} />
        <Button.Danger text={"Cancel"} onClick={handleOk} />
      </Flex>
    </div>
  );

  const onViewStudent = () => {
    setModalContent(<LogOutModal />);
    setModalTitle("Log Out");
    showModal();
  };

  const handleNotfModal = () => {
    setModalTitle("Notifications");
    setModalContent(
      <Table columns={columns} data={dataSource} pagination={false} />
    );
    showModal();
  };

  const update = () => {
    updateCollapse();
  };

  const handleMenuClick = (e) => {
    onViewStudent();
  };

  const LoqOutBtn = () => (
    <Flex gap={"8px"} centervertically={"true"}>
      {" "}
      <BiLogOut />
      &nbsp;Logout
    </Flex>
  );

  const items = [
    {
      label: <LoqOutBtn />,
      key: "1",
      danger: true,
    },
  ];

  const iconStyle = {
    fontSize: '26px',
    color: 'grey',
    transition: 'color 0.3s ease-in-out', 
  };
  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const columns = [
    {
      title: "S.No",
      render: (value, item, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "customer_name",
      render: (customer_name, record) => {
        return (
          <span style={{ color: `${record.color}` }}>{customer_name}</span>
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (amount, record) => {
        return <span style={{ color: `${record.color}` }}>{amount}</span>;
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      render: (date, record) => {
        const DATE = dayjs(date).format("DD\\MM\\YYYY");
        return <span style={{ color: `${record.color}` }}>{DATE}</span>;
      },
    },
  ];

  let content;

  if (getStatus === "loading") {
    content = <CommonLoading />;
  } else if (getStatus === "succeeded") {
    const rowKey = (dataSource) => dataSource.id;
    content = <Table columns={columns} data={dataSource} rowKey={rowKey} />;
  } else if (getStatus === "failed") {
    content = <h2>faileddddddd</h2>;
  }

  return (
    <>
      <CustomRow
        style={{
          alignItems: "center",
          justifyContent: "space-between",
          height: "70px",
        }}
      >
        <div>
          <CustomRow style={{ alignItems: "center" }}>
            <Logo>
              <span>Sun n shine</span>
            </Logo>

            <BtnSideHolder onClick={update}>
              <AiOutlineMenuUnfold className="header__icon" />
            </BtnSideHolder>
          </CustomRow>
        </div>

        <HeaderIconHolder>
          {dataSource?.length > 0 ? (
            <CustomBadge
              dot={true}
              icon={
                <AiOutlineBell
                  style={{
                    fontSize: 26,
                    color: "#1E1E1E",
                  }}
                  onClick={() => handleNotfModal()}
                />
              }
            />
          ) : (
            <CustomBadge
              icon={
                <AiOutlineBell
                  style={{
                    fontSize: 26,
                    color: "#1E1E1E",
                  }}
                />
              }
            />
          )}
          <Flex  baseLine={'true'} gap={"20px"} style={{height: '32px'}}>
              <a
                href="http://www.sunnshinehk.com"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                    textDecoration: 'none',
                    ...iconStyle,
                    ':hover': { color: 'white' }, // Apply the hover effect directly within the style
                  }}
              >
                <TbNavigationShare style={iconStyle} />
              </a>

              <a
                href="http://www.sunnshinehk.net"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                    textDecoration: 'none',
                    ...iconStyle,
                    ':hover': { color: 'white' }, // Apply the hover effect directly within the style
                  }}
              >
                <FaGlobe style={iconStyle} />
              </a>
          </Flex>

          <CustomDropDownButton
            menu={menuProps}
            bgcolor={"transparent"}
            placement="bottomLeft"
            trigger={["click"]}
            icon={<FaUserTie size={22} />}
            color={THEME.primary_color_gray2}
          />
        </HeaderIconHolder>

        <Modal
          isVisible={isModalOpen}
          handleOk={handleOk}
          handleCancel={handleCancel}
          width={600}
          modalTitle={modalTitle}
          modalContent={modalContent}
        />
      </CustomRow>
    </>
  );
};
