import UserSignin from '../../Modules/Auth/Components/UserSignin'
import AddCustomers from '../../Modules/Customers/AddCustomers'
import CustomerProfiles from '../../Modules/Customers/CustomerProfile'
import CustomerEdit from '../../Modules/Customers/EditCustomers'
import Dashboard from '../../Modules/Dashboard'
import Invoice from '../../Modules/Invoice'
import { AddProduct } from '../../Modules/Products/Add Items'
import ProductViewItems from '../../Modules/Products/ViewItems'
import ProfileMain from '../../Modules/Profile'
import Purchase from '../../Modules/Purchase'
import Quotation from '../../Modules/Quotations'
import SalesMain from '../../Modules/Sales'
import AddSpplier from '../../Modules/Suppliers/AddSupplier'
import EditSppliers from '../../Modules/Suppliers/EditSupplier'
import SupplierProfiles from '../../Modules/Suppliers/SupplierProfile'
import AddUserMain from '../../Modules/User/AddUser'
import ViewTableUser from '../../Modules/User/ViewUser'
import ViewTotalList from '../../Modules/ViewTotalList/ViewTotalList'
import PageNotFound from '../Components/PageNotFound'
import { Profit } from '../../Modules/ProfitorLoss'
import { SubmenuTable } from '../../Modules/Submenu'
import View from '../../Modules/ProfitorLoss/Partials/View'
import UnpaidTable from '../../Modules/Bills/Partials/UnpaidTable'
import FactorybillTable from '../../Modules/Bills/Partials/FactorybillTable'
import PurchaseMain from '../../Modules/Purchase/Partials/PurchaseMain'
import InvoiceMain from '../../Modules/Invoice/Partials/InvoiceMain'
import PackListMain from '../../Modules/PackingList/Partials/PackListMain'
import PurchaseViewList from '../../Modules/ViewTotalList/Partials/PurchaseViewList'
import SaleReportMain from '../../Modules/Reports/SaleReport'
import PurchaseReportMain from '../../Modules/Reports/PurchaseReport'
import PackingListReport from '../../Modules/Reports/PackingListReport'
import InvoiceReport from '../../Modules/Reports/InvoiceReport'
import Financereport from '../../Modules/Reports/FinanceReport'
import ViewProfile from '../../Modules/Profile/ViewProfile'
import { PrintQuatation } from '../../Modules/Quotations/Partials/Print'
import { PrintSales } from '../../Modules/Sales/Partials/PrintSale'
import EditSaleMain from '../../Modules/Sales/EditSales'
import OptionIndex from '../../Modules/Products/ViewItems/Partials/OptionIndex'
import { PurchaseTBView } from '../../Modules/Reports/PurchaseReport/Partials/PurchTableView'
import { PrintPurchase } from '../../Modules/Purchase/Partials/PrintPurchase'
import { PrintInvoice } from '../../Modules/Invoice/Partials/PrintInvoice'
import { PrintPacking } from '../../Modules/PackingList/Partials/PrintPacking'
export const anonymous = [
  {
    routePath: '/signin',
    Component: UserSignin,
  },

]

export const authenticated = [
  {
    routePath: '/',
    Component: Dashboard,
  },
  {
    routePath: 'headoffice_profile',
    Component: ProfileMain,
  },
  {
    routePath: 'ViewDetailsProfile',
    Component: ViewProfile,
  },
  {
    routePath: 'Users',
    Component: AddUserMain,
  },
  {
    routePath: 'viewusers',
    Component: ViewTableUser,
  },
  {
    routePath: 'Addproduct',
    Component: AddProduct,
  },
  {
    routePath: 'viewproduct',
    Component: ProductViewItems,
  },
  {
    routePath: 'AddFactory',
    Component: AddSpplier,
  },
  {
    routePath: 'viewFactory',
    Component: EditSppliers,
  },
  {
    routePath: 'Addcustomers',
    Component: AddCustomers,
  },
  {
    routePath: 'viewcustomers',
    Component: CustomerEdit,
  },
  {
    routePath: 'quotation',
    Component: Quotation,
  },
  {
    routePath: 'PackListMain/:id',
    Component: PackListMain,
  },
  {
    routePath: 'PackListMainWithPacking/:PkNo',
    Component: PackListMain,
  },
  {
    routePath: 'sales',
    Component: SalesMain,
  },
  {
    routePath: 'sales/:sc_no',
    Component: SalesMain,
  },
  {
    routePath: 'EditSale/:sc_no',
    Component: EditSaleMain,
  },
  {
    routePath: 'purchase',
    Component: Purchase,
  },
  {
    routePath: 'PurchaseMain/:id',
    Component: PurchaseMain,
  },
  {
    routePath: 'PurchaseMainWithPoNo/:poNo',
    Component: PurchaseMain,
  },
  {
    routePath: 'PurchaseViewList/:id',
    Component: PurchaseViewList,
  },
  {
    routePath: 'invoice',
    Component: Invoice,
  },
  {
    routePath: 'InvoiceMain/:id',
    Component: InvoiceMain,
  },
  {
    routePath: 'InvoiceMainWithInvoice/:invoiceNo',
    Component: InvoiceMain,
  },
  {
    routePath: 'ViewTotalList/:id',
    Component: ViewTotalList,
  },
  {
    routePath: 'SupplierProfiles/:id',
    Component: SupplierProfiles,
  },
  {
    routePath: 'CustomerProfiless/:id',
    Component: CustomerProfiles,
  },
  {
    routePath: 'profitloss',
    Component: Profit,
  },
  {
    routePath: 'viewconsign',
    Component: SubmenuTable,
  },
  {
    routePath: 'optionsdetails',
    Component: OptionIndex,
  },
  {
    routePath: 'table',
    Component: UnpaidTable,
  },
  {
    routePath: 'factorybill',
    Component: FactorybillTable,
  },
  {
    routePath: 'view',
    Component: View,
  },
  {
    routePath: 'salereport',
    Component: SaleReportMain,
  },
  {
    routePath: 'purchasereport',
    Component: PurchaseReportMain,
  },
  {
    routePath: 'packreport',
    Component: PackingListReport,
  },
  {
    routePath: 'invoicereport',
    Component: InvoiceReport,
  },
  {
    routePath: 'financereport',
    Component: Financereport,
  },
  {
    routePath: 'Print/:id',
    Component: PrintQuatation,
  },
  {
    routePath: 'PrintSale/:id',
    Component: PrintSales,
  },
  {
    routePath: 'PrintPurchase/:id',
    Component: PrintPurchase,
  },
  {
    routePath: 'PrintInvoice/:id',
    Component: PrintInvoice,
  }, 
  {
    routePath: 'PrintPacking/:id',
    Component: PrintPacking,
  },   
  
  {
    routePath: '*',
    Component: PageNotFound,
  }
]

export const common = [
  {
    routePath: 'headoffice_profile',
    Component: ProfileMain,
  },
  {
    routePath: 'ViewDetailsProfile',
    Component: ViewProfile,
  },
  {
    routePath: 'Users',
    Component: AddUserMain,
  },
  {
    routePath: 'viewusers',
    Component: ViewTableUser,
  },
  {
    routePath: 'Addproduct',
    Component: AddProduct,
  },
  {
    routePath: 'viewproduct',
    Component: ProductViewItems,
  },
  {
    routePath: 'AddFactory',
    Component: AddSpplier,
  },
  {
    routePath: 'viewFactory',
    Component: EditSppliers,
  },
  {
    routePath: 'Addcustomers',
    Component: AddCustomers,
  },
  {
    routePath: 'viewcustomers',
    Component: CustomerEdit,
  },
  {
    routePath: 'quotation',
    Component: Quotation,
  },
  {
    routePath: 'PackListMain/:id',
    Component: PackListMain,
  },
  {
    routePath: 'PackListMainWithPacking/:PkNo',
    Component: PackListMain,
  },
  {
    routePath: 'sales',
    Component: SalesMain,
  },
  {
    routePath: 'purchase',
    Component: Purchase,
  },
  {
    routePath: 'PurchaseMain/:id',
    Component: PurchaseMain,
  },
  {
    routePath: 'PurchaseViewList/:id',
    Component: PurchaseViewList,
  },
  {
    routePath: 'invoice',
    Component: Invoice,
  },
  {
    routePath: 'InvoiceMain/:id',
    Component: InvoiceMain,
  },
  {
    routePath: 'ViewTotalList/:id',
    Component: ViewTotalList,
  },
  {
    routePath: 'SupplierProfiles/:id',
    Component: SupplierProfiles,
  },
  {
    routePath: 'CustomerProfiless/:id',
    Component: CustomerProfiles,
  },
  {
    routePath: 'profitloss',
    Component: Profit,
  },
  {
    routePath: 'viewconsign',
    Component: SubmenuTable,
  },
  {
    routePath: 'table',
    Component: UnpaidTable,
  },
  {
    routePath: 'factorybill',
    Component: FactorybillTable,
  },
  {
    routePath: 'view',
    Component: View,
  },
  {
    routePath: 'salereport',
    Component: SaleReportMain,
  },
  {
    routePath: 'purchasereport',
    Component: PurchaseReportMain,
  },
  {
    routePath: 'packreport',
    Component: PackingListReport,
  },
  {
    routePath: 'invoicereport',
    Component: InvoiceReport,
  },
  {
    routePath: 'financereport',
    Component: Financereport,
  },
  {
    routePath: 'Print/:id',
    Component: PrintQuatation,
  },
  {
    routePath: 'PrintSale/:id',
    Component: PrintSales,
  },
  {
    routePath: 'PrintPurchase/:id',
    Component: PrintPurchase,
  },
  {
    routePath: 'PrintInvoice/:id',
    Component: PrintInvoice,
  },
  {
    routePath: 'PrintPacking/:id',
    Component: PrintPacking,
  },   
  
  {
    routePath: '*',
    Component: PageNotFound,
  }
]



