import React from 'react'
import TableEdit from './Partials/TableEdit'
import { TopTitle } from '../../../Components/Form/TopTitle'

const CustomerEdit = () => {
  return (
    <div>
      <TopTitle Heading={'View Customers List'} />
      <TableEdit />
    </div>
  )
}

export default CustomerEdit