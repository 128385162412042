import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Row } from '../../../../Components/Row'
import { Col } from 'antd'
import {
    AuthersiedBox,
    PrintTableFooterHolder,
    ProfileAddress,
    FooterTitle,
    PrintHolder,
    PrintViewTitle,
    PrintViewValue,
    ProfileHeader,
    ProfileSubTitle,
    SignImageHolder,
    TableHolder,
    PrintWrapper,
    PrintViewValues,
    PrintTableFooterHolders
} from '../../../../Components/Styled'
import Flex from '../../../../Components/Flex'
import { useDispatch, useSelector } from 'react-redux'
import { getActivePro, getActiveProfile } from '../../../Profile/ProfileSlice'
import Button from '../../../../Components/Form/Button'
import { AiFillPrinter, AiFillFilePdf } from 'react-icons/ai'
import { useReactToPrint } from 'react-to-print'
import { TableImageHolder } from '../../../../Components/TableImageHolder'

export const SalesView = ({ record }) => {
    const [viewData, setViewData] = useState({})

    const componentRef = useRef();

    const dispatch = useDispatch();
    const activePro = useSelector(getActivePro);

    useEffect(() => {
        dispatch(getActiveProfile());
    }, [])

    useEffect(() => {
        setViewData(record)
    }, [record])
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })

    return (
        <Fragment >
            <Flex margin={"20px"} gap={"10px"}>
                <Button.Primary text={<AiFillPrinter style={{ fontSize: '30px' }} />} onClick={handlePrint} />
            </Flex>
            <PrintWrapper>
                <PrintHolder ref={componentRef}>
                    {
                        record.draft && (
                            <div style={{ display: 'flex', justifyContent: 'end', marginTop: '10px', marginRight: '20px' }}>
                                <span style={{ borderBottom: '2px solid black', fontSize: '14px', fontWeight: 'bold' }}>DRAFT COPY</span>
                            </div>
                        )
                    }
                    <ProfileHeader>{activePro.business_name}</ProfileHeader>
                    <ProfileAddress>{activePro.address}</ProfileAddress>
                    <ProfileAddress>{activePro.phone_no} , Fax : {activePro.fax_number} , Email : {activePro.email}</ProfileAddress>
                    <div style={{ textAlign: 'center' }}>
                        <ProfileSubTitle>SALES CONFIRMATION</ProfileSubTitle>
                    </div>

                    <Row space={[8, 8]}>
                        <Col span={24} md={12}>
                            <Row space={[2, 2]}>
                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            CUSTOMER NAME & ADDRESS
                                        </PrintViewTitle>
                                        <h1 style={{ alignSelf: 'baseline' }}>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {/* {viewData.customer_name} <br /> */}
                                            {viewData.company_name}<br />
                                            {viewData.Address}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            CUSTOMER ID
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {viewData.customer_id}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            fax&nbsp;
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {viewData.fax}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            email&nbsp;
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {viewData.email}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            contact person
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {viewData.contact_person}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            mobile
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {viewData.mobile}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={10}>
                                    <Flex spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            PAYMENT TERMS
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {viewData.payment_terms}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>
                            </Row>
                        </Col>

                        <Col span={24} md={12}>
                            <Row space={[2, 2]}>
                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            sc no
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {viewData.sale_confirmation_no}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            sc date
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>

                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {viewData.sale_confirmation_date}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            shipment Date
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {viewData.shipping_date}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            ship to
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {viewData.country}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            origin
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {viewData.origin}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>
                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            delivery TERMS
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>
                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {viewData.delivery_terms}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            ship by
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {viewData.ship_by}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>
                                {
                                    viewData.container &&

                                    <Col span={24} sm={12} md={10}>
                                        <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                            <PrintViewTitle>
                                                container
                                            </PrintViewTitle>
                                            <h1>:</h1>
                                        </Flex>
                                    </Col>

                                }
                                {
                                    viewData.container &&

                                    <Col span={24} sm={12} md={14}>
                                        <Flex alignEnd={'true'} H_100>
                                            <PrintViewValue>
                                                {viewData.container}
                                            </PrintViewValue>
                                        </Flex>
                                    </Col>
                                }
                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            REVISED ON
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {viewData.revised_date}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <TableHolder>
                        <table>
                            <thead>
                                <tr>
                                    <th>SI.NO</th>
                                    <th>PRODUCT</th>
                                    <th>BARCODE</th>
                                    <th>IMAGE</th>
                                    <th>DESCRIPTION</th>
                                    <th>QUANTITY</th>
                                    <th>RATE ({viewData?.currency})</th>
                                    <th>TOTAL ({viewData?.currency})</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    viewData?.sale_table?.map((item, index) => (
                                        <tr key={item.id}>
                                            <td>{index + 1}</td>
                                            <td>{item.product_name}</td>
                                            <td>{item.barcode}</td>
                                            <td>
                                                <TableImageHolder imageUrl={item.product_image} />
                                            </td>
                                            <td>{item.description}</td>
                                            <td>{item.quantity} {item.unit}</td>
                                            <td>{item.rate}</td>
                                            <td>{item.total_amt}</td>
                                        </tr>
                                    ))
                                }

                            </tbody>
                        </table>
                    </TableHolder>
                    <PrintTableFooterHolders>
                        <Flex gap={'5px'} alignEnd={'true'}>
                            <PrintViewTitle style={{ width: '200px' }} >
                                TOTAL AMOUNT IN WORDS&nbsp;&nbsp;
                            </PrintViewTitle >
                            <h1>:</h1>
                            <PrintViewValue>
                                <h4 style={{ textTransform: 'uppercase', fontSize: '12px' }}>{viewData?.grand_total_inwords}</h4>

                            </PrintViewValue>
                        </Flex>
                        <Row space={[12, 12]}>
                            <Col span={18} md={12}>
                            </Col>
                            {/* <Col span={18} md={12}></Col>
                            <Col span={18} md={12}></Col> */}
                            <Col span={18} md={12}>
                                <Flex flexEnd={'true'}>
                                    <Row space={[2, 2]}>
                                        <Col span={24} sm={12} md={12}>
                                            <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                                <PrintViewTitle>
                                                    TOTAL QUANTITY
                                                </PrintViewTitle>
                                                <h1>:</h1>
                                            </Flex>
                                        </Col>
                                        <Col span={24} sm={12} md={12}>
                                            <Flex alignEnd={'true'} H_100>
                                                <PrintViewValue>
                                                    {viewData?.total_quantity}
                                                </PrintViewValue>
                                            </Flex>
                                        </Col>
                                        <Col span={24} sm={12} md={12}>
                                            <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                                <PrintViewTitle>
                                                    TOTAL AMOUNT
                                                </PrintViewTitle>
                                                <h1>:</h1>
                                            </Flex>
                                        </Col>
                                        <Col span={24} sm={12} md={12}>
                                            <Flex alignEnd={'true'} H_100>
                                                <PrintViewValue>
                                                    {viewData?.grand_total} {viewData?.currency}
                                                </PrintViewValue>
                                            </Flex>
                                        </Col>
                                    </Row>
                                </Flex>
                            </Col>
                        </Row>
                        <Row space={[12, 12]}>
                            <Col span={18} >
                                <Row space={[2, 2]}>
                                    <Col span={24} sm={12} md={6}>
                                        <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                            <PrintViewTitle>
                                                PACKING
                                            </PrintViewTitle>
                                            <h1>:</h1>
                                        </Flex>
                                    </Col>

                                    <Col span={24} sm={12} md={18}>
                                        <Flex alignEnd={'true'} H_100>
                                            <PrintViewValue>
                                                <pre>
                                                    {viewData.packing}
                                                </pre>

                                            </PrintViewValue>
                                        </Flex>
                                    </Col>
                                    <Col span={24} sm={12} md={6}>
                                        <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                            <PrintViewTitle>
                                                SHIPPING MARKS
                                            </PrintViewTitle>
                                            <h1>:</h1>
                                        </Flex>
                                    </Col>
                                    <Col span={24} sm={12} md={18}>
                                        <Flex alignEnd={'true'} H_100>
                                            <PrintViewValue>
                                                <pre>
                                                    {viewData.shipping_mark}
                                                </pre>
                                            </PrintViewValue>
                                        </Flex>
                                    </Col>
                                    <Col span={24} sm={12} md={6}>
                                        <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                            <PrintViewTitle>
                                                REMARKS
                                            </PrintViewTitle>
                                            <h1>:</h1>
                                        </Flex>
                                    </Col>

                                    <Col span={24} sm={12} md={18}>
                                        <Flex alignEnd={'true'} H_100>
                                            <PrintViewValue>
                                                <pre>
                                                    {viewData.remarks}
                                                </pre>
                                            </PrintViewValue>
                                        </Flex>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <FooterTitle>
                            BANK DETAILS
                        </FooterTitle>
                        <Row space={[6, 6]}>
                            <Col span={24} md={14}>
                                <Row space={[2, 2]}>
                                    <Col span={24} sm={12} md={8}>
                                        <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                            <PrintViewTitle>
                                                BENEFICIARY NAME
                                            </PrintViewTitle>
                                            <h1>:</h1>
                                        </Flex>
                                    </Col>

                                    <Col span={24} sm={12} md={16}>
                                        <Flex alignEnd={'true'} H_100>
                                            <PrintViewValue>
                                                {activePro.bank_account_name}
                                            </PrintViewValue>
                                        </Flex>
                                    </Col>

                                    <Col span={24} sm={12} md={8}>
                                        <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                            <PrintViewTitle>
                                                BENEFICIARY BANK
                                            </PrintViewTitle>
                                            <h1>:</h1>
                                        </Flex>
                                    </Col>

                                    <Col span={24} sm={12} md={16}>
                                        <Flex alignEnd={'true'} H_100>
                                            <PrintViewValue>
                                                {activePro.bank_name}
                                            </PrintViewValue>
                                        </Flex>
                                    </Col>

                                    <Col span={24} sm={12} md={8}>
                                        <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                            <PrintViewTitle>
                                                BENEFICIARY A/C NO
                                            </PrintViewTitle>
                                            <h1>:</h1>
                                        </Flex>
                                    </Col>

                                    <Col span={24} sm={12} md={16}>
                                        <Flex alignEnd={'true'} H_100>
                                            <PrintViewValue>
                                                {activePro.bank_account_number}
                                            </PrintViewValue>
                                        </Flex>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </PrintTableFooterHolders>
                        <PrintTableFooterHolder>
                         <Row space={[24, 24]}>
                            <Col span={24}>
                                <AuthersiedBox />
                                <Flex W_100 spacebetween={'true'} alignEnd={'true'}>
                                    <span>
                                        CONFIRM & ACCEPTED BY
                                    </span>

                                    <span>
                                        FOR AND ON BEHALF OF
                                    </span>
                                </Flex>

                                <Flex flexEnd={'true'}>
                                    <SignImageHolder>
                                        <img src={activePro.signature} alt="signature" />
                                    </SignImageHolder>
                                </Flex>

                                <Flex W_100 spacebetween={'true'} alignEnd={'true'}>
                                    <span>
                                        {/* BRANCH ASFAR */}
                                    </span>

                                    <span>
                                        {activePro.business_name}
                                    </span>
                                </Flex>
                            </Col>
                        </Row>

                     </PrintTableFooterHolder>

                </PrintHolder>
            </PrintWrapper>
        </Fragment >
    )
}
