import { Col, Form } from 'antd'
import React, { Fragment } from 'react'
import { Row } from '../../../../Components/Row'
import Input from '../../../../Components/Form/Input'
import { TextAreas } from '../../../../Components/Form/TextArea'
import Flex from '../../../../Components/Flex'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import Button from '../../../../Components/Form/Button'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import SupplierTableDetails from './TableDetails'
import { useDispatch } from 'react-redux'
import { getAllsupplierDetails, getSupplierDetails, getsupplierProfileDetails } from '../../SupplierSlice'

const SupplierProfile = () => {
    const [form] = Form.useForm();

    const { id } = useParams();

    const navigate = useNavigate();

    const dispatch = useDispatch()

    const [passDatas, setPassDatas] = useState(0)

    const suppProfileDetails = useSelector(getsupplierProfileDetails)

    useEffect(() => {
        dispatch(getSupplierDetails(id))
    }, [passDatas, id])

    const handleGet = () => {
        setPassDatas(passDatas)
    }

    const GetUse = () => {
        dispatch(getSupplierDetails(id))
    }

    const suppDetails = useSelector(getAllsupplierDetails)

    const onFinish = (values) => {
        console.log('Success:', values);
    };

    useEffect(() => {
        form.setFieldsValue(suppDetails)
    }, [suppDetails])



    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const member = useSelector(state => state);

    return (
        <Form
            name='SupplierProfile'
            form={form}
            labelCol={{
                span: 24,
            }}
            wrapperCol={{
                span: 24,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off">

            <Row space={[24, 24]}>
                <Col span={24} md={12}>
                    <Input label={'Company Name'} disabled placeholder={'Company Name'} name={'supplier_name'}
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter Company Name!',
                            }
                        ]} />
                </Col>
                <Col span={24} md={12}>
                    <Input label={'Supplier ID'} disabled placeholder={'Supplier ID'}
                        name={'invoice_no'} />
                </Col>

                <Col span={24} md={12}>
                    <Input label={'FAX number'} disabled placeholder={'FAX number'} name={'fax'} />
                </Col>

                <Col span={24} md={12}>
                    <Input label={'Email id'} disabled name={'email'} type="email" placeholder={"Email ID"} />
                </Col>

                <Col span={24} md={12}>
                    <Input label={'Contact Person'} disabled placeholder={'Contact Person'} name={'contact_person_name'} />
                </Col>

                <Col span={24} md={12}>
                    <Input label={'Mobile number'} disabled placeholder={'Mobile Number'} name={'mobile_number'}
                    />
                </Col>

                <Col span={24} md={12}>
                    <Input label={'Phone Number'} disabled placeholder={'Phone Number'} name={'supplier_mobile'}
                    />
                </Col>

                <Col span={24} md={12}>
                    <TextAreas label={'Address'} disabled placeholder={'Address'} name={'address'}
                    />
                </Col>

                <Col span={24} md={24}>
                    <SupplierTableDetails tableData={suppProfileDetails} handleGet={handleGet} GetUse={GetUse} />
                </Col>
                <Flex center={'true'} gap={'20px'} margin={'20px 0px'}>
                    <Button.Primary text={'Back'} onClick={() => navigate(-1)} />
                </Flex>
            </Row><br />
        </Form>
    )
}

export default SupplierProfile