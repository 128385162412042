import React from 'react'
import { CardOnBottom, CardOnTop, TableStyle } from '../../SaleReport/Partials/Style';
import { Row } from '../../../../Components/Row';
import { Col, Form } from 'antd';
import { CustomDateRangePicker } from '../../../../Components/Form/CustomDateRangePicker';
import Button from '../../../../Components/Form/Button';
import { Table } from '../../../../Components/Table';
import { useState } from 'react';
import { TbArrowsExchange } from 'react-icons/tb';
import Flex from '../../../../Components/Flex';
import dayjs from 'dayjs';
import request from '../../../../utils/request';
import { useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import ParintTable from './print';
import Input from '../../../../Components/Form/Input';
import { Modal } from '../../../../Components/Modal';
import PaymentDetailsModal from './PaymentDetailsModal';
import { useNavigate } from 'react-router-dom';
import CardPayment from './CardPayment';
import { AiFillPrinter, AiOutlineEye } from 'react-icons/ai';
import { Select } from '../../../../Components/Form/Select';
import { THEME } from '../../../../theme';
import { TableIconHolder } from '../../../../Components/Styled';
import { getFinace, getFinaceStatus, selectAllFinace } from '../FinanceSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentRole, selectCurrentSuper } from '../../../Auth/authSlice';

const FinanceTableReport = () => {

    const [form] = Form.useForm();

    const nevigate = useNavigate()

    const dispatch = useDispatch()
    const [passdata, setPassdata] = useState([])
    const [dataSource, setDataSource] = useState([])
    const [dateRange, setDateRange] = useState([]);

    const [ModalWidth, setModalWidth] = useState(0)

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);
    const [show, setShow] = useState(false)

    const AllFinace = useSelector(selectAllFinace)
    const AllFinaceStatus = useSelector(getFinaceStatus)

     // ========== Roll hidden ===========

     const Role = useSelector(selectCurrentRole);

     const Super_r = useSelector(selectCurrentSuper);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const [searchText, setSearchText] = useState([]);

    const handleSearch = (value) => {
        setSearchText(value);
    };

    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })


    const onFinish = (values) => {

        const data = {
            "start_date": dateRange[0],
            "end_date": dateRange[1]
        }

        const range = {
            range: dateRange,
            choice: 'select_date'
        }

        PostFinance(range)
    }


    const month = [
        { label: 'This Month', value: 'this_month' },
        { label: 'Select Date', value: 'select_date' }
    ]

    const handleMonthSelect = (value) => {
        if (value === 'select_date') {
            setShow(true);
        } else {
            dispatch(getFinace())
            setShow(false);
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log(errorInfo);
    };

    const PostFinance = (values) => {
        request.post('consignment/finance_report/', values)
            .then(function (response) {
                setDataSource(response.data?.sale)
                setPassdata(response.data?.amount)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    //=========================month start-end format===================

    useEffect(() => {
        dispatch(getFinace())
    }, [])

    const CallOnFunction = () => {
        if (AllFinaceStatus === 'succeeded') {
            setDataSource(AllFinace?.sale);
            const MonthStart = new Date(AllFinace?.date?.month_start);
            const MonthEnd = new Date(AllFinace?.date?.month_end);
            const dateFormat = 'YYYY/MM/DD';

            const MonthStartDate = dayjs(MonthStart).format(dateFormat);
            const MonthEndDate = dayjs(MonthEnd).format(dateFormat);

            form.setFieldsValue({ range: [dayjs(MonthStartDate, dateFormat), dayjs(MonthEndDate, dateFormat)], });

            setDateRange([AllFinace?.date?.month_start, AllFinace?.date?.month_end])

            setPassdata(AllFinace?.amount)
        }

    }

    useEffect(() => {
        CallOnFunction();
    }, [AllFinace])

    const TablePrintData = dataSource?.filter((item) => {
        // Filter the dataSource based on the searchText
        if (!searchText || typeof searchText !== 'string') return true;

        const searchTextLower = searchText.toLowerCase();
        const searchTextUpper = searchText.toUpperCase();

        // Check if any of the fields match the searchText
        return (
            String(item?.invoice_no).toLowerCase().includes(searchTextLower) ||
            String(item?.invoice_no).includes(searchTextUpper)
        );
    })

        .map((item) => ({
            customer_bill_amt: item?.customer_bill_amt,
            invoice_no: item?.invoice_no,
            profit_loss_amt: item?.profit_loss_amt,
            sc_no: item?.sc_no,
        }));

    // =============  id pass ================

    useEffect(() => {
        form.setFieldsValue({ id: dataSource[0]?.id })
    }, [dataSource])

    const handleDateRangeChange = (values) => {
        setDateRange(values);
    };
    const PaymentDetails = (record) => {
        setModalWidth(1000)
        setModalTitle("Payment Details");
        setModalContent(<PaymentDetailsModal finance={record.id} />);
        showModal();
    };

    const columns = [
        {
            title: 'Sl No',
            render: (item, value, index) => index + 1,
        },
        {
            title: 'Invoice No',
            dataIndex: 'invoice_no',
            filteredValue: searchText ? [searchText] : null,
            onFilter: (value, record) => {
                return String(record.ref_no).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.ref_no).includes(value.toUpperCase());
            },
        },
        {
            title: 'SC. NO',
            dataIndex: 'sc_no',
        },
        {
            title: 'Total Value',
            dataIndex: 'customer_bill_amt',
            render: (value, record) => `${value} ${record.currency}`,
        },
        {
            title: 'Paid',
            dataIndex: 'customer_receive_amt',
            render: (value, record) => `${value} ${record.currency}`,
        },
        {
            title: 'OUTSTANDING',
            dataIndex: 'factory_balance_amt',
            render: (value, record) => `${value} ${record.currency}`,
        },
        {
            title: 'Status',
            dataIndex: 'profit_status',
        },
        {
            title: 'PROFIT & LOSS Amount',
            dataIndex: 'profit_loss_amt',
            render: (value, record) => `${value} ${record.currency}`,
        },


        {
            title: 'Action',
            render: (record) => {
                return (
                    <Flex spaceAround={'true'}>

                        <TableIconHolder color={THEME.green} size={'22px'}
                            onClick={() => nevigate(`/ViewTotalList/${record?.id}`)}>
                            <AiOutlineEye />
                        </TableIconHolder>

                        {Role === 'Master1' || Role === 'Master2' ? (
                            <Button.Danger text={'Pay Details'} onClick={() => PaymentDetails(record)} />
                        ) : null}

                        {Super_r === true ? (
                            <Button.Danger text={'Pay Details'} onClick={() => PaymentDetails(record)} />
                        ) : null}
                    </Flex>
                )
            }
        }

    ];

    return (
        <div>
            <CardOnTop>
                <Form
                    form={form}
                    labelCol={{
                        span: 24,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    initialValues={
                        {
                            from_date: dayjs(),
                            to_date: dayjs(),
                            choice: 'this_month'
                        }
                    }
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off">
                    <Row space={[24, 24]} style={{ padding: '10px 0' }}>
                        <Col span={24} md={3}>
                            <b>Choose</b>&nbsp;&nbsp;
                        </Col>
                        <Col span={24} md={12}>
                            <Input name={'id'} display={'none'} />
                            <Select
                                options={month}
                                name={'choice'}
                                defaultValue={"this_month"}
                                placeholder={'This Month'}
                                onChange={handleMonthSelect}
                                rules={[{ required: true, message: 'Please Select the Month' }]}
                            />
                        </Col>
                        <Col span={24} md={7}></Col>
                        <Col span={24} md={2}>
                            <div style={{ display: "none" }}>
                                <ParintTable componentRef={componentRef} data={TablePrintData} />
                            </div>
                            {
                                TablePrintData?.length > 0 && (
                                    <Button.Primary text={<AiFillPrinter style={{ fontSize: '30px' }} />} onClick={handlePrint} />
                                )
                            }
                        </Col>
                    </Row>


                    <Row space={[24, 24]} style={{ padding: '10px 0' }}>
                        {show ?
                            <>
                                <Col span={24} md={3}>
                                    <b>Between</b>&nbsp;&nbsp; <TbArrowsExchange />
                                </Col>

                                <Col span={24} md={12}>
                                    <CustomDateRangePicker
                                        onChange={handleDateRangeChange}
                                        value={dateRange}
                                        name={'range'}
                                        rules={[{ required: true, message: 'Please Select the Date' }]}
                                    />
                                </Col>

                                <Col span={24} md={6}>
                                    <Flex>
                                        <Button.Primary text={'Submit'} htmlType='submit' />
                                    </Flex>
                                </Col>
                            </>
                            : null}
                    </Row>
                </Form>
                <CardPayment datas={passdata} />

            </CardOnTop >
            <CardOnBottom>
                <Row space={[24, 24]}>
                    <Col span={24} md={13}>
                        <h1>Transactions :</h1>
                    </Col>
                    <Col span={24} md={4}></Col>
                    <Col span={24} md={7} style={{ marginTop: '25px' }}>
                        <Input
                            placeholder="Search by Invoice No"
                            value={searchText}
                            onSearch={handleSearch}
                            onChange={(e) => handleSearch(e.target.value)} />
                    </Col>

                    <TableStyle>
                        <Table columns={columns} data={dataSource} />
                    </TableStyle>
                </Row>
                <Modal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel}
                    width={ModalWidth} modalTitle={modalTitle} modalContent={modalContent} />
            </CardOnBottom>
        </div>
    )
}

export default FinanceTableReport