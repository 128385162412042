import React from 'react'
import { TopTitle } from '../../../Components/Form/TopTitle'
import { PurchaseEntryPage } from './PurchseEntryPage'
import { CustomCard } from '../../../Components/CustomCard'

const PurchaseMain = ({ setSaleorder, getSaleorders, selectedDate,  }) => {

  return (
    <div>
      <TopTitle Heading={'Purchase Confirmation / Order'} />
      <CustomCard>
        <PurchaseEntryPage setSaleorder={setSaleorder} getSaleorders={getSaleorders} selectedDate={selectedDate} />
      </CustomCard>
    </div>
  )
}

export default PurchaseMain