import React from 'react'
import { TextAreas } from '../../../Components/Form/TextArea'
import { Row } from '../../../Components/Row'
import { Col } from 'antd'

const FooterForm = () => {
    return (
        <div>
            <Row space={[12, 12]}>
                <Col span={24} md={12}>
                    <TextAreas label={'Terms & Conditions'} name={'termsandconditions'}
                        placeholder={'Enter Terms & Conditions'} />
                </Col>
            </Row>
        </div>
    )
}

export default FooterForm