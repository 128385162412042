import { Col, Form } from 'antd';
import React, { Fragment, useEffect, useState } from 'react'
import { Row } from '../../../Components/Row';
import Flex from '../../../Components/Flex';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import request from '../../../utils/request';
import { TableImageHolder } from '../../../Components/TableImageHolder';
import { AuthersiedBox, FooterTitle, PrintHolder, PrintTableFooterHolder, PrintViewTitle, PrintViewValue, PrintWrapper, ProfileAddress, ProfileHeader, ProfileSubTitle, SignImageHolder, TableHolder } from '../../../Components/Styled';
import { toast } from 'react-toastify';

const PurchaseViewList = ({ purch }) => {

  const [form] = Form.useForm();

  const { id } = useParams()

  const [tableData, setTableData] = useState([])

  const [formdata, setFormdata] = useState({})
  const [signature, setSignature] = useState({})


  useEffect(() => {
    GetPurchaseConfirmation()
  }, [])

  useEffect(() => {
    form.setFieldsValue(formdata)
  }, [formdata])

  useEffect(() => {
    form.setFieldsValue(signature)
  }, [signature])



  const GetPurchaseConfirmation = () => {
    const Value = { sc_no: id }
    request.post(`purchase_order/supplier_purchase_view/`, Value)
      .then(response => {
        setTableData(response.data?.purchaseorder_table)
        setFormdata(response.data?.purchaseorder)
        setSignature(response.data?.profile)
        form.setFieldsValue(response.data[0])
      })
      .catch(error => toast.error("Failed"))
  }


  const navigate = useNavigate();



  const columns = [
    {
      title: 'SI.No',
      render: (value, item, index) => index + 1,
    },
    {
      title: 'PRODUCT NAME',
      dataIndex: 'product_name',
    },
    {
      title: 'IMAGE',
      dataIndex: 'product_image',
      render: (image) => {
        return (
          (image) ? <TableImageHolder imageUrl={image} /> : null
        )
      }
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'description',
    },
    {
      title: 'BARCODE',
      dataIndex: 'barcode',
    },
    {
      title: 'QUANTITY',
      dataIndex: 'quantity',
    },
    {
      title: 'RATE',
      dataIndex: 'purchase_rate',
    },
    {
      title: 'TOTAL',
      dataIndex: 'total',
    },
    {
      title: 'NET WEIGHT (KG)',
      dataIndex: 'net_weight',
    },
    {
      title: 'G.W (kg)',
      dataIndex: 'gross_weight',
    },
    {
      title: 'CBM',
      dataIndex: 'cbm',
    },

  ]


  useEffect(() => {
    form.setFieldsValue(purch)
  }, [purch])

  return (
    <Fragment>
      <PrintWrapper>
        <PrintHolder>
          <ProfileHeader>{signature.purchase_business_name}</ProfileHeader>
          <ProfileAddress>{signature.purchase_address}</ProfileAddress>
          <ProfileAddress>{signature.purchase_phone_no} , Fax : {signature.purchase_fax_number} , Email : {signature.purchase_email}</ProfileAddress>
          <div style={{ textAlign: 'center' }}>
            <ProfileSubTitle>PURCHASE ORDER</ProfileSubTitle>
          </div>

          <Row space={[8, 8]}>
                        <Col span={24} md={12}>
                            <Row space={[2, 2]}>
                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            SUPPLIER NAME & ADDRESS
                                        </PrintViewTitle>
                                        <h1 style={{ alignSelf: 'baseline' }}>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {formdata.supplier_name} <br />
                                            {formdata.address}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            SUPPILER ID
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {formdata.supplieid}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>
                                
                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                        Phone no
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {formdata.supplier_mobile}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            fax&nbsp;
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {formdata.fax}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            email&nbsp;
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {formdata.email}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            contact person
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {formdata.contact_person}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            mobile
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {formdata.mobile_number}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={10}>
                                    <Flex spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            PAYMENT TERMS
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {formdata.payment_terms}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24} md={12}>
                            <Row space={[2, 2]}>
                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            Pc no
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>
                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {formdata.purchaseorderconfirmation_no}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            Pc Date
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>

                                </Col>
                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {formdata.purchaseorderconfirmation_date}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>
                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            shipment Date
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {formdata.shipping_date}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            ship to
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {formdata.country}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            origin
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {formdata.origin}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            delivery TERMS
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>
                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {formdata.delivery_terms}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            ship by
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>
                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {formdata.ship_by}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>
                                {
                                    formdata.container &&
                                    <Col span={24} sm={12} md={10}>
                                        <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                            <PrintViewTitle>
                                                container
                                            </PrintViewTitle>
                                            <h1>:</h1>
                                        </Flex>
                                    </Col>

                                }
                                {
                                    formdata.container &&

                                    <Col span={24} sm={12} md={14}>
                                        <Flex alignEnd={'true'} H_100>
                                            <PrintViewValue>
                                                {formdata.container}
                                            </PrintViewValue>
                                        </Flex>
                                    </Col>
                                }

                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            REVISED ON
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>
                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {formdata.revised_date}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <TableHolder>
                        <table>
                            <thead>
                                <tr>
                                    <th>SI.NO</th>
                                    <th>PRODUCT</th>
                                    <th>BARCODE</th>
                                    <th>IMAGE</th>
                                    <th>DESCRIPTION</th>
                                    <th>QUANTITY</th>
                                    <th>RATE ({formdata?.currency})</th>
                                    <th>TOTAL ({formdata?.currency})</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    tableData?.map((item, index) => (
                                        <tr key={item.id}>
                                            <td>{index + 1}</td>
                                            <td>{item.product_name}</td>
                                            <td>{item.barcode}</td>
                                            <td>
                                                <TableImageHolder imageUrl={item.product_image} />
                                            </td>
                                            <td>{item.description}</td>
                                            <td>{item.quantity} {item.unit}</td>
                                            <td>{item.purchase_rate}</td>
                                            <td>{item.total}</td>
                                        </tr>
                                    ))
                                }

                            </tbody>
                        </table>
                    </TableHolder>
                    <PrintTableFooterHolder>
                        <Row space={[12, 12]}>
                            <Col span={18} md={12}>
                                <Row space={[2, 2]}>
                                    <Col span={24} sm={12} md={12}>
                                        <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                            <PrintViewTitle>
                                                TOTAL AMOUNT IN WORDS
                                            </PrintViewTitle>
                                            <h1>:</h1>
                                        </Flex>
                                    </Col>
                                    <Col span={24} sm={12} md={12}>
                                        <Flex gap={'5px'} alignEnd={'true'} H_100>
                                            <PrintViewValue>
                                                <h4 style={{ textTransform: 'uppercase', fontSize: '12px' }}>{formdata?.amount_in_words} {formdata?.currency}</h4>
                                            </PrintViewValue>
                                        </Flex>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={18} md={12}></Col>
                            <Col span={18} md={12}></Col>
                            <Col span={18} md={12}>
                                <Flex flexEnd={'true'}>
                                    <Row space={[2, 2]}>
                                        <Col span={24} sm={12} md={12}>
                                            <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                                <PrintViewTitle>
                                                    TOTAL QUANTITY
                                                </PrintViewTitle>
                                                <h1>:</h1>
                                            </Flex>
                                        </Col>
                                        <Col span={24} sm={12} md={12}>
                                            <Flex alignEnd={'true'} H_100>
                                                <PrintViewValue>
                                                    {formdata?.total_pieces}
                                                </PrintViewValue>
                                            </Flex>
                                        </Col>

                                        <Col span={24} sm={12} md={12}>
                                            <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                                <PrintViewTitle>
                                                    TOTAL AMOUNT
                                                </PrintViewTitle>
                                                <h1>:</h1>
                                            </Flex>
                                        </Col>
                                        <Col span={24} sm={12} md={12}>
                                            <Flex alignEnd={'true'} H_100>
                                                <PrintViewValue>
                                                    {formdata?.total_all} {formdata?.currency}
                                                </PrintViewValue>
                                            </Flex>
                                        </Col>
                                    </Row>
                                </Flex>
                            </Col>
                        </Row>
                        <Row space={[12, 12]}>
                            <Col span={18} >
                                <Row space={[2, 2]}>

                                    <Col span={24} sm={12} md={6}>
                                        <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                            <PrintViewTitle>
                                                SHIPPING MARKS
                                            </PrintViewTitle>
                                            <h1>:</h1>
                                        </Flex>
                                    </Col>

                                    <Col span={24} sm={12} md={18}>
                                        <Flex alignEnd={'true'} H_100>
                                            <PrintViewValue>
                                                <pre>
                                                    {formdata.shipping_marks}
                                                </pre>
                                            </PrintViewValue>
                                        </Flex>
                                    </Col>
                                    <Col span={24} sm={12} md={6}>
                                        <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                            <PrintViewTitle>
                                                REMARKS
                                            </PrintViewTitle>
                                            <h1>:</h1>
                                        </Flex>
                                    </Col>

                                    <Col span={24} sm={12} md={18}>
                                        <Flex alignEnd={'true'} H_100>
                                            <PrintViewValue>
                                                <pre>
                                                    {formdata.remarks}
                                                </pre>
                                            </PrintViewValue>
                                        </Flex>
                                    </Col>
                                    <Col span={24} sm={12} md={6}>
                                        <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                            <PrintViewTitle>
                                                NOTES
                                            </PrintViewTitle>
                                            <h1>:</h1>
                                        </Flex>
                                    </Col>

                                    <Col span={24} sm={12} md={18}>
                                        <Flex alignEnd={'true'} H_100>
                                            <PrintViewValue>
                                                <pre>
                                                    {formdata.notes}
                                                </pre>
                                            </PrintViewValue>
                                        </Flex>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </PrintTableFooterHolder>

                    <PrintTableFooterHolder>
                        {/* <FooterTitle>
                            BANK DETAILS
                        </FooterTitle> */}
                        <Row space={[6, 6]}>
                            <Col span={24} md={14}>
                                {/* <Row space={[2, 2]}>
                                    <Col span={24} sm={12} md={12}>
                                        <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                            <PrintViewTitle>
                                                BENEFICIARY NAME
                                            </PrintViewTitle>
                                            <h1>:</h1>
                                        </Flex>
                                    </Col>

                                    <Col span={24} sm={12} md={12}>
                                        <Flex alignEnd={'true'} H_100>
                                            <PrintViewValue>
                                                {activePro.purchase_bank_account_name}
                                            </PrintViewValue>
                                        </Flex>
                                    </Col>

                                    <Col span={24} sm={12} md={12}>
                                        <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                            <PrintViewTitle>
                                                BENEFICIARY BANK
                                            </PrintViewTitle>
                                            <h1>:</h1>
                                        </Flex>
                                    </Col>

                                    <Col span={24} sm={12} md={12}>
                                        <Flex alignEnd={'true'} H_100>
                                            <PrintViewValue>
                                                {activePro.purchase_bank_name}
                                            </PrintViewValue>
                                        </Flex>
                                    </Col>

                                    <Col span={24} sm={12} md={12}>
                                        <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                            <PrintViewTitle>
                                                BENEFICIARY A/C NO
                                            </PrintViewTitle>
                                            <h1>:</h1>
                                        </Flex>
                                    </Col>

                                    <Col span={24} sm={12} md={12}>
                                        <Flex alignEnd={'true'} H_100>
                                            <PrintViewValue>
                                                {activePro.purchase_bank_account_number}
                                            </PrintViewValue>
                                        </Flex>
                                    </Col>
                                </Row> */}
                            </Col>


                        </Row>

                        <AuthersiedBox />
                        <Flex W_100 spacebetween={'true'} alignEnd={'true'}>
                            <span>
                                CONFIRM & ACCEPTED BY
                            </span>

                            <span>
                                FOR AND ON BEHALF OF
                            </span>
                        </Flex>


              <Flex flexEnd={'true'}>
                <SignImageHolder>
                  <img src={signature.purchase_signature} alt="signature" />
                </SignImageHolder>
              </Flex>

              <Flex W_100 spacebetween={'true'} alignEnd={'true'}>
                <span>
                  {/* BRANCH ASFAR */}
                </span>

                <span>
                  {signature.purchase_business_name}
                </span>
              </Flex>
          </PrintTableFooterHolder>

        </PrintHolder>
      </PrintWrapper>
    </Fragment >
  )
}

export default PurchaseViewList