import React from 'react'
import { Col, Form } from 'antd'
import { Row } from '../../../../Components/Row'
import Input from '../../../../Components/Form/Input'
import { TextAreas } from '../../../../Components/Form/TextArea'
import Flex from '../../../../Components/Flex'
import Button from '../../../../Components/Form/Button'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import TableDetailsCustomer from './TableDetailsCustomer'
import { useDispatch, useSelector } from 'react-redux'
import { getSupplierDetails, getAllCustomerDetails, getAllCustomerProfileDetails } from '../../CustomerSlice'

const CustomerProfilesDetails = () => {

    const [form] = Form.useForm();

    const { id } = useParams();

    const dispatch = useDispatch()


    const navigate = useNavigate();


    const custDetails = useSelector(getAllCustomerDetails)
    const custProfileDetails = useSelector(getAllCustomerProfileDetails)

    useEffect(() => {
        dispatch(getSupplierDetails(id))
    }, [])

    useEffect(() => {
        if(custDetails){
            form.setFieldsValue(custDetails)
        }
    }, [custDetails])

    const GetUser = () => {
        dispatch(getSupplierDetails(id))
    }

    const onFinish = (values) => {

    };


    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };





    return (

        <Form
            form={form}
            labelCol={{
                span: 24,
            }}
            wrapperCol={{
                span: 24,
            }}
            name='customerProfile'
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off">

            <Row space={[24, 24]}>

                <Col span={24} md={12}>
                    <Input label={'Company Name'} disabled placeholder={'Customer/Company Name'}
                        name={'company_name'}
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter Your Companyname!',
                            }
                        ]} />
                </Col>
                <Col span={24} md={12}>
                    <Input label={'Company ID'} disabled placeholder={'Company ID'}
                        name={'invoice_no'} />
                </Col>

                <Col span={24} md={12}>
                    <Input label={'FAX number'} disabled placeholder={'FAX number'} name={'fax'} />
                </Col>

                <Col span={24} md={12}>
                    <Input label={'Phone No'} disabled name={'customer_mobile'} />
                </Col>

                <Col span={24} md={12}>
                    <Input label={'Contact Person'} disabled placeholder={'Contact Person'} name={'contact_person_name'} />
                </Col>

                <Col span={24} md={12}>
                    <Input label={'Email id'} disabled name={'email'} type="email" placeholder={"Email ID"} />
                </Col>

                <Col span={24} md={12}>
                    <Input label={'Mobile number'} disabled placeholder={'Contact Number'} name={'mobile_number'} />
                </Col>

                <Col span={24} md={12}>
                    <Input label={'CR NO'} disabled placeholder={'CR NO'} name={'cr_no'} />
                </Col>

                <Col span={24} md={12}>
                    <Input label={'IMPORT NO'} disabled placeholder={'IMPORT NO'} name={'import_no'} />
                </Col>

                <Col span={24} md={12}>
                    <Input label={'VAT NO'} disabled placeholder={'VAT NO'} name={'vat_no'} />
                </Col>

                <Col span={24} md={12}>
                    <TextAreas label={'Address'} disabled placeholder={'Address'} name={'address'} />
                </Col>

                <Col span={24} md={12} offset={12}></Col>
                <Col span={24} md={24}>
                    <TableDetailsCustomer tableData={custProfileDetails} GetUser={GetUser} />
                </Col>
            </Row>

            <Flex center={'true'} gap={'20px'} margin={'20px 0'}>
                <Button.Primary text={'Back'} onClick={() => navigate(-1)} />
            </Flex>
        </Form>


    )
}

export default CustomerProfilesDetails