
import React, { Fragment, useEffect, useState } from 'react'
import { Col, Form } from 'antd'
import { Row } from '../../../../Components/Row'
import Flex from '../../../../Components/Flex'
import Button from '../../../../Components/Form/Button'
import Input from '../../../../Components/Form/Input'
import { TextAreas } from '../../../../Components/Form/TextArea'
import { CustomUpload } from '../../../../Components/Form/CustomUpload'
import { CustomPageFormSubTitle, TopTitle } from '../../../../Components/Form/TopTitle'
import { toast } from 'react-toastify'
import request from '../../../../utils/request'
import { FormLoadingIndicator } from '../../../../Components/CommonLoading'

const ProfileForm = ({ handleOk, handlerecord, DisplayImage, imgUrl, productFormReset, ProductFormUpdateCall }) => {

    const [selectedImgUrl, setSelectedImgUrl] = useState(null);
    const [ImageInitialValue, setImageInitialValue] = useState([]);   //  Upload Initial UseState
    const [formLoading, setFormLoading] = useState(false)
    const [imageSignatureValue, setImageSignatureValue] = useState([]);
    const [updateFormData, setUpdateFormData] = useState({})

    const [form] = Form.useForm()
    const URL = 'business/business_view'

    const onFinish = (values) => {

        if (handlerecord) {
            EditProfiles(values, handlerecord.id)
        }
        else {
            AddProfile(values)
        }

    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const onReset = () => {
        form.resetFields();
    }

    useEffect(() => {
        setSelectedImgUrl(selectedImgUrl)
    }, [selectedImgUrl])

    useEffect(() => {
        if (handlerecord) {
    
          setImageInitialValue([
            {
              uid: '1',
              name: 'example.jpg',
              status: 'done',
              url: handlerecord.signature,
            },
          ])
    
          setImageSignatureValue([
            {
              uid: '1',
              name: 'signatureexample.jpg',
              status: 'done',
              url: handlerecord.purchase_signature,
            },
          ])
        }
        setUpdateFormData(handlerecord)
    
      }, [handlerecord])
    
      useEffect(() => {
        form.setFieldsValue(updateFormData)
        form.setFieldsValue({ 'signature': ImageInitialValue })
        form.setFieldsValue({ 'purchase_signature': imageSignatureValue })
      }, [updateFormData])
    

    const AddProfile = (values) => {

        let formdata = new FormData();
        formdata.append('business_name', values?.business_name)
        formdata.append('email', values?.email)
        formdata.append('phone_no', values?.phone_no)
        formdata.append('phone_no2', values?.phone_no2 !== undefined ? values?.phone_no2 : '')
        formdata.append('address', values?.address)
        formdata.append('fax_number', values?.fax_number !== undefined ? values?.fax_number : '')
        formdata.append('bank_account_name', values?.bank_account_name !== undefined ? values?.bank_account_name : '')
        formdata.append('bank_name', values?.bank_name !== undefined ? values?.bank_name : '')
        formdata.append('bank_account_number', values?.bank_account_number !== undefined ? values?.bank_account_number : '')

        formdata.append('purchase_business_name', values?.purchase_business_name)
        formdata.append('purchase_email', values?.purchase_email)
        formdata.append('purchase_phone_no', values?.purchase_phone_no)
        formdata.append('purchase_phone_no2', values?.purchase_phone_no2 !== undefined ? values?.purchase_phone_no2 : '')
        formdata.append('purchase_address', values?.purchase_address)
        formdata.append('purchase_fax_number', values?.purchase_fax_number !== undefined ? values?.purchase_fax_number : '')
        formdata.append('purchase_bank_account_name', values?.purchase_bank_account_name !== undefined ? values?.purchase_bank_account_name : '')
        formdata.append('purchase_bank_name', values?.purchase_bank_name !== undefined ? values?.purchase_bank_name : '')
        formdata.append('purchase_bank_account_number', values?.purchase_bank_account_number !== undefined ? values?.purchase_bank_account_number : '')


        if (values?.signature && values.signature.length > 0) {
            values.signature.forEach((file) => {
              formdata.append(`signature`, file.originFileObj);
            });
          } else {
            console.error('No company logo selected');
          }
    
          if (values?.purchase_signature && values.purchase_signature.length > 0) {
            values.purchase_signature.forEach((file) => {
              formdata.append(`purchase_signature`, file.originFileObj);
            });
          } else {
            console.error('No images selected');
          }
        
     
        if (setFormLoading) {
            setFormLoading(true)

        }
        request.post(`${URL}`, formdata, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(function (response) {
                if (response.status == 201) {
                    form.resetFields();
                    toast.success('Profile Details Add Successfully')
                    if (DisplayImage) {
                        DisplayImage();
                    }
                }
                setFormLoading(false)


            })
            .catch(function (error) {
                if (error.response.status == 403) {
                    toast.error('Business details already Created');
                    setFormLoading(false)

                }
                else if (error.response.status == 401) {
                    toast.error('You are not authorized');
                    setFormLoading(false)

                }
                if (error.response && error.response.status === 400) {
                    if (error.response.data) {
                        if (error.response.data.phone_no) {
                            toast.warn(error.response.data.phone_no[0]);
                        }
                        else if (error.response.data.phone_no2) {
                            toast.warn(error.response.data.phone_no2[0]);
                        }

                        else {
                            toast.error('Invalid input.');
                        }
                    } else {
                        toast.error('Invalid input.');
                    }
                    setFormLoading(false)

                }
            });
    };


    //====================Edit profile =====================//

    const EditProfiles = (values, id) => {

        let formdata = new FormData();


        formdata.append('business_name', values?.business_name)
        formdata.append('email', values?.email)
        formdata.append('phone_no', values?.phone_no)
        formdata.append('phone_no2', values?.phone_no2 !== undefined ? values?.phone_no2 : '')
        formdata.append('address', values?.address)
        formdata.append('fax_number', values?.fax_number !== undefined ? values?.fax_number : '');
        formdata.append('bank_account_name', values?.bank_account_name !== undefined ? values?.bank_account_name : '')
        formdata.append('bank_name', values?.bank_name !== undefined ? values?.bank_name : '')
        formdata.append('bank_account_number', values?.bank_account_number !== undefined ? values?.bank_account_number : '')


        formdata.append('purchase_business_name', values?.purchase_business_name)
        formdata.append('purchase_email', values?.purchase_email)
        formdata.append('purchase_phone_no', values?.purchase_phone_no)
        formdata.append('purchase_phone_no2', values?.purchase_phone_no2 !== undefined ? values?.purchase_phone_no2 : '')
        formdata.append('purchase_address', values?.purchase_address)
        formdata.append('purchase_fax_number', values?.purchase_fax_number !== undefined ? values?.purchase_fax_number : '')
        formdata.append('purchase_bank_account_name', values?.purchase_bank_account_name !== undefined ? values?.purchase_bank_account_name : '')
        formdata.append('purchase_bank_name', values?.purchase_bank_name !== undefined ? values?.purchase_bank_name : '')
        formdata.append('purchase_bank_account_number', values?.purchase_bank_account_number !== undefined ? values?.purchase_bank_account_number : '')
        formdata.append('id', values?.id)

   
        if (values?.signature && values.signature.length > 0) {
            values.signature.forEach((file) => {
              if (file.originFileObj !== undefined) {
                formdata.append(`signature`, file.originFileObj);
              } else {
                console.log('UNDEFINED')
              }
            });
          } else {
            console.error('No images selected');
          }
    
          if (values?.purchase_signature && values.purchase_signature.length > 0) {
            values.purchase_signature.forEach((file) => {
              if (file.originFileObj !== undefined) {
                formdata.append(`purchase_signature`, file.originFileObj);
              } else {
                console.log('UNDEFINED')
              }
            });
          } else {
            console.error('No images selected');
          }
        setFormLoading(true)
        request.put(`business/business_edit_view/${id}`, formdata, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(function (response) {
                if (response.status == 201) {
                    toast.info('Profile Details Updated Successfully')
                    if (ProductFormUpdateCall) {
                        ProductFormUpdateCall();

                    }
                    if (handleOk) {
                        handleOk()
                    }

                }
                else {
                    toast.info('Profile Details Updated Successfully')
                    if (ProductFormUpdateCall) {
                        ProductFormUpdateCall();

                    }
                    if (handleOk) {
                        handleOk()
                    }

                }
                setFormLoading(false)

            })
            .catch(function (error) {
                if (error.response.status == 403) {
                    toast.error('Business details already Created');
                }
                else if (error.response.status == 401) {
                    toast.error('You are not authorized');
                }
                if (error.response && error.response.status === 400) {
                    if (error.response.data) {
                        if (error.response.data.phone_no) {
                            toast.warn(error.response.data.phone_no[0]);
                        }
                        else if (error.response.data.phone_no2) {
                            toast.warn(error.response.data.phone_no2[0]);
                        }

                        else {
                            toast.error('Invalid input.');
                        }
                    } else {
                        toast.error('Invalid input.');
                    }
                }
            });
    };


    return (
        <Fragment>
            <FormLoadingIndicator active={formLoading} />
            <TopTitle Heading={'Sale Profile Details'} />
            <Form
                form={form}
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off">

                <Row space={[24, 24]}>

                    <Col span={24} md={12}>
                        <Input label={'Business Name/ Company Name'} name={'business_name'} placeholder={'Enter Name'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Your Business!',
                                }
                            ]} />
                    </Col>

                    <Col span={24} md={12}>
                        <Input label={'Email ID'} name={'email'} type={'email'} placeholder={"Enter Your Mail ID"}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Your Mail id!',
                                }
                            ]} />
                    </Col>

                    <Col span={24} md={12}>
                        <Input label={'Phone No 1'} name={'phone_no'}
                            placeholder={"Enter Your Phone no 1"}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter Phone Number!',
                                },
                            ]} />
                    </Col>

                    <Col span={24} md={12}>
                        <Input label={'Phone No 2'} name={'phone_no2'}
                            placeholder={"Enter Your Phone no 2"} />
                    </Col>

                    <Col span={24} md={12}>
                        <TextAreas label={'Address'} name={'address'} placeholder={"Enter Your Address"} rules={[
                            {
                                required: true,
                                message: 'Please Enter Your Address!',
                            }
                        ]} />
                    </Col>

                    <Col span={24} md={12}>

                        <CustomUpload form={form} initialValue={ImageInitialValue} label={'Signature'} name={'signature'} listType='picture-card' maxCount={1} accept='.png,.jpeg,.jpg'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please upload signature',
                                },
                            ]} />
                    </Col>

                    <Col span={24} md={12}>
                        <Input label={'Fax Number'} name={'fax_number'} placeholder={'Fax Number'} />
                    </Col>

                    <Col span={24} md={24}>
                        <CustomPageFormSubTitle Heading={'BANK DETAILS'} />
                    </Col>


                    <Col span={24} md={12}>
                        <Input label={'Beneficiary name'} placeholder={'Enter Account Name'}
                            name={'bank_account_name'} />
                    </Col>

                    <Col span={24} md={12}>
                        <Input label={'Beneficiary Bank'} placeholder={'Beneficiary Bank'}
                            name={'bank_name'} />
                    </Col>

                    <Col span={24} md={12}>
                        <Input label={'Beneficiary A/C NO'} placeholder={'Beneficiary A/C NO'}
                            name={'bank_account_number'} />
                    </Col>
                </Row>
                <TopTitle Heading={'Purchase Profile Details'} />

                <Row space={[24, 24]} >

                    <Col span={24} md={12}>
                        <Input label={'Business Name/ Company Name'} name={'purchase_business_name'} placeholder={'Enter Name'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Your Business!',
                                }
                            ]} />
                    </Col>

                    <Col span={24} md={12}>
                        <Input label={'Email ID'} name={'purchase_email'} type={'email'} placeholder={"Enter Your Mail ID"}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Your Mail id!',
                                }
                            ]} />
                    </Col>

                    <Col span={24} md={12}>
                        <Input label={'Phone No 1'} name={'purchase_phone_no'}
                            placeholder={"Enter Your Phone no 1"}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter Phone Number!',
                                },
                            ]} />
                    </Col>

                    <Col span={24} md={12}>
                        <Input label={'Phone No 2'} name={'purchase_phone_no2'}
                            placeholder={"Enter Your Phone no 2"} />
                    </Col>

                    <Col span={24} md={12}>
                        <TextAreas label={'Address'} name={'purchase_address'} placeholder={"Enter Your Address"} rules={[
                            {
                                required: true,
                                message: 'Please Enter Your Address!',
                            }
                        ]} />
                    </Col>
                    <Col span={24} md={12}>

                        <CustomUpload form={form} initialValue={imageSignatureValue} label={'Signature'} name={'purchase_signature'} listType='picture-card' maxCount={1} accept='.png,.jpeg,.jpg'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please upload signature',
                                },
                            ]} />
                    </Col>

                    <Col span={24} md={12}>
                        <Input label={'Fax Number'} name={'purchase_fax_number'} placeholder={'Fax Number'} />
                    </Col>

                    <Col span={24} md={24}>
                        <CustomPageFormSubTitle Heading={'BANK DETAILS'} />
                    </Col>


                    <Col span={24} md={12}>
                        <Input label={'Beneficiary name'} placeholder={'Enter Account Name'}
                            name={'purchase_bank_account_name'} />
                    </Col>

                    <Col span={24} md={12}>
                        <Input label={'Beneficiary Bank'} placeholder={'Beneficiary Bank'}
                            name={'purchase_bank_name'} />
                    </Col>

                    <Col span={24} md={12}>
                        <Input label={'Beneficiary A/C NO'} placeholder={'Beneficiary A/C NO'}
                            name={'purchase_bank_account_number'} />
                    </Col>
                    <Input name={'id'} display={'none'} />

                </Row>
                <Flex center={'true'} gap={'20px'} margin={'20px 0px'}>
                    <Button.Primary text={'SAVE'} htmlType={'submit'} />
                    <Button.Danger text={'RESET'} onClick={() => onReset()} />
                </Flex>

            </Form>
        </Fragment>

    )
}

export default ProfileForm