import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Row } from '../../../../Components/Row'
import { Col } from 'antd'
import {
    AuthersiedBox,
    PrintTableFooterHolder,
    ProfileAddress,
    FooterTitle,
    PrintHolder,
    PrintViewTitle,
    PrintViewValue,
    ProfileHeader,
    ProfileSubTitle,
    SignImageHolder,
    TableHolder,
    PrintWrapper,
    PrintTableFooterHolders
} from '../../../../Components/Styled'
import Flex from '../../../../Components/Flex'
import { useDispatch, useSelector } from 'react-redux'
import { getActivePro, getActiveProfile } from '../../../Profile/ProfileSlice'
import Button from '../../../../Components/Form/Button'
import { AiFillPrinter } from 'react-icons/ai'
import { useReactToPrint } from 'react-to-print'

export const PackingView = ({ record }) => {

    const [viewData, setViewData] = useState({})

    const componentRef = useRef();

    const dispatch = useDispatch();
    const activePro = useSelector(getActivePro);

    useEffect(() => {
        dispatch(getActiveProfile());
    }, [])

    useEffect(() => {
        setViewData(record)
    }, [record])

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })

    return (
        <Fragment>

            <Flex margin={"20px"} gap={"10px"}>
                <Button.Primary text={<AiFillPrinter style={{ fontSize: '30px' }} />} onClick={handlePrint} />
            </Flex>

            <PrintWrapper>
                <PrintHolder ref={componentRef}>
                    {
                        record.draft && (
                            <div style={{ display: 'flex', justifyContent: 'end', marginTop: '10px', marginRight: '20px' }}>
                                <span style={{ borderBottom: '2px solid black', fontSize: '14px', fontWeight: 'bold' }}>DRAFT COPY</span>
                            </div>
                        )
                    }
                    <ProfileHeader>{activePro.business_name}</ProfileHeader>
                    <ProfileAddress>{activePro.address}</ProfileAddress>
                    <ProfileAddress>{activePro.phone_no} , Fax : {activePro.fax_number} , Email : {activePro.email}</ProfileAddress>
                    <div style={{ textAlign: 'center' }}>
                        <ProfileSubTitle>PACKING LIST</ProfileSubTitle>
                    </div>
                    <Row space={[8, 8]}>
                        <Col span={24} md={12}>
                            <Row space={[2, 2]}>
                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            BILL TO
                                        </PrintViewTitle>
                                        <h1 style={{ alignSelf: 'baseline' }}>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {viewData.company_name}<br />
                                            {viewData.address}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>
                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            CR NO
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {viewData.cr_no}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>
                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            IMPORT NO
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {viewData.import_no}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>
                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            VAT NO
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {viewData.vat_no}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>
                                <Col span={24} sm={12} md={10}>
                                    <Flex spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            PAYMENT TERM
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {viewData.payment_terms}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>
                                <Col span={24} sm={12} md={10}>
                                    <Flex spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            deliveRy TERM
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {viewData.delivery_terms}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>
                                <Col span={24} sm={12} md={10}>
                                    <Flex spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            CONFIRM QUANTITY
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {viewData.confirm_quantity}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>
                            </Row>
                        </Col>

                        <Col span={24} md={12}>
                            <Row space={[2, 2]}>
                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            Packing List No
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {viewData.packing_list_no}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>
                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            Ref Invoice no
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {viewData.ref_invoice_no}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            sailing on
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>

                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {viewData.sailing_on}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>
                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            SHIP TO
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>

                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {viewData.ship_to}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            sc no
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>

                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {viewData.sc_no}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            vessel name
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {viewData.vessel_name}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            BL NO
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {viewData.bl_no}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            container & seal no
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {viewData.container_seal_no}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>

                                {
                                    viewData.container &&

                                    <Col span={24} sm={12} md={10}>
                                        <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                            <PrintViewTitle>
                                                container
                                            </PrintViewTitle>
                                            <h1>:</h1>
                                        </Flex>
                                    </Col>

                                }

                                {
                                    viewData.container &&

                                    <Col span={24} sm={12} md={14}>
                                        <Flex alignEnd={'true'} H_100>
                                            <PrintViewValue>
                                                {viewData.container}
                                            </PrintViewValue>
                                        </Flex>
                                    </Col>
                                }

                            </Row>
                        </Col>
                    </Row>
                    <TableHolder>
                        <table>
                            <thead>
                                <tr>
                                    <th>SI.NO</th>
                                    <th>PRODUCT</th>
                                    <th>DESCRIPTION</th>
                                    <th>QUANTITY / UNIT</th>
                                    <th>CRTS</th>
                                    <th>NET WEIGHT</th>
                                    <th>GROSS WEIGHT</th>
                                    <th>CBM</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    viewData?.packing_table?.map((item, index) => (
                                        <tr key={item.id}>
                                            <td>{index + 1}</td>
                                            <td>{item.product_name}</td>
                                            <td>{item.description}</td>
                                            <td>{item.quantity} {item.unit}</td>
                                            <td>{item.ctns}</td>
                                            <td>{item.net_weight}</td>
                                            <td>{item.gross_weight}</td>
                                            <td>{item.cbm}</td>

                                        </tr>
                                    ))
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th colSpan="3">TOTAL</th>
                                    <th>
                                        <td>
                                            {viewData?.total_quantity}
                                        </td>
                                    </th>
                                    <th>
                                        <td>
                                            {viewData?.total_ctns}
                                        </td>
                                    </th>
                                    <th>
                                        <td>
                                            {viewData?.total_net_weight}
                                        </td>
                                    </th>
                                    <th>
                                        <td>
                                            {viewData?.total_gross_weight}
                                        </td>
                                    </th>
                                    <th>
                                        <td>
                                            {viewData?.total_cbm}
                                        </td>
                                    </th>
                                </tr>
                            </tfoot>
                        </table>
                    </TableHolder>
                    <PrintTableFooterHolders>
                        <Flex gap={'5px'} alignEnd={'true'}>
                            <PrintViewTitle style={{ width: '200px' }}>
                                TOTAL QUANTTY IN WORDS&nbsp;
                            </PrintViewTitle>
                            <h1>:</h1>
                            <PrintViewValue>
                                <h4 style={{ textTransform: 'uppercase', fontSize: '12px' }}>{viewData?.total_quantity_inwords}</h4>

                            </PrintViewValue>
                        </Flex>
                        <Flex gap={'5px'} alignEnd={'true'}>
                            <PrintViewTitle style={{ width: '200px' }}>
                                TOTAL CRTS IN WORDS&nbsp;&nbsp;
                            </PrintViewTitle>
                            <h1>:</h1>
                            <PrintViewValue>
                                <h4 style={{ textTransform: 'uppercase', fontSize: '12px' }}>{viewData?.total_ctns_inwords}</h4>

                            </PrintViewValue>
                        </Flex>
                        <Row>
                            <Col span={24} md={24}>
                                <Row>
                                    <Col span={24} md={4}>
                                        <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                            <PrintViewTitle style={{ width: '200px' }}>
                                                Shipping marks
                                            </PrintViewTitle>
                                            <h1>:</h1>

                                        </Flex>
                                    </Col>
                                    <Col span={24} md={20}>

                                        <Flex alignEnd={'true'} H_100>
                                            <PrintViewValue>
                                                <h4 style={{ textTransform: 'uppercase', fontSize: '12px' }}>{viewData?.shipping_marks}</h4>

                                            </PrintViewValue>
                                        </Flex>
                                    </Col>
                                </Row>

                            </Col>
                            <Col span={24} md={24}>
                                <Row>
                                    <Col span={24} md={4}>
                                        <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                            <PrintViewTitle style={{ width: '200px' }}>
                                                Notes
                                            </PrintViewTitle>
                                            <h1>:</h1>

                                        </Flex>
                                    </Col>
                                    <Col span={24} md={20}>

                                        <Flex alignEnd={'true'} H_100>
                                            <PrintViewValue>
                                                <h4 style={{ textTransform: 'uppercase', fontSize: '12px' }}>{viewData?.notes}</h4>

                                            </PrintViewValue>
                                        </Flex>
                                    </Col>
                                </Row>

                            </Col>
                        </Row>
                    </PrintTableFooterHolders>
                    <PrintTableFooterHolder>
                    <AuthersiedBox />
                        <Row space={[24,24]}>
                            <Col span={24}>
                            <Flex W_100 spacebetween={'true'} alignEnd={'true'}>
                            <span>
                                {/* CONFIRM & ACCEPTED BY */}
                            </span>

                            <span>
                                FOR AND ON BEHALF OF
                            </span>
                        </Flex>

                        <Flex flexEnd={'true'}>
                            <SignImageHolder>
                                <img src={activePro.signature} alt="signature" />
                            </SignImageHolder>
                        </Flex>

                        <Flex W_100 spacebetween={'true'} alignEnd={'true'}>
                            <span>
                                {/* BRANCH ASFAR */}
                            </span>

                            <span>
                                {activePro.business_name}
                            </span>
                        </Flex>
                            </Col>
                        </Row>
   
                        
                    </PrintTableFooterHolder>

                </PrintHolder>
            </PrintWrapper>
        </Fragment >
    )
}
