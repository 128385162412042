import React, { useEffect } from 'react'
import { Form, Col } from 'antd'
import { Row } from '../../../Components/Row';
import { CustomSelect } from '../../../Components/Form/CustomSelect'
import Input from '../../../Components/Form/Input';
import Flex from '../../../Components/Flex';

const ViewForm = ({ ViewRecord }) => {

    // ----- Define Form
    const [form] = Form.useForm();

    const onFinish = (value) => {
        console.log(value);
    };

    const Adddetail = (value) => [
        console.log(value)
    ]

    useEffect(() => {
        form.setFieldsValue(ViewRecord)
    }, [ViewRecord])


    return (
        <Form
            labelCol={{
                span: 24,
            }}
            wrapperCol={{
                span: 24,
            }}
            form={form}
            onFinish={onFinish}
        >
            <Row space={[24, 24]}>
                <Col span={12} md={12} >
                    <Input
                        disabled
                        label={"Invoice No"}
                        placeholder={"Invoice No"}
                        name={"invoice_no"}
                    />
                </Col>
                <Input
                    disabled
                    label={"Profit or Loss"}
                    placeholder={"Profit or Loss"}
                    name={"profit_status"}
                />
            </Row>
            <Row space={[24, 24]}>
                <Col span={12} md={12}>
                    <Input disabled
                        label={"Invoice Amount"}
                        placeholder={"Invoice Amount"}
                        name={"invoice_amt"}

                    />
                </Col>
                <Col span={12} md={12}>
                    <Input
                        width={"100px"}
                        disabled
                        placeholder={"Currency"}
                        label={"Currency"}
                        name={"currency"}

                    />

                </Col>
                <Col span={12} md={12}>
                    <Input
                        placeholder={"Expense"}
                        label={"Expense"}
                        disabled
                        name={"local_charge"}
                    />
                </Col>
                <Col span={12} md={12}>
                    <Input
                        width={"100px"}
                        disabled
                        onButtonClick={Adddetail}
                        placeholder={"$"}
                        name={"local_charge_currency"}
                        label={"Currency"}
                        rules={[
                            {
                                required: true,
                                message: "Please select Currency !",
                            },
                        ]}
                    />

                </Col>
                <Col span={12} md={12}>
                    <Input
                        disabled
                        placeholder={"Factory Amount"}
                        label={"Factory Amount"}
                        name={"factory_amount"}

                    />
                </Col>
                <Col span={12} md={12}>
                    <Input
                        width={"100px"}
                        disabled
                        placeholder={"Currency"}
                        label={"Currency"}
                        name={"factory_amount_currency"}
                    />
                </Col>
            </Row>
            <Row space={[24, 24]}>
                <Col span={12} md={12} >
                    <Input
                        disabled
                        label={"Sub Total"}
                        placeholder={"Sub Total"}
                        name={"sub_total"}

                    />
                </Col>
            </Row>
            <Row space={[24, 24]}>
                <Col span={12} md={12} >
                    <Input
                        label={"Exchange Rate"}
                        placeholder={"Exchange Rate"}
                        disabled
                        name={"exchange_rate"}
                    />
                </Col>
            </Row>
            <Row space={[24, 24]}>
                <Col span={12} md={12} >
                    <Input
                        label={"Total Paid To Factory"}
                        disabled
                        placeholder={"Total paid To Factory"}
                        name={"Total_paid_to_factory"}
                    />
                </Col>
            </Row>
            <Row space={[24, 24]}>
                <Col span={12} md={12}>
                    <Input
                        disabled
                        label={"Shipping Change Sea"}
                        placeholder={"Shipping Change Sea"}
                        name={"shipping_charge_sea"}
                    />
                </Col>
                <Col span={12} md={12}>
                    <Input
                        width={"100px"}
                        disabled
                        onButtonClick={Adddetail}
                        placeholder={"$"}
                        name={"shipping_charge_sea_currency"}
                        label={"Currency"}
                    />

                </Col>
            </Row>
            <Row space={[24, 24]}>
                <Col span={12} md={12} >
                    <Input
                        disabled
                        label={"Total"}
                        placeholder={"Total"}
                        name={"total"}
                    />
                </Col>
            </Row>
            <Row space={[24, 24]}>
                <Col span={12} md={8}>
                    <Input
                        label={"ETA"}
                        placeholder={"ETA"}
                        name={"eta"}
                        disabled
                    />
                </Col>
                <Col span={12} md={8}>
                    <Input
                        label={"Status"}
                        placeholder={"Status"}
                        name={"label_status"}
                        disabled
                    />
                </Col>
                <Col span={12} md={8}>
                    <Input
                        label={"Forwarder Name"}
                        placeholder={"Forwarder Name"}
                        name={"forwarder_name"}
                        disabled
                    />
                </Col>
            </Row>

            <Flex center={"true"} gap={"20px"} margin={"20px 0"}>

            </Flex>
        </Form>
    )
}

export default ViewForm