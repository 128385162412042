

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import request from "../../utils/request";

const initialState = {
    currency: [],
    container: [],
    country: [],
    paymentTerms: [],
    notification: [],
    customercredit: [],
    profitloss: [],
    purchaseamount: [],
    purchasetotal: [],
    saleamount: [],
    salecount: [],

}

// ======   Currency    =========
export const getCurrency = createAsyncThunk(
    "Currency/Get",
    async () => {
        try {
            const response = await request.get('choice/add_currency/');
            return [...response.data];
        }
        catch (error) {
            throw error;
        }
    }
);


// ======   Container    =========
export const getContainer = createAsyncThunk(
    "Container/Get",
    async () => {
        try {
            const response = await request.get('choice/add_container/');
            return [...response.data];
        }
        catch (error) {
            throw error;
        }
    }
);

// ======   Container    =========
export const getCountry = createAsyncThunk(
    "Country/Get",
    async () => {
        try {
            const response = await request.get('choice/add_country/');
            return [...response.data];
        }
        catch (error) {
            throw error;
        }
    }
);

// ======  Payment Terms    =========
export const getPaymentTerms = createAsyncThunk(
    "PaymentTerms/Get",
    async () => {
        try {
            const response = await request.get('choice/add_payment_terms/');
            return [...response.data];
        }
        catch (error) {
            throw error;
        }
    }
);

// ======  Notification    =========
export const getNotification = createAsyncThunk(
    "Notification/Get",
    async () => {
        try {
            const response = await request.get('notifications/notifications/');
            return response.data.message;
        }
        catch (error) {
            throw error;
        }
    }
);

// ====== Dashboard Customer Credit    =========
export const getDashboard = createAsyncThunk(
    "CustomerCredit/Get",
    async () => {
        try {
            const response = await request.get('home/home_view/');
            return response.data
        }
        catch (error) {
            throw error;
        }
    }
);

const AddModuleReducer = createSlice({
    name: 'AddModules',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCurrency.fulfilled, (state, action) => {
                state.currency = action.payload;
            })
            .addCase(getContainer.fulfilled, (state, action) => {
                state.container = action.payload;
            })
            .addCase(getCountry.fulfilled, (state, action) => {
                state.country = action.payload;
            })
            .addCase(getPaymentTerms.fulfilled, (state, action) => {
                state.paymentTerms = action.payload;
            })
            .addCase(getNotification.fulfilled, (state, action) => {
                state.notification = action.payload;
            })
            .addCase(getDashboard.fulfilled, (state, action) => {
                state.customercredit = action.payload.unpaid
                state.profitloss = action.payload.profit_loss
                state.purchaseamount = action.payload.purchase_amt
                state.purchasetotal = action.payload.purchase_total
                state.saleamount = action.payload.sale_amt
                state.salecount = action.payload.sale_count
            })
    }
})

export const getAllCurrencies = (state) => state.addModules.currency
export const getAllContainers = (state) => state.addModules.container
export const getAllCountries = (state) => state.addModules.country
export const getAllPaymentTerms = (state) => state.addModules.paymentTerms
export const getAllNotifications = (state) => state.addModules.notification
export const getAllCustomerCredit = (state) => state.addModules.customercredit
export const getAllProfitLoss = (state) => state.addModules.profitloss
export const getAllPurchaseAmount = (state) => state.addModules.purchaseamount
export const getAllPurchaseTotal = (state) => state.addModules.purchasetotal
export const getAllSaleAmount = (state) => state.addModules.saleamount
export const getAllSaleCount = (state) => state.addModules.salecount

export const getStatus = (state) => state.addModules.status
export const getError = (state) => state.addModules.error


export const { reducer } = AddModuleReducer

export default AddModuleReducer.reducer
