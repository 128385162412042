import React from 'react'
import styled from 'styled-components'
import { THEME } from '../../theme';

export const FoemTitle  = styled.div`
& h5 {
    font-size: 26px;
    color: ${THEME.primary_color_dark};
    font-weight: 500;
    text-transform:capitalize;
    margin-bottom:20px;
}
`;

export const StyledInvoiceTitle  = styled.div`
& h5 {
    font-size: 20px;
    color: var(--dark-color);
    font-weight: 500;
    text-transform:capitalize;
    margin-bottom:20px;
}
`;

export const StyledPrintTitle  = styled.div`
& h5 {
    font-size: 20px;
    letter-spacing:1px;
    text-align:center;
    color: var(--dark-color);
    font-weight: 600;
    text-transform:uppercase;
    margin-bottom:20px;
}
`;


export const FormTitle = ({Title}) => {
  return (
    <FoemTitle>
        <h5>{Title}</h5>
    </FoemTitle>
  )
}

export const InvoiceTitle = ({Title}) => {
  return (
    <StyledInvoiceTitle>
        <h5>{Title}</h5>
    </StyledInvoiceTitle>
  )
}

export const PrintTableTitle = ({Title}) => {
  return (
    <StyledPrintTitle>
        <h5>{Title}</h5>
    </StyledPrintTitle>
  )
}

