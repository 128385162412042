import React, { useEffect, useState } from 'react'
import { Col } from 'antd';
import { Row } from '../../../Components/Row';
import Input from '../../../Components/Form/Input';
import { TextAreas } from '../../../Components/Form/TextArea';
import { CustomDatePicker } from '../../../Components/Form/CustomDatePicker';
import { Modal } from '../../../Components/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { CustomSelect } from '../../../Components/Form/CustomSelect';
import Country from '../../AddModules/Country';
import { getAllCountries, getAllPaymentTerms, getCountry, getPaymentTerms } from '../../AddModules/AddModulesSlice';
import PaymentTearms from '../../AddModules/PaymentTearms';

const PackingForm = ({  setSelectedDates, setShipTo, setAddNewPaymentTerms }) => {

    const [triggers, setTriggers] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);
    const [countryget, setCountryget] = useState([])
    const [contryData, setContryData] = useState([])
    const [payterms, setPayterms] = useState(0)
    const [width, setWidth] = useState(0)
    const [termsData, setTermsData] = useState([])
    const dispatch = useDispatch()

    const handleOnChanges = (date) => {
        setSelectedDates(date);
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    //==================Payment terms ===========================//

    const StorePayementres = useSelector(getAllPaymentTerms)

    useEffect(() => {
        dispatch(getPaymentTerms())
    }, [payterms])

    useEffect(() => {
        setTermsData(StorePayementres)
    }, [StorePayementres])

    const handleGetpayterms = () => {
        setPayterms(payterms + 1)
    }

    const handleSubmit = () => {
        handleOk()
    }

    const paymentOptions = termsData?.map((com) => ({ label: com.terms, value: com.terms }));

    const onViewpay = () => {
        setTriggers(triggers + 1)
        setWidth(500)
        setModalTitle("Add Payment");
        setModalContent(<PaymentTearms InvocePaymentTerms={setAddNewPaymentTerms} handleInvocPayterm={handleGetpayterms}
            handleSubmitInvce={handleSubmit} HeaderTrigger={triggers} />);
        showModal();
    }


    //========================country get================================//

    const countryreponse = useSelector(getAllCountries)

    useEffect(() => {
        dispatch(getCountry())
    }, [countryget])

    useEffect(() => {
        setContryData(countryreponse)
    }, [countryreponse])

    const AddContry = contryData.map((com) => ({ label: com.country, value: com.country }));

    const handleGetCountry = () => {
        setCountryget(countryget + 1)
    }
    const onViewCounty = () => {
        setTriggers(triggers + 1)
        setWidth(500)
        setModalTitle("Add Country");
        setModalContent(<Country setShipTo={setShipTo} handleGetCountry={handleGetCountry}
            handleOk={handleOk} SaleHeaderTrigger={triggers} />);
        showModal();
    }

    return (
        <div>
            <Row space={[12, 12]}>

                <Col span={24} md={12}>
                    <Row space={[12, 12]}>
                        <Col span={24} md={24}>
                            <Input label={'client Name / Company'}
                                placeholder={'client Name'} name={'company_name'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter client/ Company Name!',
                                    }
                                ]} />
                            <Input name={'customer_name'} display={'none'} />
                            <Input name={'customer_id'} display={'none'} />
                            <Input name={'email'} display={'none'} />
                            <Input name={'contact_person'} display={'none'} />
                            <Input name={'mobile'} display={'none'} />
                            <Input name={'currency'} display={'none'} />

                        </Col>
                        <Col span={24} md={24}>
                            <TextAreas label={'ADDRESS'} placeholder={'ADDRESS'}
                                name={'address'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter Address!',
                                    }
                                ]} />
                        </Col>
                        <Col span={24} md={24}>
                            <Input label={'CR NO'} name={'cr_no'}
                                placeholder={'CR NO'} rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter CR NO !',
                                    }
                                ]} />
                        </Col>
                        <Col span={24} md={24}>
                            <Input label={'IMPORT NO'}
                                placeholder={'IMPORT NO'} name={'import_no'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter IMPORT NO !',
                                    }
                                ]}
                            />
                        </Col>
                        <Col span={24} md={24}>
                            <Input label={'VAT NO'} placeholder={'VAT NO'} name={'vat_no'} rules={[
                                {
                                    required: true,
                                    message: 'Please Enter VAT NO !',
                                }
                            ]}
                            />
                        </Col>
                        <Col span={24} md={24}>
                            <CustomSelect
                                options={paymentOptions}
                                showSearch label={'PAYMENT TERM'}
                                placeholder={'PAYMENT TERM'} name={'payment_terms'}
                                onButtonClick={onViewpay}
                                buttonLabel={'Add'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Fill Details!',
                                    },
                                ]}
                            />
                        </Col>
                        <Col span={24} md={24}>
                            <Input label={'DELIVERY TERM'}
                                placeholder={'DELIVERY TERM'} name={'delivery_terms'} rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter DELIVERY TERM !',
                                    }
                                ]} />
                        </Col>
                        <Col span={24} md={24}>
                            <Input label={'CONFIRM QUANTITY'} placeholder={'CONFIRM QUANTITY'}
                                name={'confirm_quantity'} rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter confirm quantity!',
                                    }
                                ]}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col span={24} md={12}>
                    <Row space={[12, 12]}>
                        <Col span={24} md={24}>
                            <Input label={'PCKG LIST NO'} placeholder={'PCKG LIST NO'} name={'packlistno'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'This Field is required!',
                                    }
                                ]} />
                        </Col>
                        <Col span={24} md={24}>
                            <Input label={'REF INVOICE NO'} disabled name={'invoice_no'} placeholder={"REF INVOICE NO"}
                                rules={[
                                    {
                                        required: true,
                                        message: 'This Field is required!',
                                    }
                                ]} />
                        </Col>
                        <Col span={24} md={24}>
                            <CustomDatePicker label={'SAILING ON ABT'} name={'sealing_date'}
                                onChange={handleOnChanges}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Select date!',
                                    }
                                ]} />
                        </Col>
                        <Col span={24} md={24}>
                            <CustomSelect label={'SHIP To'} name={'country'} showSearch={true}
                                onButtonClick={onViewCounty} options={AddContry}
                                buttonLabel="ADD COUNTRY"
                                placeholder={'SHIP To'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Fill Details!',
                                    },
                                ]}
                            />
                        </Col>
                        <Col span={24} md={24}>
                            <Input label={'SC NO'} disabled name={'invoice_no'} placeholder={'SC NO'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'This Field is required!',
                                    }
                                ]} />
                        </Col>
                        <Col span={24} md={24}>
                            <Input label={'VESSEL NAME'} name={'vessel_name'} placeholder={'VESSEL NAME'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'This Field is required!',
                                    }
                                ]} />
                        </Col>
                        <Col span={24} md={24}>
                            <Input label={'BL NO'} name={'bl_no'} placeholder={'BL NO'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'This Field is required!',
                                    }
                                ]} />
                        </Col>
                        <Col span={24} md={24}>
                            <Input label={'CONTAINER & SEAL NO'} name={'container_seal_no'} placeholder={'CONTAINER & SEAL NO'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'This Field is required!',
                                    }
                                ]} />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Modal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={width} modalTitle={modalTitle} modalContent={modalContent} />

        </div>
    )
}

export default PackingForm
