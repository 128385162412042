import { Card, Col } from 'antd'
import React, { Fragment, useEffect } from 'react'
import { Row } from '../../../Components/Row'
import { TextAreas } from '../../../Components/Form/TextArea'
import { CustomInputNumber } from '../../../Components/Form/CustomInputNumber'

export const SalesFormFooter = ({  handleDepositamr }) => {

    return (
        <Fragment>
            <Row space={[24, 24]} >
                <Col lg={10} md={12} span={24}>
                    <Row space={[12, 12]} style={{ backgroundColor: 'white', padding: '15px', borderRadius: '6px' }}>

                        <Col span={24} md={20} >
                            <TextAreas label={'PACKING'} placeholder={'PACKING'}
                                name={'packing'} />
                        </Col>

                        <Col span={24} md={20} >
                            <TextAreas label={'SHIPPING MARKS'} placeholder={'SHIPPING MARKS'}
                                name={'shipping_mark'} />
                        </Col>

                        <Col span={24} md={20} >
                            <TextAreas label={'REMARKS'} placeholder={'REMARKS'}
                                name={'remarks'} />
                        </Col>

                    </Row>
                </Col>
                <Col lg={2} md={0} span={0}></Col>
                <Col lg={12} md={12} span={24}>
                    <Card>
                        <Row space={[12, 12]}>
                            <Col span={24} lg={24}>
                                <CustomInputNumber precision={2}
                                    label={'Total Quantity'}
                                    name={'total_quantity'}
                                    placed={'end'}
                                    disabled />
                            </Col>

                            <Col span={24} lg={24}>
                                <CustomInputNumber precision={2}
                                    label={'Grand Total Amount'}
                                    name={'grand_total'}
                                    placed={'end'}
                                    disabled
                                />
                            </Col>
                            <Col md={24} span={18}>

                                <CustomInputNumber
                                    precision={2} name={'deposit_amt'}
                                    label={'Deposit / Received Amt'} placed={'end'}
                                    onChange={handleDepositamr}
                                    rules={[
                                        {
                                            type: 'number',
                                            min: 0,
                                            message: 'value must be positive'
                                        }
                                    ]}
                                />

                            </Col>

                        </Row>
                    </Card>
                </Col>
            </Row >

        </Fragment>
    )
}