import React, { useEffect, useState } from 'react'
import { Table } from '../../../../Components/Table';
import request from '../../../../utils/request';
import { Overhid } from '../../InvoiceReport/Partials/Style';


const PaymentDetailsModal = ({ invoice }) => {

    const [dataSource, setDataSource] = useState([])

    useEffect(() => {
        GetInvoicepayDetails();
    }, [invoice])

    const Invoice = {
        invoice_no: invoice
    }

    const GetInvoicepayDetails = () => {
        request.post(`customerpay/invoice_get_unpaidpay/`, Invoice)
            .then(function (response) {
                setDataSource(response.data)
            })
            .catch(function (error) {
                console.log(error, 'error');
            });
    }

    const columns = [
        {
            title: 'Sl No',
            render: (item, value, index) => index + 1,
        },
        {
            title: 'Bill No',
            dataIndex: 'sale_confirmation_no',
        },
        {
            title: 'Bill Date',
            dataIndex: 'sale_confirmation_date',
        },
        {
            title: 'Bill Amount',
            dataIndex: 'bill_amount',
            render: (value, record) => `${value} ${record.currency}`,
        },
        {
            title: 'Paid Amount',
            dataIndex: 'amount',
            render: (value, record) => `${value} ${record.currency}`,
        },
        {
            title: 'Balance Amount',
            dataIndex: 'balance_amount',
            render: (value, record) => `${value} ${record.currency}`,
        },
        {
            title: 'Payment Date',
            dataIndex: 'payment_date',
        },


    ];


    return (
        <div>
            <Overhid>
                <Table columns={columns} data={dataSource} />
            </Overhid>
        </div>
    )
}

export default PaymentDetailsModal