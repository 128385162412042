import React from 'react'
import { PrintTableTitle } from '../../../../Components/Form/FormTitle'
import { TableHolder } from '../../../../Components/Styled'


const ParintTable = ({ componentRef, data }) => {

    return (
        <div ref={componentRef} style={{ margin: '10px' }}>
            <PrintTableTitle Title={'Sales Reports'} />

            <TableHolder>
                <table>
                    <thead>
                        <tr>
                            <th>SL. No</th>
                            <th>DATE</th>
                            <th>SC Number</th>
                            <th>CUSTOMER ID</th>
                            <th>SHIPPING DATE</th>
                            <th>BILL AMOUNT</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map((item, i) => (
                            <tr key={i}>
                                <td>{i + 1}</td>
                                <td>{new Date(item.date).toLocaleDateString('en-GB')}</td>
                                <td>{item.sale_confirmation_no}</td>
                                <td>{item.customer_id}</td>
                                <td>{item.shipping_date}</td>
                                <td>{item.grand_total}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </TableHolder>
            <br />
        </div>
    )
}

export default ParintTable