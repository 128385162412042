import React, { useEffect, useState } from 'react'
import { Form, Col } from 'antd'
import { Row } from '../../../Components/Row';
import Input from '../../../Components/Form/Input';
import Flex from '../../../Components/Flex';
import Button from '../../../Components/Form/Button';
import { CustomDatePicker } from '../../../Components/Form/CustomDatePicker';
import dayjs from 'dayjs';
import request from '../../../utils/request';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { CustomInputNumber } from '../../../Components/Form/CustomInputNumber';

function FactoryBills({ billrecord, handleClose, formResetBill, GetUse }) {

    const [selectedDates, setSelectedDates] = useState(dayjs().format('YYYY-MM-DD'));
    const [disabless, setDisabless] = useState(false)
    const [formRecall, setFormRecall] = useState(0)


    const navigate = useNavigate()

    // ----- Define Form
    const [form] = Form.useForm();

    const onFinish = (values) => {

        const NewValue = {
            ...values,

            payment_date: values?.payment_date === null ? '' : dayjs(selectedDates).format('YYYY-MM-DD') ? dayjs(values?.payment_date).format('YYYY-MM-DD') : dayjs(values?.payment_date).format('YYYY-MM-DD'),

        }
        let NewValues = {
            factory_name: NewValue.supplier_name,
            purchase_order_confirmation_no: NewValue.sc_no,
            bill_amount: NewValue.factory_bill_amt,
            paid_amount: NewValue.factory_receive_amt,
            balance_amount: NewValue.factory_balance_amt,
            amount: NewValue.amount,
            purchase_order_confirmation_date: NewValue.purchase_date,
            payment_date: NewValue.payment_date,
            consignment: NewValue.consignment,
        }
        PostUnFactorypaid(NewValues)
    };

    const onReset = () => {
        const requiredFields = ['amount', 'payment_date'];
        form.resetFields(requiredFields);
        setFormRecall(formRecall + 1)
    };

    const Datechange = (value) => {
        setSelectedDates(value);;
    }

    useEffect(() => {
        form.resetFields()
    }, [formResetBill])

    useEffect(() => {
        form.setFieldsValue({
            consignment: billrecord.id,
            supplier_name: billrecord.supplier_name,
            sc_no: billrecord.sc_no,
            purchase_date: billrecord.purchase_date,
            factory_bill_amt: billrecord.factory_bill_amt,
            payment_date: billrecord.payment_date,
            factory_receive_amt: billrecord.factory_receive_amt,
            amount: billrecord.amount,
            factory_balance_amt: billrecord.factory_balance_amt,
            payment_date: dayjs(),
        })
    }, [billrecord, formResetBill, formRecall])


    const PostUnFactorypaid = (values) => {
        request.post(`payment/add_factory_unpaidbill_payment/`, values)
            .then(function (response) {
                toast.success('Successfully Paid')

                if (handleClose) {
                    handleClose()
                }
                if (GetUse) {
                    GetUse()
                }
            })
            .catch(function (error) {
                toast.error('Failed')
                console.log(error)
            });
    }

    //==================Calculate the balance total====================================

    const handleAmount = (e) => {

        let Amt = billrecord?.factory_balance_amt;

        let PayAmt = e;
        let PaidAmt = Amt - PayAmt

        form.setFieldsValue({
            factory_balance_amt: PaidAmt
        })

        if (PaidAmt < 0) {
            form.setFieldsValue({
                Amt: "",
                factory_balance_amt: PaidAmt
            });
            setFormRecall(formRecall + 1)
            showToastMessage("Amount cannot be negative.", "error");
            setDisabless(true)
        }
        else if (PayAmt < 0) {
            form.setFieldsValue({
                amount: "",
                factory_balance_amt: PaidAmt
            });
            setFormRecall(formRecall + 1)
            showToastMessage("Please enter positive value.", "error");
            setDisabless(false)
        }
        else {
            form.setFieldsValue({
                factory_balance_amt: PaidAmt
            });
            setDisabless(false)
        }
    }


    const showToastMessage = (message, type) => {
        if (type === 'error') {
            toast.warn(message);
        } else {
            toast(message);
        }
    };

    return (
        <Form
            name="factoryBill"
            labelCol={{
                span: 24,
            }}
            wrapperCol={{
                span: 24,
            }}
            form={form}
            onFinish={onFinish}
        >
            <Row space={[24, 24]}>
                <Col span={12} md={12}>
                    <Input disabled
                        label={"Factory Name"}
                        placeholder={"Factory Name"}
                        name={"supplier_name"} />

                </Col>
                <Col span={12} md={12}>
                    <Input disabled
                        label={"P.C.No"}
                        placeholder={"P.C.No"}
                        name={"sc_no"}
                    />
                </Col>
                <Col span={12} md={12}>
                    <Input disabled
                        label={"P.C.Date"}
                        placeholder={"P.C.Date"}
                        name={"purchase_date"}
                    />
                </Col>
                <Col span={12} md={12}>
                    <Input disabled
                        label={"Bill Amount"}
                        placeholder={"Bill Amount"}
                        name={"factory_bill_amt"}
                    />
                </Col>
                <Col span={12} md={12}>
                    <CustomDatePicker label={"Payment Date"} name={"payment_date"} onChange={Datechange} rules={[
                        {
                            required: true,
                            message: "Please Select a Payment Date!",
                        }
                    ]}
                    />
                </Col>
                <Col span={12} md={12}>
                    <Input disabled
                        label={"Paid Amount"}
                        placeholder={"Paid Amount"}
                        name={"factory_receive_amt"}
                    />
                </Col>
                <Col span={12} md={12}>
                    <CustomInputNumber
                        label={"Amount"}
                        placeholder={"Amount"}
                        name={"amount"}
                        rules={[
                            {
                                required: true,
                                message: "Please enter Amount !",
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (parseFloat(value) <= 0) {
                                        return Promise.reject('Amount must be greater than 1');
                                    }
                                    return Promise.resolve();
                                },
                            }),
                        ]}
                        onChange={handleAmount}
                    />
                </Col>
                <Col span={12} md={12}>
                    <Input
                        disabled
                        label={"Balance Amount"}
                        placeholder={"Balance Amount"}
                        name={"factory_balance_amt"}
                    />
                </Col>
                <Input name={'consignment'} display={'none'} />
            </Row>
            <Flex center={"true"} gap={"20px"} margin={"20px 0"}>
                <Button.Primary text={"Submit"} htmlType={"submit"} disabled={disabless} />
                <Button.Danger text={"Reset"} onClick={() => onReset()} />
            </Flex>
        </Form>
    )
}

export default FactoryBills