
import React from 'react'
import { FormTitle, PrintTableTitle } from '../../../../Components/Form/FormTitle'
import { TableHolder } from '../../../../Components/Styled'




const ParintTable = ({ componentRef, data }) => {

    return (
        <div ref={componentRef} style={{ margin: '10px' }}>
            <br />
            <PrintTableTitle Title={'Finance Reports'} />

            <br />
            <TableHolder>
                <table width={'100%'}>
                    <thead>
                        <tr>
                            <th>S.NO</th>
                            <th>Invoice Number</th>
                            <th>Total Value</th>
                            <th>Sc No</th>
                            <th>Profit Loss Amount</th>

                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, i) => (
                            <tr key={i}>
                                <td>{i + 1}</td>
                                <td>{item.invoice_no}</td>
                                <td>{item.customer_bill_amt}</td>
                                <td>{item.sc_no}</td>
                                <td>{item.profit_loss_amt}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </TableHolder>
            <br />

        </div>
    )
}

export default ParintTable