import React, { Fragment, useEffect, useState } from 'react'
import Flex from '../../../Components/Flex';
import { Table } from '../../../Components/Table';
import ViewForm from './ViewForm';
import { Modal } from '../../../Components/Modal';
import { TopTitle } from '../../../Components/Form/TopTitle';
import Label from '../../../Components/Form/Label';
import Input from '../../../Components/Form/Input';
import { getProfitTable, selectProfittable } from '../ProfLosSlice';
import { useDispatch, useSelector } from 'react-redux';
import { TableIconHolder } from '../../../Components/Styled';
import { FiEdit } from 'react-icons/fi';
import { THEME } from '../../../theme';
import { AiOutlineEye } from 'react-icons/ai';
import ProfitorLoss from './ProfitorLoss';



const View = () => {

    const AllUser = useSelector(selectProfittable)

    const [dataSource, setDataSource] = useState([]);

    // ======  Modal Open ========
    const [isModalOpen, setIsModalOpen] = useState(false);

    // ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);

    // ----------  Form Reset UseState ---------
    const [formReset, setFormReset] = useState(0);
    const [searchText, setSearchText] = useState([]);

    const dispatch = useDispatch()

    const handleSearch = (value) => {
        setSearchText(value);
    };

    // ===== Modal Functions Start =====

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
        FormCancelRest();
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        FormCancelRest();
    };

    const FormExternalClose = () => {
        handleOk();
        dispatch(getProfitTable())
    };

    const CloseHanOK = () => {
        handleOk();
    };

    const FormCancelRest = () => {
        setFormReset(formReset + 1);
    };

    const Showprofitrloss = (record) => {
        setModalTitle("View Profit or Loss");
        setModalContent(
            <ViewForm
                ViewRecord={record}
                formname={"UpdateForm"}
                FormExternalClose={FormExternalClose}
                formReset={formReset}
            />
        );
        showModal();
    }

    const EditProfitLoss = (record) => {
        setModalTitle("Update");
        setModalContent(
            <ProfitorLoss handleRecord={record} formReset={formReset}
                FormExternalClose={FormExternalClose} CloseHanOK={CloseHanOK}
            />
        );
        showModal();
    }


    useEffect(() => {
        dispatch(getProfitTable())
    }, [])

    useEffect(() => {
        setDataSource(AllUser)
    }, [AllUser])

    const TableColumn = [
        {
            title: "SI No",
            render: (value, item, index) => index + 1,
        },
        {
            title: "Invoice Date",
            dataIndex: "sale_date",
        },
        {
            title: "Invoice No",
            dataIndex: "sc_no",
            filteredValue: searchText ? [searchText] : null,
            onFilter: (value, record) => {
                return String(record.sc_no).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.sc_no).includes(value.toUpperCase());
            },
        },
        {
            title: "Bill Amount",
            dataIndex: "customer_bill_amt",
            render: (value, record) => `${value} ${record.currency}`,
        },
        {
            title: "Profit or Loss",
            dataIndex: "profit_loss_amt",
            render: (value, record) => `${value} ${record.currency}`,
        },
        {
            title: "Status",
            dataIndex: "profit_status",
            render: (profit_status, record) => {
                let color;
                if (record.profit_status === "Loss") {
                    color = "red";
                } else if (record.profit_status === "Profit") {
                    color = "green";
                } else {
                    color = "blue";
                }

                return (
                    <div style={{ color }}>{profit_status}</div>
                );
            },
        },
        {
            title: "Action",
            render: (record, i) => {
                return (
                    <Flex spaceEvenly={"true"} gap={"10px"}>

                        <TableIconHolder color={THEME.green} size={'22px'} onClick={() => Showprofitrloss(record)}>
                            <AiOutlineEye />
                        </TableIconHolder>

                        <TableIconHolder color={THEME.blue} size={'22px'} onClick={() => EditProfitLoss(record)}>
                            <FiEdit />
                        </TableIconHolder>
                    </Flex>
                );
            },
        },
    ];


    return (
        <Fragment>
            <TopTitle Heading={'Profit or Loss'} />
            <Flex end={true} centervertically={true}>
                <Label>Search by Invoice No: &nbsp;</Label>
                <Input
                    placeholder="Search by Invoice No"
                    value={searchText}
                    onSearch={handleSearch}
                    onChange={(e) => handleSearch(e.target.value)} />
            </Flex>
            <Table columns={TableColumn} data={dataSource} />

            <Modal
                isVisible={isModalOpen}
                handleOk={handleOk}
                handleCancel={handleCancel}
                width={800}
                modalTitle={modalTitle}
                modalContent={modalContent}
            />


        </Fragment>
    )
}

export default View;