import { Col, Form } from 'antd'
import React, { Fragment, useState } from 'react'
import Button from '../../../../Components/Form/Button';
import Flex from '../../../../Components/Flex';
import { Row } from '../../../../Components/Row';
import Input from '../../../../Components/Form/Input';
import request from '../../../../utils/request';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { TextAreas } from '../../../../Components/Form/TextArea';
import { CustomSelect } from '../../../../Components/Form/CustomSelect';
import { Modal } from '../../../../Components/Modal';
import AddSize from './AddSize';
import AddColor from './AddColor';
import { CustomUpload } from '../../../../Components/Form/CustomUpload';
import { FormLoadingIndicator } from '../../../../Components/CommonLoading';

export const AddItems = ({ SaleTrigger, Itemdata, handleSelectProductGet,handleQuotationSelectProductGet, ProductFormUpdateCall, ProductFormExternalClose, productFormReset,
    AddProductnameGet, GetSaleCustomer, handleOks, handleOkSale, handleOkPurchse,QuotationTrigger }) => {

    const [form] = Form.useForm();

    const ADD_PRODUCT_URL = 'product/add_items_entry/'
    const SIZE_GET_URL = 'choice/add_size/'
    const COLOR_GET_URL = 'choice/add_color/'

    const EDIT_PRODUCT_URL = 'product/edit_products_details'

    //   ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    // ----------  Form Reset UseState ---------
    const [formReset, setFormReset] = useState(0);
    const [formUpdate, setFormUpdate] = useState(0);

    const [sizeArray, setSizeArray] = useState([]);   //  Size UseState
    const [colorArray, setColorArray] = useState([]);   //  Color UseState
    const [ImageInitialValue, setImageInitialValue] = useState([]);   //  Upload Initial UseState
    const [size, setSize] = useState() //state to set the newly added size
    const [color, setColor] = useState() //state to set the newly added color

    const [formLoading, setFormLoading] = useState(false)
    const [updateFormData, setUpdateFormData] = useState({})

    useEffect(() => {
        GetSize();
        GetColor();
    }, [formUpdate])

    useEffect(() => {
        if (Itemdata) {

            setImageInitialValue([
                {
                    uid: '1',
                    name: 'example.jpg',
                    status: 'done',
                    url: Itemdata.item_image,
                }
            ])
        }
        setUpdateFormData(Itemdata)

    }, [Itemdata])

    useEffect(() => {
        form.setFieldsValue(updateFormData)
        form.setFieldsValue({ 'item_image': ImageInitialValue })
    }, [updateFormData, productFormReset])

    useEffect(() => {
        form.setFieldsValue({ item_size: size })
    }, [size])

    useEffect(() => {
        form.setFieldsValue({ item_color: color })
    }, [color])

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
        FormCancelRest();
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        FormCancelRest();
    };

    // ===== Modal Functions End =====

    // -------  Form Reset Funtion

    useEffect(() => {
        form.resetFields()
    }, [SaleTrigger,QuotationTrigger])

    const FormExternalClose = () => {
        handleOk();
    }

    const FormCancelRest = () => {
        setFormReset(formReset + 1)
    }

    const FormUpdateCall = () => {
        setFormUpdate(formUpdate + 1)
    }

    const onFinish = (values) => {

        if (Itemdata) {
            const formData = new FormData();

            formData.append('item_name', values?.item_name);
            formData.append('item_description', values?.item_description);
            formData.append('item_size', values?.item_size);
            formData.append('item_color', values?.item_color);
            formData.append('barcode', values?.barcode);

            if (values?.item_image && values.item_image.length > 0) {
                values.item_image.forEach((file) => {
                    if (file.originFileObj !== undefined) {
                        formData.append(`item_image`, file.originFileObj);
                    } else {
                        console.log('UNDEFINED')
                    }
                });
            } else {
                console.error('No images selected');
            }

            Updateproduct(formData, Itemdata?.id)
            handleOks()
        }
        else {
            const formData = new FormData();

            formData.append('item_name', values?.item_name);
            formData.append('item_description', values?.item_description);
            formData.append('item_size', values?.item_size);
            formData.append('item_color', values?.item_color);
            formData.append('barcode', values?.barcode);

            if (values?.item_image && values.item_image.length > 0) {
                values.item_image.forEach((file) => {
                    formData.append(`item_image`, file.originFileObj);
                });
            } else {
                console.error('No images selected');
            }

            Addproduct(formData)
        }

    };

    const SizeOptions = sizeArray?.map((item) => ({ label: item.size, value: item.size }));
    const ColorOptions = colorArray?.map((item) => ({ label: item.color, value: item.color }));

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const AddSizeForm = () => {
        setModalTitle("Add Size");
        setModalContent(<AddSize setSize={setSize} FormExternalClose={FormExternalClose} formReset={formReset} FormUpdateCall={FormUpdateCall} url={SIZE_GET_URL} />);
        showModal();
    }

    const AddColorForm = () => {
        setModalTitle("Add Color");
        setModalContent(<AddColor setColor={setColor} FormExternalClose={FormExternalClose} formReset={formReset} FormUpdateCall={FormUpdateCall} url={COLOR_GET_URL} />);
        showModal();
    }


    const GetSize = () => {
        request.get(`${SIZE_GET_URL}`)
            .then(function (response) {
                setSizeArray(response.data);
            })
            .catch(function (error) {
                console.log(error)
            });
    }

    const GetColor = () => {
        request.get(`${COLOR_GET_URL}`)
            .then(function (response) {
                setColorArray(response.data);
            })
            .catch(function (error) {
                console.log(error)
            });
    }


    //================product-post=============================//

    const config = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    };

    const Addproduct = (values) => {
        setFormLoading(true)
        request.post(`${ADD_PRODUCT_URL}`, values, config)
            .then(function (response) {
                toast.success("Product Added Successfully")
                form.resetFields();
                if (AddProductnameGet) {
                    AddProductnameGet()
                }
                if (GetSaleCustomer) {
                    GetSaleCustomer()
                }
                if (handleSelectProductGet) {
                    handleSelectProductGet();
                }
                if(handleQuotationSelectProductGet){
                    handleQuotationSelectProductGet();
                }
                if (handleOks) {
                    handleOks()
                }
                if (handleOkSale) {
                    handleOkSale()
                }
                if (handleOkPurchse) {
                    handleOkPurchse()
                }
                setFormLoading(false)
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    toast.warning(error.response.data.item_name[0])
                    setFormLoading(false)

                }

                else {
                    toast.error("Something Went Wrong")
                    setFormLoading(false)
                }
            });
    }

    const Updateproduct = (values, id) => {

        setFormLoading(true)
        request.patch(`${EDIT_PRODUCT_URL}/${id}`, values, config)
            .then(function (response) {
                toast.info("Product Updated Successfully")
                if (ProductFormUpdateCall) {
                    ProductFormUpdateCall();
                }
                if (handleOk) {
                    handleOk()
                }

                setFormLoading(false)

            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    toast.warning(error.response.data.item_name[0])
                }
                else {
                    toast.error("Something Went Wrong")
                }
            });
    }



    const onReset = () => {
        form.resetFields();
    }

    return (
        <Fragment>
            <FormLoadingIndicator active={formLoading} />
            <Form
                form={form}
                name='AddItem'
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off">

                <Row space={[12, 12]}>

                    <Col span={24} md={12}>

                        <Input label={'Product Name'} placeholder={'Product Name'} name={'item_name'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Product Name!',
                                }
                            ]} />
                    </Col>

                    <Col span={24} md={12}>
                        <CustomSelect label={'Size'} options={SizeOptions} name={'item_size'} placeholder={'Size'}
                            onButtonClick={AddSizeForm} showSearch buttonLabel="Add Size"

                            rules={[
                                {
                                    required: true,
                                    message: 'This is required field!'
                                }
                            ]} />
                    </Col>

                    <Col span={24} md={12}>
                        <CustomSelect label={'Color'} options={ColorOptions} name={'item_color'} placeholder={'Color'}
                            onButtonClick={AddColorForm} showSearch buttonLabel="Add Color"

                            rules={[
                                {
                                    required: true,
                                    message: 'This is required field!'
                                }
                            ]} />
                    </Col>

                    <Col span={24} md={12}>
                        <Input label={'BarCode'} name={'barcode'} placeholder={"BarCode"}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter barcode',
                                },
                            ]} />
                    </Col>

                    <Col span={24} md={12}>
                        <CustomUpload form={form} initialValue={ImageInitialValue} label={'Product Image'} name={'item_image'} listType='picture-card' maxCount={1} accept='.png,.jpeg,.jpg'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please upload Product Images',
                                },
                            ]} />
                    </Col>

                    <Col span={24} md={12}>
                        <TextAreas label={'Description'} placeholder={'Description'} name={'item_description'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Description!',
                                }
                            ]} />
                    </Col>


                </Row>

                {
                    Itemdata ? (
                        <Flex center={'true'} gap={'20px'} margin={'20px 0px'}>
                            <Button.Success text={'UPDATE'} htmlType={'submit'} />
                            <Button.Danger text={'CANCEL'} onClick={() => ProductFormExternalClose()} />
                        </Flex>
                    ) : (
                        <Flex center={'true'} gap={'20px'} margin={'20px 0px'}>
                            <Button.Success text={'ADD'} htmlType={'submit'} />

                            <Button.Danger text={'RESET'} onClick={() => onReset()} />
                        </Flex>
                    )
                }
                <Modal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={500} modalTitle={modalTitle} modalContent={modalContent} />

            </Form>
        </Fragment>
    )
}