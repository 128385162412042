import React, { useState } from 'react'
import { Table } from '../../../../Components/Table';
import { Modal } from '../../../../Components/Modal';
import { Col, Form, Modal as Modals, Row } from 'antd';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import Flex from '../../../../Components/Flex';
import Button from '../../../../Components/Form/Button';
import { TopTitle } from '../../../../Components/Form/TopTitle';
import { useNavigate, useParams } from 'react-router-dom';
import Addsppliers from '../../AddSupplier/Partials/Addsppliers';
import { useEffect } from 'react';
import request from '../../../../utils/request';
import EditSuppliers from '../../AddSupplier/Partials/EditSuppilers';
import Input from '../../../../Components/Form/Input';
import Label from '../../../../Components/Form/Label';
import { Select } from '../../../../Components/Form/Select';
import { THEME } from '../../../../theme';
import { FiEdit } from 'react-icons/fi';
import { BiSolidUserDetail } from 'react-icons/bi';
import { MdDelete } from 'react-icons/md';
import { TableIconHolder } from '../../../../Components/Styled';
import { CustomPopconfirm } from '../../../../Components/CustomPopConfirm';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { getAllsuppliersList, getSupplierList, getSupplierError, getSupplierStatus } from '../../SupplierSlice';
import { CommonLoading } from '../../../../Components/CommonLoading';

const TableView = ({ setSupplier, }) => {

    const URL = 'supplier/add_supplier/'

    const { id } = useParams();

    const [form] = Form.useForm();

    const nevigate = useNavigate();

    const dispatch = useDispatch()

    const [selectedSupplier, setSelectedSupplier] = useState({});

    const [searchText, setSearchText] = useState([]);
    const [searchTexts, setSearchTexts] = useState([]);
    const [selectedOption, setSelectedOption] = useState('Supplierid');

    // ======  Modal Open ========
    const [isModalOpen, setIsModalOpen] = useState(false);

    // ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);

    const [dataSource, setDataSource] = useState([])

    const [trigger, setTrigger] = useState(0)



    const supp = useSelector(getAllsuppliersList)
    const supplierStatus = useSelector(getSupplierStatus)
    const supplierError = useSelector(getSupplierError)
    useEffect(() => {
        dispatch(getSupplierList())
    }, [])

    useEffect(() => {
        setDataSource(supp)
    }, [supp])

    useEffect(() => {
        form.setFieldsValue({ supplier_name: selectedSupplier.supplier_name })
        form.setFieldsValue({ mobile_number: selectedSupplier.mobile_number })
    }, [selectedSupplier])

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleSearchs = (value) => {
        setSearchTexts(value);
    };


    // ======  PopConfirm Function ====
    const handleConfirm = (record) => {
        DeleteSupplier(record);
    };

    const DeleteSupplier = (record) => {
        request.delete(`supplier/add_supplier_edit/${record?.id}`)
            .then(response => {
                toast.success('Supplier Deleted Successfully !')
                dispatch(getSupplierList())
            })
            .catch(error => {
                if (error.response.status === 401) {
                    toast.warning("This supplier have transactions so can't be deleted")
                }
                else {
                    toast.error("Something Went Wrong")
                }
            });
    }

    const handlePopConfrmCancel = () => {
        console.log('Called');
    };

    const handleSuppilers = () => {
        dispatch(getSupplierList())
        handleOk()
    }

    const handleSearch = (value) => {
        setSearchText(value);
    };

    const columns = [
        {
            title: 'S.No',
            render: (value, item, index) => index + 1,
        },
        {
            title: 'Supplier ID',
            dataIndex: 'invoice_no',
            filteredValue: searchTexts ? [searchTexts] : null,
            onFilter: (value, record) => {
                return String(record.invoice_no).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.invoice_no).includes(value.toUpperCase());
            },
        },
        {
            title: 'Company Name',
            dataIndex: 'supplier_name',
            filteredValue: searchText ? [searchText] : null,
            onFilter: (value, record) => {
                return String(record.supplier_name).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.supplier_name).includes(value.toUpperCase());
            },
        },

        {
            title: 'Contact Person',
            dataIndex: 'contact_person_name',
        },
        {
            title: 'Factory Phone No',
            dataIndex: 'supplier_mobile',
        },
        {
            title: 'Action',
            render: (record, i) => {
                return (
                    <>
                        <Flex spaceEvenly gap={'10px'}>
                            <TableIconHolder color={THEME.blue} size={'22px'} onClick={() => onEditStudent(record)}>
                                <FiEdit />
                            </TableIconHolder>
                            <TableIconHolder color={THEME.gray} size={'22px'} onClick={() => nevigate(`/SupplierProfiles/${record.id}`)}>
                                <BiSolidUserDetail />
                            </TableIconHolder>
                            <CustomPopconfirm
                                record={record}
                                confirm={handleConfirm}
                                cancel={handlePopConfrmCancel}
                                title={"Delete the Supplier List"}
                                description={"Are you sure to delete this Supplier List ?"}>
                                <TableIconHolder color={THEME.red} size={'22px'}>
                                    <MdDelete />
                                </TableIconHolder>
                            </CustomPopconfirm>
                        </Flex>

                    </>
                );
            },

        }
    ]

    const onEditStudent = (record) => {
        setTrigger(trigger + 1)
        showModal();
        setModalTitle("update");
        setModalContent(<EditSuppliers supplierTrigger={trigger} suppilerData={record} handleSuppilers={handleSuppilers} />);
    }


    const choose = [
        {
            label: "Company Name",
            value: "Companyname",
        },
        {
            label: "Supplier Id",
            value: "Supplierid",
        },
    ];

    const pick = (value) => {
        setSelectedOption(value);
        setSearchText(''); // Clear the searchText state
        setSearchTexts(''); // Clear the searchTexts state
    }

    let content;

    if (supplierStatus === 'loading') {
        content = <CommonLoading />
    } else if (supplierStatus === 'succeeded') {
        const rowKey = (dataSource) => dataSource.id;
        content = <Table columns={columns} data={dataSource} rowKey={rowKey} />
    } else if (supplierStatus === 'failed') {
        content = <h2>{supplierError}</h2>
    }

    return (
        <div>
            <TopTitle Heading={'View Supplier List'} />
            <Row space={[24, 24]}>
                <Col span={0} sm={8} md={12}></Col>
                <Col span={24} sm={16} md={12}>
                    <Row space={[12, 12]}>
                        <Col span={24} sm={12}>
                            <Select label={"Select by"} value={selectedOption} options={choose} onChange={pick}
                                placeholder="Search by Name or Id"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select  !",
                                    },
                                ]}
                            />
                        </Col>
                        <Col span={24} sm={1}></Col>
                        <Col span={24} sm={11}>

                            {selectedOption === 'Companyname' ? (
                                <Input
                                    placeholder="Search by Company Name"
                                    value={searchText}
                                    label="Search by Company Name"
                                    onSearch={handleSearch}
                                    onChange={(e) => handleSearch(e.target.value)}
                                />
                            ) : (
                                <Input
                                    placeholder="Search by SUPPLIER ID"
                                    value={searchTexts}
                                    label="Search by SUPPLIER ID"
                                    onSearch={handleSearchs}
                                    onChange={(e) => handleSearchs(e.target.value)}
                                />
                            )}
                        </Col>
                    </Row>
                </Col>
            </Row>
            {content}
            <Modal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={1000} modalTitle={modalTitle} modalContent={modalContent} />

        </div>
    )
}

export default TableView