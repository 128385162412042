import React, { useEffect } from 'react'
import { Tabs } from '../../../Components/Tabs';
import styled from 'styled-components';
import { THEME } from '../../../theme';
import { SalesView } from './SalesView';
import { PurchaseTBView } from './PurchTableView';
import { InvoiceView } from './InvoiceView';
import { PackingView } from './PackingView';
import { selectCurrentRole, selectCurrentSuper } from '../../Auth/authSlice';
import { useSelector } from 'react-redux';

const TabDetails = ({ id, invoice, alldtass, purchase, salesImg, purchaseImg, invoiceImg, packing, packingImg }) => {

    const TabStyle = styled.div`
    .ant-tabs-nav {
    background:${THEME.secondary_color_light};
    padding: 10px;
    color: white;
    }
    .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #ffff !important;
    }
    .ant-tabs .ant-tabs-ink-bar{
        background:${THEME.white};
    }
    .ant-tabs-tab:hover {
    color: #000;
    transition: 0.3s;
    }
    `
    const Role = useSelector(selectCurrentRole);

    const Super_r = useSelector(selectCurrentSuper);

    useEffect(() => {
        if (Role === 'Master1') {
        }
        else if (Role === 'Master2') {
        }
        else if (Role === 'Sales') {
        }
        else if (Role === 'Purchase') {
        }
        else if (Role === 'Invoice') {
        }
        else if (Role === 'Packing') {
        }
    }, [Role])

    const items = [
        {
            key: '1',
            label: `Sales Confirmation`,
            children: <SalesView record={alldtass} salesImg={salesImg} />,
        },
        {
            key: '2',
            label: `Purchase Order`,
            children: <PurchaseTBView record={purchase} purchaseImg={purchaseImg} />,
        },
        {
            key: '3',
            label: `Invoice`,
            children: <InvoiceView record={invoice} invoiceImg={invoiceImg} />,
        },
        {
            key: '4',
            label: `Packing List`,
            children: <PackingView record={packing} packingImg={packingImg} />,
        },
    ];
    const onChange = (key) => {
        console.log(key);
    };

    return (
        <TabStyle>
            <Tabs onChange={onChange} items={items} />
        </TabStyle>
    )
}

export default TabDetails
