import { Col, Form } from 'antd';
import React, { useEffect } from 'react'
import { Row } from '../../../../Components/Row';
import Input from '../../../../Components/Form/Input';
import Flex from '../../../../Components/Flex';
import Button from '../../../../Components/Form/Button';
import { toast } from 'react-toastify';
import request from '../../../../utils/request';

const AddSize = ({ FormExternalClose, formReset, FormUpdateCall, url, setSize }) => {

    const [form] = Form.useForm();      // ----- Define Form

    useEffect(() => {
        form.resetFields();
    }, [formReset])

    const onFinish = (values) => {
        FormExternalClose();
        AddSize(values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onRest = () => {
        form.resetFields();
    }

    const AddSize = (values) => {
        request.post(`${url}`, values)
            .then(function (response) {
                
                if (response.status == 200) {
                    setSize(response.data.size)
                    toast.success("Size Added Successfully")
                    form.resetFields();
                    FormUpdateCall();
                }
                else if (response.status == 226) {
                    toast.warn('Size Already Added')
                }
                else {
                    toast.error('Failed');
                }
            })
            .catch(function (error) {
                console.log(error)
            });
    }

    return (
        <Form
            form={form}
            labelCol={{
                span: 24,
            }}
            wrapperCol={{
                span: 24,
            }}

            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off">

            <Row gutter={[12, 12]}>

                <Col span={24} md={24}>
                    <Input name={'people'} display={'none'} />
                    <Input label={'Size'}
                        placeholder={'Size'} name={'size'}
                        rules={[
                            {
                                required: true,
                                message: 'This is required field!'
                            }
                        ]} />
                </Col>

            </Row>
            <Flex center gap={'20px'} margin={'30px'}>
                <Button.Success text={'ADD'} htmlType={'submit'} />
                <Button.Danger text={'RESET'} onClick={onRest} />
            </Flex>

        </Form>
    )
}

export default AddSize