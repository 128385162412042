import React from 'react'
import CustomerProfilesDetails from './Partials/CustomerProfiles'
import { TopTitle } from '../../../Components/Form/TopTitle'
import { CustomCard } from '../../../Components/CustomCard'

const CustomerProfiles = () => {

  return (
    <div>
      <TopTitle Heading={'Customer Profile'} />
      <CustomCard width={'95%'}>
        <CustomerProfilesDetails />
      </CustomCard>
    </div>
  )
}

export default CustomerProfiles