import { PrintTableTitle } from "../../../../Components/Form/FormTitle"
import { TableHolder } from "../../../../Components/Styled"


const ParintTable = ({ componentRef, data, total }) => {
    return (
        <div ref={componentRef} style={{ margin: '10px' }}>
            <PrintTableTitle Title={'Purchase Reports'} />

            <TableHolder>
                <table>
                    <thead>
                        <tr>
                            <th>SL. No</th>
                            <th>DATE</th>
                            <th>SC Number</th>
                            <th>SUPPLIER ID</th>
                            <th>SHIPPING DATE</th>
                            <th>BILL AMOUNT</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map((item, i) => (
                            <tr key={i}>
                                <td>{i + 1}</td>
                                <td>{new Date(item.date).toLocaleDateString('en-GB')}</td>
                                <td>{item.sc_no}</td>
                                <td>{item.Customer_No}</td>
                                <td>{item.shipping_date}</td>
                                <td>{item.factory_bill_amt}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </TableHolder>
            <br />
        </div>
    )
}

export default ParintTable