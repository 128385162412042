import React, { useEffect, useState } from 'react'
import { Form, Col } from 'antd'
import { Row } from '../../../Components/Row';
import { Select } from '../../../Components/Form/Select'
import { CustomSelect } from '../../../Components/Form/CustomSelect'
import Input from '../../../Components/Form/Input';
import Flex from '../../../Components/Flex';
import Button from '../../../Components/Form/Button';
import { useDispatch, useSelector } from 'react-redux';
import { getProfit, selectAllProfit } from '../ProfLosSlice';
import AddCurrency from '../../AddModules/AddCurrency';
import { Modal } from '../../../Components/Modal';
import { getAllCurrencies, getCurrency } from '../../AddModules/AddModulesSlice';
import { toast } from 'react-toastify';
import { CustomInputNumber } from '../../../Components/Form/CustomInputNumber';
import request from '../../../utils/request';

const ProfitorLoss = ({ handleRecord, FormExternalClose, CloseHanOK, formReset }) => {

    // ----- Define Form
    const [form] = Form.useForm();

    const dispatch = useDispatch()

    const [profit, setProfit] = useState([])

    const [width, setWidth] = useState(0)


    // ======  Modal Open ========
    const [isModalOpen, setIsModalOpen] = useState(false);

    // ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);

    const [selectedSC, setSelectedSC] = useState({})

    const [allCurrency, setAllCurrency] = useState([])

    const [trigger, setTrigger] = useState(0)

    const [triggers, setTriggers] = useState(0)

    const [addNewCurrency, setaddNewCurrency] = useState()

    const POST_PROFIT = 'profit/add_profit/'

    const onFinish = (value) => {

        if (value.id) {
            EditProfitLos(value, handleRecord.id)
        }
        else {
            PostProfit(value)
        }
    };

    const onFinishFailed = (errorInfo) => {
        toast.error('Please Fill the Details')
    };


    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onReset = () => {
        form.resetFields();
        setTrigger((trigger) => trigger + 1);
        handleSelect()
    };

    const invoiceno = profit?.map(mob => ({ label: mob.sc_no, value: mob.id }));

    const handleSelect = (value) => {
        const SelecteProf = profit?.find((mem) => mem.id === value)
        setSelectedSC(SelecteProf);
        setTriggers(triggers + 1)
        calculatePortBalance();
    }
    useEffect(() => {
        dispatch(getProfit())
    }, [triggers])

    useEffect(() => {
        form.setFieldsValue({ invoice_amt: selectedSC?.customer_bill_amt })
        form.setFieldsValue({ factory_amount: selectedSC?.factory_bill_amt })
        form.setFieldsValue({ invoice_amt_currency: selectedSC?.currency })
        form.setFieldsValue({ factory_amount_currency: selectedSC?.factory_currency })

    }, [selectedSC])

    const selectoption = useSelector(selectAllProfit)

    useEffect(() => {
        setProfit(selectoption)
    }, [selectoption])

    useEffect(() => {
        form.setFieldsValue({ local_charge_currency: addNewCurrency })
        form.setFieldsValue({ shipping_charge_sea_currency: addNewCurrency })
    }, [addNewCurrency])


    const handleCurrency = () => {
        setWidth(400)
        setTrigger(trigger + 1)
        setModalTitle("Add Currency");
        setModalContent(<AddCurrency setaddNewCurrency={setaddNewCurrency} ProfitTrigger={trigger} AddCurrenynameGet={AddCurrencyData} />);
        showModal();
    };

    const AddCurrencyData = () => {
        handleOk()
        dispatch(getCurrency())
    }

    // ========= post in profitloss ===============

    const PostProfit = (values) => {
        request.post(`${POST_PROFIT}`, values)
            .then(function (response) {
                form.resetFields();
                toast.success('Add Successfully')
                dispatch(getProfit())
            })
            .catch(function (error) {
                toast.error('Failed');
            })
    }

    // =====  get all currency  ======
    const Curr = useSelector(getAllCurrencies)

    useEffect(() => {
        dispatch(getCurrency())
    }, [])

    useEffect(() => {
        setAllCurrency(Curr)
    }, [Curr])

    const currencySelect = allCurrency.map(val => ({
        label: val.rate,
        value: val.rate
    }))


    // ============  Edit record datas ==============

    const EDITPROFT = 'profit/edit_profit'
    const EditProfitLos = (values, id) => {
        request.put(`${EDITPROFT}/${id}/`, values)
            .then(function (response) {

                if (response.status === 200) {
                    form.resetFields();
                    CloseHanOK()
                    FormExternalClose()
                    toast.success("Add Successfully")
                    dispatch(getProfit())
                }
                else {
                    toast.error("Faild")
                }
            })
            .catch(function (error) {
                toast.error("Faild")
            });
    }

    useEffect(() => {
        form.setFieldsValue(handleRecord)
    }, [handleRecord, formReset])


    // ============ calculations ==================

    const calculatePortBalance = () => {
        const expense = form.getFieldValue('local_charge') || 0;
        const factryamt = form.getFieldValue('factory_amount') || 0;
        const exchangerate = form.getFieldValue('exchange_rate');
        const shippingChange = form.getFieldValue('shipping_charge_sea');

        let subTotal = parseFloat(expense) + parseFloat(factryamt);


        let TatalPaidFactory = subTotal / exchangerate;

        let Total = TatalPaidFactory + parseFloat(shippingChange)

        if (expense < 0) {
            form.resetFields(['local_charge']);
            subTotal = parseFloat(form.getFieldValue('sub_total'));
        }
        else if (exchangerate < 0) {
            form.resetFields(['exchange_rate']);
            TatalPaidFactory = parseFloat(form.getFieldValue('Total_paid_to_factory'));
        }
        else if (shippingChange < 0) {
            form.resetFields(['shipping_charge_sea']);
            Total = parseFloat(form.getFieldValue('total'));
        }

        form.setFieldsValue({ sub_total: parseFloat(subTotal).toFixed(2) })
        form.setFieldsValue({ Total_paid_to_factory: parseFloat(TatalPaidFactory).toFixed(2) })
        form.setFieldsValue({ total: parseFloat(Total).toFixed(2) })
    };


    return (
        <>
            <Form
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Row space={[24, 24]}>
                    <Col span={12} md={12} >
                        {handleRecord ?
                            (<Input
                                label={"Invoice No"}
                                placeholder={"Invoice No"}
                                name={"invoice_no"}
                                disabled
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Enter Invoice No !",
                                    },
                                ]}
                            />) :
                            (
                                <Select
                                    label={"Choose Invoice No"}
                                    placeholder={"Choose Invoice No"}
                                    name={"invoice_no"}
                                    onChange={handleSelect}
                                    options={invoiceno}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please select Invoice No !",
                                        },
                                    ]}
                                />
                            )
                        }

                    </Col>


                    <Input name={'id'} display={'none'} />

                </Row><br />
                <Row space={[24, 24]}>
                    <Col span={12} md={12}>
                        <Input disabled
                            label={"Invoice Amount"}
                            placeholder={"Invoice Amount"}
                            name={"invoice_amt"}
                        />
                    </Col>
                    <Col span={12} md={12}>
                        <Input
                            width={"100px"}
                            disabled
                            placeholder={"Currency"}
                            label={"Currency"}
                            name={"invoice_amt_currency"}
                        />
                    </Col>

                    <Col span={12} md={12}>
                        <CustomInputNumber
                            placeholder={"Expense"}
                            label={"Expense"}
                            name={"local_charge"}
                            onChange={calculatePortBalance}
                            precision={2}
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter Expense !",
                                },
                            ]}
                        />
                    </Col>
                    <Col span={12} md={12}>
                        <CustomSelect
                            width={"100px"}
                            onButtonClick={handleCurrency}
                            placeholder={"$"}
                            name={"local_charge_currency"}
                            label={"Currency"}
                            options={currencySelect}
                            rules={[
                                {
                                    required: true,
                                    message: "Please select Currency !",
                                },
                            ]}
                        />
                    </Col>
                    <Col span={12} md={12}>
                        <CustomInputNumber
                            precision={2}
                            disabled
                            placeholder={"Factory Amount"}
                            label={"Factory Amount"}
                            name={"factory_amount"}
                        />
                    </Col>
                    <Col span={12} md={12}>
                        <Input
                            width={"100px"}
                            disabled
                            placeholder={"Currency"}
                            label={"Currency"}
                            name={"factory_amount_currency"}

                        />
                    </Col>
                </Row>
                <Row space={[24, 24]}>
                    <Col span={12} md={12} >
                        <Input
                            disabled
                            label={"Sub Total"}
                            placeholder={"Sub Total"}
                            name={"sub_total"}
                        />
                    </Col>
                </Row>
                <Row space={[24, 24]}>
                    <Col span={12} md={12} >
                        <Input
                            label={"Exchange Rate"}
                            placeholder={"Exchange Rate"}
                            name={"exchange_rate"}
                            onChange={calculatePortBalance}
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter Exchange Rate !",
                                }
                            ]}
                        />
                    </Col>
                </Row>
                <Row space={[24, 24]}>
                    <Col span={12} md={12} >
                        <Input
                            label={"Total Paid To Factory"}
                            disabled
                            defaultValue={0}
                            placeholder={"Total paid To Factory"}
                            name={"Total_paid_to_factory"}
                        />
                    </Col>
                </Row>
                <Row space={[24, 24]}>
                    <Col span={12} md={12}>
                        <Input
                            label={"Shipping Charge"}
                            placeholder={"Shipping Charge"}
                            name={"shipping_charge_sea"}
                            onChange={calculatePortBalance}
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter Shipping Charge !",
                                },
                            ]}
                        />
                    </Col>
                    <Col span={12} md={12}>
                        <CustomSelect
                            width={"100px"}
                            onButtonClick={handleCurrency}
                            placeholder={"$"}
                            name={"shipping_charge_sea_currency"}
                            label={"Currency"}
                            options={currencySelect}
                            rules={[
                                {
                                    required: true,
                                    message: "Please select Currency !",
                                },
                            ]}
                        />

                    </Col>
                </Row>
                <Row space={[24, 24]}>
                    <Col span={12} md={12} >
                        <Input
                            disabled
                            label={"Total"}
                            placeholder={"Total"}
                            defaultValue={0}
                            name={"total"}
                        />
                    </Col>
                </Row>
                <Row space={[24, 24]}>
                    <Col span={12} md={8}>
                        <Input
                            label={"ETA"}
                            placeholder={"ETA"}
                            name={"eta"}
                            rules={[
                                {
                                    required: true,
                                    message: "Please select ETA !",
                                },
                            ]}
                        />
                    </Col>
                    <Col span={12} md={8}>
                        <Input
                            label={"Status"}
                            placeholder={"Status"}
                            name={"label_status"}
                            rules={[
                                {
                                    required: true,
                                    message: "Please select Status !",
                                },
                            ]}
                        />
                    </Col>
                    <Col span={12} md={8}>
                        <Input
                            label={"Forwarder Name"}
                            placeholder={"Forwarder Name"}
                            name={"forwarder_name"}
                            rules={[
                                {
                                    required: true,
                                    message: "Please select Forwarder Name !",
                                }
                            ]} />
                    </Col>
                </Row>

                <Flex center={"true"} gap={"20px"} margin={"20px 0"}>
                    <Button.Primary text={"Submit"} htmlType={"submit"} />

                    {handleRecord ?
                        (
                            <Button.Danger text={"CANCEL"} onClick={() => CloseHanOK()} />
                        )
                        : (
                            <Button.Danger text={"Reset"} onClick={() => onReset()} />
                        )}
                </Flex>
            </Form>
            <Modal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel}
                width={width} modalTitle={modalTitle} modalContent={modalContent} />
        </>
    )
}

export default ProfitorLoss