import React, { useEffect, useState } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { authenticated } from '../config/user'
import Flex from '../../Components/Flex'
import styled from 'styled-components'
import { DashLayout } from '../../layout/DashLayout'
import { selectCurrentRole, selectCurrentSuper, selectCurrentToken } from '../../Modules/Auth/authSlice'
import { useSelector } from 'react-redux'

const PageFlex = styled(Flex)`
  overflow: hidden;
  `
const AuthPage = ({ isAuthenticated }) => {
  const [routeAuth, setRouteAuth] = useState([])

  const token = useSelector(selectCurrentToken);
  const Role = useSelector(selectCurrentRole);
  const Super_r = useSelector(selectCurrentSuper)

  console.log(Role, isAuthenticated)
  console.log(Super_r, isAuthenticated)

  const navigate = useNavigate()

  useEffect(() => {
    if (isAuthenticated) {

      if (Role === 'Sales') {
        setRouteAuth(authenticated)
        navigate('/sales')
      }
      else if (Role === 'Purchase') {
        setRouteAuth(authenticated)
        navigate('/viewconsign')
      }
      else if (Role === 'Invoice') {
        setRouteAuth(authenticated)
        navigate('/viewconsign')
      }
      else if (Role === 'Packing') {
        setRouteAuth(authenticated)
        navigate('/viewconsign')
      }
      else if (Super_r) {
        setRouteAuth(authenticated)
        navigate('/')
      }
      else {
        setRouteAuth(authenticated)
        navigate('/')
      }

    }
    else {
      console.log(isAuthenticated)
      navigate('/signin')
    }

  }, [isAuthenticated])

  return (
    <PageFlex>
      {isAuthenticated && (
        <DashLayout>
          <Routes>
            {routeAuth.map(({ routePath, Component }) => {
              return (
                <Route
                  key={routePath}
                  path={routePath}
                  element={<Component />}
                ></Route>
              )
            })}
          </Routes>
        </DashLayout>
      )}
    </PageFlex>
  )
}
   
export default AuthPage
