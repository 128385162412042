import React, {useEffect, useState } from 'react'
import { Table } from '../../../../Components/Table';
import Flex from '../../../../Components/Flex';
import { FiEdit } from "react-icons/fi";
import dayjs from 'dayjs';
import { TopTitle } from '../../../../Components/Form/TopTitle';
import AddUser from '../../AddUser/Partials/AddUser';
import { Modal } from '../../../../Components/Modal';
import Input from '../../../../Components/Form/Input';
import Label from '../../../../Components/Form/Label';
import request from '../../../../utils/request';
import { TableIconHolder } from '../../../../Components/Styled';
import { THEME } from '../../../../theme';
import { AiFillLock, AiFillUnlock } from 'react-icons/ai';
import { CustomTag } from '../../../../Components/CustomTag';
import { useDispatch, useSelector } from 'react-redux';
import { getUnitError, getUnitStatus, getUser, selectAllUser } from '../../UserSlice';
import { CommonLoading } from '../../../../Components/CommonLoading';

const TableDetails = () => {

  // ----------  Form Reset UseState ---------
  const [formReset, setFormReset] = useState(0);

  const [dataSource, setDataSource] = useState([]);

  const [usercheck, setUsercheck] = useState();

  // ===========  Modal ======
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null)

  const [searchText, setSearchText] = useState([]);

  const URLE = 'user/g_user_Enable'

  const URLD = 'user/g_user_disable'

  const dispatch = useDispatch()

  const AllUser = useSelector(selectAllUser)

  const UserStatus = useSelector(getUnitStatus)

  const UserError = useSelector(getUnitError)

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const FormExternalClose = () => {
    handleOk();
    dispatch(getUser())
  };

  useEffect(() => {
    dispatch(getUser())
  }, [])

  useEffect(() => {
    setDataSource(AllUser)
  }, [AllUser])

  const handleChage = (record) => {
    Getpatch(record);
  };

  const Getpatch = (values) => {
    if (values.status === 'Disabled') {
      request.patch(`${URLE}/${values.id}`)
        .then(function (response) {
          dispatch(getUser())
        })
        .catch(function (error) {
          console.error( error);
        });
    }
    else {
      request.patch(`${URLD}/${values.id}`)

        .then(function (response) {
          dispatch(getUser())
        })
        .catch(function (error) {
          console.error( error);
        });
    }
  }

  const UserUpadate = () => {
    setUsercheck(usercheck + 1)
  }


  const columns = [
    {
      title: 'S.No',
      render: (value, item, index) => index + 1,
    },
    {
      title: 'Date',
      dataIndex: 'created_at',
      render: (date) => {
        return dayjs(date).format('DD\\MM\\YYYY');
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      filteredValue: searchText ? [searchText] : null,
      onFilter: (value, record) => {
        return String(record.name).toLowerCase().includes(value.toLowerCase()) ||
          String(record.name).includes(value.toUpperCase());
      },
    },
    {
      title: 'Email ID',
      dataIndex: 'email'
    },
    {
      title: 'Role',
      dataIndex: 'user_role'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status, i) => {
        const displayStatus = status === 'Enabled' ? 'Enabled' : 'Disabled';
        return (
          <>
            <CustomTag bordered={true} color={status === 'Enabled' ? 'success' : 'error'} title={displayStatus} />
          </>
        )
      },
    },
    {
      title: 'Action',
      render: (record) => {
        return (
          <Flex spaceEvenly>
            <TableIconHolder color={THEME.blue} size={'22px'} onClick={() => EditProduct(record)}>
              <FiEdit />
            </TableIconHolder>
            {record?.status === 'Enabled' ?
              <TableIconHolder color={THEME.secondary_color_light} size={'22px'}>
                <AiFillUnlock onClick={() => handleChage(record)} />
              </TableIconHolder> : <TableIconHolder color={THEME.green} size={'22px'}>
                <AiFillLock onClick={() => handleChage(record)} />
              </TableIconHolder>}
          </Flex>
        )

      }
    },
  ]

  let content;

  if (UserStatus === 'loading') {
    content = <CommonLoading />
  } else if (UserStatus === 'succeeded') {
    const rowKey = (dataSource) => dataSource.id;
    content = <Table columns={columns} data={dataSource} rowKey={rowKey} />
  } else if (UserStatus === 'failed') {
    content = <h2>{UserError}</h2>
  }

  const EditProduct = (record) => {
    setFormReset(formReset + 1);
    setModalTitle("Edit User");
    setModalContent(
      <AddUser
        GetUser={FormExternalClose} UserUpadate={UserUpadate}
                formReset={formReset}
                handleRecord={record} CloseHanOK={handleOk} 
      />
    );
    showModal();
  }



  return (
    <div>
      <TopTitle Heading={'User List'} />

      <Flex end={true} centervertically={true}>
        <Label>Search by Name :&nbsp;</Label>
        <Input
          placeholder="Search by Name"
          value={searchText}
          onSearch={handleSearch}
          onChange={(e) => handleSearch(e.target.value)} />
      </Flex>
      <Modal
        isVisible={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        width={800}
        modalTitle={modalTitle}
        modalContent={modalContent}
      />
      {content}

    </div>
  )
}

export default TableDetails