import React from 'react'
import TableDetails from './Partials/TableDetails'
import { TopTitle } from '../../../Components/Form/TopTitle'

const ViewTableUser = () => {
    return (
        <div>
            <TableDetails />
        </div>
    )
}

export default ViewTableUser