import React from 'react'
import { Row as AntdRow } from 'antd'

export const Row = ({ space, style, children }) => {

  const rowStyle = {
    margin: '0px',
    ...style, // Merge the passed style prop
  };

  return (
    <AntdRow gutter={space} style={rowStyle}>
      {children}
    </AntdRow>
  )
}


// const StyledRow = styled(AntdRow)`
//     margin-left:0 !important;
//     margin-right:0 !important;
// `;