import { Menu } from "antd";
import { useNavigate } from 'react-router-dom';
import { BiSitemap } from "react-icons/bi";
import { useEffect, useState } from "react";
import { ImProfile } from "react-icons/im"
import { TbReportSearch } from "react-icons/tb"
import { GiProfit } from 'react-icons/gi'
import { CgUserlane } from 'react-icons/cg'
import { HiShoppingCart } from "react-icons/hi"
import { FaShoppingCart, FaUserEdit } from 'react-icons/fa'
import { UsergroupAddOutlined } from '@ant-design/icons';
import { AiOutlineUserAdd } from "react-icons/ai";
import { MdPointOfSale } from "react-icons/md";
import { useSelector } from "react-redux";
import { selectCurrentRole, selectCurrentSuper } from "../../Modules/Auth/authSlice";

function getItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label,
        type,
    };
}


export const SideMenu = () => {

    const Role = useSelector(selectCurrentRole);

    const Super_r = useSelector(selectCurrentSuper);

    const [menuItems, setMenuItems] = useState([])


    const rootSubinmenuKeys = ['sub1', 'sub2', 'sub3', 'sub4', 'sub5', 'sub6', 'sub7'];

    const [openKeys, setOpenKeys] = useState(['sub1']);

    const onOpenChange = (keys) => {
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
        if (rootSubinmenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };

    const MaserItems = [
        getItem('Dashboard', '', <BiSitemap />),

        getItem('Profile', 'sub1', <ImProfile />, [
            getItem('Add Profile', 'headoffice_profile'),
            getItem('View Profile', 'ViewDetailsProfile'),
        ]),

        getItem('Users', 'sub2', <AiOutlineUserAdd />, [
            getItem('Users', 'Users'),
            getItem('View Users', 'viewusers'),
        ]),

        getItem('Product', 'sub3', <FaShoppingCart />, [
            getItem('Add Product', 'Addproduct'),
            getItem('View Product', 'viewproduct'),
            getItem('Options', 'optionsdetails'),
        ]),

        getItem('Factory', 'sub4', <MdPointOfSale />, [
            getItem('Add Factory', 'AddFactory'),
            getItem('View Factory', 'viewFactory'),
        ]),

        getItem('Customers', 'sub5', <FaUserEdit />, [
            getItem('Add Customers', 'Addcustomers'),
            getItem('View Customers', 'viewcustomers'),
        ]),

        getItem('Quotation', 'quotation', <UsergroupAddOutlined />),

        getItem('Profit or Loss', 'sub6', <HiShoppingCart />, [
            getItem('Add Details', 'profitloss'),
            getItem('View Details', 'view'),

        ]),

        getItem('Consign', 'sub7', <CgUserlane />, [
            getItem('Add Consign', 'sales'),
            getItem('View Consign', 'viewconsign'),
        ]),

        getItem('Unpaid Bills', 'sub8', <GiProfit />, [
            getItem('Customer Bills', 'table'),
            getItem('Factory Bills', 'factorybill'),
        ]),

        getItem('Reports', 'sub9', <TbReportSearch />, [
            getItem('Sale Confirmation', 'salereport'),
            getItem('Purchase Order', 'purchasereport'),
            getItem('Invoice Report', 'invoicereport'),
            getItem('Packing Report', 'packreport'),
            getItem('Finance Report', 'financereport'),
        ]),
    ];

    const SalesItems = [
        getItem('Product', 'sub1', <FaShoppingCart />, [
            getItem('Add Product', 'Addproduct'),
            getItem('View Product', 'viewproduct'),
        ]),

        getItem('Customers', 'sub2', <FaUserEdit />, [
            getItem('Add Customers', 'Addcustomers'),
            getItem('View Customers', 'viewcustomers'),
        ]),

        getItem('Add Consign', 'sales', <CgUserlane />),
        getItem('View Consign', 'viewconsign', <TbReportSearch />),

        getItem('Quotation', 'quotation', <UsergroupAddOutlined />),

        getItem('Report', 'salereport', <TbReportSearch />),

    ];

    const PurchaseItems = [

        getItem('Factory', 'sub4', <MdPointOfSale />, [
            getItem('Add Factory', 'AddFactory'),
            getItem('View Factory', 'viewFactory'),
        ]),

        getItem('View Consign', 'viewconsign', <GiProfit />),

        getItem('Report', 'purchasereport', <TbReportSearch />),

    ];

    const InvoiceItems = [

        getItem('View Consign', 'viewconsign', <GiProfit />),

        getItem('Report', 'invoicereport', <TbReportSearch />),

    ];

    const PackingItems = [

        getItem('View Consign', 'viewconsign', <GiProfit />),

        getItem('Packing Report', 'packreport', <TbReportSearch />),

    ];


    useEffect(() => {
        if (Role === 'Master1') {
            setMenuItems(MaserItems)
        }
        else if (Role === 'Master2') {
            setMenuItems(MaserItems)
        }
        else if (Role === 'Sales') {
            setMenuItems(SalesItems)
        }
        else if (Role === 'Purchase') {
            setMenuItems(PurchaseItems)
        }
        else if (Role === 'Invoice') {
            setMenuItems(InvoiceItems)
        }
        else if (Role === 'Packing') {
            setMenuItems(PackingItems)
        }
        else if (Super_r === true) {
            setMenuItems(MaserItems)
        }
    }, [Role, Super_r])


    const navigate = useNavigate();

    const onClick = ({ key }) => {

        if (key === null) {
        }
        else {
            navigate(`${key}/`)
        }
    }

    return (
        <>
            <Menu
                onClick={onClick}
                openKeys={openKeys}
                onOpenChange={onOpenChange}
                defaultSelectedKeys={['1']}
                mode="inline"
                items={menuItems}
            />
        </>
    )
}

