import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import request from "../../utils/request";

const initialState = {
    profloss: [],
    proftable:[],
    status: 'idle',  // 'idle' | 'loading' | 'succeeded' | 'failed'  
    error: null
}

export const getProfit = createAsyncThunk(
    "profit/Get",
    async () => {
        try {
            const response = await request.get('profit/get_invoice_no/');
            return [...response.data];
        }
        catch (error) {
            // return error.message
            // rejectWithValue(error.message);
            throw error;
        }
    }
);
export const getProfitTable = createAsyncThunk(
    "profitTable/Get",
    async () => {
        try {
            const response = await request.get('profit/view_profit/');
            return [...response.data];
        }
        catch (error) {
            throw error;
        }
    }
);

const profitSlice = createSlice({
    name: 'proflos',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProfit.fulfilled, (state, action) => {
                state.profloss = action.payload;
            })
            .addCase(getProfitTable.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(getProfitTable.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.proftable = action.payload;
            })
            .addCase(getProfitTable.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
})

export const selectAllProfit = (state) => state.profit.profloss

// ============ Table get ==============

export const selectProfittable = (state) => state.profit.proftable
export const getProfitStatustable = (state) => state.profit.status
export const getProfitErrortable = (state) => state.profit.error

export const { reducer } = profitSlice;

export default profitSlice.reducer


