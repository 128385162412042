import React from 'react'
import AddUser from './Partials/AddUser'
import { CustomCard } from '../../../Components/CustomCard'
import { TopTitle } from '../../../Components/Form/TopTitle'

const AddUserMain = () => {
    return (
        <div>
            <TopTitle Heading={'Add User'} />
            <CustomCard>
                <AddUser />
            </CustomCard>
        </div>
    )
}

export default AddUserMain