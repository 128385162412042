import React from 'react'
import { TopTitle } from '../../../Components/Form/TopTitle'
import { CustomCard } from '../../../Components/CustomCard'
import { InvoiceEntryTable } from './InvoiceEntryPage'


const InvoiceMain = () => {
    return (
        <div>
            <TopTitle Heading={'Create Invoice'} />
            <CustomCard>
                <InvoiceEntryTable />
            </CustomCard>
        </div>
    )
}

export default InvoiceMain