import React, { useEffect, useState } from 'react'
import { Table } from '../../../../Components/Table';
import { Modal } from '../../../../Components/Modal';
import { THEME } from '../../../../theme';
import Flex from '../../../../Components/Flex';
import { CustomPopconfirm } from '../../../../Components/CustomPopConfirm';
import { TableIconHolder } from '../../../../Components/Styled';
import { MdDeleteForever } from 'react-icons/md';
import request from '../../../../utils/request';
import { toast } from 'react-toastify';
import Label from '../../../../Components/Form/Label';
import Input from '../../../../Components/Form/Input';

const Country = () => {


    //   ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const COUNTRY_GET_URL = 'choice/add_country/'

    const DEL_COUNTRY_URL = 'choice/edit_country'

    const [contryArray, setContryArray] = useState([]); 

    const [formUpdate, setFormUpdate] = useState(0);
    
    const [searchText, setSearchText] = useState([]);

    const handleSearch = (value) => {
        setSearchText(value);
    };

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        GetCountry()
    }, [formUpdate])


    const DeleteProduct = (record) => {
        request.delete(`${DEL_COUNTRY_URL}/${record?.id}/`).then((response) => {
            GetCountry()

        }).catch(error => {
            if (error.response.status === 401) {
                toast.warning('This product used for Transactions')
            }
            else {
                toast.error("Something Went Wrong")
            }
        });
    };

    // ======  PopConfirm Function ====

    const handleConfirm = (record) => {
        DeleteProduct(record);
    };

    const GetCountry = () => {
        request.get(`${COUNTRY_GET_URL}`)
            .then(function (response) {
                setContryArray(response.data);
            })
            .catch(function (error) {
                console.log(error)
            });
    }

    const columns = [
        {
            title: 'SI.No',
            render: (value, item, index) => index + 1,
        },
        {
            title: 'Country',
            dataIndex: 'country',
            filteredValue: searchText ? [searchText] : null,
            onFilter: (value, record) => {
                return String(record.country).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.country).includes(value.toUpperCase());
            },
        },
        {
            title: 'Action',
            render: (record) => {
                return (
                    <Flex center={"true"} gap={'10px'}>
                        <CustomPopconfirm
                            record={record}
                            confirm={handleConfirm}
                            title={"Delete the Country"}
                            description={"Are you sure to delete this Country ?"}>
                            <TableIconHolder color={THEME.red} size={'22px'}>
                                <MdDeleteForever />
                            </TableIconHolder>
                        </CustomPopconfirm>
                    </Flex>
                )
            }

        },
    ]

    return (
        <div>
            <Flex end={true} centervertically={true}>
                <Label>Search by Country Name&nbsp;: &nbsp;</Label>
                <Input
                    placeholder="Search by Country"
                    value={searchText}
                    onSearch={handleSearch}
                    onChange={(e) => handleSearch(e.target.value)} />
            </Flex>
            <Table columns={columns} data={contryArray} />
            <Modal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={1000} modalTitle={modalTitle} modalContent={modalContent} />

        </div>
    )
}

export default Country