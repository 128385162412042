import { Col, Form } from 'antd';
import React, { useEffect } from 'react'
import { toast } from 'react-toastify';
import Button from '../../Components/Form/Button';
import Input from '../../Components/Form/Input';
import { Row } from '../../Components/Row';
import Flex from '../../Components/Flex';
import request from '../../utils/request';
import { useForm } from 'antd/es/form/Form';

export const AddContainer = ({ setcontainerAdd, QuotationTrigger, handleAddContainer }) => {

    const [form] = useForm()

    useEffect(() => {
        form.resetFields()
    }, [QuotationTrigger])


    const onReset = () => {
        form.resetFields();
        if (handleAddContainer) {
            handleAddContainer()
        }
    }

    const onFinish = (values) => {
        AddContainerPost(values)
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const AddContainerPost = (values) => {
        request.post('choice/add_container/', values)
            .then(response => {

                if (response.status == 200) {
                    if (setcontainerAdd) {
                        setcontainerAdd(response.data.container_name)
                    }
                    form.resetFields();
                    toast.success('Container Added Successfully')
                    if (handleAddContainer) {
                        handleAddContainer()
                    }
                }
                else if (response.status == 226) {
                    toast.warn('Container Already Added')
                }

                else {
                    toast.error('Failed');
                }

            })
            .catch(function (error) {
                toast.error('Failed');
            });
    }

    return (
        <Form
            name='AddContainer'
            form={form}
            labelCol={{
                span: 24,
            }}
            wrapperCol={{
                span: 24,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off">
            <Row space={[24, 24]}>

                <Col span={24} md={24}>
                    <Input label={'Add Container'} placeholder={'Enter container'} name={'container_name'}
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter Container!',
                            }
                        ]}
                    />
                </Col>
            </Row>
            <Flex center gap={'20px'} style={{ margin: '30px' }}>
                <Button.Success text={'ADD'} htmlType={'submit'} />
                <Button.Danger text={'RESET'} onClick={onReset} />
            </Flex>
        </Form>
    )
}
