

import React, { useEffect, useState } from 'react'
import { FiEdit } from 'react-icons/fi';
import Flex from '../../../../Components/Flex';
import { Modal } from '../../../../Components/Modal';
import { TableIconHolder } from '../../../../Components/Styled';
import { Table } from '../../../../Components/Table';
import { THEME } from '../../../../theme';
import request from '../../../../utils/request';
import ProfileForm from '../../AddProfile/Partials/ProfileForm';
import ViewDetails from '../../AddProfile/Partials/ViewDetails';
import { CustomTag } from '../../../../Components/CustomTag';
import { useDispatch, useSelector } from 'react-redux';
import { CommonLoading } from '../../../../Components/CommonLoading';
import { getProfile, getProfileError, getProfilesStatus, selectAllProfile } from '../../ProfileSlice';
import { AiFillLock, AiFillUnlock, AiOutlineEye } from 'react-icons/ai';
import { CustomPopconfirm } from '../../../../Components/CustomPopConfirm';
import { MdDelete } from 'react-icons/md';
import { toast } from 'react-toastify';
import dayjs from 'dayjs'

const ViewProfileTable = () => {

    const [dataSource, setDataSource] = useState([]);
    const [productFormReset, setProductFormReset] = useState(0);  //  --  FormReset
    const [productFormUpdate, setProductFormUpdate] = useState(0);    //  --  FormUpdate
    const dispatch = useDispatch();

    const URLE = 'business/profile_default'

    const URLD = 'business/profile_nodefault'

    const DEL_PRODUCT_URL = 'business/business_edit_view'

    const AllProfile = useSelector(selectAllProfile)
    const ProfileStatus = useSelector(getProfilesStatus)
    const ProfileError = useSelector(getProfileError)

    //   ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
        ProductFormCancelRest();
    };
    const handleCancel = () => {
        ProductFormCancelRest();
        setIsModalOpen(false);
    };
    const ProductFormCancelRest = () => {
        setProductFormReset(productFormReset + 1)
    }

    useEffect(() => {
        setDataSource(AllProfile)
    }, [AllProfile])

    const handleConfirm = (record) => {
        DeleteProfile(record);
    };

    const handlePopConfrmCancel = () => {
        console.log('Called');
    };

    const DeleteProfile = (record) => {
        request.delete(`${DEL_PRODUCT_URL}/${record?.id}`)
            .then((response) => {
                dispatch(getProfile());
                toast.success('Deleted Successfuly')
            }).catch(error => {
                console.log(error);
            });
    };


    const columns = [
        {
            title: 'Sl No',
            render: (item, value, index) => index + 1,
        },
        {
            title: 'Date',
            dataIndex: 'created_at',
            render: (date) => {
                return dayjs(date).format('DD\\MM\\YYYY');
            },
        },
        {
            title: 'Company Name',
            dataIndex: 'business_name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
        },
        {
            title: 'Phone',
            dataIndex: 'phone_no',
        },
        {
            title: 'Address',
            dataIndex: 'address',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (status, i) => {
                const displayStatus = status === 'ACTIVE' ? 'ACTIVE' : 'DEACTIVE';
                return (
                    <>
                        <CustomTag bordered={true} color={status === 'ACTIVE' ? 'processing' : 'error'} title={displayStatus} />
                    </>
                )
            },
        },
        {
            title: 'Action',
            render: (record) => {
                return (
                    <Flex center={"true"} gap={'10px'}>
                        {record?.status === "ACTIVE" &&
                            <TableIconHolder color={THEME.gray_dark} size={'22px'} onClick={() => handleChage(record)}>
                                <AiFillLock />
                            </TableIconHolder>
                        }

                        {record?.status === "DEACTIVE" &&
                            <TableIconHolder color={THEME.green} size={'22px'} onClick={() => handleChage(record)}>
                                <AiFillUnlock />
                            </TableIconHolder>
                        }

                        <TableIconHolder color={THEME.green} size={'22px'} onClick={() => onViewProfile(record)}>
                            <AiOutlineEye />
                        </TableIconHolder>

                        <TableIconHolder color={THEME.blue} size={'22px'} onClick={() => onEditProfile(record)}>
                            <FiEdit />
                        </TableIconHolder>

                        <CustomPopconfirm
                            record={record}
                            confirm={handleConfirm}
                            cancel={handlePopConfrmCancel}
                            title={"Delete the Profile List"}
                            description={"Are you sure to delete this Profile List ?"}>
                            <TableIconHolder color={THEME.red} size={'22px'}>
                                <MdDelete />
                            </TableIconHolder>
                        </CustomPopconfirm>
                    </Flex>
                )
            }
        },


    ];
    const onViewProfile = (record) => {
        setModalTitle("View Profile");
        setModalContent(<ViewDetails handlerecord={record} ProductFormUpdateCall={ProductFormUpdateCall} />);
        showModal();
    };
    const onEditProfile = (record) => {
        setModalTitle("Edit Profile");
        setModalContent(<ProfileForm handlerecord={record} productFormReset={productFormReset} ProductFormUpdateCall={ProductFormUpdateCall} handleOk={handleOk} />);
        showModal();
    };

    const ProductFormUpdateCall = () => {
        setProductFormUpdate(productFormUpdate + 1)
    }

    useEffect(() => {
        dispatch(getProfile())
    }, [productFormUpdate])


    const handleChage = (record) => {
        Getpatch(record);
    };

    const Getpatch = (values) => {
        if (values.status === 'DEACTIVE') {
            request.patch(`${URLE}/${values.id}`)

                .then(function (response) {
                    ProductFormUpdateCall();

                })
                .catch(function (error) {
                    console.error('Error updating user:', error);
                });
        }
        else {
            request.patch(`${URLD}/${values.id}`)
                .then(function (response) {
                    ProductFormUpdateCall();
                })
                .catch(function (error) {
                    console.error('Error updating user:', error);
                });
        }
    }

    //=====================Redux using status,loading (Get request)

    let content;

    if (ProfileStatus === 'loading') {
        content = <CommonLoading />
    } else if (ProfileStatus === 'succeeded') {
        const rowKey = (dataSource) => dataSource.id;
        content = <Table columns={columns} data={dataSource} rowKey={rowKey} />
    } else if (ProfileStatus === 'failed') {
        content = <h2>{ProfileError}</h2>
    }

    return (
        <div>
            {content}
            <Modal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={1000} modalTitle={modalTitle} modalContent={modalContent} />
        </div>
    )
}

export default ViewProfileTable