import React from 'react'
import { CardOnBottom, CardOnTop, TableStyle } from '../../SaleReport/Partials/Style';
import { Row } from '../../../../Components/Row';
import { Col, Form } from 'antd';
import { CustomDateRangePicker } from '../../../../Components/Form/CustomDateRangePicker';
import Button from '../../../../Components/Form/Button';
import { Table } from '../../../../Components/Table';
import { useState } from 'react';
import { TbArrowsExchange } from 'react-icons/tb';
import Flex from '../../../../Components/Flex';
import dayjs from 'dayjs';
import request from '../../../../utils/request';
import { useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import ParintTable from './print';
import Input from '../../../../Components/Form/Input';
import { Modal } from '../../../../Components/Modal';
import PaymentDetailsModal from './PaymentDetailsModal';
import CardPayment from './CardPayment';
import { AiFillPrinter, AiOutlineEye } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { getInvoice, selectGetInvoice } from '../../../Sales/SalesSlice';
import { TableIconHolder } from '../../../../Components/Styled';
import { InvoiceView } from './InvoiceView';
import { THEME } from '../../../../theme';
import { selectCurrentRole, selectCurrentSuper } from '../../../Auth/authSlice';

const InvoiceTableReport = () => {

    const [form] = Form.useForm();

    const SEARCH_URL = 'invoice/invoice_details_search/'


    const [passdata, setPassdata] = useState([])
    const [dataSource, setDataSource] = useState([])
    const [dateRange, setDateRange] = useState([]);
    const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY-MM-DD'));

    //   ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalWidth, setModalWidth] = useState(null);

    // ========== Roll hidden ===========

    const Role = useSelector(selectCurrentRole);

    const Super_r = useSelector(selectCurrentSuper);

    const dispatch = useDispatch()

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const [searchText, setSearchText] = useState([]);

    const handleSearch = (value) => {
        setSearchText(value);
    };

    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })


    const onFinish = (values) => {

        const data = {
            "start_date": dateRange[0],
            "end_date": dateRange[1]
        }

        const range = {
            range: dateRange
        }

        PostdateRange(range)
    }

    const onFinishFailed = (errorInfo) => {
        console.log(errorInfo);
    };

    const PostdateRange = (values) => {

        request.post(`${SEARCH_URL}`, values)
            .then(function (response) {
                setDataSource(response.data?.sale)
                setPassdata(response.data?.amount)
            })
            .catch(function (error) {
                console.log(error);
            });

    }


    const handleDateRangeChange = (values) => {
        setDateRange(values);
    };

    const PaymentDetails = (record) => {
        setModalTitle("Payment Details");
        setModalContent(<PaymentDetailsModal invoice={record.id} />);
        showModal();
    };

    const InvoiceReport = async (record) => {
        try {
            const response = await request.get(`invoice/get_print_invoice/${record?.id}`);

            setModalWidth(1200)
            setModalTitle("Invoice Details");
            setModalContent(<InvoiceView record={response.data} />);
            showModal();
            return response.data;
        }
        catch (error) {
            throw error;
        }
    };
    console.log(dataSource,'fgfdgdg');
    const columns = [
        {
            title: 'Sl No',
            render: (item, value, index) => index + 1,
        },
        {
            title: 'Date',
            dataIndex: 'invoice_date',
            render: (date) => {
                return dayjs(date).format('DD\\MM\\YYYY');
            },
        },
        {
            title: 'Invoice No',
            dataIndex: 'invoice_no',
            filteredValue: searchText ? [searchText] : null,
            onFilter: (value, record) => {
                return String(record.invoice_no).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.invoice_no).includes(value.toUpperCase());
            },
        },
        {
            title: 'Customer Id',
            dataIndex: 'customer_id',
        },
        {
            title: 'Sealing Date',
            dataIndex: 'sealing_date',
        },
        {
            title: 'Bill Amount',
            dataIndex: 'grand_total',
            render: (value, record) => `${value} ${record.currency}`,
        },
        {
            title: 'Action',
            render: (record) => {
                return (
                    <Flex gap={'10px'}>
                        <TableIconHolder color={THEME.green} size={'22px'} onClick={() => InvoiceReport(record)}>
                            <AiOutlineEye />
                        </TableIconHolder>

                        {Role === 'Master1' || Role === 'Master2' ? (
                            <Button.Danger text={'Pay Details'} onClick={() => PaymentDetails(record)} />

                        ) : null}

                        {Super_r === true ? (
                            <Button.Danger text={'Pay Details'} onClick={() => PaymentDetails(record)} />

                        ) : null}
                       
                    </Flex>
                )
            }
        }

    ];

    //===========================print view =================================//

    const TablePrintData = dataSource?.filter((item) => {
        // Filter the dataSource based on the searchText
        if (!searchText || typeof searchText !== 'string') return true;

        const searchTextLower = searchText.toLowerCase();
        const searchTextUpper = searchText.toUpperCase();

        // Check if any of the fields match the searchText
        return (
            String(item?.invoice_no).toLowerCase().includes(searchTextLower) ||
            String(item?.invoice_no).includes(searchTextUpper)
        );
    })
        .map((item) => ({
            invoice_date: item?.invoice_date,
            invoice_no: item?.invoice_no,
            customer_id: item?.customer_id,
            sealing_date: item?.sealing_date,
            grand_total: item?.grand_total,
        }));


    //================Table get ============================//
    useEffect(() => {
        dispatch(getInvoice())
    }, [])

    const tableget = useSelector(selectGetInvoice)



    //=========================month start-end format===================

    const CallOnFunction = () => {
        setDataSource(tableget?.sale);

        const MonthStart = new Date(tableget?.date?.month_start);
        const MonthEnd = new Date(tableget?.date?.month_end);
        const dateFormat = 'YYYY/MM/DD';

        const MonthStartDate = dayjs(MonthStart).format(dateFormat);
        const MonthEndDate = dayjs(MonthEnd).format(dateFormat);

        form.setFieldsValue({ range: [dayjs(MonthStartDate, dateFormat), dayjs(MonthEndDate, dateFormat)] });

        setDateRange([tableget?.date?.month_start, tableget?.date?.month_end])

        setPassdata(tableget?.amount)
    }

    useEffect(() => {
        CallOnFunction();
    }, [tableget])

    return (
        <div>
            <CardOnTop>
                <Form
                    form={form}
                    labelCol={{
                        span: 24,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    initialValues={
                        {
                            from_date: dayjs(),
                            to_date: dayjs(),
                            payment_type: 'Cash'
                        }
                    }
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off">
                    <Row space={[24, 24]} style={{ padding: '10px 0' }}>
                        <Col span={24} md={3}>
                            <b>Between</b>&nbsp;&nbsp; <TbArrowsExchange />
                        </Col>

                        <Col span={24} md={12}>
                            <CustomDateRangePicker
                                onChange={handleDateRangeChange}
                                value={dateRange}
                                name={'range'}
                                rules={[{ required: true, message: 'Please Select the Date' }]}
                            />
                        </Col>

                        <Col span={24} md={6}>
                            <Flex>
                                <Button.Primary text={'Submit'} htmlType='submit' />
                            </Flex>
                        </Col>
                        <Col span={24} md={2} >
                            <div style={{ display: "none" }}>
                                <ParintTable componentRef={componentRef} data={TablePrintData} />
                            </div>
                            {
                                TablePrintData?.length > 0 && (
                                    <Button.Primary text={<AiFillPrinter style={{ fontSize: '30px' }} />} onClick={handlePrint} />
                                )
                            }

                        </Col>
                    </Row>

                </Form>
                <CardPayment datas={passdata} />

            </CardOnTop >
            <CardOnBottom>
                <Row space={[24, 24]}>
                    <Col span={24} md={13}>
                        <h1>Transactions :</h1>
                    </Col>
                    <Col span={24} md={4}></Col>
                    <Col span={24} md={7} style={{ marginTop: '25px' }}>
                        <Input
                            placeholder="Search by Invoice No"
                            value={searchText}
                            onSearch={handleSearch}
                            onChange={(e) => handleSearch(e.target.value)} />
                    </Col>

                    <TableStyle>
                        <Table columns={columns} data={dataSource} />
                    </TableStyle>
                </Row>
                <Modal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={1000} modalTitle={modalTitle} modalContent={modalContent} />
            </CardOnBottom>
        </div>
    )
}

export default InvoiceTableReport