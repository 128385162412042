import { Col, Form } from 'antd';
import React, { useEffect } from 'react'
import { TextAreas } from '../../../../Components/Form/TextArea';
import Input from '../../../../Components/Form/Input';
import Button from '../../../../Components/Form/Button';
import Flex from '../../../../Components/Flex';
import { Row } from '../../../../Components/Row';
import request from '../../../../utils/request';
import { toast } from 'react-toastify';

const EditCustomerForm = ({ record, handleCustomerClose, CustomerTrigger }) => {

    const [form] = Form.useForm()

    useEffect(() => {
        form.resetFields()
    }, [CustomerTrigger])

    useEffect(() => {
        form.setFieldsValue(record)
    }, [record, CustomerTrigger])

    const onReset = () => {
        form.resetFields()
        if (handleCustomerClose) {
            form.resetFields();
            handleCustomerClose();
        }
    }

    //===================edit========================//
    const EditCustomers = (record, id) => {
        request.patch(`customer/edit_cutomer/${id}`, record).then((response) => {
            toast.info("Updated Sucessfully")
            handleCustomerClose()
        }).catch(error => {
            console.log(error, 'error');
        });
    }


    const onFinish = (values) => {
        EditCustomers(values, record.id)
    };



    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Form
            form={form}
            labelCol={{
                span: 24,
            }}
            wrapperCol={{
                span: 24,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off">
            <Row space={[24, 24]}>
                <Col span={24} md={12}>
                    <Input label={'Company Name'} placeholder={'Customer/Company Name'}
                        name={'company_name'}
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter Your Companyname!',
                            }
                        ]} />
                </Col>
                <Col span={24} md={12}>
                    <Input label={'Customer ID'} disabled placeholder={'Customer ID'}
                        name={'invoice_no'} />
                </Col>

                <Col span={24} md={12}>
                    <Input label={'FAX number'} placeholder={'FAX number'} name={'fax'} />
                </Col>

                <Col span={24} md={12}>
                    <Input label={'Phone No'} placeholder={'Company Contact Number'} name={'customer_mobile'}
                        rules={[
                            {
                                required: true,
                                message: 'Please enter Phone Number!',
                            },
                        ]} />
                </Col>

                <Col span={24} md={12}>
                    <Input label={'Contact Person'} placeholder={'Contact Person'}
                        name={'contact_person_name'} rules={[
                            {
                                required: true,
                                message: 'Please enter contact person!',
                            }
                        ]} />
                </Col>

                <Col span={24} md={12}>
                    <Input label={'Email id'} name={'email'} type="email" placeholder={"Email ID"} />
                </Col>

                <Col span={24} md={12}>
                    <Input label={'Mobile number'} placeholder={'Contact Person Number'}

                        name={'mobile_number'}
                        rules={[
                            {
                                required: true,
                                message: 'Please enter Phone Number!',
                            }
                        ]} />
                </Col>

                <Col span={24} md={12}>
                    <Input label={'CR NO'} placeholder={'CR NO'} name={'cr_no'}
                        rules={[
                            {
                                required: true,
                                message: 'This field is required!',
                            },
                        ]} />
                </Col>

                <Col span={24} md={12}>
                    <Input label={'IMPORT NO'} placeholder={'IMPORT NO'} name={'import_no'}
                        rules={[
                            {
                                required: true,
                                message: 'This field is required!',
                            },
                        ]} />
                </Col>

                <Col span={24} md={12}>
                    <Input label={'VAT NO'} placeholder={'VAT NO'} name={'vat_no'}
                        rules={[
                            {
                                required: true,
                                message: 'This field is required!',
                            },
                        ]} />
                </Col>

                <Col span={24} md={12}>
                    <TextAreas label={'Address'} placeholder={'Address'} name={'address'} />
                </Col>
            </Row>

            <Flex center={'true'} gap={'20px'} margin={'20px 0'}>
                <Button.Primary text={'Save'} htmlType={'submit'} />
                <Button.Danger text={'Cancel'} onClick={() => onReset()} />
            </Flex>
        </Form>
    )
}

export default EditCustomerForm