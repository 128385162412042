import { Col } from 'antd'
import React, { useEffect, useState } from 'react'
import { DashboardData } from '../../../Assets/DashboardData'
import Flex from '../../../Components/Flex'
import { Row } from '../../../Components/Row'
import { Cards } from './style'
import { useDispatch, useSelector } from 'react-redux'
import { getAllProfitLoss, getAllPurchaseAmount, getAllPurchaseTotal, getAllSaleAmount, getAllSaleCount, getDashboard } from '../../AddModules/AddModulesSlice'

const Cards_Dasboard = () => {

    const [dashData, setDashData] = useState(DashboardData);

    const profitandloss = useSelector(getAllProfitLoss);
    const purchaseamount = useSelector(getAllPurchaseAmount);
    const purchasetotal = useSelector(getAllPurchaseTotal);
    const saleamount = useSelector(getAllSaleAmount);
    const salecount = useSelector(getAllSaleCount);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getDashboard())
        console.log(process.env.NODE_ENV,'process');
    }, [])

    useEffect(() => {

        setDashData((prevData) =>
            prevData.map((item) => {
                switch (item.h1) {
                    case 'Sales Confirmation':
                        return {
                            ...item,
                            Amount: saleamount || 0,
                            Total: salecount || 0,
                        };
                    case 'Purchase Confirmation':
                        return {
                            ...item,
                            Amount: purchaseamount || 0,
                            Total: purchasetotal || 0,
                        };
                    case 'Profit & Loss':
                        return {
                            ...item,
                            Amount: profitandloss || 0,
                        };
                    default:
                        return item;
                }
            })
        );

    }, [profitandloss, purchaseamount, purchasetotal, saleamount, salecount])

    return (
        <div>
            <Row space={[16, 16]} >
                {dashData.map((item, i) => {
                    return (
                        <Col span={24} xs={24} sm={12} md={12} lg={8} key={i}>
                            <div >
                                <Cards>
                                    <Flex spacebetween={'true'}>
                                        <div>
                                            {item.icon}
                                        </div>
                                        <div >
                                            <h2>{item.Total}</h2>
                                            <p>{item.p}</p>
                                        </div>
                                    </Flex>
                                    <Flex spacebetween={'true'}>
                                        <h1 >{item.h1}</h1>
                                        <h4>{item.Amount}</h4>
                                    </Flex>

                                </Cards>
                            </div>
                        </Col>
                    )
                })}
            </Row>

        </div>
    )
}

export default Cards_Dasboard