import React, { Fragment, useEffect, useState } from 'react'
import Flex from '../../../Components/Flex';
import Button from '../../../Components/Form/Button';
import { Table } from '../../../Components/Table';
import { useNavigate, useParams } from 'react-router-dom';
import { Row } from '../../../Components/Row';
import { Col, Form } from 'antd';
import Input from '../../../Components/Form/Input';
import { Select } from '../../../Components/Form/Select';
import request from '../../../utils/request';
import { selectCurrentRole } from '../../Auth/authSlice';
import { useSelector } from 'react-redux';


const Submenu = () => {

    const nevigate = useNavigate();

    const { id } = useParams()

    const [form] = Form.useForm();

    const [searchText, setSearchText] = useState([]);

    const [searchTexts, setSearchTexts] = useState([]);

    const [selectedOption, setSelectedOption] = useState('Customer_No');


    const [confirmation, setConfirmation] = useState([])

    // ========== Roll hidden ===========

    const Role = useSelector(selectCurrentRole);

    const [sales, setSales] = useState(false)
    const [pack, setPack] = useState(false)
    const [invoice, setInvoice] = useState(false)
    const [purchase, setPurchase] = useState(false)

    useEffect(() => {
        if (Role === 'Master1') {
        }
        else if (Role === 'Master2') {
        }
        else if (Role === 'Sales') {
            setSales(false)
            setPurchase(true)
            setInvoice(true)
            setPack(true)
        }
        else if (Role === 'Purchase') {
            setPurchase(false)
            setPack(true)
            setInvoice(true)
            setSales(true)

        }
        else if (Role === 'Invoice') {
            setInvoice(false)
            setPack(true)
            setSales(true)
            setPurchase(true)
        }
        else if (Role === 'Packing') {
            setPack(false)
            setSales(true)
            setInvoice(true)
            setPurchase(true)
        }
    }, [Role])


    const handleSearch = (value) => {
        setSearchText(value);
    };

    const handleSearchs = (value) => {
        setSearchTexts(value);
    };


    const choose = [
        {
            label: "Customer No",
            value: "Customer_No",
        },
        {
            label: "SC No",
            value: "SC_No",
        },
    ];


    const pick = (value) => {
        setSelectedOption(value);
        setSearchText(''); // Clear the searchText state
        setSearchTexts(''); // Clear the searchTexts state
    }

    // ==================== sale confirmation ================

    const TBLDATA = 'consignment/get_consignment/'

    const GetTableDatas = () => {
        request.get(`${TBLDATA}`)
            .then(function (response) {
                setConfirmation(response.data?.consign)
                console.log(response.data,'fgfgfg');
            })
            .catch(function (error) {
                // toast.error('Failed');
            });
    }

    useEffect(() => {
        GetTableDatas()
    }, [])
   
  

    const TableColumn = [
        {
            title: "SI No",
            render: (value, item, index) => index + 1,
        },
        {
            title: "SC.No",
            dataIndex: "sc_no",
            filteredValue: searchTexts ? [searchTexts] : null,
            onFilter: (value, record) => {
                return String(record.sc_no).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.sc_no).includes(value.toUpperCase());
            },
        },
        {
            title: "Customer No",
            dataIndex: "Customer_No",
            filteredValue: searchText ? [searchText] : null,
            onFilter: (value, record) => {
                return String(record.Customer_No).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.Customer_No).includes(value.toUpperCase());
            },
        },
        {
            title: "Date",
            dataIndex: "sale_date",
        },
        {
            title: "Status",
            dataIndex: "status",
        },
        {
            title: "Action",
            render: (record, i) => {
                return (
                    <Flex spaceEvenly={"true"} gap={"10px"}>

                        <Button.Primary text={"View"}
                            onClick={() => nevigate(`/ViewTotalList/${record.id}`)} />

                        {record.status === 'Sale Confirmation Pending' && (
                            record.draft_consign === true ?
                                <Button.Danger text={"Sale Draft"} disabled={sales} onClick={() => nevigate(`/EditSale/${record.sale_confirmation}`)} /> :
                                <Button.Danger text={"PC/PO"} disabled={purchase} onClick={() => nevigate(`/PurchaseMain/${record?.sale_confirmation}`)} />
                        )}
                        {record.status === 'Purchase Order Pending' && (
                            record.draft_purchase_consign === true ?
                                <Button.Danger text={"Purchase Draft"} disabled={purchase} onClick={() => nevigate(`/PurchaseMainWithPoNo/${record?.purchase_order}`)} /> :

                                <Button.Danger text={"PC/PO"} disabled={purchase} onClick={() => nevigate(`/PurchaseMain/${record?.sale_confirmation}`)} />
                        )}
                        {record.status === 'Invoice Pending' && (
                            record.draft_invoice_consign === true ?
                                <Button.Danger text={"Invoice Draft"} disabled={invoice} onClick={() => nevigate(`/InvoiceMainWithInvoice/${record?.invoice}`)} /> :
                                <Button.Danger text={"Invoice"} disabled={invoice} onClick={() => nevigate(`/InvoiceMain/${record?.sale_confirmation}`)} />
                        )}
                        {record.status === 'Packing Pending' && (
                            record.draft_packing_consign === true ?
                                <Button.Danger text={"packing Draft"} disabled={pack} onClick={() => nevigate(`/PackListMainWithPacking/${record?.packing}`)} /> :
                                <Button.Danger text={"Pack"} disabled={pack} onClick={() => nevigate(`/PackListMain/${record?.invoice}`)} />
                        )}
                    </Flex>
                )

            },
        },
    ];


    return (
        <Fragment>
            <Form
                form={form}
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
            >
                <Row space={[24, 24]}>
                    <Col span={0} sm={8} md={12}></Col>
                    <Col span={24} sm={16} md={12}>
                        <Row space={[12, 12]}>
                            <Col span={24} sm={12}>

                                <Select label={"Select by"} options={choose} value={selectedOption} onChange={pick}
                                    placeholder="Search by SC.NO or C. NO"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please select  !",
                                        },
                                    ]}
                                />
                            </Col>
                            <Col span={24} sm={12}>

                                {selectedOption === 'Customer_No' ? (
                                    <Input
                                        placeholder="Search by Customer No"
                                        value={searchText}
                                        label="Search by Customer No"
                                        onSearch={handleSearch}
                                        onChange={(e) => handleSearch(e.target.value)}
                                    />
                                ) : (
                                    <Input
                                        placeholder="Search by SC.NO"
                                        value={searchTexts}
                                        label="Search by SC.NO"
                                        onSearch={handleSearchs}
                                        onChange={(e) => handleSearchs(e.target.value)}
                                    />
                                )}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
            <Table columns={TableColumn} data={confirmation} />
        </Fragment>
    )
}

export default Submenu;