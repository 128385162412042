import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import request from "../../utils/request";

const initialState = {
    saleList: [],
    saleproduct: [],
    saleunit:[],
    invoice:[],
    status: 'idle',  // 'idle' | 'loading' | 'succeeded' | 'failed'  
    error: null
}

export const getSales = createAsyncThunk(
    "Sales/Get",
    async () => {
        try {
            const response = await request.get('sale_confirmation/sale_confirm_details_search/');
            return response.data;
        }
        catch (error) {
            throw error;
        }
    }
);
export const getInvoice = createAsyncThunk(
    "Invoice/Get",
    async () => {
        try {
            const response = await request.get('invoice/invoice_details_search/');
            return response.data;
        }
        catch (error) {
            throw error;
        }
    }
);

export const getSaleProduct = createAsyncThunk(
    "saleproduct/Get",
    async () => {
        try {
            const response = await request.get('sale_confirmation/get_detail_sale_confirmation/');
            return [...response.data];
        }
        catch (error) {
    
            throw error;
        }
    }
);
export const getSaleUnit = createAsyncThunk(
    "saleunit/Get",
    async () => {
        try {
            const response = await request.get('choice/add_measurement/');
            return [...response.data];
        }
        catch (error) {
    
            throw error;
        }
    }
);

const salesSlice = createSlice({
    name: 'sales',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSales.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(getSales.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.saleList = action.payload;
            })
            .addCase(getSales.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(getSaleProduct.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(getSaleUnit.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.saleunit = action.payload;
            })
            .addCase(getInvoice.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.invoice = action.payload;
            })
    }
})

export const selectGetSaleProduct = (state) => state.sales.saleproduct
export const selectGetSaleUnit = (state) => state.sales.saleunit
export const selectAllSales = (state) => state.sales.saleList
export const getSalesStatus = (state) => state.sales.status
export const getSalesError = (state) => state.sales.error
export const selectGetInvoice = (state) => state.sales.invoice

export const { reducer } = salesSlice;

export default salesSlice.reducer


