import React,{Fragment} from 'react'
import { useNavigate } from 'react-router-dom';
import Flex from '../../Components/Flex';
import Button from '../../Components/Form/Button';
import { Table } from '../../Components/Table';
import { TopTitle } from '../../Components/Form/TopTitle';


const Purchase = () => {

    const nevigate = useNavigate();

    const TableColumn = [
        {
            title: "SI No",
            render: (value, item, index) => index + 1,
        },
        {
            title: "S.C.No",
            dataIndex: "sc_no",
        },
        {
            title: "Customer No",
            dataIndex: "customer_no",
        },
        {
            title: "Date",
            dataIndex: "date",
        },
        {
            title: "Status",
            dataIndex: "status",
        },
        {
            title: "Action",
            render: (record, i) => {
                return (
                    <Flex spaceEvenly={"true"} gap={"10px"}>
                         <Button.Primary text={"View"}  onClick={() => nevigate('/PurchaseMain')} />
                    </Flex>
                );
            },
        },
    ];

    const data = [
        {
            key: "1",
            sc_no: "007",
            customer_no:"12345",
            date: "07/07/2023",
            status: "Active",
        },
    ];
    return (
        <Fragment>
            <TopTitle Heading={'Purchase'} />
            <Table columns={TableColumn} data={data} />
        </Fragment>
    )
}

export default Purchase;