import React from 'react'
import { Row } from '../../../Components/Row'
import { Card, Col } from 'antd'
import Flex from '../../../Components/Flex'
import Label from '../../../Components/Form/Label'
import Input from '../../../Components/Form/Input'
import { TextAreas } from '../../../Components/Form/TextArea'

const PackingFooter = () => {
    return (
        <div>
            <Row space={[24, 24]}>
                <Col lg={24} md={24} span={24}>
                    <Card>
                        <Row space={[24, 24]}>
                            <Col span={24} md={2}></Col>
                            <Col span={24} md={6}>
                                <Flex alignCenter={true}>
                                    <Label style={{ paddingTop: '30px' }}>Total&nbsp;:</Label>&nbsp;&nbsp;&nbsp;
                                    <Input label={"CRT'S"} disabled name={'ctnsd'} />
                                </Flex>
                            </Col>
                            <Col span={24} md={4}>
                                <Input label={"quantity"} disabled name={'total_quantity'} />
                            </Col>
                            <Col span={24} md={4}>
                                <Input label={'N/W'} disabled name={'net_weights'} />
                            </Col>
                            <Col span={24} md={4}>
                                <Input label={'G.W.'} disabled name={'gross_weights'} />
                            </Col>
                            <Col span={24} md={4}>
                                <Input label={'CBM'} disabled name={'cbms'} />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>

            <Row space={[12, 12]}>
                <Col span={24} md={13}>
                    <Row space={[12, 12]}>

                        <Col span={24} md={24}>
                            <TextAreas label={'SHIPPING MARKS'} name={'shipping_marks'} placeholder={'SHIPPING MARKS'} />
                        </Col>

                        <Col span={24} md={24}>
                            <TextAreas label={'NOTES'} name={'notes'} placeholder={'NOTES'} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default PackingFooter