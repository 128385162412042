import { Col, Collapse, Form } from 'antd'
import React, { useEffect } from 'react'
import Flex from '../../Components/Flex';
import Button from '../../Components/Form/Button';
import Input from '../../Components/Form/Input';
import { Row } from '../../Components/Row';
import { toast } from 'react-toastify';
import request from '../../utils/request';

const Country = ({ setShipTo, handleOk, AddCountryGet, HandleCountryFormQuot, handleGetCountry, SaleHeaderTrigger, quotationTrigger }) => {

    const [form] = Form.useForm();

    const CUNTY = 'choice/add_country/'

    const onRest = () => {
        form.resetFields();
        if (HandleCountryFormQuot) {
            HandleCountryFormQuot()
        }
    }

    const onFinish = (values) => {
        AddCountry(values)
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    useEffect(() => {
        form.resetFields();
    }, [SaleHeaderTrigger])

    useEffect(() => {
        form.resetFields()
    }, [quotationTrigger])

    const AddCountry = (values) => {
        request.post(`${CUNTY}`, values)
            .then(function (response) {
                if (response.status == 200) {
                    if (setShipTo) {
                        setShipTo(response.data.country)
                    }
                    form.resetFields();
                    toast.success('Country Added Successfully')

                    if (handleOk) {
                        handleOk()
                    }
                    if (AddCountryGet) {
                        AddCountryGet()
                    }
                    if (HandleCountryFormQuot) {
                        HandleCountryFormQuot()
                    }
                    if (handleGetCountry) {
                        handleGetCountry()
                    }
                }
                else if (response.status == 226) {
                    toast.warn('Country Already Added')
                }

                else {
                    toast.error('Failed');
                }
            })
            .catch(function (error) {
                console.log(error, 'error');
                toast.error('Failed');
            });
    }
    return (
        <Form
            name='AddCountry'
            form={form}
            labelCol={{
                span: 24,
            }}
            wrapperCol={{
                span: 24,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off">
            <Row space={[24, 24]}>

                <Col span={24} md={24}>
                    <Input label={'Add Country'} placeholder={'Enter country'} name={'country'}
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter country!',
                            }
                        ]}
                    />
                </Col>
            </Row>
            <Flex center gap={'20px'} style={{ margin: '30px' }}>
                <Button.Success text={'ADD'} htmlType={'submit'} />
                <Button.Danger text={'RESET'} onClick={onRest} />
            </Flex>
        </Form>
    )
}

export default Country