import { Input, } from 'antd'
import styled, { css } from 'styled-components'
import { THEME } from '../../theme';

export const MainLayout = styled.section`
    min-height:100vh;
    display:flex;
    width:100%;
    transition:0.5s;
`;

export const SideBar = styled.aside`

    z-index: 10;
    position: relative;
    top: 90px;
    height: calc(100vh - 70px);
    overflow-y: auto;
    width: 250px;
    background: ${THEME.primary_color_light};
    transition: all 0.5s ease 0s;

    &.active{
        width:0px;
        transition:0.5s;
        margin:0;
    }

    & ul.ant-menu.ant-menu-root {
        margin: 18px 0;
    }

    .ant-menu-item.ant-menu-item-only-child{
        padding-left:20px !important;
    }

    .ant-menu-submenu-title{
        padding-left:20px !important;
    }

    .ant-menu-submenu-title:hover{
        background:  ${THEME.secondary_color_light} !important;
        color: ${THEME.white} !important;
    }

    .ant-menu-item:not(.ant-menu-item-selected):hover {
        color: ${THEME.white} !important;
        background:  ${THEME.secondary_color_light} !important;
    }

    .ant-menu-item-selected {
        color: ${THEME.white} !important;
        background:  ${THEME.secondary_color_dark} !important;
    }

    .ant-menu-submenu-title:active {
        background-color: #fff0 !important;
        color:${THEME.white};
    }

    .ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline {
        padding: 0 0 0 32px !important;
        border: 0;
        color: #adadad !important;
        border-radius: 0;
        box-shadow: none;
    }

    .ant-menu-light .ant-menu-submenu-selected >.ant-menu-submenu-title {
        color: ${THEME.secondary_color_dark} !important;
    }

    .ant-menu {
        color: ${THEME.white};
        background: ${THEME.primary_color_light};
        border-radius: 15px;
    }

    @media (max-width:900px) {
        position:fixed;
    }
`;


export const ContentLayout = styled.section`
    transition:0.5s;
    height:inherit;
    height:100vh;
    overflow:hidden;
    /* background:red; */
    width:calc(100% - 250px);

    &.active{
        width:100%;
        transition:0.5s;
    }

    @media (max-width:900px) {
        width:100%;
    }
`;

export const ContentHeader = styled.div`
    z-index:20;
    position:fixed;
    padding:10px 20px;
    background:${THEME.primary_color_dark};
    top:0;
    left:0;
    right:0;
    transition:0.5s;

    .ant-tooltip-inner {
        background-color: red !important;
    }
`;

export const MainContent = styled.section`
    padding: 0 10px;
    margin:100px 10px 10px 10px;
    height:calc(100vh - 110px);
    overflow-x:hidden;
    overflow-y:auto;
`;

export const SearchInput = styled(Input)`
    &.ant-input-affix-wrapper{
        border:none ;
        box-shadow:none;
    }

    & .ant-input-prefix{
        margin-inline-end:10px;
    }
`;

export const HeaderIconHolder = styled.div`
    display: flex;
    gap: 20px;
    padding: 10px;
    align-items:center;

    & .header__icon{
    font-size: 28px;
    cursor: pointer;
    }
`

export const BtnSideHolder = styled.div`
    margin: 0 10px;
    background: ${THEME.primary_color_gray1};
    width: 40px;
    height: 40px;
    display: flex;
    color: white;
    border-radius: 10px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
/*  display:none;*/

    & svg{
        font-size:1.5rem;
    }

    /* @media (max-width:1100px) {
        display:flex
    } */
 
 & .header__icon{
    font-size: 26px;
    color:${THEME.white};
    }
`

export const Logo = styled.div`
        width:max-content;
        
        @media ${THEME.MOBILEL} {
            width:250px;
        }

    & span{
        text-transform:uppercase;
        font-weight:700;
        font-size:18px;
        color:${THEME.white};

        @media ${THEME.MOBILEL} {
            font-size:22px;
        }
    }
`