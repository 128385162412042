import React from 'react'
import { Row } from '../../../../Components/Row'
import { Col } from 'antd'
import DeleteSize from './DeleteSize'
import DeleteColor from './DeleteColor'
import { TopTitle } from '../../../../Components/Form/TopTitle'
import Country from './Country'
import styled from 'styled-components'
import DeleteCurrency from './DeleteCurrency'
import UnitDelete from './UnitDelete'
import PaymentTermsDelete from './PaymentDelete'
import ContainerDelete from './ContainerDelete.js'

export const TableHeigt = styled.div`
 height: 300px;
 overflow-y: auto;
 padding: 2px 15px;
`

const OptionIndex = () => {

    return (
        <div>
            <Row gutter={[24, 24]}>
                <Col span={24} md={12} >
                    <TopTitle Heading={'Size'} />
                    <TableHeigt>
                        <DeleteSize />
                    </TableHeigt>
                </Col>
                <Col span={24} md={12}>
                    <TopTitle Heading={'Color'} />
                    <TableHeigt>
                        <DeleteColor />
                    </TableHeigt>
                </Col>
                <Col span={24} md={12}>
                    <TopTitle Heading={'Country'} />
                    <TableHeigt>
                        <Country />
                    </TableHeigt>
                </Col>
                <Col span={24} md={12}>
                    <TopTitle Heading={'Currency'} />
                    <TableHeigt>
                        <DeleteCurrency />
                    </TableHeigt>
                </Col>
                <Col span={24} md={12}>
                    <TopTitle Heading={'Unit'} />
                    <TableHeigt>
                        <UnitDelete />
                    </TableHeigt>
                </Col>
                <Col span={24} md={12}>
                    <TopTitle Heading={'Payment Terms'} />
                    <TableHeigt>
                        <PaymentTermsDelete />
                    </TableHeigt>
                </Col>
                <Col span={24} md={12}>
                    <TopTitle Heading={'Container'} />
                    <TableHeigt>
                        <ContainerDelete />
                    </TableHeigt>
                </Col>
            </Row>
        </div>
    )
}

export default OptionIndex