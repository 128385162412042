import React from 'react'
import { TopTitle } from '../../../Components/Form/TopTitle'
import { CustomCard } from '../../../Components/CustomCard'
import { EditSalesEntryPage } from './Partials/EditSalesEntryPage'

const EditSaleMain = () => {
    return (
        <div>
            <TopTitle Heading={'Sales Confirmations'} />
            <CustomCard>
                <EditSalesEntryPage />
            </CustomCard>
        </div>
    )
}

export default EditSaleMain