import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Row } from '../../../Components/Row'
import { Col } from 'antd'
import {
    AuthersiedBox,
    PrintTableFooterHolder,
    ProfileAddress,
    FooterTitle,
    PrintHolder,
    PrintViewTitle,
    PrintViewValue,
    ProfileHeader,
    ProfileSubTitle,
    SignImageHolder,
    TableHolder,
    PrintWrapper
} from '../../../Components/Styled'
import Flex from '../../../Components/Flex'
import { useDispatch, useSelector } from 'react-redux'
import { getActivePro, getActiveProfile } from '../../Profile/ProfileSlice'
import { useReactToPrint } from 'react-to-print'
import { TableImageHolder } from '../../../Components/TableImageHolder'

export const PurchaseTBView = ({ record, purchaseImg }) => {

    const [viewData, setViewData] = useState({})
    const [viewDatas, setViewDatas] = useState([])

    const componentRef = useRef();

    const dispatch = useDispatch();
    const activePro = useSelector(getActivePro);

    useEffect(() => {
        dispatch(getActiveProfile());
    }, [])

    useEffect(() => {
        setViewData(record)
    }, [record])

    useEffect(() => {
        setViewDatas(purchaseImg)
    }, [purchaseImg])



    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })

    return (
        <Fragment>
            <PrintWrapper>
                <PrintHolder ref={componentRef}>
                    {
                        record.draft && (
                            <div style={{ display: 'flex', justifyContent: 'end', marginTop: '10px', marginRight: '20px' }}>
                                <span style={{ borderBottom: '2px solid black', fontSize: '14px', fontWeight: 'bold' }}>DRAFT COPY</span>
                            </div>
                        )
                    }
                    <ProfileHeader>{activePro.purchase_business_name}</ProfileHeader>
                    <ProfileAddress>{activePro.purchase_address}</ProfileAddress>
                    <ProfileAddress>{activePro.purchase_phone_no} , Fax : {activePro.purchase_fax_number} , Email : {activePro.purchase_email}</ProfileAddress>
                    <div style={{ textAlign: 'center' }}>
                        <ProfileSubTitle>PURCHASE CONFIRMATION</ProfileSubTitle>
                    </div>
                    <Row space={[8, 8]}>
                        <Col span={24} md={12}>
                            <Row space={[2, 2]}>
                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            SUPPLIER NAME & ADDRESS
                                        </PrintViewTitle>
                                        <h1 style={{ alignSelf: 'baseline' }}>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {viewData.supplier_name} <br />
                                            {viewData.address}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            SUPPILER ID
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {viewData.supplieid}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            Phone no
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {viewData.supplier_mobile}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            fax&nbsp;
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {viewData.fax}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            email&nbsp;
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {viewData.email}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            contact person
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {viewData.contact_person}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            mobile
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {viewData.mobile_number}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={10}>
                                    <Flex spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            PAYMENT TERMS
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {viewData.payment_terms}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24} md={12}>
                            <Row space={[2, 2]}>
                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            Pc no
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>
                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {viewData.purchaseorderconfirmation_no}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            Pc Date
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>

                                </Col>
                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {viewData.purchaseorderconfirmation_date}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>
                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            shipment Date
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {viewData.shipping_date}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            ship to
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {viewData.country}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>
                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            origin
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {viewData.origin}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            delivery TERMS
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>
                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {viewData.delivery_terms}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>

                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            ship by
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>
                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {viewData.ship_by}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>
                                {
                                    viewData.container &&
                                    <Col span={24} sm={12} md={10}>
                                        <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                            <PrintViewTitle>
                                                container
                                            </PrintViewTitle>
                                            <h1>:</h1>
                                        </Flex>
                                    </Col>

                                }
                                {
                                    viewData.container &&

                                    <Col span={24} sm={12} md={14}>
                                        <Flex alignEnd={'true'} H_100>
                                            <PrintViewValue>
                                                {viewData.container}
                                            </PrintViewValue>
                                        </Flex>
                                    </Col>
                                }

                                <Col span={24} sm={12} md={10}>
                                    <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                        <PrintViewTitle>
                                            REVISED ON
                                        </PrintViewTitle>
                                        <h1>:</h1>
                                    </Flex>
                                </Col>
                                <Col span={24} sm={12} md={14}>
                                    <Flex alignEnd={'true'} H_100>
                                        <PrintViewValue>
                                            {viewData.revised_date}
                                        </PrintViewValue>
                                    </Flex>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <TableHolder>
                        <table>
                            <thead>
                                <tr>
                                    <th>SI.NO</th>
                                    <th>PRODUCT</th>
                                    <th>BARCODE</th>
                                    <th>IMAGE</th>
                                    <th>DESCRIPTION</th>
                                    <th>QUANTITY</th>
                                    <th>RATE ({viewData?.currency})</th>
                                    <th>TOTAL ({viewData?.currency})</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    viewDatas?.map((item, index) => (
                                        <tr key={item.id}>
                                            <td>{index + 1}</td>
                                            <td>{item.product_name}</td>
                                            <td>{item.barcode}</td>
                                            <td>
                                                <TableImageHolder imageUrl={item.product_image} />
                                            </td>
                                            <td>{item.description}</td>
                                            <td>{item.quantity} {item.unit}</td>
                                            <td>{item.purchase_rate}</td>
                                            <td>{item.total}</td>
                                        </tr>
                                    ))
                                }

                            </tbody>
                        </table>
                    </TableHolder>
                    <PrintTableFooterHolder>
                        <Row space={[12, 12]}>
                            <Col span={18} md={12}>
                                <Row space={[2, 2]}>
                                    <Col span={24} sm={12} md={12}>
                                        <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                            <PrintViewTitle>
                                                TOTAL AMOUNT IN WORDS
                                            </PrintViewTitle>
                                            <h1>:</h1>
                                        </Flex>
                                    </Col>
                                    <Col span={24} sm={12} md={12}>
                                        <Flex gap={'5px'} alignEnd={'true'} H_100>
                                            <PrintViewValue>
                                                <h4 style={{ textTransform: 'uppercase', fontSize: '12px' }}>{viewData?.amount_in_words} {viewData?.currency}</h4>
                                            </PrintViewValue>
                                        </Flex>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={18} md={12}></Col>
                            <Col span={18} md={12}></Col>
                            <Col span={18} md={12}>
                                <Flex flexEnd={'true'}>
                                    <Row space={[2, 2]}>
                                        <Col span={24} sm={12} md={12}>
                                            <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                                <PrintViewTitle>
                                                    TOTAL QUANTITY
                                                </PrintViewTitle>
                                                <h1>:</h1>
                                            </Flex>
                                        </Col>
                                        <Col span={24} sm={12} md={12}>
                                            <Flex alignEnd={'true'} H_100>
                                                <PrintViewValue>
                                                    {viewData?.total_pieces}
                                                </PrintViewValue>
                                            </Flex>
                                        </Col>

                                        <Col span={24} sm={12} md={12}>
                                            <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                                <PrintViewTitle>
                                                    TOTAL AMOUNT
                                                </PrintViewTitle>
                                                <h1>:</h1>
                                            </Flex>
                                        </Col>
                                        <Col span={24} sm={12} md={12}>
                                            <Flex alignEnd={'true'} H_100>
                                                <PrintViewValue>
                                                    {viewData?.total_all} {viewData?.currency}
                                                </PrintViewValue>
                                            </Flex>
                                        </Col>
                                    </Row>
                                </Flex>
                            </Col>
                        </Row>
                        <Row space={[12, 12]}>
                            <Col span={18} >
                                <Row space={[2, 2]}>

                                    <Col span={24} sm={12} md={6}>
                                        <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                            <PrintViewTitle>
                                                SHIPPING MARKS
                                            </PrintViewTitle>
                                            <h1>:</h1>
                                        </Flex>
                                    </Col>

                                    <Col span={24} sm={12} md={18}>
                                        <Flex alignEnd={'true'} H_100>
                                            <PrintViewValue>
                                                <pre>
                                                    {viewData.shipping_marks}
                                                </pre>
                                            </PrintViewValue>
                                        </Flex>
                                    </Col>
                                    <Col span={24} sm={12} md={6}>
                                        <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                            <PrintViewTitle>
                                                REMARKS
                                            </PrintViewTitle>
                                            <h1>:</h1>
                                        </Flex>
                                    </Col>

                                    <Col span={24} sm={12} md={18}>
                                        <Flex alignEnd={'true'} H_100>
                                            <PrintViewValue>
                                                <pre>
                                                    {viewData.remarks}
                                                </pre>
                                            </PrintViewValue>
                                        </Flex>
                                    </Col>
                                    <Col span={24} sm={12} md={6}>
                                        <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                            <PrintViewTitle>
                                                NOTES
                                            </PrintViewTitle>
                                            <h1>:</h1>
                                        </Flex>
                                    </Col>

                                    <Col span={24} sm={12} md={18}>
                                        <Flex alignEnd={'true'} H_100>
                                            <PrintViewValue>
                                                <pre>
                                                    {viewData.notes}
                                                </pre>
                                            </PrintViewValue>
                                        </Flex>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </PrintTableFooterHolder>

                    <PrintTableFooterHolder>
                        {/* <FooterTitle>
                            BANK DETAILS
                        </FooterTitle> */}
                        <Row space={[6, 6]}>
                            <Col span={24} md={14}>
                                {/* <Row space={[2, 2]}>
                                    <Col span={24} sm={12} md={12}>
                                        <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                            <PrintViewTitle>
                                                BENEFICIARY NAME
                                            </PrintViewTitle>
                                            <h1>:</h1>
                                        </Flex>
                                    </Col>

                                    <Col span={24} sm={12} md={12}>
                                        <Flex alignEnd={'true'} H_100>
                                            <PrintViewValue>
                                                {activePro.purchase_bank_account_name}
                                            </PrintViewValue>
                                        </Flex>
                                    </Col>

                                    <Col span={24} sm={12} md={12}>
                                        <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                            <PrintViewTitle>
                                                BENEFICIARY BANK
                                            </PrintViewTitle>
                                            <h1>:</h1>
                                        </Flex>
                                    </Col>

                                    <Col span={24} sm={12} md={12}>
                                        <Flex alignEnd={'true'} H_100>
                                            <PrintViewValue>
                                                {activePro.purchase_bank_name}
                                            </PrintViewValue>
                                        </Flex>
                                    </Col>

                                    <Col span={24} sm={12} md={12}>
                                        <Flex gap={'5px'} spacebetween={'true'} alignEnd={'true'}>
                                            <PrintViewTitle>
                                                BENEFICIARY A/C NO
                                            </PrintViewTitle>
                                            <h1>:</h1>
                                        </Flex>
                                    </Col>

                                    <Col span={24} sm={12} md={12}>
                                        <Flex alignEnd={'true'} H_100>
                                            <PrintViewValue>
                                                {activePro.purchase_bank_account_number}
                                            </PrintViewValue>
                                        </Flex>
                                    </Col>
                                </Row> */}
                            </Col>

                        </Row>

                        <AuthersiedBox />
                        <Flex W_100 spacebetween={'true'} alignEnd={'true'}>
                            <span>
                                {/* CONFIRM & ACCEPTED BY */}
                            </span>

                            <span>
                                FOR AND ON BEHALF OF
                            </span>
                        </Flex>

                        <Flex flexEnd={'true'}>
                            <SignImageHolder>
                                <img src={activePro.purchase_signature} alt="signature" />
                            </SignImageHolder>
                        </Flex>

                        <Flex W_100 spacebetween={'true'} alignEnd={'true'}>
                            <span>
                                {/* BRANCH ASFAR */}
                            </span>

                            <span>
                                {activePro.purchase_business_name}
                            </span>
                        </Flex>

                    </PrintTableFooterHolder>

                </PrintHolder>
            </PrintWrapper>
        </Fragment >
    )
}
