import { Col, Form, Input as AntdInput } from 'antd';
import React, { useEffect, useState } from 'react'
import Input from '../../../../Components/Form/Input';
import { Row } from '../../../../Components/Row';
import Flex from '../../../../Components/Flex';
import Button from '../../../../Components/Form/Button';
import { Select } from '../../../../Components/Form/Select';
import { toast } from 'react-toastify';
import request from '../../../../utils/request';
import styled from 'styled-components';
import { THEME } from '../../../../theme';

export const Passwd = styled(AntdInput.Password)`
 &.ant-input-affix-wrapper{
    border: 1px solid ${THEME.primary_color} !important;
  }
`

const AddUser = ({ handleRecord, GetUser, UserUpadate, CloseHanOK, formReset }) => {

  const [form] = Form.useForm();

  const URL = 'user/register'

  const EDITUSER = 'user/g_user_edit'

  const [values, setValues] = useState({});

  const onFinish = (values) => {

    if (values.id) {
      const record = { ...values };
      let result = {
        password: record.pass,
        user_role: record.user_role,
        name: record.name,
        email: record.email,
      }
      EditUser(result, handleRecord.id);
    } else {
      AddUser(values);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onReset = () => {
    form.resetFields();
  }
  const option = [
    { label: 'Master 1', value: 'Master1' },
    { label: 'Master 2', value: 'Master2' },
    { label: 'Sales', value: 'Sales' },
    { label: 'Purchase', value: 'Purchase' },
    { label: 'Invoice', value: 'Invoice' },
    { label: 'Packing', value: 'Packing' },
  ]

  const AddUser = (values) => {
    request.post(`${URL}`, values)
      .then(function (response) {
        if (response.status === 201) {
          form.resetFields();
          toast.success("User Details Add Successfully")
        }
        else {
          toast.error("You are Unauthorized")
        }
      })
      .catch(function (error) {
        if (error.response && error.response.status === 400) {
          if (error.response.data) {
            if (error.response.data.email) {
              toast.warn(error.response.data.email[0])
            }
            else {
              toast.error("Faild")
            }
          }
          else {
            toast.error("Faild")
          }

        }
      });
  }

  const EditUser = (values, id) => {
    request.patch(`${EDITUSER}/${id}`, values)
      .then(function (response) {
        if (response.status === 200) {
          form.resetFields();
          GetUser()
          if (UserUpadate) {
            UserUpadate()
          }
          toast.info("User Details Add Successfully")
        }
        else {
          toast.error("You are Unauthorized")
        }
      })
      .catch(function (error) {
        toast.error("Faild")
      });
  }

  useEffect(() => {
    form.resetFields()
  }, [formReset])

  useEffect(() => {
    form.setFieldsValue(handleRecord);
    setValues(handleRecord || {});
  }, [handleRecord, formReset]);


  return (
    <Form
      form={form}
      labelCol={{
        span: 24,
      }}
      wrapperCol={{
        span: 24,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off">

      <Row space={[24, 24]}>

        <Col span={24} md={12}>

          <Input name={'id'} display={'none'} />

          <Input label={'Name'} placeholder={'Enter Your Name'} name={'name'}
            rules={[
              {
                required: true,
                message: 'Please Enter Your Name!',
              }
            ]}
          />
        </Col>

        <Col span={24} md={12}>
          <Input label={'Email ID'} name={'email'} type={'email'} placeholder={"Enter Your Mail ID"}
            rules={[
              {
                required: true,
                message: 'Please Enter Your MailID!',
              }
            ]} />
        </Col>

        <Col span={24} md={12}>

          {values.id ? (
            <Form.Item
              label="Password"
              name="pass"
            >
              <Passwd size="large" placeholder="Password" />
            </Form.Item>
          ) :
            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}>
              <Passwd size='large' placeholder={'Password'} />
            </Form.Item>}

        </Col>


        <Col span={24} md={12}>
          <Select options={option} label={'Role'} name={'user_role'} placeholder={'Select Role'} rules={[
            {
              required: true,
              message: 'Please Enter Your Role!',
            }
          ]} />
        </Col>

      </Row>

      <Flex center={'true'} gap={'20px'} margin={'20px 0px'}>
        <Button.Primary text={'ADD'} htmlType={'submit'} />
        {handleRecord ?
          (
            <Button.Danger text={"CANCEL"} onClick={() => CloseHanOK()} />
          )
          : (
            <Button.Danger text={'RESET'} onClick={() => onReset()} />
          )}
        {/* <Button.Danger text={'RESET'} onClick={() => onReset()} /> */}
      </Flex>

    </Form>
  )
}

export default AddUser