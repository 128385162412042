import { Col } from 'antd'
import React, { Fragment, useEffect, useState } from 'react'
import { Row } from '../../../Components/Row';
import Input from '../../../Components/Form/Input';
import { CustomDatePicker } from '../../../Components/Form/CustomDatePicker';
import { TextAreas } from '../../../Components/Form/TextArea';
import { Select } from '../../../Components/Form/Select';
import { Modal } from '../../../Components/Modal';
import Addsppliers from '../../Suppliers/AddSupplier/Partials/Addsppliers';
import request from '../../../utils/request';
import { CustomSelect } from '../../../Components/Form/CustomSelect';
import Country from '../../AddModules/Country';
import PaymentTearms from '../../AddModules/PaymentTearms';
import { toast } from 'react-toastify';


export const PurchaseFormHeader = ({ poNo, handleship, choose, setChoose, setAddNewPaymentTerms, setShipTo, setSelectedrevisedDates, setSelectedDates, setSelectedDate, setSelectedSupplierDetails, trigger, GetSaleCustomer, setInvoiceNumber }) => {

    const [contryData, setContryData] = useState([])

    const [payTermData, setPayTermData] = useState([])

    const [width, setWidth] = useState(0)

    // ======  Modal Open ========
    const [isModalOpen, setIsModalOpen] = useState(false);

    // ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);

    const [triggers, setTriggers] = useState(0);

    const [getdata, setGetdata] = useState([])

    // const [choose, setChoose] = useState('');

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };



    const ShipBy = [
        { label: 'Sea', value: 'Sea' },
        { label: 'Air', value: 'Air' },
    ]

    const handleChoose = (value) => {
        if (poNo) {
            handleship()
        }
        setChoose(value)
    }

    const onViewPayment = () => {
        setWidth(400)
        setModalTitle("Add Payment");
        setModalContent(<PaymentTearms setAddNewPaymentTerms={setAddNewPaymentTerms} AddPaymentGet={AddPaymentGets} />);
        showModal();
    }

    const AddPaymentGets = () => {
        if (AddPaymentGet) {
            AddPaymentGet()
            handleOk()
        }
    }

    // ==========  Date Change =======
    const handleOnChange = (date) => {
        setSelectedDate(date);
    };

    const handleOnChanges = (date) => {
        setSelectedDates(date);
    };

    const handleOnChangess = (date) => {
        setSelectedrevisedDates(date);
    };

    const onViewRow = () => {
        setTriggers(triggers + 1)
        setWidth(800)
        setModalTitle("Add Supplier");
        setModalContent(<Addsppliers handleSupplier={handleSupplier} SaleHeaderTrigger={triggers} />);
        showModal();
    }

    const onViewCounty = () => {
        setWidth(400)
        setModalTitle("Add Country");
        setModalContent(<Country setShipTo={setShipTo} AddCountryGet={AddCountryGets} />);
        showModal();
    }
    const AddCountryGets = () => {
        AddCountryGet()
        handleOk()
    }

    useEffect(() => {
        if (trigger) {
            GetSupplier();
        }
    }, [trigger]);

    useEffect(() => {
        GetSupplier();
        AddCountryGet();
        AddPaymentGet();
    }, [])

    const GetSupplier = () => {

        request.get('purchase_order/purchase_order_details/')
            .then(function (response) {
                setGetdata(response.data?.supplier)
                if (GetSaleCustomer) {
                    GetSaleCustomer()
                }
            })
            .catch(function (error) {
                toast.error("Failed")
            });
    }


    const handleSupplier = () => {
        handleCancel();
        GetSupplier();
    }


    const AddPayment = payTermData?.map(com => ({ label: com.terms, value: com.terms }));

    const AddContry = contryData.map((com) => ({ label: com.country, value: com.country }));

    const SupplierName = getdata?.map(mob => ({ label: mob.supplier_name, value: mob.id }));


    const handleSelectedSalessss = (value) => {
        const SelectedPersonDetailsss = getdata?.find((mem) => mem.id === value)
        setSelectedSupplierDetails(SelectedPersonDetailsss);
    }

    const CUNTY = 'choice/add_country/'

    const AddCountryGet = () => {
        request.get(`${CUNTY}`)
            .then(function (response) {
                setContryData(response.data)
            })
            .catch(function (error) {
                // toast.error('Failed');
            });
    }

    const PAYEMT = 'choice/add_payment_terms/'

    const AddPaymentGet = () => {
        request.get(`${PAYEMT}`)
            .then(function (response) {
                setPayTermData(response.data)
            })
            .catch(function (error) {
                // toast.error('Failed');
            });
    }

    return (
        <Fragment>

            <Row space={[24, 24]} >
                <Col span={24} md={12}>
                    <Row space={[24, 24]} >
                        <Col span={24} md={24}>
                            {poNo ?
                                <Select options={SupplierName} onButtonClick={onViewRow}
                                    showSearch={true} buttonLabel="ADD SUPPLIER"
                                    onChange={handleSelectedSalessss} label={'Supplier NAME'}
                                    name={'supplier_name'} placeholder={'Supplier NAME'} rules={[
                                        {
                                            required: true,
                                            message: 'Please Fill Details!',
                                        },
                                    ]} />
                                :
                                <CustomSelect options={SupplierName} onButtonClick={onViewRow}
                                    showSearch={true} buttonLabel="ADD SUPPLIER"
                                    onChange={handleSelectedSalessss} label={'Supplier NAME'}
                                    name={'supplier_name'} placeholder={'Supplier NAME'} rules={[
                                        {
                                            required: true,
                                            message: 'Please Fill Details!',
                                        },
                                    ]} />
                            }
                        </Col>
                        <Col span={24} md={24}>
                            <TextAreas label="ADDRESS"
                                name='address' placeholder="ADDRESS" rules={[
                                    {
                                        required: true,
                                        message: 'Please Fill Details!',
                                    },
                                ]} />
                        </Col>
                        <Col span={24} md={24}>
                            <Input label='SUPPLIER ID' disabled
                                name="supplieid" placeholder="SUPPLIER ID" />
                            <Input disabled
                                name="supplier" display={'none'} />

                        </Col>
                        <Col span={24} md={24}>
                            <Input label='PHONE NUMBER'
                                name="supplier_mobile" placeholder="PHONE NUMBER" rules={[
                                    {
                                        required: true,
                                        message: 'Please Fill Details!',
                                    },
                                ]} />
                        </Col>
                        <Col span={24} md={24}>
                            <Input label={'FAX NO'} name={'fax'} placeholder={'FAX NO'} rules={[
                                {
                                    required: true,
                                    message: 'Please Fill Details!',
                                },
                            ]} />
                        </Col>
                        <Col span={24} md={24}>
                            <Input label={'EMAIL ID'} type="email" name={'email'} placeholder={'EMAIL ID'} rules={[
                                {
                                    required: true,
                                    message: 'Please Fill Details!',
                                },
                            ]} />
                        </Col>
                        <Col span={24} md={24}>
                            <Input label={'CONTACT PERSON'} placeholder={'CONTACT PERSON'} name={'contact_person_name'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Enter Contact Person Name !'
                                    }
                                ]} />
                        </Col>
                        <Col span={24} md={24}>
                            <Input label={'MOBILE'} placeholder={'MOBILE NUMBER'} name={'mobile_number'} rules={[
                                {
                                    required: true,
                                    message: 'This field is required !'
                                }
                            ]} />
                        </Col>
                        <Col span={24} md={24}>
                            <CustomSelect
                                options={AddPayment}
                                showSearch label={'PAYMENT TERMS'}
                                placeholder={'PAYMENT TERMS'} name={'terms'}
                                onButtonClick={onViewPayment}
                                buttonLabel={'Add'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Select PAYMENT TERMS !'
                                    }
                                ]} />
                        </Col>
                    </Row>
                </Col>

                <Col span={24} md={12}>
                    <Row space={[24, 24]} >
                        <Col span={24} md={24}>

                            <Input label={'PC NO'} disabled placeholder={'PC NO'} name={'id'} rules={[
                                {
                                    required: true,
                                    message: 'Please Fill Details!',
                                },
                            ]} />
                        </Col>
                        <Col span={24} md={24}>
                            <CustomDatePicker label={'Pc DATE'} onChange={handleOnChange}
                                name={'purchaseorderconfirmation_date'} rules={[
                                    {
                                        required: true,
                                        message: 'Please Fill Details!',
                                    },
                                ]} />
                        </Col>
                        <Col span={24} md={24}>
                            <CustomDatePicker label={'SHIPMENT DATE'} onChange={handleOnChanges}
                                name={'shipping_date'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required!'
                                    }
                                ]} />
                        </Col>
                        <Col span={24} md={24}>
                            <CustomSelect label={'SHIP To'} name={'country'} showSearch={true}
                                onButtonClick={onViewCounty} options={AddContry}
                                buttonLabel="ADD COUNTRY"
                                placeholder={'SHIP To'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required!'
                                    }

                                ]} />
                        </Col>
                        <Col span={24} md={24}>
                            <Input showSearch label={'ORIGIN'} placeholder={'ORIGIN'} name={'origin'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required!'
                                    }
                                ]} />
                        </Col>
                        <Col span={24} md={24}>
                            <Input showSearch label={'DELIVERY TERMS'} placeholder={'DELIVERY TERMS'}
                                name={'delivery_terms'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required!'
                                    }
                                ]} />
                        </Col>
                        <Col span={24} md={24}>
                            <Select label={'SHIP BY'} options={ShipBy} placeholder={'SHIP BY'} name={'ship_by'}
                                onChange={handleChoose}
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required!'
                                    }
                                ]} />
                        </Col>
                        {choose === 'Sea' && (
                            <Col span={24} md={24}>
                                <Input label={'CONTAINER'} placeholder={'CONTAINER'} name={'container'}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'This field is required!'
                                        }
                                    ]} />
                            </Col>
                        )}
                        <Col span={24} md={24}>
                            <CustomDatePicker label={'REVISED ON'} onChange={handleOnChangess} name={'revised_date'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required!'
                                    }
                                ]} />
                        </Col>

                    </Row>
                </Col>
            </Row>

            <Modal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={width} modalTitle={modalTitle} modalContent={modalContent} />
        </Fragment>
    )
}
