import { Col, } from 'antd';
import React, { useEffect, useState } from 'react'
import { CustomDatePicker } from '../../../Components/Form/CustomDatePicker';
import { CustomInputNumber } from '../../../Components/Form/CustomInputNumber';
import Input from '../../../Components/Form/Input';
import { TextAreas } from '../../../Components/Form/TextArea';
import { Row } from '../../../Components/Row';
import request from '../../../utils/request';
import { CustomSelect } from '../../../Components/Form/CustomSelect';
import PaymentTearms from '../../AddModules/PaymentTearms';
import { Modal } from '../../../Components/Modal';
import { getAllCountries, getAllPaymentTerms, getCountry, getPaymentTerms } from '../../AddModules/AddModulesSlice';
import { useDispatch, useSelector } from 'react-redux';
import Country from '../../AddModules/Country';

const InvoiceHeader = ({ setShipTo, setAddNewPaymentTerms, trigger, setSelectedDate, setSelectedDates }) => {

    const [getdata, setGetdata] = useState([])
    const [triggers, setTriggers] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);
    const [countryget, setCountryget] = useState([])
    const [contryData, setContryData] = useState([])
    const [payterms, setPayterms] = useState(0)
    const [width, setWidth] = useState(0)
    const [termsData, setTermsData] = useState([])
    const dispatch = useDispatch()


    // ==========  Date Change =======
    const handleOnChange = (date) => {
        setSelectedDate(date);
    };
    const handleOnChanges = (date) => {
        setSelectedDates(date);
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    //==================Payment terms ===========================//

    const StorePayementres = useSelector(getAllPaymentTerms)

    useEffect(() => {
        dispatch(getPaymentTerms())
    }, [payterms])

    useEffect(() => {
        setTermsData(StorePayementres)
    }, [StorePayementres])

    const handleGetpayterms = () => {
        setPayterms(payterms + 1)
    }

    const handleSubmit = () => {
        handleOk()
    }


    const paymentOptions = termsData?.map((com) => ({ label: com.terms, value: com.terms }));

    const onViewpay = () => {
        setTriggers(triggers + 1)
        setWidth(500)
        setModalTitle("Add Payment");
        setModalContent(<PaymentTearms InvocePaymentTerms={setAddNewPaymentTerms} handleInvocPayterm={handleGetpayterms}
            handleSubmitInvce={handleSubmit} HeaderTrigger={triggers} />);
        showModal();
    }


    useEffect(() => {
        if (trigger) {
            GetCustomer();
        }
    }, [trigger]);

    const GetCustomer = () => {

        request.get('invoice/get_detail_invoice/')
            .then(function (response) {
                setGetdata(response.data?.customer)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        GetCustomer()
    }, [])

    //========================country get================================//

    const countryreponse = useSelector(getAllCountries)

    useEffect(() => {
        dispatch(getCountry())
    }, [countryget])

    useEffect(() => {
        setContryData(countryreponse)
    }, [countryreponse])

    const AddContry = contryData.map((com) => ({ label: com.country, value: com.country }));

    const handleGetCountry = () => {
        setCountryget(countryget + 1)
    }
    const onViewCounty = () => {
        setTriggers(triggers + 1)
        setWidth(500)
        setModalTitle("Add Country");
        setModalContent(<Country setShipTo={setShipTo} handleGetCountry={handleGetCountry}
            handleOk={handleOk} SaleHeaderTrigger={triggers} />);
        showModal();
    }



    return (
        <Row space={[24, 24]}>
            <Col span={24} md={12}>
                <Row space={[24, 24]}>
                    <Col span={24} md={24}>
                        <Input label={'Client Name / Company'} name={'company_name'}
                            placeholder={'Client Name'} rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Client Name !',
                                }
                            ]} />

                        <Input name={'contact_person'} display={'none'} />
                    </Col>
                    <Col span={24} md={24}>
                        <TextAreas label={'ADDRESS'} placeholder={'ADDRESS'}
                            name={'address'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Address!',
                                }
                            ]} />
                        <Input name={'customer_obj'} display={'none'} />

                    </Col>
                    <Col span={24} md={24}>
                        <Input label={'C.R NO'} name={'cr_no'}
                            placeholder={'C.R NO'} rules={[
                                {
                                    required: true,
                                    message: 'Please Enter C.R NO !',
                                }
                            ]} />
                    </Col>
                    <Col span={24} md={24}>
                        <Input label={'IMPORT NO'} placeholder={'IMPORT NO'} name={'import_no'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter IMPORT NO !',
                                }
                            ]} />
                    </Col>
                    <Col span={24} md={24}>
                        <Input label={'VAT NO'} placeholder={'VAT NO'} name={'vat_no'} rules={[
                            {
                                required: true,
                                message: 'Please Enter VAT NO !',
                            }
                        ]}
                        />
                    </Col>
                    <Col span={24} md={24}>
                       
                        <CustomSelect
                            options={paymentOptions}
                            showSearch label={'PAYMENT TERMS'}
                            placeholder={'PAYMENT TERMS'} name={'payment_terms'}
                            onButtonClick={onViewpay}
                            buttonLabel={'Add'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Fill Details!',
                                },
                            ]}
                        />
                    </Col>
                    <Col span={24} md={24}>
                        <Input label={'DELIVERY TERM'} placeholder={'DELIVERY TERM'} name={'delivery_terms'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter DELIVERY TERM !',
                                }
                            ]} />
                    </Col>
                    <Col span={24} md={24}>
                        <Input label={'CONFIRM QUANTITY'} placeholder={'CONFIRM QUANTITY'}
                            name={'confirm_quantity'} rules={[
                                {
                                    required: true,
                                    message: 'Please Enter confirm quantity!',
                                }
                            ]}
                        />
                    </Col>
                </Row>
            </Col>

            <Col span={24} md={12}>
                <Row space={[24, 24]}>
                    <Col span={24} md={24}>
                        <Input disabled name={'customer_id'} display={'none'} />
                        <Input label={'INVOICE NO'} disabled
                            placeholder={'INVOICE NO'} name={'invoice_no'}
                        />
                    </Col>
                    <Col span={24} md={24}>
                        <CustomDatePicker label={'INVOICE DATE'} name={'invoice_date'}
                            onChange={handleOnChange} />
                    </Col>
                    <Col span={24} md={24}>
                        <CustomDatePicker label={'SAILING ON ABT'} name={'shipping_date'}
                            onChange={handleOnChanges}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Select date!',
                                }
                            ]} />
                    </Col>
                    <Col span={24} md={24}>
                        <CustomSelect label={'SHIP To'} name={'country'} showSearch={true}
                            onButtonClick={onViewCounty} options={AddContry}
                            buttonLabel="ADD COUNTRY"
                            placeholder={'SHIP To'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Fill Details!',
                                },
                            ]}
                        />
                        <Input name={'mobile_number'} display={'none'} />
                        <Input name={'email'} display={'none'} />
                    </Col>
                    <Col span={24} md={24}>
                        <CustomInputNumber label={'SC NO'} placeholder={'SC NO'} name={'sc_no'}
                            disabled
                        />
                    </Col>
                    <Col span={24} md={24}>
                        <Input label={'VESSELL NAME'} placeholder={'VESSELL NAME'} name={'vessel_name'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter vessel name!',
                                }
                            ]} />
                    </Col>
                    <Col span={24} md={24}>
                        <Input label={'BL NO'} placeholder={'BL NO'} name={'bl_no'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter BL no!',
                                }
                            ]} />
                    </Col>
                    <Col span={24} md={24}>
                        <Input label={'CONTAINER & SEAL NO'} placeholder={'CONTAINER & SEAL NO'}
                            name={'container'}
                            rules={[
                                {
                                    required: true,
                                    message: 'This field is required!',
                                }
                            ]} />
                    </Col>
                </Row>
            </Col>
            <Modal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={width} modalTitle={modalTitle} modalContent={modalContent} />

        </Row>

    )
}

export default InvoiceHeader