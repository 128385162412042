import { Col, Form } from "antd"
import React, { Fragment, useEffect, useState } from "react"
import Flex from "../../../Components/Flex";
import { DeleteButtonWrapper, Table } from "../../../Components/Table";
import Button from "../../../Components/Form/Button";
import { DeleteOutlined } from "@ant-design/icons";
import { CustomInputNumber } from "../../../Components/Form/CustomInputNumber";
import { Row } from "../../../Components/Row";
import { SalesFormHeader } from "./SalesFormHeader";
import { SalesFormFooter } from "./SalesFormFooter";
import { Modal } from "../../../Components/Modal";
import { Select } from "../../../Components/Form/Select";
import Input from "../../../Components/Form/Input";
import dayjs from 'dayjs'
import request, { IMG_BASE_URL } from "../../../utils/request";
import { toast } from "react-toastify";
import { CustomSelect } from "../../../Components/Form/CustomSelect";
import AddUnitMeasurement from "../../AddModules/AddUnit";
import { AddItems } from "../../Products/Add Items/Partials/AddItems";
import AddCurrency from "../../AddModules/AddCurrency";
import { useDispatch } from "react-redux";
import { getCurrency, getNotification } from "../../AddModules/AddModulesSlice";
import { getSaleProduct, getSaleUnit } from "../SalesSlice";
import { TextAreas } from "../../../Components/Form/TextArea";
import { TableImageHolder } from "../../../Components/TableImageHolder";
import { useNavigate, useParams } from "react-router-dom";
import { PrintSales } from "./PrintSale";


export const SalesEntryPage = ({ setSale }) => {

  const dispatch = useDispatch();

  const { sc_no } = useParams()

  const [getproductData, setGetproductData] = useState(0)
  const [getunitData, setGetunitData] = useState(0)
  const [currencydatas, setCurrencydatas] = useState(0)

  const [count, setCount] = useState(1);
  const [width, setWidth] = useState(0)

  const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [selectedDates, setSelectedDates] = useState(dayjs().format('YYYY-MM-DD'));
  const [selectedrevisedDates, setSelectedrevisedDates] = useState(dayjs().format('YYYY-MM-DD'));

  const [getdata, setGetdata] = useState([])
  const [bardata, setBardata] = useState([])
  const [unitData, setUnitData] = useState([])
  const [currencyData, setcurrencyData] = useState([])
  const [scnoData, setScnoData] = useState({})

  const [selectedSale, setSelectedSale] = useState({})

  const [ShipTo, setShipTo] = useState()
  const [addNewPaymentTerms, setAddNewPaymentTerms] = useState()


  // ======  Modal Open ========
  const [isModalOpen, setIsModalOpen] = useState(false);

  // ======  Modal Title and Content ========
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  // ================  SalesFormFooter checked ==========
  const [round, setRound] = useState(false);
  const [roundDecimalValue, setRoundDecimalValue] = useState(null);
  const [balance, setBalance] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState({})

  const [shipState, setShipState] = useState(0)

  // -----------------  Balance Checking ------------
  const [withDecimal, setWithDecimal] = useState(null);
  const [withOutDecimal, setWithOutDecimal] = useState(null);
  const [balanceChangeAmount, setBalanceChangeAmount] = useState(0);
  const [balanceChange, setBalanceChange] = useState(false);

  const [trigger, setTrigger] = useState(0);
  const [payType, setPayType] = useState('Cash');

  const navigate = useNavigate();

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [form] = Form.useForm();


  // =========================  Modal Content Start  ===========================
  const [choose, setChoose] = useState('');
  // ===========  ITEM MODAL SHOW  ==================
  const handleItemModalShow = () => {
    setTrigger(trigger + 1)
    setWidth(400)
    setModalTitle("Add Currency");
    setModalContent(<AddCurrency handleGetSaleCurrency={handleGetSaleCurrency} handleOk={handleOk} SaleTrigger={trigger} />);
    showModal();
  };


  const handleship = (value) => {
    form.setFieldsValue({ container: setTrigger(trigger + 1) })
  }


  useEffect(() => {
    form.setFieldsValue({ country: ShipTo })
  }, [ShipTo])

  useEffect(() => {
    form.setFieldsValue({ payment_terms: addNewPaymentTerms })
  }, [addNewPaymentTerms])

  useEffect(() => {
    form.setFieldsValue({ customer_obj: selectedSale.id })
    form.setFieldsValue({ company_name: selectedSale.company_name })
    form.setFieldsValue({ customer_id: selectedSale.invoice_no })
    form.setFieldsValue({ mobile: selectedSale.mobile_number })
    form.setFieldsValue({ phone_no: selectedSale.customer_mobile })
    form.setFieldsValue({ email: selectedSale.email })
    form.setFieldsValue({ Address: selectedSale.address })
    form.setFieldsValue({ contact_person: selectedSale.contact_person_name })
    form.setFieldsValue({ barcode: selectedSale.barcode })
    form.setFieldsValue({ fax: selectedSale.fax })

  }, [selectedSale])

  // ===========  Sale MobileNumber Data =========

  useEffect(() => {
    form.setFieldsValue({ sale_confirmation_no: scnoData?.sale_confirm_no })

  }, [scnoData])

  useEffect(() => {
    form.setFieldsValue({ sale_confirmation_no: scnoData?.sale_confirm_no })
  }, [])


  const GetSaleCustomer = () => {
    request.get('sale_confirmation/get_detail_sale_confirmation/')
      .then(function (response) {
        setGetdata(response.data?.item)
        setBardata(response.data?.item)
        dispatch(getSaleProduct())
      })
      .catch(function (error) {
        toast.error('Something went Wrong')
      });
  }


  useEffect(() => {
    GetSaleCustomer();
    dispatch(getSaleProduct())
  }, [getproductData])

  const handleSelectProductGet = () => {
    setGetproductData(getproductData + 1)
  }

  // -----------------------  RoundOff Checked Function ----------
  const RoundOffChecked = (value) => {
    setWithDecimal(tableSecondaryData[0].grand_total - roundDecimalValue);
    setRound(value)
  }

  const TotalBalance = (value) => {
    setBalance(value)
    setWithDecimal(tableSecondaryData[0].grand_total - roundDecimalValue);
    setWithOutDecimal(tableSecondaryData[0].grand_total)
  }

  const hadleUnitAdd = () => {
    setWidth(400)
    setTrigger(trigger + 1)
    setModalTitle("");
    setModalContent(<AddUnitMeasurement handleSaleUnitGet={handleSaleUnitGet} handleOk={handleOk} SaleTrigger={trigger} />);
    showModal();
  }

  const hadleProdctAdd = () => {
    setTrigger(trigger + 1)
    setWidth(700)
    setModalTitle("Add Product");
    setModalContent(<AddItems handleOkSale={handleOk} handleSelectProductGet={handleSelectProductGet} SaleTrigger={trigger} />);
    showModal();
  }


  const HandleCheQueChage = (value) => {

  }

  // =========================  Modal Content End  ===========================

  // =========================  Other Functions start  =========================


  // =========================  Other Functions End  =========================

  const initialData = [
    {
      key: 0,
      product_name: '',
      item: '',
      barcode: '',
      barcode_id: '',
      product: '',
      product_image: '',
      description: '',
      quantity: '',
      unit: '',
      rate: '',
      total_amt: '',
      net_weight: '',
      gross_weight: '',
      cbm: '',
    }
  ];

  const secondaryData = [
    {
      total_quantity: '',
      tax_total: '',
      grand_total: '',
      deposit_amt: '',
    },
  ];

  const footerCalData = [
    {
      round_off_value: '',
      grand_total: '',
      total_rowamount: '',
      received_amt: '',
      balance: '',
    },
  ];

  const [tableData, setTableData] = useState(initialData);
  const [tableSecondaryData, setTableSecondaryData] = useState(secondaryData);
  const [salesForm, setSalesForm] = useState({})

  const currencyoption = currencyData?.map((com) => ({ label: com.rate, value: com.rate }));

  // +++++++++++++++++++++   Use Effects Start +++++++++++++++++++


  useEffect(() => {
    if (sc_no) {
      request.get(`sale_confirmation/invoice_get_sale_confirmation/${sc_no}/`)
        .then((response) => {
          setTableData(response.data.sale_table)
          setSalesForm(response.data)
          setCount(response.data.sale_table.length)
        })
        .catch((error) => {
          toast.error('Something went Wrong')
        });
    }
  }, [sc_no])

  // ------------------  Dynamic Table  --------------------

  useEffect(() => {
    tableData.forEach(record => {
      form.setFieldsValue({ [`product_name${record.key}`]: record.product_name });
      form.setFieldsValue({ [`product${record.key}`]: record.product });
      form.setFieldsValue({ [`barcode${record.key}`]: record.barcode });
      form.setFieldsValue({ [`barcode_id${record.key}`]: record.product });
      form.setFieldsValue({ [`product_image${record.key}`]: record.product_image });
      form.setFieldsValue({ [`description${record.key}`]: record.description });
      form.setFieldsValue({ [`unit${record.key}`]: record.unit })
      form.setFieldsValue({ [`quantity${record.key}`]: record.quantity })
      form.setFieldsValue({ [`rate${record.key}`]: record.rate })
      form.setFieldsValue({ [`total_amt${record.key}`]: record.total_amt });
      form.setFieldsValue({ [`net_weight${record.key}`]: record.net_weight });
      form.setFieldsValue({ [`gross_weight${record.key}`]: record.gross_weight });
      form.setFieldsValue({ [`cbm${record.key}`]: record.cbm });
    });


    form.setFieldsValue({ [`total_quantity`]: tableSecondaryData[0].total_quantity })
    form.setFieldsValue({ [`grand_total`]: tableSecondaryData[0].grand_total });

    setWithOutDecimal(tableSecondaryData[0].grand_total);
  }, [tableData])


  useEffect(() => {
    if (sc_no) {
      form.setFieldsValue(salesForm)
      form.setFieldsValue({ revised_date: dayjs(salesForm.revised_date, 'YYYY/MM/DD') })
      form.setFieldsValue({ sale_confirmation_date: dayjs(salesForm.sale_confirmation_date, 'YYYY/MM/DD') })
      form.setFieldsValue({ shipping_date: dayjs(salesForm.shipping_date, 'YYYY/MM/DD') })
      form.setFieldsValue({ deposit_amt: salesForm.deposit_amt })
      form.setFieldsValue({ grand_total: salesForm.grand_total })
      form.setFieldsValue({ customer_obj: salesForm.customer_id })
      if (salesForm.container) {
        setChoose(salesForm.ship_by)
      }
    }
  }, [salesForm])

  useEffect(() => {
    if (sc_no) {
      form.setFieldsValue({ sale_confirmation_no: salesForm.sale_confirmation_no })
    }
  }, [choose])


  // --------------------- Round Off Checked  -----------------

  //=================================================

  const BalanceOnChange = (value) => {
    setBalanceChangeAmount(value)
  }

  useEffect(() => {

    if (round) {
      if (balance) {
        form.setFieldsValue({ received_amt: withDecimal });
        form.setFieldsValue({ balance: 0 });
        setBalanceChangeAmount(withDecimal);
      }
      else {
        form.setFieldsValue({ received_amt: 0 });
        form.setFieldsValue({ balance: withDecimal });
        setBalanceChangeAmount(0);

      }
    }
    else {
      if (balance) {
        form.setFieldsValue({ received_amt: withOutDecimal });
        form.setFieldsValue({ balance: 0 });
        setBalanceChangeAmount(withOutDecimal);
      }
      else {
        form.setFieldsValue({ received_amt: 0 });
        form.setFieldsValue({ balance: withOutDecimal });
        setBalanceChangeAmount(0);
      }
    }
  }, [balance])


  useEffect(() => {

    let fizedAmount = 0;

    if (round) {
      fizedAmount = withDecimal;

      if (balance) {
        form.setFieldsValue({ received_amt: withDecimal });
        form.setFieldsValue({ balance: 0 });
        setBalanceChange(false);
      }
      else {
        // ===
        let setAmt = balanceChangeAmount;
        let balSetAmt = withDecimal - setAmt;

        if (balSetAmt < 0) {
          setBalanceChange(true);
        }
        else {
          setBalanceChange(false);
        }
        form.setFieldsValue({ received_amt: setAmt });
        form.setFieldsValue({ balance: balSetAmt });
      }
    }
    else {
      fizedAmount = withOutDecimal;
      if (balance) {
        form.setFieldsValue({ received_amt: withOutDecimal });
        form.setFieldsValue({ balance: 0 });
        setBalanceChange(false);
      }
      else {
        // ===
        let setAmt = balanceChangeAmount;
        let balSetAmt = withOutDecimal - setAmt;

        if (balSetAmt < 0) {
          setBalanceChange(true);
        }
        else {
          setBalanceChange(false);
        }

        form.setFieldsValue({ received_amt: setAmt });
        form.setFieldsValue({ balance: balSetAmt });
      }
    }


  }, [balanceChangeAmount])

  // ===============  Table Data Start ==================
  const columns = [
    {
      title: '#',
      render: (text, record, index) => {

        return (
          (
            <Flex alignCenter gap={'20px'} style={{ alignItems: 'center' }}>
              <h4>{index + 1}</h4>
              <DeleteButtonWrapper>
                <Button
                  style={{
                    display: 'flex',
                    padding: '10px',
                    height: 'auto',
                    fontSize: '16px',
                  }}
                  htmlType="button"
                  danger
                  onClick={() => onDelete(record.key)}
                >
                  <DeleteOutlined />
                </Button>
              </DeleteButtonWrapper>
            </Flex>
          )
        );
      },
    },
    {
      title: 'PRODUCT',
      children: [
        {
          title: <Button.Yellow text={'Add Product'} onClick={() => hadleProdctAdd()} />,
          dataIndex: 'product_name',
          key: 'product_name',
          render: (text, record) => (
            <>
              <Select
                minWidth={'120px'}
                showSearch
                name={`product${record.key}`}
                options={itemDetails}
                onChange={(value) => handleOnChangeProduct(value, record)}
                rules={[
                  {
                    required: true,
                    message: 'This is a required field',
                  },
                ]}
              />
              <Input name={`product_name${record.key}`} display={'none'} />

            </>

          )
        },
      ]
    },
    {
      title: 'BARCODE',
      dataIndex: 'barcode',
      key: 'barcode',
      render: (text, record) => {
        return (
          <>
            <Select
              rules={[
                {
                  required: true,
                  message: 'This is a required field',
                },
              ]}
              minWidth={'120px'}
              showSearch
              name={`barcode_id${record.key}`}
              options={barcodeOptions}
              onChange={(value) => handleOnChangeBarCode(value, record)}
            />
            <Input name={`barcode${record.key}`} display={'none'} />
          </>
        )
      }
    },
    {
      title: 'IMAGE',
      dataIndex: 'product_image',
      render: (product_image, record) => <><TableImageHolder imageUrl={product_image} />
        <Input name={`product_image${record.key}`} display={'none'} />
      </>,
    },
    {
      title: 'PRODUCT DESCRIPTION',
      dataIndex: 'description',
      render: (text, record) => (
        <TextAreas
          minWidth={'120px'}
          placed={'end'}
          name={`description${record.key}`}
          style={{ width: '200px', }}
          onChange={(value) => handleOnChangeDescription(value, record)}
        />
      )
    },
    {
      title: 'QUANTITY',
      children: [
        {
          title: <Button.Yellow text={'Add Unit'} onClick={() => hadleUnitAdd()} />,
          dataIndex: 'quantity',
          render: (text, record) => (
            <>
              <CustomInputNumber name={`quantity${record.key}`} onChange={(value) => handleOnChangeQuantity(value, record)}
                rules={[
                  {
                    type: 'number',
                    min: 0,
                    message: 'value must be positive'
                  },
                  {
                    required: true,
                    message: 'This is a required field',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (parseFloat(value) <= 0) {
                        return Promise.reject('Quantity must be greater than 1');
                      }
                      return Promise.resolve();
                    },
                  }),
                ]} />

              <Select
                rules={[
                  {
                    required: true,
                    message: 'This is a required field',
                  },
                ]}
                minWidth={'120px'}
                showSearch
                name={`unit${record.key}`}
                options={unitselectOptions}
                onChange={(value) => handleOnChangeUnits(value, record)}
              />
            </>
          )
        },
      ]
    },
    {
      title: 'RATE',
      children: [
        {
          title: <CustomSelect name={'currency'} options={currencyoption} placeholder={'CURRENCY'}
            buttonLabel={'ADD'} onButtonClick={handleItemModalShow}
            rules={[
              {
                required: true,
                message: 'This is a required field'
              },

            ]} />,
          dataIndex: 'rate',
          render: (text, record) => (
            <CustomInputNumber precision={2} rules={[
              {
                required: true,
                message: 'This is a required field'
              },
              {
                type: 'number',
                min: 0,
                message: 'value must be positive'
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (parseFloat(value) <= 0) {
                    return Promise.reject('Quantity must be greater than 1');
                  }
                  return Promise.resolve();
                },
              }),
            ]}
              onChange={(value) => handleOnChangeRate(value, record)}
              minWidth={'120px'}
              name={`rate${record.key}`}
            />
          )
        },
      ]
    },
    {
      title: 'TOTAL',
      children: [
        {
          title: <Input name={'currency'} placeholder={'CURRENCY'} disabled style={{ background: '#fff' }} readOnly />,
          dataIndex: 'total_amt',
          render: (text, record) => (
            <CustomInputNumber precision={2}
              rules={[
                {
                  required: true,
                  message: 'This is a required field'
                }
              ]}
              disabled
              minWidth={'120px'}
              name={`total_amt${record.key}`}
            />
          )
        }
      ]

    },

    {
      title: <div style={{ whiteSpace: 'pre-line' }}>NET  WEIGHT<br />(KG)</div>,
      dataIndex: 'net_weight',
      render: (text, record) => (
        <CustomInputNumber precision={2}
          minWidth={'120px'}
          placed={'end'}
          min={0.00}
          name={`net_weight${record.key}`}
          onChange={(value) => handleOnChangeNetWeight(value, record)} />
      )
    },
    {
      title: 'G.W (kg)',
      dataIndex: 'gross_weight',
      render: (text, record) => (
        <CustomInputNumber
          precision={0}
          minWidth={'120px'}
          placed={'end'}
          min={0.00}
          name={`gross_weight${record.key}`}
          onChange={(value) => handleOnChangeGrossWeight(value, record)}
        />
      )
    },
    {
      title: 'CBM',
      dataIndex: 'cbm',
      render: (text, record) => (
        <CustomInputNumber precision={2}
          minWidth={'120px'}
          placed={'end'}
          min={0.00}
          onChange={(value) => handleOnChangeCBM(value, record)}
          name={`cbm${record.key}`}
        />
      )
    },
  ]
  // ===============  Table Data End ==================


  // ==================  Table Functions Start ==================

  // ----------------- Add Row Function 

  const AddRow = () => {
    const newData = {
      key: count,
      product_name: '',
      item: '',
      barcode: '',
      product_image: '',
      description: '',
      quantity: '',
      unit: '',
      rate: '',
      total_amt: '',
      net_weight: '',
      gross_weight: '',
      cbm: '',
    };

    setTableData(pre => {
      return [...pre, newData]
    })
    setCount(count + 1);

  }

  // -----------------------  Delete Row Function

  const onDelete = (key) => {
    if (tableData.length > 1) {
      setTableData(prevState => {
        const newData = prevState.filter(item => item.key !== key);

        // ------ Variables 
        let totalQuantity = 0;
        let totalDiscount = 0;
        let totalTax = 0;
        let totalAmount = 0;

        newData.forEach(item => {
          if (item.quantity !== '' || item.total_amt !== '' || item.sale_discount !== '' || item.tax_cal_amt !== '') {
            totalQuantity += parseFloat(item.quantity);
            totalDiscount += parseFloat(item.sale_discount);
            totalTax += parseFloat(item.tax_cal_amt);
            totalAmount += parseFloat(item.total_amt);
          }
        });

        // update the grand_total value in the tableSecondaryData array
        setTableSecondaryData([{
          total_quantity: totalQuantity.toFixed(2),
          discount_total: totalDiscount.toFixed(2),
          tax_total: totalTax.toFixed(2),
          grand_total: totalAmount.toFixed(2),
          // deposit_amt: salesForm?.deposit_amt
        }]);

        // setTableFooterData

        return newData;
      });
    } else {
      toast.error(`only ${tableData.length} row is available`)
    }
  };

  // ========================   Total Calculating Functions
  // ----------------- 1. Calculate TotalAmount 
  const CalculateTotal = (record) => {
    setTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];

      item.quantity = record.quantity || 0;
      // ------ Variables 

      let totalQuantity = 0;
      let totalAmount = 0;
      let depositAmount = 0;


      newData.forEach(item => {
        if (item.quantity !== '' || item.total_amt !== '' || item.net_weight !== '') {
          totalQuantity += parseFloat(item.quantity);
          totalAmount += parseFloat(item.total_amt) || 0;
        }
      });

      // update the grand_total value in the tableSecondaryData array
      setTableSecondaryData([{
        total_quantity: totalQuantity.toFixed(2) || 0,
        grand_total: totalAmount.toFixed(2),
      }]);

      return newData;
    })
  };

  // ============  OnChange Functions  ==============

  const HandleQty = (value, record) => {
    setTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];

      item.quantity = value || 0;

      const Quantity = (item.quantity) || 0;
      const Rate = (item.rate) || 0;
      const Total = Quantity * Rate;

      item.total_amt = Total || 0;

      CalculateTotal({
        ...item,
        quantity: item.quantity,
        total_amt: Total,
      });

      return newData;
    });
  };

  const HandleRate = (value, record) => {
    setTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];

      item.rate = value || 0;
      item.total_quantity = record.total_quantity || 0;

      const Quantity = (item.quantity) || 0;
      const Rate = (item.rate) || 0;
      const Total = Quantity * Rate;

      item.total_amt = Total || 0;


      CalculateTotal({
        ...item,
        quantity: item.quantity,
        total_amt: Total,
        rate: item.rate,
        total_quantity: item.total_quantity,
      });

      return newData;
    });
  };


  const HandleDescript = (value, record) => {
    setTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];

      item.description = value;
      CalculateTotal(item)
      return newData;
    });
  };

  const HandleUnit = (value, record) => {
    setTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];
      item.unit = value || 0;

      CalculateTotal({
        ...item,
        unit: item.unit
      })

      return newData;
    });
  };

  const HandleNetWeight = (value, record) => {
    setTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];

      item.net_weight = value || 0;

      CalculateTotal({
        ...item,
        net_weight: item.net_weight,
      });
      return newData;
    });
  };

  const HandleGrossWeight = (value, record) => {
    setTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];

      item.gross_weight = value || 0;
      CalculateTotal({
        ...item,
        gross_weight: item.gross_weight
      })
      return newData;
    });
  };

  const HandleCBM = (value, record) => {
    setTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];

      item.cbm = value || 0;
      CalculateTotal({
        ...item,
        cbm: item.cbm
      })
      return newData;
    });
  };

  //========================Product Select Table================================//

  const HandleProduct = (value, record) => {

    setTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];

      const setSelectedSale = getdata.find(item => item.id === value);
      item.product_name = setSelectedSale.item_name;
      item.product = setSelectedSale.id;
      item.barcode = setSelectedSale.barcode;
      item.barcode_id = setSelectedSale.id;
      item.product_image = setSelectedSale.item_image;
      item.description = setSelectedSale.item_description;
      const isItemAlreadyAdded = newData.some(
        (item, i) => i !== index && item.product === value
      );

      if (isItemAlreadyAdded) {
        item.product_name = '';
        item.product = '';
        item.barcode = '';
        item.product_image = '';
        item.description = '';
        toast.error("Product already added in the table.");
        return newData;
      }

      CalculateTotal(item)

      return newData;
    });
  }
  //==================================================================

  //=================Barcode Select table =============================//
  const HandleBarCode = (value, record) => {
    setTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];
      const BarcodeSelect = bardata.find(item => item.id === value)

      item.product_name = BarcodeSelect.item_name;
      item.product = BarcodeSelect.id;
      item.barcode_id = BarcodeSelect.barcode;
      item.barcode = BarcodeSelect.barcode;
      item.product_image = BarcodeSelect.item_image;
      item.description = BarcodeSelect.item_description;
      const isItemAlreadyAdded = newData.some(
        (item, i) => i !== index && item.product === value

      );

      if (isItemAlreadyAdded) {
        item.product_name = '';
        item.product = '';
        item.barcode = '';
        item.product_image = '';
        item.description = '';
        item.cbm = '';
        item.gross_weight = '';
        item.net_weight = '';
        item.quantity = '';
        item.rate = '';
        item.total_amt = '';
        item.unit = '';
        toast.error("BarCode already added in the table.");
        return newData;
      }

      return newData;
    });
  }


  // ---------------- 1.TotalQuantity ONCHANGE Function


  const handleOnChangeQuantity = (value, record) => {
    HandleQty(value, record);
  };

  const handleOnChangeRate = (value, record) => {
    HandleRate(value, record);
  };
  const handleOnChangeUnits = (value, record) => {
    HandleUnit(value, record);
  };

  const handleOnChangeNetWeight = (value, record) => {
    HandleNetWeight(value, record);
  };

  const handleOnChangeGrossWeight = (value, record) => {
    HandleGrossWeight(value, record);
  };

  const handleOnChangeCBM = (value, record) => {
    HandleCBM(value, record);
  };

  //==============Unit Get =============================//

  useEffect(() => {
    handleChangeUnit();
    dispatch(getSaleUnit());
  }, [getunitData])


  const handleChangeUnit = () => {

    request.get('choice/add_measurement/')
      .then(function (response) {
        setUnitData(response.data)
        dispatch(getSaleUnit())
      })
      .catch(function (error) {
        toast.error('Failed')
      });
  }
  const handleSaleUnitGet = () => {
    setGetunitData(getunitData + 1)
  }

  //==============================================================


  //=========================Currency get ===============================//
  useEffect(() => {
    dispatch(getCurrency())
    handleChangeCurrency();
  }, [currencydatas])

  const handleChangeCurrency = () => {

    request.get('choice/add_currency/')
      .then(function (response) {
        setcurrencyData(response.data)
        dispatch(getCurrency())
      })
      .catch(function (error) {
        toast.error('Failed')
      });
  }
  const handleGetSaleCurrency = () => {
    setCurrencydatas(currencydatas + 1)
  }

  //==============================================================

  //==============Table Select Options==============================================================//

  const itemDetails = getdata?.map((com) => ({ label: com.item_name, value: com.id }));
  const barcodeOptions = bardata?.map((com) => ({ label: com.barcode, value: com.id }))

  const unitselectOptions = unitData?.map((com) => ({ label: com.unit, value: com.unit }));


  const handleOnChangeProduct = (value, record) => {
    HandleProduct(value, record)
  }

  const handleOnChangeBarCode = (value, record) => {
    HandleBarCode(value, record)

  }
  const handleOnChangeDescription = (value, record) => {
    HandleDescript(value.target.value, record)
  }

  const [sampleData, setSampleData] = useState(false);

  //====================Footer Deposit/received calc==============================//
  const [isDisabled, setIsDisabled] = useState(false);

  // Function to enable or disable elements
  const setDisabled = (value) => {
    setIsDisabled(value);
  };
  const handleDepositamr = (value) => {
    const totalall = parseFloat(form.getFieldValue('grand_total')) || 0;
    const depositamt = value || 0;

    if (depositamt === 0 || depositamt <= totalall) {
      setSampleData(false);
    } else {
      setSampleData(true);
      setDisabled(false);
      toast.warn('Received amount not greater than Total amount!');
      form.setFieldsValue({
        deposit_amt: '',
      });
    }
  };

  const [draft, setDraft] = useState('False')

  const draftChange = (values) => {
    const record = { ...values, draft: 'True' }
    request.post('sale_confirmation/add_sale_confirmation/', record)
      .then(function (response) {
        if (response.status == 201) {
          toast.success('Sales Entry Saved To Draft')
          form.resetFields();
          setTrigger((trigger) => trigger + 1);
          dispatch(getNotification())
          setTableData(initialData);
          setTableSecondaryData(secondaryData);
          setRound(false)
          handleOk()
        }
        else {
          toast.error('Something went Wrong')
        }
      })
      .catch(function (error) {
        if (error.response && error.response.status === 400) {
          if (error.response.data) {
            if (error.response.data.GSTIN) {
              toast.warn(error.response.data.GSTIN[0]);
            } else if (error.response.data.mobile_number) {
              toast.warn(error.response.data.mobile_number[0]);
            } else {
              toast.error('Failed.');
            }
          } else {
            toast.error('Failed.');
          }
        }
      });
  };

  const onResett = () => {
    handleOk()
  }

  const printSalebill = (id) => {
    setWidth(400)
    setModalTitle("Sale Print");
    setModalContent(<SalePrintConfirm id={id} />);
    showModal();
  }


  const SalePrintConfirm = ({ id }) => {
    return (
      <>
        <Flex center>
          <h1 style={{ fontSize: '20px', color: '#685e5e' }}>Do you Want to go the Print page</h1>
        </Flex>
        <Flex center gap={'50px'} style={{ margin: '20px 0px' }} >

          <Button.Primary text={'Yes'} onClick={() => navigate(`/PrintSale/${id}`)} />
          <Button.Danger text={'No'} onClick={() => onResett()} />
        </Flex>
      </>
    )
  }
  const confirmClick = (values) => {
    request.post('sale_confirmation/add_sale_confirmation/', values)
      .then(function (response) {
        if (response.status == 201) {
          toast.success('Successfully Billed Sales Entry ')
          form.resetFields();
          setTrigger((trigger) => trigger + 1);
          dispatch(getNotification())
          setTableData(initialData);
          setTableSecondaryData(secondaryData);
          setRound(false)

          if (response.data?.id) {
            printSalebill(response.data.id);
          }

        }
        else {
          toast.error('Something went Wrong')
        }
      })
      .catch(function (error) {
        if (error.response && error.response.status === 400) {
          if (error.response.data) {
            if (error.response.data.GSTIN) {
              toast.warn(error.response.data.GSTIN[0]);
            } else if (error.response.data.mobile_number) {
              toast.warn(error.response.data.mobile_number[0]);
            } else {
              toast.error('Failed.');
            }
          } else {
            toast.error('Failed.');
          }
        }
      });
  };

  const HandleModel = ({ result }) => {
    return (
      <>
        <Flex center>
          <h1 style={{ fontSize: '20px', color: '#685e5e' }}>Do you Want to Confirm or Draft</h1>
        </Flex>
        <Flex center gap={'50px'} style={{ margin: '20px 0px' }} >

          <Button.Primary text={'Confirm'} onClick={() => sc_no ? EditConfirmClick(result) : confirmClick(result)} />
          <Button.Danger text={'Draft'} onClick={() => sc_no ? EditDraftChange(result) : draftChange(result)} />
        </Flex>
      </>
    )
  }

  const EditConfirmClick = (values) => {
    request.put(`sale_confirmation/edit_sale_confirmation/${sc_no}/`, values)
      .then(function (response) {
        if (response.status == 201) {
          toast.success('Successfully Billed Sales Entry ')
          form.resetFields();
          setTrigger((trigger) => trigger + 1);
          dispatch(getNotification())
          setTableData(initialData);
          setTableSecondaryData(secondaryData);
          setRound(false)
          if (response.data?.id) {
            printSalebill(response.data.id);
          }

        }
        else {
          toast.error('Something went Wrong')
        }
      })
      .catch(function (error) {
        if (error.response && error.response.status === 400) {
          if (error.response.data) {
            if (error.response.data.GSTIN) {
              toast.warn(error.response.data.GSTIN[0]);
            } else if (error.response.data.mobile_number) {
              toast.warn(error.response.data.mobile_number[0]);
            } else {
              toast.error('Failed.');
            }
          } else {
            toast.error('Failed.');
          }
        }
      });
  }

  const EditDraftChange = (values) => {
    const record = { ...values, draft: 'True' }
    request.put(`sale_confirmation/edit_sale_confirmation/${sc_no}/`, record)
      .then(function (response) {
        if (response.status == 201) {
          toast.success('Sales Entry Saved To Draft')
          form.resetFields();
          setTrigger((trigger) => trigger + 1);
          dispatch(getNotification())
          setTableData(initialData);
          setTableSecondaryData(secondaryData);
          setRound(false)
          handleOk()
          window.history.go(-1);
        }
        else {
          toast.error('Something went Wrong')
        }
      })
      .catch(function (error) {
        if (error.response && error.response.status === 400) {
          if (error.response.data) {
            if (error.response.data.GSTIN) {
              toast.warn(error.response.data.GSTIN[0]);
            } else if (error.response.data.mobile_number) {
              toast.warn(error.response.data.mobile_number[0]);
            } else {
              toast.error('Failed.');
            }
          } else {
            toast.error('Failed.');
          }
        }
      });
  }

  const onOpenConfirm = (result) => {
    setWidth(400)
    setModalTitle("");
    setModalContent(<HandleModel result={result} />);
    showModal();
  };

  const PrintPdfOpen = (values) => {
    setWidth(800)
    setModalTitle("");
    setModalContent(<PrintSales />);
    showModal();
  };


  // ====================  On Finish Function ============

  const onFinish = (values) => {
    const record = {
      ...values, draft: 'False',
      shipping_date: values?.shipping_date === null ? '' : dayjs(selectedDates).format('YYYY-MM-DD') ? dayjs(values?.shipping_date).format('YYYY-MM-DD') : dayjs(values?.shipping_date).format('YYYY-MM-DD'),
      revised_date: values?.revised_date === null ? '' : dayjs(selectedrevisedDates).format('YYYY-MM-DD') ? dayjs(values?.revised_date).format('YYYY-MM-DD') : dayjs(values?.revised_date).format('YYYY-MM-DD'),
      sale_confirmation_date: values?.sale_confirmation_date === null ? '' : dayjs(selectedDate).format('YYYY-MM-DD') ? dayjs(values?.sale_confirmation_date).format('YYYY-MM-DD') : dayjs(values?.sale_confirmation_date).format('YYYY-MM-DD'),
    };
    let result = {
      company_name: record.company_name,
      customer_obj: record.customer_obj,
      Address: record.Address,
      customer_id: record.customer_id,
      fax: record.fax,
      draft: record.draft,
      email: record.email,
      customer_name: record.contact_person,
      contact_person: record.contact_person,
      mobile: record.mobile,
      phone_no: record.phone_no,
      payment_terms: record.payment_terms,
      sale_confirmation_no: record.sale_confirmation_no,
      sale_confirmation_date: record.sale_confirmation_date,
      shipping_date: record.shipping_date,
      shipping_mark: record.shipping_mark,
      packing: record.packing,
      remarks: record.remarks,
      origin: record.origin,
      delivery_terms: record.delivery_terms,
      ship_by: record.ship_by,
      container: record.container,
      total_quantity: record.total_quantity,
      grand_total: record.grand_total,
      deposit_amt: record.deposit_amt,
      revised_date: record.revised_date,
      currency: record.currency,
      country: record.country,
      id: selectedSale.id,


      sales: Object.entries(record)
        .filter(([key]) => key.startsWith('product_name'))
        .map(([key, product_name]) => {
          const index = key.match(/\d+/)[0];
          const itemidkey = `product${index}`;
          const barcodekey = `barcode${index}`;
          const descripKey = `description${index}`;
          const quantityKey = `quantity${index}`;
          const unitKey = `unit${index}`;
          const saleKey = `rate${index}`;
          const netweightKey = `net_weight${index}`;
          const grossweightKey = `gross_weight${index}`;
          const cbmKey = `cbm${index}`;
          const totaltaxantKey = `total_amt${index}`;
          return {
            product_name,
            barcode: record[barcodekey],
            product: record[itemidkey],
            description: record[descripKey],
            unit: record[unitKey],
            quantity: parseFloat(record[quantityKey]).toFixed(2),
            rate: parseFloat(record[saleKey]).toFixed(2),
            net_weight: record[netweightKey] || 0,
            gross_weight: record[grossweightKey] || 0,
            cbm: record[cbmKey] || 0,
            total_amt: parseFloat(record[totaltaxantKey]).toFixed(2),

          };
        }),
    };
    onOpenConfirm(result);

  }


  const onFinishFailed = (errorInfo) => {
    toast.error('Please Fill the Details')
  };


  // ==============  Add Row Component  ================

  const FooterComponent = () => {
    return (
      <div style={{ background: 'var(--light-color)', padding: '20px' }}>
        <Row>
          <Col lg={4} sm={12} span={24}><Button type="primary" style={{
            fontSize: '1rem',
            height: 'auto',
            fontFamily: 'Poppins',
            fontWeight: 500,
            letterSpacing: '1px',
          }}
            htmlType="button"
            onClick={AddRow}>
            Add Row
          </Button>
          </Col>
        </Row>
      </div >
    )
  }
  // ==================  Table  ==================
  const onRest = () => {
    form.resetFields();
    form.setFieldsValue({ invoice_no: invoiceNumber })
    setSelectedSale(!selectedSale)
    setTrigger((trigger) => trigger + 1);
    setTableData(initialData);
    setTableSecondaryData(secondaryData);
    setRound(false);
    setPayType('Cash');

  }
  //=================Orgin Set Form=======================
  useEffect(() => {
    form.setFieldsValue({origin:'CHINA'})
  }, [])
  
  return (
    <Fragment>
      <Form name="sales"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        form={form}
        initialValues={
          {
            sale_confirmation_date: dayjs(),
            payment_type: 'Cash',
            recevied_status: false,
            round_off: false,
            round_off_value: 0,
            received_amt: 0,
          }
        }
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off">

        <SalesFormHeader setShipTo={setShipTo} setAddNewPaymentTerms={setAddNewPaymentTerms} choose={choose} setChoose={setChoose} handleship={handleship} setShipState={setShipState} trigger={trigger} setScnoData={setScnoData} setSelectedrevisedDates={setSelectedrevisedDates} setSale={setSale} setSelectedDates={setSelectedDates} setSelectedDate={setSelectedDate} selectedSale={selectedSale} setSelectedSale={setSelectedSale} setInvoiceNumber={setInvoiceNumber} />
        <div style={{ margin: '20px 0' }}>
          <Table columns={columns.filter(Boolean)} data={tableData} pagination={false} />
          <FooterComponent />
        </div>
        <div style={{ margin: '20px 0' }}>
          <SalesFormFooter isDisabled={isDisabled} payType={payType} sampleData={sampleData} tableData={tableData} handleDepositamr={handleDepositamr} setPayType={setPayType} BalanceOnChange={BalanceOnChange} HandleCheQueChage={HandleCheQueChage} RoundOffChecked={RoundOffChecked} TotalBalance={TotalBalance} tableSecondaryData={tableSecondaryData} footerCalData={footerCalData} setRoundDecimalValue={setRoundDecimalValue} round={round} />
        </div>
        <Flex center={'true'} gap={'20px'} margin={'20px 0'} >
          <Button.Primary text={'SUBMIT'} htmlType="submit" />
          <Button.Danger text={'RESET'} onClick={onRest} />
        </Flex>

      </Form>

      <Modal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={width} modalTitle={modalTitle} modalContent={modalContent} />
    </Fragment>
  )
}