import React, { useEffect } from 'react'
import styled from 'styled-components'
import Flex from '../../../Components/Flex'
import SignInForm from './SignInForm'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setCredentials, selectCurrentUser, selectCurrentRole, selectCurrentSuper } from '../authSlice'
import initializeApp from '../../../utils/initializeApp'
import request from '../../../utils/request'
import { toast } from 'react-toastify'
import { THEME } from '../../../theme'
import LoginImage from '../../../Assets/img/LoginContainer.jpg'

export const Wrapper = styled(Flex)`
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  /* background:${THEME.secondary_color_dark} ; */
  background-image: url(${LoginImage});

`
const SignInCard = styled.div`
  background-color:rgba(255,255,255,0.025);
  border:1px solid rgb(255,255,255);
  border-bottom:2px solid rgba(255,255,255,0.5);
  border-right:2px solid rgba(255,255,255,0.5);
  backdrop-filter:blur(5px);
  padding: 20px;
  border-radius:0px 40px 0px 40px;
  max-width: 450px;
  width: 100%;
  margin: auto;
`

const UserSignin = () => {

  const navigate = useNavigate()

  const dispatch = useDispatch();

  const Role = useSelector(selectCurrentRole);

  const Super_r = useSelector(selectCurrentSuper);

  const handleSignIn = async (data) => {
    try {
      const authData = await request.post(`user/login`, {
        ...data,
      })

      // Mock API, add the origin API and payload data
      if (authData?.data !== '') {
        initializeApp(authData?.data) // pass the token to this function
        toast.success(`Login Successful, Welcome ${authData?.data?.username}`)
        dispatch(setCredentials(authData?.data))

        if (Role === 'Master1') {
          navigate('/') 
        }
        else if (Role === 'Master2') {
          navigate('/')
        }
        else if (Role === 'Sales') {
          navigate('/sales')
        }
        else if (Role === 'Purchase') {
          navigate('/viewconsign')
        }
        else if (Role === 'Invoice') {
          navigate('/viewconsign')
        }
        else if (Role === 'Packing') {
          navigate('/viewconsign')
        }
        else if (Super_r === true) {
          navigate('/')
        }
      }
      else {
        toast.error('UserName or Password is incorrect ')
      }

    } catch (error) {
      toast.error('Getting error while login, Please Login Again')

      console.error('Getting error while login', error)
    }
  }


  const token = useSelector(selectCurrentUser);

  useEffect(() => {
    if (token) {
      navigate('/signin')
    }
  }, [token])

  return (
    <Wrapper column>
      <SignInCard>
        <SignInForm handleSignIn={handleSignIn} />
      </SignInCard>
    </Wrapper>
  )
}
export default UserSignin
